import React from "react";
import { useEffect } from "react";
import { useState } from 'react';
import Select from 'react-select'
import { Modal, Button, ListGroup } from "react-bootstrap";
import { connect } from "react-redux";
import './manageCoe.css'
import './addUser.css'
import { addUser } from "../../../redux-container/settings/manage_coe/action";
import userService  from "../../Services/user.service";
//import Autosuggestions from "../../Commons/Autosuggestions";
//import {ListGroup } from "react-bootstrap";
import AutoSuggest from "react-autosuggest";




function AddUser(props) {

  const [show, setShow] = useState(false);
  const [inputs, setInputs] = useState({
    coeId: props.selectedRowData.coeId,
    userId: ''
  });

  const handleClose = () => {
    setShow(false)
    props.setIsOpen(false)
  };

  function onSubmit() {
    //console.log(user)
    if(inputs != '')
      props.addUser(inputs);
    handleClose()
    props.setIsOpen(false);
  }

  const [disabled, setDisabled] = useState(true);
  const [value, setValue] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  
  function getSuggestions(value) {
    console.log(value.length);
    if((value.length)>=3){
      var data = {
        val: value,
        coeId: props.selectedRowData.coeId,
        status: 'ACTIVE'
      }
      var promise  = userService.getUserSuggestions(data);
      promise.then((res) =>{
         res = res.map((res) => res);
         setSuggestions(res);
        //return lowerCasedCompanies = result;
        console.log(res);
        })
    }
    else 
    setSuggestions([]);
  }
  
function getSuggestionValue(suggestion){
  console.log(suggestion);
  setInputs(inputs => ({...inputs, userId : suggestion.id}));
  if(suggestion != ''){
    setDisabled(false);
  }
  return suggestion.firstName
  
}


  /* function handleChange(e) {  
    console.log(e);
    if(e.type === 'change'){
      if(e.nativeEvent.data != null)
        setValue(value + e.nativeEvent.data)
      else{
        setValue(value.slice(0,-1))
      }
    } 
    if(e.type === 'click')
      setValue(e.target.innerText)
    //setUser(user => ({...user, userId : e.value}));
    } */

  useEffect(() => {
    //first time show value is true 
    setShow(true)
  }, [])
  return (
    <>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{props.modalHeading}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="addUserInCoe">
          <AutoSuggest
            suggestions={suggestions}
            onSuggestionsClearRequested={() => setSuggestions([])}
            
            onSuggestionSelected={(_, { suggestionValue }) =>{
              console.log("Selected: " + suggestionValue);
            }}

            getSuggestionValue={getSuggestionValue}

            renderSuggestion={suggestion => <ListGroup>
            <ListGroup.Item >
               {suggestion.firstName}
            </ListGroup.Item>
          </ListGroup> }
          
            inputProps={{ 
              placeholder: "Enter User Name",
              value: value,
              onChange: (_, { newValue, method }) => {
                setValue(newValue);
                console.log(newValue);
              }
            }}
            onSuggestionsFetchRequested={({ value }) => {
              setValue(value);
              getSuggestions(value);
            }}
            highlightFirstSuggestion={true}
          />
          {/* <Autosuggestions component='AddUser' inputProps={inputProps} setInputs={setInputs} coeId={props.selectedRowData.coeId}/> */}
        </Modal.Body>
        <Modal.Footer>
          <Button className="cancel-btn w-70 px-4 cancel-btn lh-base btn " variant="light" onClick={handleClose}>
            Close
          </Button>
          <Button className="update-btn px-3 lh-base btn border-0" disabled={disabled?disabled:''} onClick={onSubmit}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

    </>
  );
}

const mapDispatchToProps = (dispatch) =>{
  return{
      addUser: (user) => {dispatch(addUser(user))},
  }
}

export default connect(null, mapDispatchToProps)(AddUser);


