export const groupMoment = {
    "data": {
    "OPENING": [
            {
                "aI": 1123.0,
                "globalAverage": 54.18,
                "isActive": true,
                "manualV": 1123.0,
                "momentId": 1123.0,
                "momentName": "Self introduction",
                "momentbucketName": "Sales Moment Bucket",
                "negativeRemark": "Self introduction did not mentioned",
                "observed": 1.0,
                "user": 1123.0,
                "weightage": 1.0
            },
            {
                "aI": 1124.0,
                "globalAverage": 72.14,
                "isActive": true,
                "manualV": 1124.0,
                "momentId": 1124.0,
                "momentName": "Start Greeting",
                "momentbucketName": "Sales Moment Bucket",
                "negativeRemark": "Relevant Opening  did not mentioned",
                "observed": 1.0,
                "user": 1124.0,
                "weightage": 1.0
            }
        ],

        "PROCESS FOLLOWED": [
            {
                "aI": 1125.0,
                "globalAverage": 100.0,
                "isActive": true,
                "manualV": 1125.0,
                "momentId": 1125.0,
                "momentName": "Acknowledgment",
                "momentbucketName": "Sales Moment Bucket",
                "negativeRemark": "Acknowledgment was not done",
                "user": 1125.0,
                "weightage": 1.0
            },
            {
                "aI": 1126.0,
                "globalAverage": 5.36,
                "isActive": true,
                "manualV": 1126.0,
                "momentId": 1126.0,
                "momentName": "Empathy",
                "momentbucketName": "Sales Moment Bucket",
                "negativeRemark": "Empathy not done",
                "observed": 0.0,
                "user": 1126.0,
                "weightage": 1.0
            },
            {
                "aI": 1127.0,
                "globalAverage": 21.88,
                "isActive": true,
                "manualV": 1127.0,
                "momentId": 1127.0,
                "momentName": "Issue Identification",
                "momentbucketName": "Sales Moment Bucket",
                "negativeRemark": "Issue Identification is not mentioned",
                "user": 1127.0,
                "weightage": 1.0
            },
            {
                "aI": 1128.0,
                "globalAverage": 99.28,
                "isActive": true,
                "manualV": 1128.0,
                "momentId": 1128.0,
                "momentName": "Correct Response",
                "momentbucketName": "Sales Moment Bucket",
                "negativeRemark": "Correct Response not mentioned",
                "user": 1128.0,
                "weightage": 1.0
            }
        ],
        "CLOSING": [
            {
                "aI": 1129.0,
                "globalAverage": 36.55,
                "isActive": true,
                "manualV": 1129.0,
                "momentId": 1129.0,
                "momentName": "Relevant closing",
                "momentbucketName": "Sales Moment Bucket",
                "negativeRemark": "Relevant closing was not there",
                "observed": 1.0,
                "user": 1129.0,
                "weightage": 2.0
            },
            {
                "aI": 1130.0,
                "globalAverage": 85.45,
                "isActive": true,
                "manualV": 1130.0,
                "momentId": 1130.0,
                "momentName": "End Greeting",
                "momentbucketName": "Sales Moment Bucket",
                "negativeRemark": "End Greeting  did not shared",
                "observed": 1.0,
                "user": 1130.0,
                "weightage": 1.0
            }
        ]
        
        
        
        ,"postTypeComments": {}
    },
    "status": 0,
    "message": "Success"
}