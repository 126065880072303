import React, { useState } from "react";
import Carousel from "react-elastic-carousel";
import { Card, Stack, Col, Row, Popover, OverlayTrigger, Button, ButtonToolbar } from "react-bootstrap"
import Loading from "../../Commons/Loading";
import totalCalls from "../../../assets/images/totalCalls.png"
import duration from "../../../assets/images/totalDuration.png"
import { useHistory } from "react-router-dom";
import NodataFound from "../../Commons/NoDataFound";

export default function ActivityData(props){

	const [carouselData, setCarouselData] = useState([{ name: "", }])
    const [show, setShow] = useState(false)
    const [popoverStates, setPopoverStates] = useState({});
    const storedData = JSON.parse(localStorage.getItem("AGENT"))
    const rdata = JSON.parse(localStorage.getItem("ROUTING_PERMISSIONS"))

    const handleClick = ()=>{
        setShow(true)
    }

    const history = useHistory()
    const callHandler = (id,name,empId)=>{
        localStorage.setItem("AGENT",JSON.stringify({
            "pageSelected":'',
            "analysis":storedData?.analysis,
            "productivity":storedData?.productivity,
            "fromD":storedData?.fromD,
            "toD":storedData?.toD,
            "agentDetailCOE":storedData?.agentDetailCOE,
            "coe":storedData?.coe,
            "selectedCOE":storedData?.selectedCOE,
            "selectedCoeRange":storedData?.selectedCoeRange,
            "userIds":storedData?.userIds,
            "userNames":storedData?.userNames,
            "empId":storedData?.employeeId,
            "empName":storedData?.user,
            "role":storedData?.role,
            "designation":storedData?.designation,
            "type":storedData?.type,
            "teamCOE":storedData?.teamCOE,
            "callsFrom":storedData?.callsFrom,
            "callsTo":storedData?.callsTo,
            "aht":storedData?.aht,
            "callScore":storedData?.callScore,
            "pitchScore":storedData?.pitchScore,
            "customerName":storedData?.customerName,
            "customerMobile":storedData?.customerMobile,
            "fileName":storedData?.fileName,
            "sellerROS":storedData?.sellerROS,
            "sellerName":[id],
            "selectedSeller":`${name}(${empId})`,
            "cScore":storedData?.cScore,
            "callHealth":storedData?.callHealth,
            "callDuration":storedData?.callDuration,
            "words":storedData?.words,
            "moment":storedData?.moment,
            "momentId":storedData?.momentId,
            // "coeName":this.state.coe,
            "pitch":storedData?.pitch,
            "prob":storedData?.prob,
            "opportunity":storedData?.opp,
            "urgency":storedData?.urgency,
            "selectedDate":storedData?.selectedDate,
            "selectedRange":storedData?.selectedRange,
            "momentBucket":storedData?.momentBucket,
            "momentBucketId":storedData?.momentBucketId,
            "frequency":storedData?.frequency,
            "reportType":storedData?.reportType
        }))
        if(JSON.parse(localStorage.getItem("USER_DETAIL"))?.accountType=='SALES'){
            history.push( rdata && rdata.Conversations && Array.isArray(rdata.Conversations) && rdata.Conversations.length > 1 ? `/voice?redirected=${id}`:`/sales_conversations?redirected=${id}`,{
                employeeId:id,
                employeeName:name,
                idOfEmployee:empId
            })
            history.replace(rdata && rdata.Conversations && Array.isArray(rdata.Conversations) && rdata.Conversations.length > 1 ? `/voice?redirected=${id}`:`/sales_conversations?redirected=${id}`, {
                // employeeId: id,
                // employeeName: name,
                // idOfEmployee: empId
              });
        }
        else{
            history.push(`/support_conversations`,{
                employeeId:id,
                employeeName:name,
                idOfEmployee:empId
            })
        }
    }

    const agentHandler = (id,userExternalId,phone) =>{
        history.push(`/team_member_detail?id=${id}&agentExternalId=${userExternalId}&phone=${phone}`)
    }


    const handleMouseEnter = (id) => {
        setPopoverStates({ ...popoverStates, [id]: true });
      };
    
      const handleMouseLeave = (id) => {
        setPopoverStates({ ...popoverStates, [id]: false });
      };
    

    const popoverr = (name,employeeId,call,pitch,id,index,userExternalId,phone,empId)=>(
        <Popover onMouseEnter={() => handleMouseEnter(index)} onMouseLeave={() => handleMouseLeave(index)}  id={`popover-${index}`} style={{"marginLeft":"13px"}}>
            <h3 className="popover-header">Call Detail</h3>
            <div className="popover-body">
                <div className="popover-body-call">
                    <p className="mb-0"><i className="bx bx-user font-16 align-middle me-1"></i>{`${name}(Agent)`}</p>
                    <p className="mb-0"><i className="bx bx-stats font-16 align-middle me-1"></i>Call Score:<span className="ms-1">{call?`${call}%`:"0%"}</span></p>
                    {JSON.parse(localStorage.getItem("USER_DETAIL"))?.clientConfig?.data?.conversationListing.includes("PITCH SCORE")?<p className="mb-0"><i className="bx bx-stats font-16 align-middle me-1"></i>Pitch Score:<span className="ms-1">{pitch?`${pitch}%`:"0%"}</span></p>:""}

                </div>
                <div className="popover-footer text-center mt-3">
                    <a className="rounded-pill btn btn-outline-success fw-normal align-items-center" target="_blank" onClick={()=>{callHandler(employeeId,name,empId)}}>View Call<i className="bx bx-link-external ms-1 me-0"></i></a>
                </div>
                <div className="popover-footer text-center mt-2">
                    <a className="rounded-pill btn btn-outline-success fw-normal align-items-center" target="_blank" onClick={()=>{agentHandler(employeeId,userExternalId,phone)}}>View Agent<i className="bx bx-link-external ms-1 me-0"></i></a>
                </div>
            </div>
        </Popover>
    )

    function secondsToHms(d) {
        d = Number(d);
        var h = Math.floor(d / 3600);
        var m = Math.floor(d % 3600 / 60);
        var s = Math.floor(d % 3600 % 60);
        var hDisplay = h > 0 ? h + (h == 1 ? " Hr. " : " Hrs. ") : "";
        var mDisplay = m > 0 ? m + (m == 1 ? " Min. " : " Mins. ") : "";
        var sDisplay = s > 0 ? s + (s == 1 ? " Sec." : " Secs.") : "";
        return hDisplay + mDisplay + sDisplay; 
    }

    return(
        props.loader?(
            <div className="loader-container">
                <Loading variant="dark" />
            </div>
            ):(
                <>
                   {props?.data?.length>0? <Carousel pagination={false} itemsToShow={3}>
                        {props?.data?.length>0?props.data?.map((item,index) => {
                            var shortName = item?.agentName.split(' ')
                            shortName = shortName.length>1?shortName.shift().charAt(0) + shortName.pop().charAt(0):shortName.pop().charAt(0);
                            shortName = shortName.toUpperCase();
                            return (
                        
                            <Card className="w-100 me-3 my-2 no-shadow">
                                <Card.Body className="activityLog p-0">
                                    <div class="d-flex item">
                                        <div class="radius-10 w-100 m-1 b-shadow">
                                            <div class="card-body">
                                                <div class="d-flex align-items-center">
                                                    <div class="user-box dropdown border-light-2 ms-0">
                                                        <div class="d-flex align-items-center" href="javascript:;" role="button">
                                                            <p class="user-header-avatat">{shortName?shortName:'_'}</p>
                                                            {/* <!-- <img src="assets/images/avatars/avatar-2.png" class="user-img" alt="user avatar"> --> */}
                                                            <div class="user-info ps-4">
                                                                <p class="user-name mb-0">{item?.agentName}</p>
                                                                <p class="designattion mb-0">{item?.email}</p>
                                                                {/* <p class="designattion mb-0">+91-8004686403</p> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="best-selling-products p-3 mb-3 height-auto">
                                                {item.callDuration?<div class="d-flex align-items-center">
                                                    <div class="product-img">
                                                        <i class="font-28 text-primary-blue bx bx-phone-call font-32"></i>
                                                        {/* <!-- <img src="assets/images/icons/telephone.png" class="p-1" alt="" /> --> */}
                                                    </div>
                                                    <div class="ps-3">
                                                        <div class="d-flex justify-content-between">
                                                            <h6 class="mb-0 font-weight-bold justify-content-start">Average Call Duration</h6>
                                                        </div>
                                                        <div>
                                                            <p class="mb-0 text-secondary mx-1"><span class="time">{secondsToHms(item.callDuration)}</span></p>
                                                        </div>
                                                        {/* <p class="ms-auto mb-0 text-purple text-nowrap"><span class="time me-1">{item.date}</span>
                                                        </p> */}
                                                    </div>
                                                    <ButtonToolbar>
                                                        <OverlayTrigger key={index} trigger="manual" show={popoverStates[index]} placement="bottom" overlay={popoverr(item?.agentName,item.userId,item.callScore,item.pitchScore,item.callId,index,item?.userExternalId,item?.phone,item?.employeeId)} rootClose>
                                                            <button onMouseEnter={() => handleMouseEnter(index)} onMouseLeave={() => handleMouseLeave(index)} className="mb-0 ms-3 font-22" style={{"border":"none"}}>
                                                                <i className="fadeIn animated bx bx-info-circle text-primary-blue"></i>
                                                            </button>
                                                        </OverlayTrigger>
                                                    </ButtonToolbar>
                                                    {/* <a href="javascript:;" class="mb-0 text-secondary ms-3 font-22" data-bs-toggle="popover" title="Call Duration" onClick={handleClick}>
                                                        <i class="fadeIn animated bx bx-info-circle text-primary-blue"></i>
                                                    </a> */}
                                                    
                                                </div>:""}
                                                <hr/>
                                                {item.totalCallDuration?<div className="d-flex align-items-center ">
                                                    <div class="product-img">
                                                        <img src={duration}/>
                                                        {/* <!-- <img src="assets/images/icons/telephone.png" class="p-1" alt="" /> --> */}
                                                    </div>
                                                    <div class="ps-4" style={{marginLeft:"-8px"}}>
                                                        <div class="d-flex justify-content-between">
                                                            <h6 class="mb-0 font-weight-bold justify-content-start">Total Call Duration</h6>
                                                        </div>
                                                        <div>
                                                            <p class="mb-0 text-secondary mx-1"><span class="time">{secondsToHms(item.totalCallDuration)}</span></p>
                                                        </div>
                                                        {/* <p class="ms-auto mb-0 text-purple text-nowrap"><span class="time me-1">{item.date}</span>
                                                        </p> */}
                                                    </div>
                                                </div>:""}
                                                <hr/>
                                                {item.totalCallVolume?<div class="d-flex align-items-center">
                                                    <div class="product-img font-32">
                                                        <img src={totalCalls}/>
                                                        {/* <i class="text-primary-blue lni lni-dinner font-32"></i> */}
                                                        {/* <!-- <img src="assets/images/icons/plate.png" class="p-1" alt="" /> --> */}
                                                    </div>
                                                    <div class="ps-4" style={{marginLeft:"-8px"}}>
                                                        <div class="d-flex justify-content-between">
                                                            <h6 class="mb-0 font-weight-bold justify-content-start">Total Call Volume</h6>
                                                        </div>
                                                        <div>
                                                            <p class="mb-0 text-secondary mx-1"><span class="time">{item.totalCallVolume}</span></p>
                                                        </div>
                                                        {/* <p class="ms-auto mb-0 text-purple text-nowrap"><span class="time me-1">{item.date}</span>
                                                        </p> */}
                                                    </div>
                                                </div>:""}
                                                <hr/>
                                            </div>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        
                        )}):<NodataFound/>}
                    </Carousel>
                    :<NodataFound />}
                </>
            )
        )}