import * as React from "react"
import { useState, useEffect, useRef, useLayoutEffect } from "react"
import callsService from "../../Services/calls.service"
import Loading from "../Loading"
import linkIcon from "../../../assets/images/linkIcon.png"
import { Card, Col, Row } from "react-bootstrap"
import Carousel from "react-elastic-carousel";
import phone from "../../../assets/images/callList-images/phone.svg"
import user from "../../../assets/images/callList-images/user.svg"
import { useHistory } from "react-router-dom"
import NoDataFound from "../NoDataFound"
import PieChart, {
    CommonSeriesSettings,
    Legend,
    Series,
    Export,
    Label,
    Title,
    Tooltip,
    Subtitle,
} from 'devextreme-react/pie-chart';
import 'devextreme/dist/css/dx.light.css'

export default function AgentRecommendation(props) {
    const [loader, setLoader] = useState(false)
    const [recommendation, setRecommendation] = useState([])
    const [agentRecommendation, setAgentRecommendation] = useState({})
    const storedData = JSON.parse(localStorage.getItem("AGENT"))
    const chartRef = useRef(null);
    const [isChartReady, setChartReady] = useState(false);
    const [legendColors, setLegendColors] = useState([]);
    const history = useHistory()

    let values = {
        "clientExternalId": JSON.parse(localStorage.getItem("USER_DETAIL"))?.externalId,
        "coeIds": props?.filteredData ? props.filteredData.coeId ? props.filteredData.coeId : props.defaultFilterData?.coeIds : props.defaultFilterData?.coeIds,
        "fromD": JSON.parse(localStorage.getItem("AGENT"))?.fromD,
        "toD": JSON.parse(localStorage.getItem("AGENT"))?.toD,
        "userId": props?.userId,
    }

    const getRecommendation = () => {
        setLoader(true)
        callsService.getTopThreeCalls(values).then(res => {
            console.log(res)
            if (res) {
                if (res?.data?.status === 0) {
                    setRecommendation(res?.data?.data?.data)
                    callsService.getAgentRecommendation(values).then(res => {
                        if (res) {
                            console.log(res)
                            setAgentRecommendation(res?.data?.data)
                            setLoader(false)
                        }
                    })
                }
                else {
                    setRecommendation([])
                    callsService.getAgentRecommendation(values).then(res => {
                        if (res) {
                            console.log(res)
                            setAgentRecommendation(res?.data?.data)
                            setLoader(false)
                        }
                    })
                }
            }
        })
    }

    // for default filter valuesDef is used
    let valuesDef = {
        "clientExternalId": JSON.parse(localStorage.getItem("USER_DETAIL"))?.externalId,
        "coeIds": props.defaultFilterData?.coeIds,
        "fromD": storedData && storedData.fromD ? storedData?.fromD : JSON.parse(localStorage.getItem("AGENT"))?.fromD,
        "toD": storedData && storedData.toD ? storedData?.toD : JSON.parse(localStorage.getItem("AGENT"))?.toD,
        "userId": props?.userId,
    }

    const getDefRecommendation = () => {
        setLoader(true)
        callsService.getTopThreeCalls(valuesDef).then(res => {
            console.log(res)
            if (res) {
                if (res?.data?.status === 0) {
                    setRecommendation(res?.data?.data?.data)
                    callsService.getAgentRecommendation(valuesDef).then(res => {
                        if (res) {
                            console.log(res)
                            setAgentRecommendation(res?.data?.data)
                            setLoader(false)
                        }
                    })
                }
                else {
                    callsService.getAgentRecommendation(valuesDef).then(res => {
                        if (res) {
                            console.log(res)
                            setAgentRecommendation(res?.data?.data)
                            setLoader(false)
                        }
                    })
                }
            }
        })
    }


    useEffect(() => {
        if ((props?.keySelected == 'recommendation') && (!props.filteredData))
            getDefRecommendation()
    }, [props?.keySelected == 'recommendation', props.defaultFilterData])

    useEffect(() => {
        if (props?.keySelected == 'recommendation' && props.filteredData)
            getRecommendation()
    }, [props?.keySelected == 'recommendation', props.filteredData])


    const clickHandler = (phone) => {
        history.push(`${JSON.parse(localStorage.getItem("USER_DETAIL"))?.externalId == '8387430' ? '/voice' : `/sales_conversations?redirected=${phone}`}`, {
            agentPhone: phone
        })
    }

    // const callCountData = agentRecommendation!='{}'&&Object.keys(agentRecommendation).length>0&&Object.entries(agentRecommendation?.callCount)?.map(item=>{
    //     return{"Country":item[0],"Export":item[1]}
    // })

    const callCountData =
        agentRecommendation !== null &&
        typeof agentRecommendation === 'object' &&
        Object.keys(agentRecommendation).length > 0 &&
        Object.entries(agentRecommendation?.callCount)?.map(item => {
            return { "Country": item[0], "Export": item[1] };
        });


    const contentTemplate = (data) => {
        return `${data.argumentText}<br>(${data.originalValue}%)`
    }


    // const legendData = callCountData?.map((item, index) => ({
    //     color: getSegmentColor(index), // Replace with your logic to get the color for each segment
    //     label: item[0],
    // }));



    // useEffect(() => {
    //     if (chartRef.current && chartRef.current.instance) {
    //         const chart = chartRef.current.instance;

    //         const waitForChartReady = () => {
    //             if (chart.getAllSeries().length > 0) {
    //               const series = chart.getAllSeries()[0];
    //               const segmentColors = series.getPoints().map(point => point.getColor());
    //               setLegendColors(segmentColors);
    //             } else {
    //               setTimeout(waitForChartReady, 100);
    //             }
    //         };
    //         waitForChartReady()

    //         // const series = chart.getAllSeries()[0];
    //         // const segmentColors = series.getPoints().map(point => point.getColor());
    //         // console.log(segmentColors)
    //         // setLegendColors(segmentColors);
    //     }
    // }, []);

    const handleChartInitialized = (e) => {
        setTimeout(() => {
            const chart = chartRef?.current?.instance;
            console.log(chart)
            if (chart) {
                const series = chart?.getAllSeries()[0];
                if (series) {
                    const segmentColors = series?.getPoints().map((point) => point?.getColor());
                    setLegendColors(segmentColors);
                }
            }
        }, 1000);
    };


    console.log(agentRecommendation != {} ? true : "dkjnwend")



    return (
        <>
            {loader ? (
                <div className="loader-container">
                    <Loading variant="dark" />
                </div>
            ) :
                <>
                    {recommendation && recommendation.length > 0 || agentRecommendation && Object.keys(agentRecommendation).length > 0 ?
                        <>
                            {recommendation && recommendation.length > 0 && <div style={{ marginTop: "20px", border: "1px solid #E1E1E1", borderRadius: "5px" }}>
                                <Row>
                                    <Col lg={12} style={{ paddingLeft: "22px", paddingTop: "19px" }}>
                                        <Row>
                                            <Col lg={12} className="mb-2">
                                                <h6 className="d-inline mt-3">Top 3 Calls</h6>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col lg={12} style={{ paddingLeft: "27px", paddingRight: "27px", paddingBottom: "12px" }}>
                                        <Row>
                                            <Col lg={12} className="agentRecom">
                                                <Carousel pagination={false} itemsToShow={3} showArrows={false}>
                                                    {recommendation.map(item => {
                                                        return (
                                                            <Card className="w-100 me-3 my-2">
                                                                <Card.Body className="salesCallCard">
                                                                    <div className="user">
                                                                        <div className="caller" style={{ width: "70%" }}>
                                                                            <div className="name">
                                                                                <span>
                                                                                    <img src={user} alt="" />
                                                                                </span>
                                                                                <span className="text">{item?.name}</span>
                                                                            </div>
                                                                            <div className="rank" style={{ cursor: "pointer" }}>{`#${item?.callId}`}</div>
                                                                        </div>
                                                                        <div className="callCount">
                                                                            {item?.date}
                                                                        </div>
                                                                    </div>
                                                                    <hr />
                                                                    <div className="seller">
                                                                        <div className="number">
                                                                            <span className="icon">
                                                                                <img src={phone} alt="" />
                                                                            </span>
                                                                            {item?.mobile}
                                                                        </div>
                                                                        <div>
                                                                            <button className="groupCall-recommendation" onClick={() => { clickHandler(item?.mobile) }}>
                                                                                View Group Call
                                                                                <img src={linkIcon} alt="" style={{ paddingLeft: "3px" }}></img>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </Card.Body>
                                                            </Card>
                                                        )
                                                    })}
                                                </Carousel>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </div>}
                            {agentRecommendation && Object.keys(agentRecommendation).length > 0 && <div style={{ marginTop: "20px", border: "1px solid #E1E1E1", borderRadius: "5px" }}>
                                <Row>
                                    <Col lg={4}>
                                        <h6 style={{ padding: "12px" }}>Overall Remarks</h6>
                                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                            <PieChart
                                                ref={chartRef}
                                                id="pie"
                                                type="doughnut"
                                                innerRadius={0.2}
                                                palette="Material"
                                                dataSource={callCountData}
                                                onDrawn={handleChartInitialized}
                                            >
                                                <CommonSeriesSettings>
                                                    <Label visible={false} />
                                                </CommonSeriesSettings>
                                                <Series name="Export" argumentField="Country" valueField="Export" />
                                                <Series name="Import" argumentField="CountryImp" valueField="Import" />

                                                <Export enabled={false} />
                                                <Legend visible={false} />

                                                <Tooltip enabled={true} contentTemplate={contentTemplate} />
                                            </PieChart>
                                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                {callCountData && callCountData.map((item, index) => (
                                                    <div key={index} style={{ margin: '0 10px' }}>
                                                        <div style={{ backgroundColor: legendColors[index], width: '10px', height: '10px', display: 'inline-block', marginRight: '5px' }}></div>
                                                        <span>{item?.Country}</span>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </Col>
                                    <Col lg={8} style={{ height: "70vh", overflow: "auto" }}>
                                        <div style={{ display: "flex" }}>
                                            <div style={{ overflowX: "auto", flex: 1 }}>
                                                <table className="table table-bordered mb-0">
                                                    <thead className="fixed-header-table">
                                                        <tr className="agent-table">
                                                            <th>Good Remarks</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="fixed-header-table">
                                                        {agentRecommendation.goodCall.map(item => {
                                                            return (
                                                                <tr>
                                                                    <td style={{ fontSize: "11px" }}>
                                                                        {item?.positiveRemark}
                                                                        <br />
                                                                        <span style={{ color: "#2DE434", fontSize: "11px" }}>{`${item?.value}% (${item?.count}/${item?.totalCount})`}</span>
                                                                    </td>
                                                                </tr>)
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div style={{ overflowX: "auto", flex: 1 }}>
                                                <table className="table table-bordered mb-0">
                                                    <thead className="fixed-header-table">
                                                        <tr className="agent-table">
                                                            <th>Average Remarks</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="fixed-header-table">
                                                        {agentRecommendation.avgCall.map(item => {
                                                            return (
                                                                <tr>
                                                                    <td style={{ fontSize: "11px" }}>
                                                                        {item?.positiveRemark}
                                                                        <br />
                                                                        <span style={{ color: "#FFD646", fontSize: "11px" }}>{`${item?.value}% (${item?.count}/${item?.totalCount})`}</span>
                                                                    </td>
                                                                </tr>)
                                                        })
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div style={{ overflowX: "auto", flex: 1 }}>
                                                <table className="table table-bordered mb-0">
                                                    <thead className="fixed-header-table">
                                                        <tr className="agent-table">
                                                            <th>Bad Remarks</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="fixed-header-table">
                                                        {agentRecommendation?.badCall?.map(item => {
                                                            return (
                                                                <tr>
                                                                    <td style={{ fontSize: "11px" }}>
                                                                        {item?.positiveRemark}
                                                                        <br />
                                                                        <span style={{ color: "#FF2121", fontSize: "11px" }}>{`${item?.value}% (${item?.count}/${item?.totalCount})`}</span>
                                                                    </td>
                                                                </tr>)
                                                        })
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>}
                        </>
                        : <NoDataFound />
                    }
                </>
            }
        </>
    )
}
