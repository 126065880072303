import React from "react";
import { useEffect } from "react";
import { useState } from 'react';
import { Modal, Button } from "react-bootstrap";

export default function Recommendations(props){
    const [show, setShow] = useState(props.setIsOpen(true));

  const handleClose = () => {
    setShow(false)
    props.setIsOpen(false)
  };

  function deleteCoe() {
    handleClose()

  }
  useEffect(() => {
    setShow(true)
  }, [])
  return (
    <>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            {/* {props.modalHeading} */}
           Recommendations</Modal.Title>
        </Modal.Header>
        <Modal.Body>{
          <ul>
          <li>Contrary to popular belief, Lorem is not simply random text.</li>
          <li>Contrary to popular belief, Lorem is not simply random text.</li>
          <li>Contrary to popular belief, Lorem is not simply random text.</li>
          <li>Contrary to popular belief, Lorem is not simply random text.</li>
          <li>Contrary to popular belief, Lorem is not simply random text.</li>
      </ul>

        }</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
       
        </Modal.Footer>
      </Modal>

    </>
  );
}


