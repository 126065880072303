import ReactApexChart from "react-apexcharts";
import React,{useState, useEffect} from "react"
import Loading from "../../Commons/Loading";
import Pagination from "../../Commons/Table/Pagination";
import NodataFound from "../../Commons/NoDataFound";

export default function QuestionsGraph(props){ 
  let PageSize = 10;
  const pageCount = props?.filter?.length>0?(props.filter.length/PageSize):0
  const [currentPage, setCurrentPage] = useState(0);
  const [currentPages, setCurrentPages] = useState(0);
  const [pageIndex, setPageIndex] = useState(0);
  const [data, setData] = useState([])
  const [canNextPage, setCanNextPage] = useState(true);
  const [canPreviousPage, setCanPreviousPage] = useState(false);

  useEffect(()=>{     
      setData(props?.filter?.length>0?props.filter.slice(0,10): [])
      setPageIndex(0)
      setCurrentPages(pageCount)
  },[props.filter])

  const nextPage = () =>{
    if(pageIndex < pageCount-1){
     let a = (pageIndex+1)*PageSize;
     setData(props.filter.slice(a,a+10))
     setCanPreviousPage(true)
    }
    else{
      setCanNextPage(false);
      setPageIndex(pageIndex+1)
    }
  }

  const previousPage = ()=>{
    if(pageIndex > 0){
      let a = (pageIndex)*PageSize;
      setData(props.filter.slice(a-10,a))
      setCanNextPage(true)
      }
      else{
        setCanPreviousPage(false);
      }
  }

  const gotoPage = (val) =>{
    let a = val*PageSize;
    setPageIndex(val)
    setData(props.filter.slice(a,a+10))
  }

  const changePageIndex = (val) =>{
    let a = val * PageSize;
    setPageIndex(val);
    setData(props?.filter?.slice(a, a + 10));
  }

  console.log(props?.filter)

  let questionCount = data?.map(item => item.totalCount !== 0 ? item.totalCount : "")
  console.log(questionCount)

  let question = data?.map(item => item.questions)

  console.log(props)

    const series = [
        {
          name: "Question Count",
          data: questionCount,
        },
      ];
      const options = {
        chart: {
          toolbar: {
            show: false,
          },
          width:300,
        },
        // colors: ["#0d6efd"],
        dataLabels: {
          enabled: false,
        },
        fill: {
          color: '#0077B6',
          opacity: 1,
        },
        plotOptions: {
          bar: {
            columnWidth: "10%",
            horizontal: true,
            opacity: '1',
          },
    
        },
        grid: {
          yaxis: {
            lines: {
              show: true,
            },
          },
          xaxis: {
            // reversed: true,
            lines: {
              show: false,
            },
          },
        },
        color: ['#0077B6'],
        xaxis: {
          categories: question,
          title: {
            text: "Question Count",
            style: {
              color: "#666666",
              fontSize: "11px",
              textAlign: "right"
            }
          },
          // tickAmount: (Math.max(...questionCount) / 2)
        },
        yaxis: {
          labels: {
            style: {
              fontSize: '11.5px', // Adjust the font size as needed
            },
          },
        },
      };

    console.log(series)

    return(
      <>
      {
      series[0].data.length>0 ?
      (
        <div className="p-4">
            <ReactApexChart type={'bar'} options={options} series={series} height="450"/>
            <Pagination
            className="pagination-bar mb-1"
            currentPage={currentPage}
            totalCount={props?.filter.length}
            pageSize={PageSize}
            pageIndex={pageIndex}
            currentPages={currentPages}
            nextPage={nextPage}
            previousPage={previousPage}
            gotoPage={gotoPage}
            canPreviousPage={canPreviousPage}
            canNextPage={canNextPage}
            handlePageIndex={changePageIndex}
          /> 
        </div>)
      :<NodataFound/>
      }
      </>
    )}