import * as React from 'react'
import { Card } from "react-bootstrap";
// import {GetTokens} from "./Firebase"
import { useState, useEffect } from 'react';
import toast, { Toaster } from 'react-hot-toast';
// import {onMessageListener } from './Firebase';
import PaginationAlter from '../Commons/Table/PaginationAlter';
import NotificationDetail from './NotificationDetail';

export default function Notifications(props) {

    const [currentPage, setCurrentPage] = useState(1)
    const callBack = (e)=>{
        setCurrentPage(e)
    }
    useEffect(() => {
        document.title = "Notification  - Odio"
    }, [])

  return (

        <>
        <div className="page-wrapper">
            <div className="page-content">
                <div className="row row-cols-1 row-cols-md-2 row-cols-xl-4">
                    <div className="col-12 mb-2">
                        <div className="row">
                            <div className="col-md-12">
                                <h2 className="dashboard-headings mb-0 pb-1">Notifications</h2>
                            </div>
                        </div>
                    </div>

                    <Toaster/>

                    <div className="col-12 mb-2">
                        <Card className="notification-card">
                            <Card.Body> 
                                <NotificationDetail/>
                            </Card.Body>
                        </Card>
                    </div>
                </div>

            {/* <div className="row row-cols-1 row-cols-md-2 row-cols-xl-4">
                <div className="col-12 mb-2">
                <div className="row">
                    <div className="col-md-12">
                    <h2 className="dashboard-headings mb-0 pb-1">Earlier Notifications</h2>
                    </div>
                </div>
                </div>
            </div> */}
            {/* <PaginationAlter
                callBack={callBack} 
                availablePages={1}
            /> */}
            </div>
        </div>   
    </>
  )
}
