export const transcript = {
    "data": {
        "customerName": "joseph",
        "sellerName": "Devis",
        "sequence": [
            {
                "index": 0,
                "speaker": "customer",
                "time": "00:00:00",
                "endTime": "00:00:02",
                "trans": `Hi,

 I want to inquiry about the process for claim under my policy.
My policy Number is PBCY371FGU73K7J.  Unfortunately, I recently experienced some health issue, and I would like guidance on how to proceed with the claim.

Could you please provide me the process and documents list? 
 if there is a specific form I need to fill, Please provide?

Thank you.

Best regards,
Joseph`,
            },
            {
                "index": 1,
                "speaker": "seller",
                "time": "00:00:03",
                "endTime": "00:00:05",
                "trans": `Dear Joseph,

Thank you for reaching out to us regarding your claim inquiry. We understand that this can be a stressful time, and we're here to assist you.

To initiate the claims process, please find attached the claims form that needs to be completed. Additionally, make sure to include any relevant supporting documentation, such as receipts, police reports, or medical records, depending on the nature of the claim.

Once you've gathered all the necessary information, you can submit the completed form and documentation through our online claims portal or by replying to this email with scanned copies.

If you have any further questions or need clarification on any of the steps, feel free to reach out. We're here to help!

Best regards,
Devis
`
            },
            
        ],
        "postTypeComments": {
            "0": [
                {
                    "id": 89,
                    "name": "comment",
                    "postId": "0",
                    "createdAtString": "2023-03-12T10:44:01.000+00:00",
                    "firstName": "Insurance",
                    "lastName": "Demo",
                    "createdAt": null
                }
            ]
        },
        "message": null
    },
    "status": 0,
    "message": "Success"
}