import {SET_CALL_SCORE, CLEAR_CALL_SCORE } from './type';

const initialState = {
    callScore: 0,
};

  
const callListReducer = (state = initialState, action) => {
    switch (action.type) {
        case  SET_CALL_SCORE:
            return {
                ...state,
                callScore: action?.payload,
            }
    
        case CLEAR_CALL_SCORE:{
            return {
                ...state,
                callScore: 0
            }
        }
        default:{
            return state
        }
    }
}

export default callListReducer;