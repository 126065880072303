import * as React from 'react'
import Loading from '../../Commons/Loading';
import NodataFound from '../../Commons/NoDataFound';
import ReactTable from '../../Commons/Table/ReactTable'

export default function QuestionsData(props){
  
    const saleColumn = [
        {
          Header: "Id",
          accessor: "id",
        },
        {
          Header: "Question",
          accessor: (d) => (d.questions),
        },
        {
          Header: "Question Count",
          accessor: (d)=>(d.totalCount),
        },
      ];

      console.log(props.data)
   
    return(
        <>
        {props.loader?(
            <div className='loader-container'>
                <Loading  variant="dark"/>
            </div>
        ):props?.data?.length>0?(
        <div className="table-responsive">
            <div className="table-columns-filter-btn">
                <div className="table-responsive dataTable-style react-table">
                    <ReactTable
                        columns={saleColumn}
                        componentType="PRODUCTIVITY"
                        data={props.data}
                        // isExcelPdfButton={true}
                        isDisable={props.data.length==0||props.data==undefined?true:false}
                    />
                </div>
            </div>
        </div>
         ):<NodataFound/> 
        } 
        </>
    )}