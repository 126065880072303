import { Tab, Tabs, Nav } from "react-bootstrap";
import React,{ useState } from "react";
import { Col,Row } from "react-bootstrap";
import All_transcript from "./All_transcript";
import Loading from "../../Commons/Loading";
import EmailTranscript from "../Sales/Modals/EmailTranscript";

export default function Transcript(props) {
  const [show, setShow] = useState(false)
  const closeButton=()=>setShow(false)
  const [keyClicked,setKeyClicked]=useState("all");
  const handleClick=()=>{
    setShow(true)
  }
console.log("zzzzzzzzzzz",props.callTranscript)

  const handleSelect = (keyClicked)=>{
    setKeyClicked(keyClicked)
  }

  const setEmailFalse = ()=>{
    setShow(false)
  }

  console.log(props?.list)

  return (

    <>
    {props.loader ? (
      <div className="loader-container">
        <Loading variant="dark" />
      </div>) :

      <div className="tab-content py-3">
        <div className="tab-pane fade active show" role="tabpanel" id="primarytranscript">
          <div className="d-flex">
            <div className="moment-filter-tab-box">
            <Tab.Container id="left-tabs-example" defaultActiveKey={keyClicked?keyClicked:"all"}>
                <Row>
                  <Col sm={10}>
                    <Nav variant="pills" className="flex" onSelect={handleSelect}>
                      <Nav.Item>
                        {props?.callTranscript?.sequence?.length>0?<Nav.Link eventKey="all">All</Nav.Link>:""}
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="customer">{props.callTranscript?props.callTranscript?props.callTranscript.customerName:"":""}</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="seller">{props.callTranscript?props.callTranscript.sellerName:""}</Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </Col>
                  <Col sm={2} className="moments-email-btn mb-3 text-sm-right pe-0">
                    <a className="btn compose-mail-btn" onClick={handleClick}>Email Transcript</a>
                  </Col>
                  <Col sm={12} className="px-0">
                    <Tab.Content>
                    {keyClicked=='all'||keyClicked=='customer'||keyClicked=='seller'?
                      <Tab.Pane eventKey={keyClicked}>
                        <All_transcript type={props.type} audioTime={props.audioTime} keyClicked={keyClicked?keyClicked:""} callTranscript={props.callTranscript?props.callTranscript:''} tabSelected={props.tabSelected} tabRedirected={props?.tabRedirected} callId={props?.callId} url={props?.url} func={props.func} time={props.time} phone={props?.phone} agentExternalId={props.agentExternalId} tabValue={props?.tabValue} likeDislikeData={props?.likeDislikeData} eventType={props?.eventType} postId={props?.postId} agentId={props?.agentId}/>
                      </Tab.Pane>
                    :""}
                    </Tab.Content>
                  </Col>
                </Row>
              </Tab.Container>
            </div>
          </div>
        </div>
        <EmailTranscript show={show} setShow={setShow} closeButton={closeButton} callTranscript={props.callTranscript?props.callTranscript:""} tabSelected={props.tabSelected} callId={props?.callId} setEmailFalse={setEmailFalse} fileName={props?.fileName} typeOfCall={props?.typeOfCall} agentId={props?.agentId}/>
      </div>
    }
    </>
  );
}
