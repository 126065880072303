export const FETCH_USER_DETAILS = 'FETCH_USER_DETAILS';
export const SET_USER_DETAILS = 'USER_DETAILS';
export const SIGN_IN = 'SIGN_IN';
export const REQUEST_FAILURE = 'REQUEST_FAILURE';
export const REQUEST_SUCCESS = 'REQUEST_SUCCESS';
export const LOGIN_USER_DETAIL = 'LOGIN_USER_DETAIL';
export const SET_LOADER = 'SET_LOADER';
export const SIGN_OUT ='SIGN_OUT';
export const FETCH_USER_PERMISSION = 'FETCH_USER_PERMISSIONS';
export const SET_USER_PERMISSION = 'FETCH_USER_PERMISSION';
export const SET_USERNAME = 'SET_USERNAME'
export const HEADER = 'SHOW_HEADER'