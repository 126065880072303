import * as React from "react"
import { useState,useEffect } from "react"
import Loading from "../Loading"
import { Button } from "react-bootstrap"
import EmailTranscript from "../../Conversations_Dashboard/Sales/Modals/EmailTranscript"
import callsService from "../../Services/calls.service"
import toaster from "../../../Components/Toast/toaster";
import notificationsService from '../../Services/notifications.service'
import CommentOnModal from "../../Conversations_Dashboard/Sales/Modals/CommentOnModal"
import { color } from "highcharts"

export default function AgentMoments(props){
  const [show, setShow] = useState(false)
  const [moments, setMoments] = useState([])
  const [loader,setLoader] = useState([])
  const [likeButton, setLikeButton] = useState(false)
  const [dislikeButton, setDislikeButton] = useState(false)
  const [commentButton, setCommentButton] = useState(false)
  const [commentShow, setCommentShow] = useState(false)
  const [likeId, setLikeId] = useState("")
  const [dislikeId, setDislikeId] = useState("")
  const [momentsId, setMomentsId] = useState("")
  const [moment, setMoment] = useState("")
  const [commentData, setCommentData] = useState([])
  const [likeDislike, setLikeDislike] = useState([])

  const closeButton=()=>{
    setShow(false)
    setCommentShow(false)
  }

  const handleClick=()=>{
      setShow(true)
  }
  const setEmailFalse = ()=>{
    setShow(false)
  }

  const likeButtonHandler = (id,label)=>{
    setLikeButton(true)
    setLikeId(id)
    let value={
      "callId": props?.userId,
      "commentType": "MOMENTS",
      "dislike": null,
      "like": 1,
      "postId": id
    }
    callsService.addLikeDislike(value).then(res=>{
      if(res){
        callsService.getLikeDislike(props?.userId,"MOMENTS").then(res=>{
          if(res){
            setLikeDislike(res.data.data)
            setLikeButton(false)
          }
        })
      }
    })
    let notificationBody = [{
			"callId":null,
			"userExternalId":props?.externalId,
			"mobileNumber":props?.phone,
			"notificationEvent":{
				"eventType":"LIKE",
				"tabName":"teamMoments",
				"tabValue":label,
				"postId":id,
			}
		}]
    notificationsService.createNotifications(notificationBody).then(res=>{
      if(res){
        console.log(res)
        toaster.success(res.data.message)
      }
    })
  }

  const dislikeButtonHandler = (id,label)=>{
    setDislikeButton(true)
    setDislikeId(id)
    let value={
      "callId": props?.userId,
      "commentType": "MOMENTS",
      "dislike": 0,
      "like": null,
      "postId": id
    }
    callsService.addLikeDislike(value).then(res=>{
      if(res){
        callsService.getLikeDislike(props?.userId,"MOMENTS").then(res=>{
          if(res){
            setLikeDislike(res.data.data)
            setDislikeButton(false)
          }
        })
      }
    })
    let notificationBody = [{
			"callId":null,
			"userExternalId":props?.externalId,
			"mobileNumber":props?.phone,
			"notificationEvent":{
				"eventType":"DISLIKE",
				"tabName":"teamMoments",
				"tabValue":label,
				"postId":id,
			}
		}]
    notificationsService.createNotifications(notificationBody).then(res=>{
      if(res){
        console.log(res)
        toaster.success(res.data.message)
      }
    })
  }

  const commentButtonHandler = (id,label)=>{
    // setCommentButton(true)
    setCommentShow(true)
    setMomentsId(id)
    setMoment(label)
  }
  const updateCommentState = ()=>{
    setCommentButton(true)
    setCommentShow(true)
    // setId(id)
    // setStats(label)
  }

  const storedData = JSON.parse(localStorage.getItem("AGENT"))

// for filtered data values is used 
  let values= {
    "coeId":props?.filteredData?props.filteredData.coeId?props.filteredData.coeId:props.defaultFilterData?.coeIds:props.defaultFilterData?.coeIds,
    "fromD":JSON.parse(localStorage.getItem("AGENT"))?.fromD,
    "toD":JSON.parse(localStorage.getItem("AGENT"))?.toD,
    "userId":props?.userId,
    // "userExternalId":props?.userId
  }
  
  const getMoments = ()=>{
    setLoader(true)
    // if(props?.userId.length>4){
    //   delete values.userId
    // }
    // else{
    //   delete values.userExternalId
    // }
    callsService.getTeamMemberMoments(values).then(res=>{
      if(res){
        setMoments(res.data.data)
        setLoader(false)
      }
    })
  }


// for default filter valuesDef is used
  let valuesDef = {
    "coeId":props.defaultFilterData?.coeIds,
    "fromD":storedData&&storedData.fromD?storedData?.fromD:JSON.parse(localStorage.getItem("AGENT"))?.fromD,
    "toD":storedData&&storedData.toD?storedData?.toD:JSON.parse(localStorage.getItem("AGENT"))?.toD,
    "userId":props?.userId,
    // "userExternalId":props?.userId
  }

  const getDefMoments = ()=>{
    setLoader(true)
    // if(props?.userId.length>4){
    //   delete valuesDef.userId
    // }
    // else{
    //   delete valuesDef.userExternalId
    // }
    callsService.getTeamMemberMoments(valuesDef).then(res=>{
      if(res){
        setMoments(res.data.data)
        setLoader(false)
        callsService.getLikeDislike(props?.userId,"MOMENTS").then(res=>{
          if(res){
            setLikeDislike(res.data.data)
            if(props?.eventType=='COMMENT'&&props?.tabRedirected=='teamMoments'){
              setCommentShow(true)
              setMomentsId(props?.postId)
            }
          }
        })
      }
    })
  }

  useEffect(()=>{
    if((props?.keySelected=='teamMoments'||props?.tabRedirected=='teamMoments') && (!props.filteredData)){ 
      getDefMoments()
      
    }
  },[props?.keySelected=='teamMoments',props.defaultFilterData,props?.tabRedirected=='teamMoments'])

  useEffect(()=>{
    if(props?.keySelected=='teamMoments'&&props.filteredData)
      getMoments()
  },[props?.keySelected=='teamMoments',props.filteredData])

  // useEffect(()=>{
  //   if(props?.keySelected=='teamMoments'&&props.filteredData)
  //     getMoments()
  // },[props?.keySelected=='teamMoments',props.filteredData])

  // useEffect(()=>{
    
  // },[])

  const callback = (e)=>{
    setCommentData(e)
    console.log(e)
  }

  const iconsFunc = (item,id)=>{
    let transNew = Object.entries(Object.keys(commentData).length>0?commentData?.postTypeComments:moments?.postTypeComments).filter(val=>{
      return(val[1][0].postId==id)
    })
    let ids = transNew.length>0?transNew[0][1]:''
    let likeIcon = likeDislike.filter(item=>{
      return(item.postId==id)
    })
    return(
      <div className="d-flex table-group-header justify-content-between">
        <div className="table-group-heading">
          <p className="mb-0">{item[0]}</p>
          <div className="list-after-click-group">
            {likeIcon.length>0&& likeIcon[0].like!==null&&likeIcon[0].postId==id||likeButton&&likeId==id?<button className="btn list-like-after" ><span className=" text-primary-blue"> <i className="bx bx-like"></i></span></button>:
            likeIcon.length>0&&likeIcon[0].dislike!=null&&likeIcon[0].postId==id||dislikeButton&&dislikeId==id?<button className="btn list-dislike-after"><span className=" text-primary-blue"> <i className="bx bx-dislike"></i></span></button>:''}
            {ids&&ids[0].postId==id||commentButton&&momentsId==id?<button className="btn list-comment-after switcher-btn-1" onClick={()=>commentButtonHandler(id,item[0])} data-bs-toggle="tooltip" data-bs-placement="bottom" title="Comment on Stats"><span class="text-primary-blue"><i class="lni lni-comments-alt"></i></span></button>:''}
          </div>
        </div>
        <div className="list-hover-input-group">
          {likeIcon.length>0&& likeIcon[0].like!==null&&likeIcon[0].postId==id||likeButton&&likeId==id||dislikeButton&&dislikeId==id? '':<button className="btn list-like-before" onClick={()=>likeButtonHandler(id,item[0])}>
            <span className="text-primary-blue">
              <i className="bx bx-like"></i>
            </span>
          </button>
          }
          {likeIcon.length>0&&likeIcon[0].dislike!=null&&likeIcon[0].postId==id||dislikeButton&&dislikeId==id||likeButton&&likeId==id ? ""
          :
          <button className="btn list-dislike-before" onClick={()=>dislikeButtonHandler(id,item[0])}>
            <span className="text-primary-blue">
              <i className="bx bx-dislike"></i>
            </span>
          </button>
          }
          {/* {mailButton?'':
          <button className="btn compose-mail-btn" onClick={handleClick} data-bs-toggle="tooltip" data-bs-placement="bottom" title="Email Stats">
            <span className="text-primary-blue">
              <i className="bx bx-mail-send"></i>
            </span>
          </button>
          } */}
          {ids&&ids[0].postId==id||commentButton&&momentsId==id?'':  
          <button className="btn list-comment-before switcher-btn-1" onClick={()=>commentButtonHandler(id,item[0])} data-bs-toggle="tooltip" data-bs-placement="bottom" title="Comment on Stats">
            <span className="text-primary-blue">
              <i className="lni lni-comments-alt"></i>
            </span>
          </button>
          } 
        </div>
      </div>
    )
  }

  console.log(commentData)

    return(
    <>
      {loader? (
        <div className="loader-container">
          <Loading variant="dark" />
        </div>
      ) :
      <div className="tab-content py-3">
        <div className="tab-pane fade active show" role="tabpanel" id="primarymoments">
          <div className="d-flex">
            <div className="moment-filter-tab-box">
              <div className="moments-heading">
                <h5 className="m-0">Moments</h5>
              </div>
            </div>
            <div className="moments-email-btn mb-2">
              <a className="btn compose-mail-btn" onClick={handleClick}>Email Moments</a>
            </div>
          </div>
          <div>
            <div className="table-responsive moment-list-group-scroll moment-group-table">
              <table className="table mb-0">
                <thead className="fixed-header-table">
                  <tr>
                    <th>Name</th>
                    <th className="text-center">Averages</th>
                    <th className="text-center">Global Averages</th>
                  </tr>
                </thead>
                {moments||Object.keys(commentData).length>0?Object.entries(Object.keys(commentData).length>0?commentData:moments).map((item,id)=>{
                  return(
                  <tbody className="fixed-header-table">
                      {item[0]=='postTypeComments'?"":
                        <>
                        <tr className={`${id==props?.postId&&props?.tabRedirected=='teamMoments'?'shadow-call-moments':''}`} >
                          <th
                            colspan="4"
                            className="moment-table-hover-icon bg-light-blue"
                          >
                            {iconsFunc(item,id)}
                            
                          </th>
                        </tr>
                        {item?item[1].map(val=>{
                          return(
                          <tr>
                              <td>
                                <a className="font-weight-bold mb-0"
                                  data-bs-toggle="modal"
                                  data-bs-target="#momentTabonName"
                                  type="button"
                                  style={{color:val?.isActive === false ?'#757778':''}}
                                >
                                  {val.momentName}
                                </a>
                              </td>
                              <td class="text-center" >
                                <span 
                                // className="badge rounded-pill fw-normal btn btn-outline-danger"
                                className={val?.isActive === false ?"badge rounded-pill btn btn-outline-dark font-normal ":"badge rounded-pill btn btn-outline-danger font-normal"} style={{backgroundColor:val?.isActive === false ?'#c0c0c0':''}}
                                >
                                  {val.average!=null?`${val.average}%`:val.average==0?"0%":"N/A"}
                                </span>
                              </td>
                              <td class="text-center">
                                <span className={val?.isActive === false ?"badge rounded-pill btn btn-outline-dark font-normal ":"badge rounded-pill btn btn-outline-danger font-normal"} style={{backgroundColor:val?.isActive === false ?'#c0c0c0':''}}
>
                                  {val.globalAverage?`${val.globalAverage}%`:"0%"}
                                </span>
                              </td>
                          </tr>
                        )
                      }):""}
                      </> } 
                  </tbody>
                )
                }):""}
              </table>
            </div>
          </div>
        </div>
        {commentShow && momentsId!==''&&<CommentOnModal updateState={updateCommentState} commentShow={commentShow} closeButton={closeButton} tabSelected={props.tabRedirected=='teamMoments'?props?.tabRedirected:props?.keySelected} callId={props?.userId} momentId={momentsId} moment={moment} callback={callback} agentExternalId={props?.externalId} phone={props?.phone}/>}
        <EmailTranscript show={show} setShow={setShow} closeButton={closeButton} tabSelected={props.keySelected} teamMoments={moments} userId={props?.userId} setEmailFalse={setEmailFalse} filteredData={props.filteredData} defaultFilterData={props.defaultFilterData}/>
      </div>
      }
    </>
    )
}