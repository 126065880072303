
import React from "react";
import ReactApexChart from "react-apexcharts";
import { useState } from "react";
import { useHistory } from "react-router-dom";

function LineGraph(props) {
  console.log("ISCOMPARE",props?.comparedData)
  const history = useHistory()
  let rdata = JSON.parse(localStorage.getItem("ROUTING_PERMISSIONS"))
  const values = props?.widgetData?.map(item=>{
    return item.result
  })
  const dates = props?.widgetData?.map(item=>{
    return item.dateString
  })
  const value = props.comparedData!=''?props?.comparedData?.map(item=>{
    return item.result
  }):""

  const comparedDates = props?.comparedData !=''?props?.comparedData?.map(item => {
    return item.dateString;
  }):''
  console.log("valueBOTH",values,value,dates,comparedDates,props.comparedData);

  const type = props?.widgetData?props?.widgetData[0]?.type?props?.widgetData[0]?.type:"":""

  const longerArray = [...dates,...comparedDates]

  const [chartCategories, setChartCategories] = useState([...dates, '', ...comparedDates]);


  const handleLegendClick = (event, chartContext, { seriesIndex }) => {
    setChartCategories(seriesIndex === 0 ? dates : comparedDates);
  }

  const sendTocalls = (data, index) => {
    console.log(data, index, "skbbscldnc");
    // let storedData = JSON.parse(localStorage.getItem("AGENT"))
    //let graphdata = categoriesVal?categoriesVal[value] :''
    let key = props.widgetName
    // localStorage.setItem("AGENT", JSON.stringify(
    //   {
    //     "pageSelected": 1,
    //     "fromD": props.widgetData?.from == undefined ? props.defaultFilteredData?.from : props.widgetData?.from,
    //     "toD": props.widgetData?.to == undefined ? props.defaultFilteredData?.to : props.widgetData?.to,
    //     "selectedDate": props.widgetData?.selectedDate == undefined ? props.defaultFilteredData?.selectedDate : props.widgetData?.selectedDate,
    //     // "selectedRange": storedData?.selectedDate === "This Year" ? "7" : '5',
    //     "selectedRange": storedData?.selectedRange,
    //     "selectedCoeRange": storedData?.selectedCoeRange,
    //     "selectedCOE":storedData?.selectedCOE,
    //     "coe":storedData?.coe
    //   }))
    history.push(rdata && rdata.Conversations && Array.isArray(rdata.Conversations) && rdata.Conversations.length > 1 ? `/voice?${key}&fromDate=${data[index]}&toDate=${data[index]}&redirected=${true}` : `/sales_conversations?${key}&fromDate=${data[index]}&toDate=${data[index]}&redirected=${true}`)
  }

    const series =  [{
        name: props.widgetName,
        data: values}
    ]
    const seriesVal = [{
      name: props.widgetName,
      data: values
      },
      {
      name:"Compared",
      data:value
    }]
   
    const options = {
      chart: {
        height: 350,
        type: 'line',
        zoom: {
          enabled: false
        },
        toolbar: {
          show: false
        },
        events:{
          legendClick: handleLegendClick,
          dataPointSelection: (event, chartContext, config) => {
            console.log(chartContext, config);
            console.log(config.w.config.series[config.seriesIndex])
            console.log(config.w.config.series[config.seriesIndex].name)
            console.log(config.w.config.series[config.seriesIndex].data[config.dataPointIndex])
            console.log(config.dataPointIndex);
            sendTocalls(config.w.globals.categoryLabels, config.dataPointIndex)
          }
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        width: 5,
        curve: 'smooth',
        show:true

      },
      grid: {
        row: {
          colors: [ 'transparent'], // takes an array which will be repeated on columns
          opacity: 0.5
        },
      },
      markers: {
        size: 4,
        colors: ["#0077B6"],
        strokeColors: "#fff",
        strokeWidth: 2,
        hover: {
          size: 7,
        }
      },
      colors: ["#0077B6","#77B6EA"],
      xaxis: {
        title: {
          text: "Dates",
          style:{
            color:"#666666",
            fontSize:"11px",
            textAlign:"right",
          }
        },
        type: 'day',
        categories: dates,
        labels: {
          rotate:-30,
          rotateAlways:true,
          formatter: (value, index) => {
            // Custom label formatting based on index
            // console.log(value)
            // console.log(index)
            // const labelIndex = longerArray.indexOf(value);
            // if (labelIndex % 2 === 0) {
            //   return value;
            // } else {
            //   return '';
            // }
            return value;
          },
        },
      },
      yaxis:{
        title: {
          text: type!=''?`${props.widgetName}(${type})`:props.widgetName, 
          style:{
            color:"#666666",
            fontSize:"11px",
            textAlign:"right"
          }
        }
      },
      tooltip: {
        shared: false,
        intersect: true,
        x: {
          formatter: (val, { series, seriesIndex, dataPointIndex, w }) => {
            return w.globals.seriesNames[seriesIndex] == props.widgetName ? dates[dataPointIndex] : comparedDates[dataPointIndex]
          },
        },
      },
      grid: {
        padding: {
            bottom: 20 // Increase this value if labels are still cut off
        }
    }
    }

    return (
      <div id="chart">
        <ReactApexChart options={options} series={props.isCompare == true && value !=''?seriesVal:props.isCompare == false || value ==''?series:series} type="line" height={350} />
      </div>
    );
}
export default LineGraph;


