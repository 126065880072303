import * as React from 'react'
import ReactTable from '../../Commons/Table/ReactTable';
import Loading from '../../Commons/Loading';
import NodataFound from '../../Commons/NoDataFound';

export default function DurationMetricsData(props){
      let userDetail = JSON.parse(localStorage.getItem("USER_DETAIL"))?.clientConfig?.data?.insights?.durationMetrics?.data

      const colorCode = (d)=>{
        console.log(d,"hellhellhell")
        // if(d<=100&&d>90){
        //   return(
        //     <span
        //       className={`badge rounded-pill btn fw-normal font-13 ${"btn-outline-success"
        //       } `}
        //     >
        //       {" "}
        //       {`${d}%`}{" "}
        //     </span>
        //   )
        // }
        // else if(d<=90&&d>80){
        //   return(
        //     <span
        //       className={`badge rounded-pill btn fw-normal font-13 ${"btn-outline-warning"
        //       } `}
        //     >
        //       {" "}
        //       {`${d}%`}{" "}
        //     </span>
        //   )
        // }
        // else if(d<=80&&d>=0){
        //   return(
        //     <span
        //       className={`badge rounded-pill btn fw-normal font-13 ${"btn-outline-danger"
        //       } `}
        //     >
        //       {" "}
        //       {`${d}%`}{" "}
        //     </span>
        //   )
        // }
        if(d?.row?.original?.callScoreColor == "Green"){
          return (
            <span
              className={`badge rounded-pill btn fw-normal font-13 ${"btn-outline-success"
              } `}
            >
              {" "}
              {`${d.value}%`}{" "}
            </span>
          )
        }
        else if(d?.row?.original?.callScoreColor == "Red"){
          return (
            <span
              className={`badge rounded-pill btn fw-normal font-13 ${"btn-outline-danger"
              } `}
            >
              {" "}
              {`${d.value}%`}{" "}
            </span>
          )
        }
        else if(d?.row?.original?.callScoreColor == "Amber"){
            return (
              <span
                className={`badge rounded-pill btn fw-normal font-13 ${"btn-outline-warning"
                } `}
              >
                {" "}
                {`${d.value}%`}{" "}
              </span>
            )
        }
        else{
          return (
            <span
              className={`rounded-pill btn fw-normal font-13 ${"btn-outline-secondary"
              } `}
            >
              {" "}
              {`${d.value}%`}{" "}
            </span>
          )
        }
      }

      const saleColumn = [
          {
            Header: "Employee Id",
            accessor: "employeeId",
          },
          {
            Header: "Name",
            // accessor: (d) => (
            //   <div style={{textTransform:"capitalize",cursor:"pointer"}} className="teamHover" >
            //     {`${d.agentName}`}
            //   </div>
            // ),
            accessor: "agentName",
            Cell: (cell) => (
                 <div style={{ textTransform: "capitalize", cursor: "pointer" }} className="teamHover">
                  {cell.value}
                  </div>
            ),
          },
      
          {
            Header: "Total Calls",
            accessor: "totalCalls",
          },
          {
            Header: "(0-3) Min",
            accessor: "calls3Min",
          },
          {
            Header: "(3-6) Min",
            accessor: "calls6Min",
          },
          {
            Header: "(6-10) Min",
            accessor: "calls10Min",
          },
          {
            Header: "10+ Min",
            accessor: "moreThan10",
          },
          {
            Header: "Rating",
            accessor: (d) => (
              <p className="text-center mb-0">
                {d.callScoreColor ? (
                <span
                  className={`badge rounded-pill btn fw-normal font-13 ${
                   d.callScoreColor=='Red'?"text-danger" :d.callScoreColor=='Amber'?"text-warning":d.callScoreColor=='Green'?"text-success":""
                  } `}
                >
                  {" "}
                  <i className="lni lni-star-filled"></i>{" "}
                </span>
                ) : 
                d.pitchScoreColor ? (
                  <span
                    className={`badge rounded-pill btn fw-normal font-13 ${
                    d.pitchScoreColor=='Red'?"text-danger" :d.pitchScoreColor=='Amber'?"text-warning":d.pitchScoreColor=='Green'?"text-success":""
                    } `}
                  >
                    {" "}
                    <i className="lni lni-star-filled"></i>{" "}
                  </span>
                ) :
                (
                  "-"
                )}
              </p>
            ),
          },
      ];

      if(userDetail?.includes("Call Score")){
        saleColumn.splice(7,0,{
        Header: "Call Score",
        accessor: (de)=>`${de.averageCallScore}`,
        Cell: (d) => (
          <div className="text-center mb-0">
            {d ? (
              colorCode(d)
            ) : (
              "-"
            )}
          </div>
        ),
      })}
      else if(userDetail?.includes("Pitch Score")){
        saleColumn.splice(7,0,{
        Header: "Pitch Score",
        accessor: (de)=>`${de.averagePitchScore}`,
        Cell: (d) => (
          <p className="text-center mb-0">
            {d.averagePitchScore ? (
              colorCode(d)
            ) : (
              "-"
            )}
          </p>
        )
        })
      }
      else if(userDetail?.includes("Call Score")&&userDetail.includes("Pitch Score")){
        saleColumn.splice(7,0,
          {
            Header: "Call Score",
            accessor: (de)=>`${de.averageCallScore}`,
            Cell: (d) => (
              <p className="text-center mb-0">
                {d ? (
                  colorCode(d)
                ) : (
                  "-"
                )}
              </p>
            ),
          },
          { 
            Header: "Pitch Score",
            accessor: (de)=>`${de.averagePitchScore}`,
            Cell: (d) => (
              <p className="text-center mb-0">
                {d.averagePitchScore ? (
                  colorCode(d)
                ) : (
                  "-"
                )}
              </p>
            )
          }
        )
      }

    return(
        <>
        {props.loader?(
            <div className='loader-container'>
                <Loading  variant="dark"/>
            </div>
        ):props?.metricsData?.length>0?
        (
        <div className="table-responsive">
            <div className="table-columns-filter-btn">
                <div className="table-responsive dataTable-style react-table">
                    <ReactTable
                        columns={saleColumn}
                        componentType="DURATION_METRICS"
                        data={props.metricsData}
                        isExcelPdfButton={true}
                        isDisable={props.metricsData.length<=0?true:false}
                    />
                </div>
            </div>
        </div>)
        :<NodataFound/>
        }
        </>
    )}