import React, { useCallback, useEffect, useRef, useState } from 'react'
import '../KnowledgeAI/knowledge.css'
import { useHistory } from 'react-router-dom';
import BubbleLoader from '../Commons/BubbleLoader';
import { apiCall } from '../Services/Interceptor';
import { toast } from 'react-toastify';
import Typewriter from './TypewriterEffect';

let data = [
  {
      "name":"Product AI",
      "options":[{
          "name":"Loan Calculator",
          "options":`Loan Calculator : -

          Using this information, you can calculate the monthly mortgage payment. Let's break down the calculation:
              • The loan amount (principal) is $200,000.
              • The annual interest rate is 4.5%, which needs to be converted to a monthly interest rate. Divide the annual rate by 12 (the number of months in a year) to get the monthly interest rate: 4.5%/12​=0.375% or 0.00375 as a decimal.
              • The loan term is 30 years, which means 30 * 12 = 360 monthly payments.
          Now, you can use the following formula to calculate the monthly mortgage payment (M):
          M=P×(r(1+r)n/(1+r)n--1​)
          Where:
              • P is the loan amount (principal)
              • r is the monthly interest rate
              • n is the number of payments​
          Calculating this formula will give you the monthly mortgage payment. You can use a calculator, spreadsheet, or online calculator to perform the calculation.`
      },{
          "name":"Verification Process",
          "options":`Verification process - 

          The lender verifies the documents provided by the borrower, which may include:
              • Proof of identity (such as a driver's license or passport)
              • Proof of income (such as pay stubs, tax returns, or bank statements)
              • Employment verification (such as contacting the employer to confirm employment status and income)
              • Proof of assets (such as bank statements, investment accounts, or property deeds)
              • Debt obligations (such as credit card statements, loan statements, or mortgage documents)`
      },{
          "name":"Payments Options",
          "options":`Payment Options - 

          Payment options for a loan typically depend on the type of loan and the terms agreed upon between the lender and the borrower. Here are some common payment options for loans:
              1. Fixed Monthly Payments: With fixed monthly payments, the borrower pays a set amount each month for the duration of the loan term. This amount includes both principal and interest, and the payments remain consistent throughout the life of the loan. Fixed monthly payments are common for installment loans, such as personal loans and mortgages.
              2. Variable Monthly Payments: Some loans, such as adjustable-rate mortgages (ARMs) or lines of credit, may have variable monthly payments. The payment amount can fluctuate based on changes in interest rates or other factors specified in the loan agreement. Borrowers should be aware that variable payments can increase or decrease over time, potentially impacting their budget.
              3. Biweekly Payments: With biweekly payments, the borrower makes payments every two weeks instead of once a month. This results in 26 half-payments per year, which is equivalent to 13 full payments. Biweekly payments can help borrowers pay off their loans faster and reduce the total interest paid over the life of the loan.
              4. Interest-Only Payments: In some cases, borrowers may have the option to make interest-only payments for a certain period, typically at the beginning of the loan term. With interest-only payments, the borrower pays only the interest portion of the loan, without reducing the principal balance. This can result in lower initial payments but may lead to higher payments later on when principal payments kick in.
              5. Balloon Payments: A balloon payment is a large lump-sum payment due at the end of the loan term. This payment typically covers the remaining balance of the loan. Balloon payments are common in certain types of loans, such as balloon mortgages or balloon auto loans. Borrowers should be prepared to make the balloon payment or refinance the loan when it comes due.
              6. Extra Payments: Borrowers may have the option to make extra payments towards their loan principal in addition to their regular monthly payments. Making extra payments can help borrowers pay off their loans faster and reduce the total interest paid over time.
              7. Automatic Payments: Many lenders offer the option for borrowers to set up automatic payments, where the monthly loan payment is automatically deducted from their bank account on a specified date each month. Automatic payments can help ensure timely payments and may sometimes qualify the borrower for a small interest rate discount.`
      }]
  },{
      "name":'Tool Based AI',
      "options":[{
          "name":"Calls",
          "options":[{
              "name":"Call Score",
              "options":''
          },{
               "name":"Duration",
              "options":''
          },{
              "name":"Call Health",
              "options":''
          }]
      },{
          "name":"Agents",
          "options":[{
              "name":"Employee ID",
              "options":''
          },{
              "name":"Employee Name",
              "options":''
          },{
              "name":"Agent Call Score",
              "options":''
          },{
              "name":"AHT",
              "options":''
        }]
      },
      {
          "name":"Keyword Search",
          // "options":[{
          //     "name":"Keyword",
          //     "options":''
          // }]
      }
    ]
  }]

function KnowledgeAI() {
  const history = useHistory();
  const chatContainerRef = useRef(null);
  const [defaultPrompt,setDefaultPrompt] = useState(["Product AI","Tool Based AI"])
  const [chatStart, setChatStart] = useState(false)
  const [chatHistory, setChatHistory] = useState([])
  const [chatOptions, setChatOptions] = useState([])
  const [selectedOption, setSelectedOption] = useState('')
  const [dataSet, setDataSet] = useState({
    callScore : '',
    duration: '',
    callhealth: '',
    employeeId:'',
    employeeName:'',
    agentCallScore:'',
    aht:'',
    keyword:'',
  })
  const [keyData, setKeyData] = useState([])
  const [pageNo,setPageNo] = useState(1)
  const [loader, setLoader] = useState(false)
  const [prevWord, setPrevWord] = useState("")
  const [dotVisible, setDotVisible] = useState(true);
  const [typingComplete, setTypingComplete] = useState(false);
  

  useEffect(()=>{
    if(["Call Score","Duration","Call Health","Employee ID","Employee Name","Agent Call Score","AHT","Keyword Search"].includes(selectedOption) == true){
      setChatHistory([...chatHistory,{ sender: 'Knowledge AI', message: selectedOption == "Keyword Search" ? 'Please enter the keyword you want to search for' : `Please enter the ${selectedOption} in this format ${(selectedOption == "Call Health" ? 'fatal or non-fatal' : selectedOption == "Employee ID" ? "ISS300006" : selectedOption == "Employee Name" ? "Kevin Hart" : selectedOption == "Agent Call Score" ? '0-60 maximum end value 100' : selectedOption == "AHT" ? '0-160 maximum end value 300' : selectedOption == "Duration" ? '0-300 or 600-1200 or 1500-2400 (These are in seconds)' : '0-60 or 30-90 or 10-100')}`}])
    }
  },[selectedOption])

  useEffect(() => {
    if(chatHistory[chatHistory.length - 1]?.message.type == "a"){
      {chatHistory[1]?.message == "Calls" && handleOption(data[1].options[0])}
      {chatHistory[1]?.message == "Agents" && handleOption(data[1].options[1])}
    }
  }, [chatHistory]);

  useEffect(()=>{
    chatContainerRef.current.scrollTo({
      top: chatContainerRef.current.scrollHeight,
      behavior: 'smooth',
    });
  },[chatHistory,chatOptions,keyData, loader,dotVisible])

  useEffect(() => {
    if (typeof(chatOptions[0]?.options) === "string" && !typingComplete) {
      const dotInterval = setInterval(() => {
        setDotVisible(prev => !prev);
      }, 300);

      return () => clearInterval(dotInterval);
    }
  }, [chatOptions, typingComplete]);

  const handleTypingComplete = useCallback(() => {
    setTypingComplete(true);
  }, []);

  const keywordSearch = async (wordData,pageno)=>{
    if(wordData !== prevWord){
      setPageNo(1)
    }
    setLoader(true)
    await apiCall.get(`/odio/api/search?input=${wordData}&page=${wordData != prevWord ? 1 : pageno}&size=10`,data).then(response => {
      if(wordData == prevWord && response.data.data.trans.length != 0){
      setKeyData(prevKeyData =>[...prevKeyData , ...response.data.data.trans])}
      else if(wordData == prevWord && response.data.data.trans.length == 0){
        setKeyData(prevKeyData =>[...prevKeyData , ...response.data.data.trans])
      }else{
        setKeyData(response.data.data.trans)
      }
      if(response.data.data.trans.length == 0){
        setChatHistory(prevChatHistory =>[...prevChatHistory,{sender:'Knowledge AI', message: "No data Available for this keyword. Please search for another keyword or Restart the chat."}])
      }
      // setKeyData(prevKeyData =>[...prevKeyData , ...keydatakey])
      setLoader(false)
      setPrevWord(wordData)
    }).catch(error => {
      console.log("Error:", error);
      toast.error("Unable to give response")
      setLoader(false)
      restartChat()
    })
  }

  const moreResults = ()=>{
    setPageNo(pageNo + 1)
    keywordSearch(dataSet.keyword, pageNo + 1)
  }

  const restartChat = ()=>{
    setChatStart(false)
    setChatHistory([])
    setChatOptions([])
    setSelectedOption('')
    setDataSet({callScore : '',duration: '',callhealth: '',employeeId:'',employeeName:'',agentCallScore:'',aht:'',keyword:''})
    setKeyData([])
    setPageNo(1)
    setTypingComplete(false)
  }

  const handleDefaultSelect =(item)=>{
    setChatStart(true)
    setChatHistory([{sender:'You', message:item}])
    setSelectedOption(item)
    setChatOptions( data.filter((opt)=> opt.name == item))
  }

  const handleOption = (opt) =>{
    console.log(opt)
    if(opt?.options == ''){
      setChatOptions([])
    }else{
      setChatOptions([opt])
    }
    // setChatHistory([...chatHistory,{sender:'You', message:opt.name},...(opt.options === '' ? [{ sender: 'Knowledge AI', message: `Please enter the ${opt.name}` }] : [])])
    if(typeof(chatHistory[chatHistory.length - 1].message) == "string"){
      setChatHistory([...chatHistory,{sender:'You', message:opt.name}])
    }
    setSelectedOption(opt.name)
    // setChatOptions( typeof(opt.options) == 'object' && )
  }

  const historyPush = (e) => {
    const paths = {
      "Call Score": `/voice?callScore=${dataSet.callScore}`,
      "Duration": `/voice?callDuration=${dataSet.duration}`,
      "Call Health": `/voice?callHealth=${dataSet.callhealth}`,
      "Employee ID": `/team?employeeId=${dataSet.employeeId}`,
      "Employee Name": `/team?employeeName=${dataSet.employeeName}`,
      "Agent Call Score": `/team?agentCallScore=${dataSet.agentCallScore}`,
      "AHT": `/team?aht=${dataSet.aht}`,
      "Keyword Search": `/voice_detail?callId=${e.callId}&tabSelected=transcript&name=SINGLE_CALL`
    };
  
    const path = paths[selectedOption];
    if (path) {
      history.push(path);
    }
  };

  const handleSubmit = (e)=>{
    console.log(e)
    e.preventDefault()
    if(selectedOption == "Call Score"){setChatHistory(prevChatHistory =>[...prevChatHistory,{sender:'You', message: dataSet.callScore},{sender:'Knowledge AI', message: <a href='#' onClick={historyPush}>/voice?callScore={dataSet.callScore}</a>}])}
    else if(selectedOption == "Duration"){setChatHistory(prevChatHistory =>[...prevChatHistory,{sender:'You', message: dataSet.duration},{sender:'Knowledge AI', message: <a href='#' onClick={historyPush}>/voice?callDuration={dataSet.duration}</a>}])}
    else if(selectedOption == "Call Health"){setChatHistory(prevChatHistory =>[...prevChatHistory,{sender:'You', message: dataSet.callhealth},{sender:'Knowledge AI', message: <a href='#' onClick={historyPush}>/voice?callHealth={dataSet.callhealth}</a>}])}
    else if(selectedOption == "Employee ID"){setChatHistory(prevChatHistory =>[...prevChatHistory,{sender:'You', message: dataSet.employeeId},{sender:'Knowledge AI', message: <a href='#' onClick={historyPush}>/team?employeeId={dataSet.employeeId}</a>}])}
    else if(selectedOption == "Employee Name"){setChatHistory(prevChatHistory =>[...prevChatHistory,{sender:'You', message: dataSet.employeeName},{sender:'Knowledge AI', message: <a href='#' onClick={historyPush}>/team?employeeName={dataSet.employeeName}</a>}])}
    else if(selectedOption == "Agent Call Score"){setChatHistory(prevChatHistory =>[...prevChatHistory,{sender:'You', message: dataSet.agentCallScore},{sender:'Knowledge AI', message: <a href='#' onClick={historyPush}>/team?agentCallScore={dataSet.agentCallScore}</a>}])}
    else if(selectedOption == "AHT"){setChatHistory(prevChatHistory =>[...prevChatHistory,{sender:'You', message: dataSet.aht},{sender:'Knowledge AI', message: <a href='#' onClick={historyPush}>/team?aht={dataSet.aht}</a>}])}
    else if(selectedOption == "Keyword Search"){
      {setChatHistory(prevChatHistory =>[...prevChatHistory,{sender:'You', message: dataSet.keyword}])}
      keywordSearch(dataSet.keyword, dataSet.keyword != prevWord ? 1 : pageNo + 1)
      dataSet.keyword == prevWord && setPageNo(pageNo + 1)
    }
    
    setDataSet(prevDataSet => ({
      ...prevDataSet,
      callScore : '',
      duration: '',
      callhealth: '',
      employeeId:'',
      employeeName:'',
      agentCallScore:'',
      aht:'',
    }));
  }

  const valueChange = (e) => {
    const value = e.target.value;
    if (selectedOption === "Keyword Search") {
      const filteredValue = value.replace(/[^\w\s]/gi, "");
      setDataSet(prevDataSet => ({ ...prevDataSet, keyword: filteredValue }));
    } else {
      const updates = {
        "Call Score": { callScore: value },
        "Duration": { duration: value },
        "Call Health": { callhealth: value },
        "Employee ID": { employeeId: value },
        "Employee Name": { employeeName: value },
        "Agent Call Score": { agentCallScore: value },
        "AHT": { aht: value },
      };
      setDataSet(prevDataSet => ({ ...prevDataSet, ...updates[selectedOption] }));
    }
  };

  console.log(chatHistory,"history")
  console.log(chatOptions,"options")
  console.log(selectedOption,"selectedopt")
  console.log(keyData,"keydata")
  console.log(dataSet)
  
  return (
    <>
        <div className="know-page-wrapper">
        <div className="maindiv custom-scrollbar" ref={chatContainerRef}>
        <div className='know-div'>
        {!chatStart && <>
        <div className='know-intro'><p className='intro-text'>Hello, Team <br/><p>How can I help you today?</p></p></div>
        <div className='know-default-prompt-container'>
          {defaultPrompt.map((item)=> 
            <div className='know-default-prompt' onClick={()=>handleDefaultSelect(item)}><b>{item}</b><div className='know-dp-icon'><i class='bx bxs-chevrons-right' /></div></div>)}
        </div></>}
        {chatStart && <>
          {chatHistory?.map((chat)=>{
              return <>
                <div className='know-chat'>
                  <div className='know-chat-icon'>
                    <i class={chat.sender == "You" ? 'bx bx-user-circle' : 'bx bx-shape-circle'}/><b>{chat.sender}</b>
                  </div>
                  <p>{chat.message}</p>
                </div>
              </>
          })}
        </>}
        {Array.isArray(chatOptions[0]?.options)  && <>
                <div className='know-chat'>
                  <div className='know-chat-icon'>
                    <i class='bx bx-shape-circle'/><b>KnowledgeAI</b>
                  </div>
                  <p>Please select any option</p>
                  <div className='know-chat-opt'>
                  {chatOptions[0]?.options.map((chat)=>{return <button onClick={()=>handleOption(chat)}>{chat.name}</button>})}
                  </div></div></>
        }        
        {typeof(chatOptions[0]?.options) == "string" && <>
                  <div className='know-chat'>
                  <div className='know-chat-icon'>
                    <i class='bx bx-shape-circle'/><b>KnowledgeAI</b>
                  </div>
                  <p style={{ whiteSpace: 'pre-wrap' }}>
                    <Typewriter text={chatOptions[0]?.options} speed={3} check={true} onTypingComplete={handleTypingComplete}/>
                    {!typingComplete && dotVisible && <span className="typing-dot" />}
                  </p></div></>
        }
        {loader ? <div className="calls-container-knowAI">
                    <BubbleLoader />
         </div> : keyData.length > 0 ? <>
                <div className='know-chat'>
                  <div className='know-chat-icon'>
                    <i class='bx bx-shape-circle'/><b>KnowledgeAI</b>
                  </div>
                  <p>Please select any option</p>
                  <div className='know-chat-opt-keyword'>
                  {keyData.map((chat)=>{
                    const truncatedTrans = chat.trans[0].length > 65 ? chat.trans[0].slice(0, 65) + "..." : chat.trans[0];
                    return <button className='know-chat-opt-keyword-button' onClick={()=>historyPush(chat)}>{`${truncatedTrans}......  `}<h6 style={{display:'inline'}}>Call Id: </h6>{chat.callId}</button>})}
                    <button className='know-chat-opt-keyword-button' onClick={moreResults}><h6 style={{display:'inline'}}>Show More Results</h6></button>
                  </div></div>
         </> : ""}
        <form className='know-input-div' onSubmit={handleSubmit}>
            <input type='text' placeholder='Select prompt first...' value={selectedOption == "Call Score" ? dataSet.callScore : selectedOption == "Duration" ? dataSet.duration : selectedOption == "Call Health" ? dataSet.callhealth : selectedOption == "Employee ID" ? dataSet.employeeId : selectedOption == "Employee Name" ? dataSet.employeeName : selectedOption == "Agent Call Score" ? dataSet.agentCallScore : selectedOption == "AHT" ? dataSet.aht : dataSet.keyword} onChange={valueChange}/>
            <button type='submit' className='know-submit-button'><i class='bx bx-send' /></button>
            {selectedOption && <button className='know-revise-button' onClick={restartChat}><i class='bx bx-revision' /></button>}
        </form>
        </div>
            </div>
        </div>
    </>
  )
}

export default KnowledgeAI