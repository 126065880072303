import React, { useState, useEffect, useLayoutEffect } from "react";
import MultiSelect from "react-multiple-select-dropdown-lite";
import "react-multiple-select-dropdown-lite/dist/index.css";
import Loading from "../../Commons/Loading";
import coeService from "../../Services/coe.service";
import reportConfigService from "../../Services/reportConfig.service";
import userService from "../../Services/user.service";
import ReportGroup from "./ReportGroup";
import ManageSettingReportColumn from "./ManageSettingReportColumn";

export default function Reports(props) {
  const [loader, setLoader] = useState(false);
  const [Role, setRole] = useState("");
  const [reportList, setReportList] = useState([]);
  const [Employee, setEmployee] = useState("");
  const [reportType, setReportType] = useState([]);
  const [coeList, setCoeList] = useState([]);
  const [frequencyList, setFrequencyList] = useState([]);
  const [newReport, setNewReport] = useState(false);
  const [editData, setEditData] = useState()
  // const [allreports, setAllReports] = useState();

  const editObject = {
    reportFrequencyId: 1,
    reportFrequencyType: "DAILY",
    reportId: 2,
    reportName: "COE Detail Report",
    reportDescription: "Daily,Weekly,Monthly coe aggregated report.",
    triggeredAt: "2023-05-19T05:15:58.000+00:00",
    createdBy: "Insurancedekho",
    sentTo: "413,412",
    customFromDate: null,
    customToDate: null,
    weeklyDay: null,
    triggerTime: "10:00:00",
    triggerDate: null,
    coeId: 80
  }
  const repType = [
    {
      reportId: 1,
      reportName: "Call Detail Report",
      reportsType: "DETAIL_CALL_REPORT",
      defalt: true,
      status: true,
    },
    {
      reportId: 2,
      reportName: "Agent Detail Report",
      reportsType: "AGENT_DETAIL_REPORT",
      defalt: true,
      status: true,
    },
    {
      reportId: 3,
      reportName: "Coe Summary Report",
      reportsType: "COE_SUMMARY_REPORT",
      defalt: true,
      status: true,
    },
  ];
  var data = {
    coeId: 1,
    frequency: "DAILY",
    roleId: 1,
    sendOn: "05:00:00",
    status: true,
    inclusiveEmployees: {
      inclusiveIds: [9],
      inclusiveName: ["JAG Test"],
      isInclusive: true,
    },
    exclusiveEmployees: {
      exclusiveIds: [7, 8],
      exclusiveName: ["Ayushi Test", "Ayushi1 Test"],
      isInclusive: false,
    },
  };

  useEffect(() => {
    setLoader(true)
    reportConfigService.gettriggeredReports().then((res) => {
      console.log("RES", res)
      setReportList(res.data.data)
      setLoader(false)
    })

    // reportConfigService.getReportTypes().then(res =>{
    // return setReportType(res?res.customReport?.concat(res.standardReport):'')})
    setReportType(repType);

    coeService.getCOEList().then((res) => {
      setCoeList(res ? res.data.data : "");
    });
    reportConfigService.getAllFrequency().then((response) => {
      console.log("FREQUENCY RESPONSE", response);
      setFrequencyList(response ? response.data?.data : "");
    });
    // userService.getUserSuggestions().then(res =>{
    //     console.log('user list--', res);
    //     return //setCoeList(res.data.data)
    // })
  }, []);

  console.log("DATAEDIT", editData)
  // useEffect(() => {
  //   setReportList(props.reportDet);
  // }, [props.reportDet]);

  const updatereportList = (list) => {
    setReportList(list);
  };

  const createReport = () => {
    setNewReport(true);
  };

  const deleteReport = (e) => {
    console.log("DELETEREPORT", e);
    if (e) {
      reportConfigService.deleteReport(e).then((res) => {
        console.log(res);
        reportConfigService.getReportConfig().then((res) => {
          props.setReportDet(res);
        });
      });
    }
  };

  console.log("REPORTLIST++", reportList);

  return (
    <>
      {props.loading ? (
        <div className="loader-container">
          <Loading variant="dark" />
        </div>
      ) : (
        <div id="moment-profile" className="">
          {loader ? (
            <div className="loader-container">
              <Loading variant="dark" />
            </div>
          ) : (
            <div className="report">
              {reportList
                ? reportList.length > 0
                  ?
                  // reportList.map((rep) => (
                  //     <ReportGroup
                  //       clientId={props.clientDetails.clientId}
                  //       report={rep}
                  //       deleteReport={(rep) => deleteReport(rep.report_id)}
                  //       reportType={reportType}
                  //       coeList={coeList}
                  //       frequencyList={frequencyList}
                  //       setReportDet={updatereportList}
                  //       setReportList={setReportList}
                  //     />
                  //   ))
                  <ManageSettingReportColumn
                    reportList={reportList}
                    setReportList={setReportList}
                    setEditData={setEditData}
                    coeList={coeList}
                  />
                  : null
                : null}
              {newReport ? (
                coeList ? (
                  <ReportGroup
                    RemoveForm={() => setNewReport(false)}
                    clientId={props.clientDetails.clientId}
                    entityType={true}
                    reportType={reportType}
                    deleteReport={(rep) => deleteReport(rep.report_id)}
                    coeList={coeList}
                    frequencyList={frequencyList}
                    setReportDet={props.setReportDet}
                    setReportList={setReportList}
                  />
                ) : (
                  ""
                )
              ) : editData ? <ReportGroup
                clientId={props.clientDetails.clientId}
                report={editObject}
                // deleteReport={(rep) => deleteReport(rep.report_id)}
                reportType={reportType}
                coeList={coeList}
                frequencyList={frequencyList}
                setReportDet={updatereportList}
                setReportList={setReportList}
              /> : null}
              <div class="pt-3 text-center">
                <a
                  href="javascript:;"
                  class="text-primary-blue font-16 add-rep-btn"
                  onClick={createReport}
                >
                  Add Report
                </a>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
}
