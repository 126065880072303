import React, { useEffect, useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import Loading from "../../Commons/Loading";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

export default function Feedbacks(props) {
  const history = useHistory()
  const [all, setAll] = useState(true);
  const [stats, setStats] = useState(true);
  const [moments, setMoments] = useState(true);
  const [signals, setSignals] = useState(true);
  const [questions, setQuestions] = useState(true);
  const [actions, setActions] = useState(true);
  const [transcript, setTranscript] = useState(true);
  const [foulLanguage, setFoulLanguage] = useState(true);
  const [historyObject, setHistoryObject] = useState("")
  // const [options, setOptions]=useState({
  //   stats:false,
  //   moments:false,
  //   signals:false,
  //   questions:false,
  //   actions:false,
  //   transcript:false,
  //   foulLanguage:false
  // })
 
// console.log("HISTORY",history.location.state.callName)
  function handleChange(event) {
    var value = event.target.value;
    if (value == "all") {
      setAll(true);
      setStats(true);
      setMoments(true);
      setSignals(true);
      setQuestions(true);
      setActions(true);
      setTranscript(true);
      setFoulLanguage(true);
    }
    if (value == "Stats") {
      setAll(false);
      setStats(true);
      setMoments(false);
      setSignals(false);
      setQuestions(false);
      setActions(false);
      setTranscript(false);
      setFoulLanguage(false);
    }
    if (value == "Moments") {
      setAll(false);
      setStats(false);
      setMoments(true);
      setSignals(false);
      setQuestions(false);
      setActions(false);
      setTranscript(false);
      setFoulLanguage(false);
    }
    if (value == "Signals") {
      setAll(false);
      setStats(false);
      setMoments(false);
      setSignals(true);
      setQuestions(false);
      setActions(false);
      setTranscript(false);
      setFoulLanguage(false);
    }
    if (value == "Questions") {
      setAll(false);
      setStats(false);
      setMoments(false);
      setSignals(false);
      setQuestions(true);
      setActions(false);
      setTranscript(false);
      setFoulLanguage(false);
    }
    if (value == "Actions") {
      setAll(false);
      setStats(false);
      setMoments(false);
      setSignals(false);
      setQuestions(false);
      setActions(true);
      setTranscript(false);
      setFoulLanguage(false);
    }
    if (value == "Transcript") {
      setAll(false);
      setStats(false);
      setMoments(false);
      setSignals(false);
      setQuestions(false);
      setActions(false);
      setTranscript(true);
      setFoulLanguage(false);
    }
    if (value == "FoulLanguage") {
      setAll(false);
      setStats(false);
      setMoments(false);
      setSignals(false);
      setQuestions(false);
      setActions(false);
      setTranscript(false);
      setFoulLanguage(true);
    }
  }

  console.log(props?.callFeedback,"CALLFEEDBACK")
  let tabs = props?.callFeedback.map(item=>{
    return item.type
  })
  let values = props?.callFeedback.map(item=>{
    return item.postTextData
  })
  let sentences = values.flat(1).map(item=>{
    return item.postText
  })
  let comments = values?.map(item=>{
    return item.comments
  })

  const dateFunc = (date)=>{
    let d = date.split("T")
    console.log(d)
    return `${d[0].split("-")[2]}-${d[0].split("-")[1]}-${d[0].split("-")[0]}`
  }

  useEffect(()=>{
    setHistoryObject(history?.location?.state)
  },[])

  console.log(historyObject)

  return (
    <>
    {props.loader?(
      <div className="loader-container">
        <Loading variant="dark"/>
      </div>
    ):
    <div className="tab-pane" role="tabpanel">
      <div className="d-flex align-items-center feedback-headder my-2">
        <div className="feedback-heading">
          <h5>Feedback</h5>
        </div>
        <div className="feedback-fillter">
          <div className="input-group">
            <span className="input-group-text" id="basic-addon1">
              <i className="bx bx-filter-alt" />
            </span>
            <select
              className="form-select form-select-sm mb-3"
              id="feedbackFillterSelect"
              onChange={handleChange}
            >
              <option value="all" selected>
                All
              </option>
              <option value="Stats">Stats</option>
              <option value="Moments">Moments</option>
              {historyObject?.callName === "SINGLE_CALL" ?(
              <>
              <option value="Signals">Signals</option>
              <option value="Questions">Questions</option>
              <option value="Actions">Actions</option>
              <option value="Transcript">Transcript</option>
              <option value="FoulLanguage">Foul Language</option>
              </>
              ):''}
            </select>
          </div>
        </div>
      </div>
      {/* {
                        all ? */}
      <div className="moment-list-group-scroll">
        <div className="accordion" id="accordionfeedback">
          {props?.callFeedback.map(item=>{
            return(
          <Accordion defaultActiveKey="0">
            {item.type==='STATS'&&stats? (
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  <div className="accordion-button-stats">
                    <h6>
                      <i className="bx bx-stats me-1" /> Stats
                    </h6>
                  </div>
                </Accordion.Header>
                {item.postTextData.map(value=>{
                return(
                  <Accordion.Body>
                    <p className="mb-0">
                      {value.postText}
                      {/* <span className="badge btn btn-outline-success font-normal font-12 rounded-pill">
                        4 Min. 5 Sec.
                      </span> */}
                    </p>
                    {value.comments.map((lines,id)=>{
                    return(
                      <div className="accordion-body">
                        <div className="list-group-item">
                          <div className="d-flex w-100 justify-content-between">
                            <h5 className="mb-0 font-16">
                              <i className="bx bx-user-voice me-1" />
                              {JSON.parse(localStorage.getItem("USER_DETAIL"))?.clientName}
                            </h5>
                            <small className="text-muted">
                              {dateFunc(value?.createdOn[id])}
                            </small>
                          </div>
                          <p className="mb-1">
                            {lines}
                          </p>
                        </div>
                      </div>
                    )})}
                  </Accordion.Body>
                )})}
              </Accordion.Item>
            ) : null}

            {item.type==='MOMENTS'&& moments ? (
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  <div className="accordion-button-moments">
                    <h6>
                      <i className="bx bx-cut me-1" /> Moments
                    </h6>
                    
                  </div>
                </Accordion.Header>
                {item.postTextData.map(value=>{
                return(
                  <Accordion.Body>
                    <p className="mb-0">
                      {props.type=='chat'?value.postText.replace('call','chat').replace('CALL','CHAT').replace('Call','Chat'):value.postText}
                    </p>
                    {value.comments.map((lines,id)=>{
                      return(
                      <div className="accordion-body">
                        <div className="list-group-item">
                          <div className="d-flex w-100 justify-content-between">
                            <h5 className="mb-0 font-16">
                              <i className="bx bx-user-voice me-1" />
                              {JSON.parse(localStorage.getItem("USER_DETAIL"))?.clientName}
                            </h5>
                            <small className="text-muted">
                            {dateFunc(value?.createdOn[id])}
                            </small>
                          </div>
                          <p className="mb-1">
                            {lines}
                          </p>
                        </div>
                      </div>
                    )})}
                  </Accordion.Body>
                )
                })}
              </Accordion.Item>
            ) : null}
            {item.type==='SIGNALS'&&signals ? (
              <Accordion.Item eventKey="2">
                <Accordion.Header>
                  <div className="accordion-button-moments">
                    <h6>
                      <i className="bx bx-wifi-2 me-1" /> Signals
                    </h6>
                  </div>
                </Accordion.Header>
                {item.postTextData.map(value=>{
                return(
                  <Accordion.Body>
                    <p className="mb-0">
                      {value.postText}
                    </p>
                    {value.comments.map((lines,id)=>{
                    return(
                      <div className="accordion-body">
                        <div className="list-group-item">
                          <div className="d-flex w-100 justify-content-between">
                            <h5 className="mb-0 font-16">
                              <i className="bx bx-user-voice me-1" />
                              {JSON.parse(localStorage.getItem("USER_DETAIL"))?.clientName}
                            </h5>
                            <small className="text-muted">
                              {dateFunc(value?.createdOn[id])}
                            </small>
                          </div>
                          <p className="mb-1">
                            {lines}
                          </p>
                        </div>
                      </div>
                    )})}
                  </Accordion.Body>
                )
                })}
              </Accordion.Item>
            ) : null}
            {item.type==='QUESTIONS'&&questions?(
              <Accordion.Item eventKey="3">
                <Accordion.Header>
                  <div className="accordion-button-moments">
                    <h6>
                      <i className="bx bx-question-mark me-1" /> Questions
                    </h6>
                  </div>
                </Accordion.Header>
                {item.postTextData.map(value=>{
                return(
                  <Accordion.Body>
                    <p className="mb-0">
                      {value.postText}
                    </p>
                    {value.comments.map((lines,id)=>{
                    return (
                    <div className="accordion-body">
                      <div className="list-group-item">
                        <div className="d-flex w-100 justify-content-between">
                          <h5 className="mb-0 font-16">
                            <i className="bx bx-user-voice me-1" />
                            {JSON.parse(localStorage.getItem("USER_DETAIL"))?.clientName}
                          </h5>
                          <small className="text-muted">
                            {dateFunc(value?.createdOn[id])}
                          </small>
                        </div>
                        <p className="mb-1">
                          {lines}
                        </p>
                      </div>
                    </div>)})}
                  </Accordion.Body>)
                })}
              </Accordion.Item>
            ) : null}
          
            {item.type==='ACTIONS'&&actions ? (
              <Accordion.Item eventKey="4">
                <Accordion.Header>
                  <div className="accordion-button-moments">
                    <h6>
                      <i className="lni lni-list me-1" /> Actions
                    </h6>
                    
                  </div>
                </Accordion.Header>
                {item.postTextData.map(value=>{
                return(
                  <Accordion.Body>
                    <p className="mb-0">
                      {value.postText}
                    </p>
                    {value.comments.map((lines,id)=>{
                    return (
                      <div className="accordion-body">
                        <div className="list-group-item">
                          <div className="d-flex w-100 justify-content-between">
                            <h5 className="mb-0 font-16">
                              <i className="bx bx-user-voice me-1" />
                              {JSON.parse(localStorage.getItem("USER_DETAIL"))?.clientName}
                            </h5>
                            <small className="text-muted">
                              {dateFunc(value?.createdOn[id])}
                            </small>
                          </div>
                          <p className="mb-1">
                            {lines}
                          </p>
                        </div>
                      </div>)})}
                  </Accordion.Body>
                  )
                })}
              </Accordion.Item> 
            ): null}
            {item.type==='TRANSCRIPT'&&transcript &&props.type!='chat' ? (
              <Accordion.Item eventKey="5">
                <Accordion.Header>
                  <div className="accordion-button-moments">
                    <h6>
                      <i className="bx bx-pen me-1" /> Transcript
                    </h6>
                  </div>
                </Accordion.Header>
                {item.postTextData.map(value=>{
                return(
                  <Accordion.Body>
                  <p className="mb-0">
                      {value.postText}
                  </p>
                  {value.comments.map((lines,id)=>{
                  return(
                    <div className="accordion-body">
                      <div className="list-group-item">
                        <div className="d-flex w-100 justify-content-between">
                          <h5 className="mb-0 font-16">
                            <i className="bx bx-user-voice me-1" />
                            {JSON.parse(localStorage.getItem("USER_DETAIL"))?.clientName}
                          </h5>
                          <small className="text-muted">
                            {dateFunc(value?.createdOn[id])}
                          </small>
                        </div>
                        <p className="mb-1">
                          {lines}
                        </p>
                      </div>
                    </div>
                      )
                    })}
                  </Accordion.Body>
                )})} 
              </Accordion.Item>
            ) : null}
            {item.type==='FOUL_LANGUAGE'&&foulLanguage ? (
              <Accordion.Item eventKey="6">
                <Accordion.Header>
                  <div className="accordion-button-moments">
                    <h6>
                      <i className="bx bx-user-voice me-1" /> Foul Language
                    </h6>
                    
                  </div>
                </Accordion.Header>
                {item.postTextData.map(value=>{
                return(
                <Accordion.Body>
                  <p className="mb-0">
                    {value.postText}
                  </p>
                  {value.comments.map((lines,id)=>{
                  return(
                  <div className="accordion-body">
                    <div className="list-group-item">
                      <div className="d-flex w-100 justify-content-between">
                        <h5 className="mb-0 font-16">
                          <i className="bx bx-user-voice me-1" />
                          {JSON.parse(localStorage.getItem("USER_DETAIL"))?.clientName}
                        </h5>
                        <small className="text-muted">
                          {dateFunc(value?.createdOn[id])}
                        </small>
                      </div>
                      <p className="mb-1">
                        {lines}
                      </p>
                    </div>
                  </div>)})}
                </Accordion.Body>
                )})}
              </Accordion.Item>
            ) : null}
          </Accordion> )})}
        </div>
      </div>
      {/* : null
                      }
                       */}
    </div>
  }
  </>
  );
}
