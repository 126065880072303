import { Card, Col } from "react-bootstrap";
import ReactApexChart from "react-apexcharts";
import { Popover, OverlayTrigger } from "react-bootstrap";
import Loading from "../../../Commons/Loading";
import { CommonSeriesSettingsHoverStyle } from "devextreme-react/chart";
import ComplianceView from "./ComplianceView";
import { useMemo, useState } from "react";

export default function AhtAndCompliance(props) {
  const [show, setShow] = useState()
  let grapData = props.data.data || [];
  let scroll = props.scroll;

  const dataArr = useMemo(() => {
    const allMonthNumbers = Array.from(new Set(grapData?.flatMap(item => item?.months?.map(month => month.monthNumber))));
    const sortedMonthNumbers = allMonthNumbers.sort((a, b) => a - b);

    // Find the index of the minimum month number
    const minMonthIndex = sortedMonthNumbers.indexOf(Math.min(...allMonthNumbers));

    // Create the new array
    const computedArray = grapData?.map(item => {
      const countsArray = Array.from({ length: sortedMonthNumbers.length }, (_, index) => {
        const circularIndex = (index + minMonthIndex) % sortedMonthNumbers.length;
        const monthNumber = sortedMonthNumbers[circularIndex];
        const monthData = item?.months?.find(month => month.monthNumber === monthNumber);
        return monthData ? monthData.count : 0;
      });
      return countsArray;
    });

    return computedArray;
    
  }, [grapData]);

  const transposedData = dataArr[0]?.map((_, i) => dataArr?.map(row => row[i]));

  console.log(props,"aht")

  

  const series1 = [];
  const categories1 = [];
  const categories2 = [];
  const countSeries = [];
  const monthNameSeries = [];

  const seriesVal = [];
  const categoriesVal_months = [];
  const categoriesVal_moments = [];
  const countVal = [];
  const monthData = [];

  const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sept',
    'Oct',
    'Nov',
    'Dec',
  ];

  let mon1 = transposedData &&  transposedData[0] || [];
  let mon2 = transposedData &&  transposedData[1] || [];
  let mon3 = transposedData &&  transposedData[2] || [];

  let mon1Name = '';
  let mon2Name = '';
  let mon3Name = '';

  grapData &&
    grapData.forEach((data) => {
      seriesVal.push(data.result);
      categoriesVal_months.push(data.month);
      categoriesVal_moments.push(data.moment);
      countVal.push(data.count);
      monthData.push(data?.months);

      // if (data?.months && data.months.length > 0) {
      //   for (let i = 0; i < Math.min(data.months.length, 3); i++) {
      //     const month = data.months[i];
      //     if (i === 0) {
      //       mon1.push(month?.count || '');
      //     } else if (i === 1) {
      //       mon2.push(month?.count || '');
      //     } else if (i === 2) {
      //       mon3.push(month?.count || '');
      //     }
      //   }
      // } else {
      //   // If data.months is undefined or empty, push empty strings to the arrays
      //   mon1.push('');
      //   mon2.push('');
      //   mon3.push('');
      // }
    });

  // Fill arrays to have a length of 5 with empty strings if needed
  mon1 = mon1.concat(Array(Math.max(0, 5 - mon1.length)).fill(''));
  mon2 = mon2.concat(Array(Math.max(0, 5 - mon2.length)).fill(''));
  mon3 = mon3.concat(Array(Math.max(0, 5 - mon3.length)).fill(''));

  mon1.length = 5;
  mon2.length = 5;
  mon3.length = 5;

  for (let i = 0; i < 5; i++) {
    series1.push(seriesVal[i] ? seriesVal[i] : '');
    const monthIndex = categoriesVal_months[i];
    categories1.push(
      monthNames[monthIndex - 1] ? monthNames[monthIndex - 1] : ''
    );
    categories2.push(categoriesVal_moments[i] ? categoriesVal_moments[i] : '');
    countSeries.push(countVal[i] ? countVal[i] : '');
    monthNameSeries.push(monthData[i] ? monthData[i] : []);
  }

  monthNameSeries?.forEach((data) => {
    if (data?.length > 0) {
      mon1Name = monthNames[data[0]?.monthNumber - 1];
      mon2Name =
        data[1]?.monthNumber !== undefined
          ? monthNames[data[1]?.monthNumber - 1]
          : '';
      mon3Name =
        data[2]?.monthNumber !== undefined
          ? monthNames[data[2]?.monthNumber - 1]
          : '';
    }
  });

  console.log(series1, 'ser1');
  console.log(seriesVal, 'serval');
  console.log(categories1, 'cat1');
  console.log(categories2, categoriesVal_moments, 'cat2');
  console.log(countSeries, 'count');
  console.log(monthData, 'mond');
  console.log(monthNameSeries, 'num');
  console.log(grapData, 'data1');
  console.log(transposedData, 'data2')
  console.log(mon1, mon2, mon3, 'hell');
  console.log(mon1Name, mon2Name, mon3Name, 'monname');


  let series;
  let options;

  if (props.wgt_data.wgt_name === 'AHT') {
    series = [
      {
        name: 'Time',
        // type:'column',
        data: series1 ? series1 : '',
      },
      {
        name: 'Count',
        // type:'line',
        data: countSeries ? countSeries : '',
      },
    ];

    options = {
      chart: {
        type: 'line',
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      annotations: {
        yaxis: [
          {
            x: 500,
            borderColor: '#0077B6 ',
            label: {
              borderColor: '#0077B6',
              style: {
                color: '#0077B6',
                backgroundColor: '#0077B6',
              },
            },
          },
        ],
      },
      fill: {
        color: '#0077B6',
        opacity: 1,
      },
      plotOptions: {
        bar: {
          columnWidth: '40%',
          horizontal: false,
          opacity: '1',
        },
      },
      grid: {
        xaxis: {
          lines: {
            show: false,
          },
        },
        yaxis: {
          // reversed: true,
          lines: {
            show: true,
          },
        },
      },
      colors: ['#0077B6', '#ffc107'],
      xaxis: {
        categories: categories1,
        group: {
          style: {
            fontSize: '11px',
            fontWeight: 800,
            align: 'center',
            textAlign: 'right',
          },
          groups: [
            {
              title: 'Months',
              cols: series1 ? series1.length : '',
            },
          ],
        },
      },
      yaxis: [
        {
          title: {
            text: 'Time (Sec)',
          },
        },
        {
          opposite: true,
          title: {
            text: 'Count',
          },
        },
      ],
    };
  } else if (props.wgt_data.wgt_name === 'Compliance') {
    series = [
      {
        name: `${mon1Name}`,
        data: mon1,
      },
      {
        name: `${mon2Name}`,
        data: mon2,
      },
      {
        name: `${mon3Name}`,
        data: mon3,
      },
    ];

    options = {
      chart: {
        type: 'bar',
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      annotations: {
        yaxis: [
          {
            x: 500,
            borderColor: '#0077B6 ',
            label: {
              borderColor: '#0077B6',
              style: {
                color: '#0077B6',
                backgroundColor: '#0077B6',
              },
            },
          },
        ],
      },
      fill: {
        color: '#0077B6',
        opacity: 1,
      },
      plotOptions: {
        bar: {
          rowWidth: '15%',
          horizontal: true,
          opacity: '1',
        },
      },
      grid: {
        xaxis: {
          lines: {
            show: false,
          },
        },
        yaxis: {
          // reversed: true,
          lines: {
            show: true,
          },
        },
      },
      colors: ['#0077B6', '#ffc107', '#29cc39'],
      xaxis: {
        categories: categories2,
        title: {
          text: 'Count',
          style: {
            color: '#666666',
            fontSize: '11px',
            textAlign: 'right',
          },
        },
      },
    };
  }

  const clickHandler = () => setShow(true)

  const closeButton = () => setShow(false)
  
  const popoverr = () => (
    <Popover
      id='popover-trigger-click-root-close'
      title='right'
      style={{ marginLeft: '13px' }}
    >
      <h3 className='popover-header'>{props.wgt_data.wgt_des}</h3>
    </Popover>
  );

  return (
    <>
      <Col xs={props.width}>
        <Card className='radius-10 w-100 aht-chart-box'>
          <Card.Body>
            <div className='d-flex align-items-center'>
              <div>
                <h6 className='font-weight-bold'>{props.wgt_data.wgt_name}</h6>
              </div>
              <div style={{ marginBottom: '5px', marginLeft: '8px' }}>
                <OverlayTrigger
                  trigger={['hover', 'focus']}
                  placement='bottom'
                  overlay={popoverr()}
                  rootClose
                >
                  <i className='fadeIn animated bx bx-info-circle text-primary-blue'></i>
                </OverlayTrigger>
              </div>
              {props.wgt_data.wgt_name === "Compliance" ? <div className=" font-22 ms-auto">
                <p className="mb-0 ms-auto view-all-agents-btn">
                  <a
                    onClick={clickHandler}
                    target="_blank"
                    className="btn btn-sm radius-10"
                  >
                    View All
                  </a>
                </p>
              </div> : ''}
            </div>
            <div id='chart' style={{ width: '100%', height: '260px' }}>
              {!scroll ? (
                grapData && grapData.length >= 0 ? (
                  <ReactApexChart
                    height='290px'
                    type={'bar'}
                    options={options}
                    series={series}
                  />
                ) : (
                  ''
                )
              ) : (
                <div className='loader-container dashboard-loder sellers-loader'>
                  <Loading />
                </div>
              )}
            </div>
          </Card.Body>
        </Card>
      </Col>
      <ComplianceView show={show} closeButton={closeButton} name={props?.wgt_data?.wgt_name} data={props?.data?.data} values={dataArr} mon1={mon1Name} mon2={mon2Name} mon3={mon3Name}/>
    </>
  );
}
