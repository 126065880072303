export const callListing = {
    "data": {
        "availablePages": 2.0,
        "currentPage": 1.0,
        "response": [{
                "details": [],
                "callAlarms": [],
                "callColour": null,
                "callId": 7541,
                "callRatingName": null,
                "callRec": [],
                "callScore": 100,
                "customerName": "Joseph",
                "date": "31/01/2023 12:10:00",
                "filename": "Devis_16763526.wav",
                "moments": [
                    "Self introduction",
"Relevant Opening",
"Acknowledgment",
"Empathy",
"Issue Identification",
"Correct Response",
"Relevant Closing",
"End Greeting"


                ],
                "parameters": {"audit": [
                        {
                            "k": "1123",
                            "v": "1",
                            "remark": "",
                            "time": [],
                            "moment_group_id": "879",
                            "l": "self_introduction_missing"
                        },
                        {
                            "k": "1124",
                            "v": "1",
                            "remark": "",
                            "time": [],
                            "moment_group_id": "879",
                            "l": "relevant_opening"
                        },
                        {
                            "k": "1125",
                            "v": "1",
                            "remark": "",
                            "time": [],
                            "moment_group_id": "880",
                            "l": "acknowledgment"
                        },
                        {
                            "k": "1126",
                            "v": "1",
                            "remark": "",
                            "time": [],
                            "moment_group_id": "880",
                            "l": "empathy"
                        },
                        {
                            "k": "1127",
                            "v": "1",
                            "remark": "",
                            "time": [],
                            "moment_group_id": "880",
                            "l": "issue_identification"
                        },
                        {
                            "k": "1128",
                            "v": "1",
                            "remark": "Agent did not confirmed the details of customer . ",
                            "time": [],
                            "moment_group_id": "880",
                            "l": "correct_response"
                        },
                        {
                            "k": "1129",
                            "v": "1",
                            "remark": "",
                            "time": [],
                            "moment_group_id": "881",
                            "l": "relevant_closing"
                        },
                        {
                            "k": "1130",
                            "v": "1",
                            "remark": "",
                            "time": [],
                            "moment_group_id": "881",
                            "l": "end_greeting"
                        }]
                    
                 
                },

                "phone": "2137657101",
                "email": "devis@company.com",
                "pitchColour": null,
                "pitchRatingName": null,
                "pitchScore": 5.0,
                "processingTime": "Sat, 25 Feb 2023 00:00:00 GMT",
                "ratingColour": null,
                "recordingUrl": "https://demo-odio-calls.s3.ap-south-1.amazonaws.com/merged_calls/bajaj/Pratibha.Khopade__ShubhamPSFOutboundCampaign__26__-1__8219395419__2023-01-31_13-35-29.wav",
                "sellerName": "Devis",
                "talkDuration": "00:02:33",
                "userExternalId": 5362616,
                "employeeId": "5362616",
                "coeName": [
                    "Los Angeles"
                ],
                "coeIds": [
                    101
                ],
                "totalCalls": 1,
                "audioRecordingUrl": null
            },{
                "details": [],
                "callAlarms": [
                   
                ],
                "callColour": null,
                "callId": 7542,
                "callRatingName": null,
                "callRec": [],
                "callScore": 87.8,
                "customerName": "Kevin",
                "date": "18/02/2023 03:50:00",
                "filename": "Leos_73871732.wav",
                "moments": [
                    "Self introduction",
"Relevant Opening",
"Acknowledgment",

"Issue Identification",
"Relevant Closing",
"End Greeting"

                ],
                "parameters": {
                    "audit": [
                        {
                            "k": "1123",
                            "v": "1",
                            "remark": "",
                            "time": [],
                            "moment_group_id": "879",
                            "l": "self_introduction_missing"
                        },
                        {
                            "k": "1124",
                            "v": "1",
                            "remark": "",
                            "time": [],
                            "moment_group_id": "879",
                            "l": "relevant_opening"
                        },
                        {
                            "k": "1125",
                            "v": "1",
                            "remark": "",
                            "time": [],
                            "moment_group_id": "880",
                            "l": "acknowledgment"
                        },
                        {
                            "k": "1126",
                            "v": null,
                            "remark": "",
                            "time": [],
                            "moment_group_id": "880",
                            "l": "empathy"
                        },
                        {
                            "k": "1127",
                            "v": "1",
                            "remark": "",
                            "time": [],
                            "moment_group_id": "880",
                            "l": "issue_identification"
                        },
                        {
                            "k": "1128",
                            "v": "0",
                            "remark": "Agent did not confirmed the details of customer . ",
                            "time": [],
                            "moment_group_id": "880",
                            "l": "correct_response"
                        },
                        {
                            "k": "1129",
                            "v": "1",
                            "remark": "",
                            "time": [],
                            "moment_group_id": "881",
                            "l": "relevant_closing"
                        },
                        {
                            "k": "1130",
                            "v": "1",
                            "remark": "",
                            "time": [],
                            "moment_group_id": "881",
                            "l": "end_greeting"
                        }
                    ]
                },
                "phone": "2137657102",
                "email": "leos@company.com",
                "pitchColour": null,
                "pitchRatingName": null,
                "pitchScore": 48.0,
                "processingTime": "Sat, 25 Feb 2023 00:00:00 GMT",
                "ratingColour": null,
                "recordingUrl": "https://demo-odio-calls.s3.ap-south-1.amazonaws.com/merged_calls/bajaj/Pratibha.Khopade__ShubhamPSFOutboundCampaign__26__-1__9434821888__2023-01-31_15-33-55.wav",
                "sellerName": "Leos",
                "talkDuration": "00:03:08",
                "userExternalId": 68296252,
                "employeeId": "68296252",
                "coeName": [
                    "Los Angeles"
                ],
                "coeIds": [
                    101
                ],
                "totalCalls": 1,
                "audioRecordingUrl": null
            },
{
                "details": [],
                "callAlarms": [
                "Acknowledgment",
                    
                ],
                "callColour": null,
                "callId": 7543,
                "callRatingName": null,
                "callRec": [],
                "callScore": 78.5,
                "customerName": "valleria",
                "date": "23/02/2023 05:15:00",
                "filename": "Ken_83628302.wav",
                "moments": ["Self introduction",
"Relevant Opening",

"Empathy",

"Relevant Closing",
"End Greeting"

                ],
                "parameters": {"audit": [
                        {
                            "k": "1123",
                            "v": "1",
                            "remark": "",
                            "time": [],
                            "moment_group_id": "879",
                            "l": "self_introduction_missing"
                        },
                        {
                            "k": "1124",
                            "v": "1",
                            "remark": "",
                            "time": [],
                            "moment_group_id": "879",
                            "l": "relevant_opening"
                        },
                        {
                            "k": "1125",
                            "v": "0",
                            "remark": "",
                            "time": [],
                            "moment_group_id": "880",
                            "l": "acknowledgment"
                        },
                        {
                            "k": "1126",
                            "v": "1",
                            "remark": "",
                            "time": [],
                            "moment_group_id": "880",
                            "l": "empathy"
                        },
                        {
                            "k": "1127",
                            "v": "0",
                            "remark": "",
                            "time": [],
                            "moment_group_id": "880",
                            "l": "issue_identification"
                        },
                        {
                            "k": "1128",
                            "v": "0",
                            "remark": "Agent did not confirmed the details of customer . ",
                            "time": [],
                            "moment_group_id": "880",
                            "l": "correct_response"
                        },
                        {
                            "k": "1129",
                            "v": "1",
                            "remark": "",
                            "time": [],
                            "moment_group_id": "881",
                            "l": "relevant_closing"
                        },
                        {
                            "k": "1130",
                            "v": "1",
                            "remark": "",
                            "time": [],
                            "moment_group_id": "881",
                            "l": "end_greeting"
                        }
                    ]
                },
                "phone": "2137657103",
                "email": "ken@company.com",
                "pitchColour": null,
                "pitchRatingName": null,
                "pitchScore": 88.0,
                "processingTime": "Sat, 25 Feb 2023 00:00:00 GMT",
                "ratingColour": null,
                "recordingUrl": "https://demo-odio-calls.s3.ap-south-1.amazonaws.com/merged_calls/bajaj/Pratibha.Khopade__ShubhamPSFOutboundCampaign__26__-1__9232330990__2023-01-31_15-54-51.wav",
                "sellerName": "Ken",
                "talkDuration": "00:06:49",
                "userExternalId": 6262823,
                "employeeId": "6262823",
                "coeName": [
                    "Los Angeles"
                ],
                "coeIds": [
                    101
                ],
                "totalCalls": 1,
                "audioRecordingUrl": null
            },

            
            {
                "details": [],
                "callAlarms": [],
                "callColour": null,
                "callId": 7544,
                "callRatingName": null,
                "callRec": [],
                "callScore": 85.6,
                "customerName": "Jen",
                "date": "12/03/2023 02:42:00",
                "filename": "Kelin_73253102.wav",
                "moments": [
                   "Self introduction",
"Relevant Opening",
"Acknowledgment",
"Empathy",

"Correct Response",
"Relevant Closing",
"End Greeting"

                ],
                "parameters": {
                    "audit": [
                        {
                            "k": "1123",
                            "v": "1",
                            "remark": "",
                            "time": [],
                            "moment_group_id": "879",
                            "l": "self_introduction_missing"
                        },
                        {
                            "k": "1124",
                            "v": "1",
                            "remark": "",
                            "time": [],
                            "moment_group_id": "879",
                            "l": "relevant_opening"
                        },
                        {
                            "k": "1125",
                            "v": "1",
                            "remark": "",
                            "time": [],
                            "moment_group_id": "880",
                            "l": "acknowledgment"
                        },
                        {
                            "k": "1126",
                            "v": "1",
                            "remark": "",
                            "time": [],
                            "moment_group_id": "880",
                            "l": "empathy"
                        },
                        {
                            "k": "1127",
                            "v": "0",
                            "remark": "",
                            "time": [],
                            "moment_group_id": "880",
                            "l": "issue_identification"
                        },
                        {
                            "k": "1128",
                            "v": "1",
                            "remark": "Agent did not confirmed the details of customer . ",
                            "time": [],
                            "moment_group_id": "880",
                            "l": "correct_response"
                        },
                        {
                            "k": "1129",
                            "v": "1",
                            "remark": "",
                            "time": [],
                            "moment_group_id": "881",
                            "l": "relevant_closing"
                        },
                        {
                            "k": "1130",
                            "v": "1",
                            "remark": "",
                            "time": [],
                            "moment_group_id": "881",
                            "l": "end_greeting"
                        }]
                },
                "phone": "2137657104",
                "email": "kelin@company.com",
                "pitchColour": null,
                "pitchRatingName": null,
                "pitchScore": 57.0,
                "processingTime": "Sat, 25 Feb 2023 00:00:00 GMT",
                "ratingColour": null,
                "recordingUrl": "https://demo-odio-calls.s3.ap-south-1.amazonaws.com/merged_calls/bajaj/PrathameshS.Sawant__RajeshPSFOutboundCampaign__37__-1__9376108807__2023-01-31_09-34-44.wav",
                "sellerName": "Kelin",
                "talkDuration": "00:24:47",
                "userExternalId": 62729363,
                "employeeId": "62729363",
                "coeName": [
                    "Los Angeles"
                ],
                "coeIds": [
                    101
                ],
                "totalCalls": 1,
                "audioRecordingUrl": null
            },
{
                "details": [],
                "callAlarms": [
                    
            
                ],
                "callColour": null,
                "callId": 7545,
                "callRatingName": null,
                "callRec": [],
                "callScore": 89.0,
                "customerName": "Justin",
                "date": "20/03/2023 04:47:00",
                "filename": "Ros_72836226.wav",
                "moments": [
                    "Self introduction",
"Relevant Opening",
"Acknowledgment",
"Empathy",
"Issue Identification",
"Correct Response",

"End Greeting"

                ],
                "parameters": {
                    "audit": [
                        {
                            "k": "1123",
                            "v": "1",
                            "remark": "",
                            "time": [],
                            "moment_group_id": "879",
                            "l": "self_introduction_missing"
                        },
                        {
                            "k": "1124",
                            "v": "1",
                            "remark": "",
                            "time": [],
                            "moment_group_id": "879",
                            "l": "relevant_opening"
                        },
                        {
                            "k": "1125",
                            "v": "1",
                            "remark": "",
                            "time": [],
                            "moment_group_id": "880",
                            "l": "acknowledgment"
                        },
                        {
                            "k": "1126",
                            "v": "1",
                            "remark": "",
                            "time": [],
                            "moment_group_id": "880",
                            "l": "empathy"
                        },
                        {
                            "k": "1127",
                            "v": "",
                            "remark": "",
                            "time": [],
                            "moment_group_id": "880",
                            "l": "issue_identification"
                        },
                        {
                            "k": "1128",
                            "v": "1",
                            "remark": "Agent did not confirmed the details of customer . ",
                            "time": [],
                            "moment_group_id": "880",
                            "l": "correct_response"
                        },
                        {
                            "k": "1129",
                            "v": "0",
                            "remark": "",
                            "time": [],
                            "moment_group_id": "881",
                            "l": "relevant_closing"
                        },
                        {
                            "k": "1130",
                            "v": "1",
                            "remark": "",
                            "time": [],
                            "moment_group_id": "881",
                            "l": "end_greeting"
                        }]
                },
                "phone": "2137657105",
                "email": "ros@company.com",
                "pitchColour": null,
                "pitchRatingName": null,
                "pitchScore": 93.0,
                "processingTime": "Sat, 25 Feb 2023 00:00:00 GMT",
                "ratingColour": null,
                "recordingUrl": "https://demo-odio-calls.s3.ap-south-1.amazonaws.com/merged_calls/bajaj/Pratiksha.jain__RajeshPSFOutboundCampaign__37__-1__9848639178__2023-01-31_16-17-13.wav",
                "sellerName": "Ros",
                "talkDuration": "00:16:03",
                "userExternalId": 62827467,
                "employeeId": "62827467",
                "coeName": [
                    "Los Angeles"
                ],
                "coeIds": [
                    101
                ],
                "totalCalls": 1,
                "audioRecordingUrl": null
            }

]
          
    },
    "status": 0,
    "message": "Success"
}
