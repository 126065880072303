import { useState } from "react"
import { apiCall } from "../Components/Services/Interceptor"
import toast from "../Components/Toast/toaster"
import "./Pages.css"
import logoIcon from "../assets/images/logoIcon.png"
import odio_logo_blue from "../assets/images/odio_logo_blue.png"
import Loading from "../Components/Commons/Loading"
import { useHistory } from "react-router-dom"
import toaster from "../Components/Toast/toaster"

const RecordingSubmit = ()=>{

    const [uploadedFile, setUploadedFile] = useState("")
    const [url, setUrl] = useState("")
    const [response, setResponse] = useState("")
    const [button, setButton] = useState("")
    const [loading, setLoading] = useState(false)
    const [loader, setLoader] = useState(true)
    const history = useHistory()
    const [errorMessage, setErrorMessage] = useState("")

    const handelFileUpload = async(e)=>{
        console.log(e)
        setUploadedFile(e.target.files[0])
    }

    const handleClick = (e)=>{
        e.target.value=null
    }

    const onSubmitHandler = async()=>{
        console.log("click")
        let UPLOAD_FILE = '/odio/api/reports/upload/trans'
        let formData = new FormData();
        formData.append("recording", uploadedFile);
        await apiCall.post(UPLOAD_FILE, formData).then(res=>{
            if(res&&uploadedFile!=''&&url==''){
                toaster.success("Uploaded correctly")
                setUploadedFile('')
            }
            else if(res&&uploadedFile!=''&&url!=''){
                setErrorMessage("Audio File already selected")
            }
            else{
                toast.error("No file selected")
            }
        })
    }

    const recordingUrlHandler = (e)=>{
        setUrl(e.target.value)
        if(errorMessage!=''){
            setErrorMessage('')
        }
    }

    const responseHandler = (e)=>{
        setResponse(e.target.value)
    }

    const onSubmitUrlHandler = async()=>{
        if(uploadedFile!=''&&url!=''){
            setErrorMessage("Audio File already selected")
        }
        else{
            await apiCall.post(`/odio/api/reports/upload/trans?recordingUrl=${url}`).then(res=>{
                if(res){
                    toaster.success("Submitted")
                }
            });
            setUrl('')
        }
    }

    const onSubmitUrlOrFileHandler = (buttonClicked)=>{
        if(buttonClicked=='file'){
            setButton("file")
            onSubmitHandler(buttonClicked)
        }
        else if(buttonClicked=='url'){
            setButton("url")
            onSubmitUrlHandler(buttonClicked)
        }
    }

    const onSubmitResponseHandler = async()=>{
        setLoading(true)
        await apiCall.post(`/odio/api/reports/filename/trans?fileName=${response}`).then(res=>{
            if(res){
                console.log(res)
                history.push("/redirect_transcript",{
                    fileName:res.data.response.fileName,
                    trans:res.data.response.transcription
                })
            }
        })
        setTimeout(() => {
            setLoading(false)
        }, 1000);
    }

    setTimeout(() => {
        setLoader(false)
    }, 1000);

    return(

        <>
        {loader?
        <div className="loader-container">
            <Loading variant="dark"/>
        </div>:
        <div className="wrapper-forget-content">
            <div className='odio-header'>
                <div>
                    <img src={logoIcon} className="logo-icon-page" alt="logo icon"/>
                </div>
                <div>
                    <h4 className='odio-logo-text'>
                        <img src={odio_logo_blue} className='logo-imgg' alt='logo-icon'/>
                    </h4>
                </div>
            </div>
            <div id="upload" className="page-header">
                <h6 style={{color:"#272727"}}>UPLOAD CALL RECORDINGS</h6>    
            </div>
            <div id="upload-rec" className="call-upload">
                <div className="bg-image" style={{padding:"30px",height:"423px"}}>
                    <div className="mx-1 file-upload-content rec-submit">
                        <h6>Recording File</h6>
                        <fieldset>
                            <label for="fileselect">Drop your file here or Browse
                                <br/>Supported formats: .mp3/.wav
                            </label>
                            <input type="file" id="fileselect" name="fileselect" multiple="multiple" accept=".wav" onChange={handelFileUpload} onClick={handleClick}/>
                        </fieldset>
                            {uploadedFile?<div className="uploaded-file">
                                <p className="mb-0"><b>File to Upload:</b>{uploadedFile.name}</p>
                            </div>:''
                            }
                    </div>
                </div>
                <div className="upload-call-rec">
                    <div className="first-div">
                        <button type="submit" className="btn btn-primary" onClick={()=>onSubmitUrlOrFileHandler("file")}>
                            Submit
                        </button>
                    </div>
                    {errorMessage!=''&&button=='file'?<div className="error-message">
                        <span style={{ color: "red"}}>
                            {errorMessage}
                        </span>
                    </div>:""}         
                </div>
                <div style={{position:"absolute",marginTop:"345px",marginRight:"509px"}}>
                    <div className="mx-2">
                        <h6>Recording URL </h6>
                    </div>
                    <div className="inputWithButton">
                        <input type="text" placeholder="Paste your Recording URL here" value={url} onChange={recordingUrlHandler}/>
                        <button type="submit" onClick={()=>onSubmitUrlOrFileHandler("url")}>
                            <i className="bx bx-up-arrow-alt"></i>
                        </button>
                        {errorMessage!=''&&button=='url'    ?<div className="error-message">
                            <span style={{ color: "red"}}>
                                {errorMessage}
                            </span>
                        </div>:""} 
                    </div>
                </div>
                <div style={{position:"absolute",marginTop:"461px",marginRight:"473px"}}>
                    <div className="mx-2">
                        <h6>Check response by FileName</h6>
                    </div>
                    <div className="inputWithButton">
                        <input type="text" placeholder="Paste your FileName here" value={response} onChange={responseHandler}/>
                        <button type="submit" className="btn btn-primary" onClick={onSubmitResponseHandler} style={{background:"#0077b6",fontSize:"12px",width:"62px",height:"27px"}}>
                            {loading?<Loading variant="light"/>:<>Submit</>}
                        </button>
                    </div>
                </div>
            </div>
            
        </div>}
        </>
    )
}
export default RecordingSubmit