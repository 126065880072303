import React, {useState,useRef,useEffect, forwardRef} from "react";
import SalesColumn from "./SalesColumn";
import { Button } from "react-bootstrap";
import CallUploadModal from "../CallUploadModal";
import "./SalesCallDetail.css"
// import images from "../../../assets/images/callList-images/sort.svg"
import asc from "../../../assets/images/callList-images/ascending.svg"
import dsc from "../../../assets/images/callList-images/descending.svg"
import { Card } from "react-bootstrap";
import withClickOutside from "../../Commons/withClickOutside";
import ReactTooltip from 'react-tooltip';
import { useHistory } from "react-router-dom";
import { apiCall } from "../../Services/Interceptor";
import {Modal} from "react-bootstrap"
import toaster from "../../Toast/toaster";
import NodataFound from "../../Commons/NoDataFound";
import { dateFormat, positionFormat } from "../../Commons/DateFormatter";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import AgentCallDetail from "../AgentCallDetail";
import Filter from "../../Filter/Filter";

const ConversationSales = forwardRef(({showList, setShowList}, ref)=>{

  const storedData = JSON.parse(localStorage.getItem("AGENT"))
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  let competitorWords = urlParams.get('competitorWords')
  let callRange = urlParams.get('callScore')
  let durationRange = urlParams.get('callDuration')
  let tabRedirected = urlParams.get('tabRedirected')
  const [filterData, setFilterData] = useState([])
  const [defaultData, setDefaultData] = useState([])
  const [flag, setFlag] = useState(true);
  const [forPageWhenFilter, setForPageWhenFilter] = useState(false);
  const [show, setShow] = useState(false);
  const [glModal, setGlModal] = useState(false)
  const [loader, setLoader] = useState(false)
  const [selectedValues, setSelectedValues] = useState("")
  const [sortedField, setSortedField] = useState("")
  const [sortedName, setSortedName] = useState("")
  const [defaultValues, setDefaultValues] = useState("")
  const [loading, setLoading] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [isExportToExcel, setIsExportToExcel] = useState([])
  const [isDisabled, setIsDisabled] = useState(false)
  const [historyObject, setHistoryObject] = useState(null)
  const [order, setOrder] = useState("Ascending Order")
  const [orderId, setOrderId] = useState("asc")
  const [callView, setCallView] = useState(competitorWords ? 'Agent' : 'Group');
  const history  = useHistory()

  useEffect(() => {
    document.title = "Conversation Dashboard - Odio";
  }, [])

  useEffect(()=>{
    if(history.location.state!=''){
      setHistoryObject(history.location.state)
    }
    // else{
    //   setHistoryObject('')
    // } 
  },[history.location.state])

  const handleCallViewChange = (reset) => {
    if(reset){
      callView != 'Group' && setCallView('Group')
    }else{
      setCallView('Agent')
    }
  }

  const handleCallListingChange = (event, newAlignment) => {
    if (newAlignment !== null && newAlignment !== callView) {
      setCallView(newAlignment);
    }
  };

  const defaultConvFilter = (val) => {
    setDefaultData(val)
  }

  const filterCallList = (val) => {
    setFilterData(val);
    !flag && setFlag(true);
    !forPageWhenFilter && setForPageWhenFilter(true);
  }

  const setEmptyObject= ()=>{
    setHistoryObject('')
  }


  const closeButton = () => {
    setShow(false)
    setGlModal(false)
  };

  const showDetailModal = () =>{
		setShow(true)
	}

  const showGLModal = ()=>{
    setGlModal(true)
  }

  const loaderFalse = ()=>{
    setLoader(false)
  }

  const loaderTrue = ()=>{
    setLoader(true)
  }


  const sortArray = (id,name)=>{
    setSortedField(id)
    setSortedName(name)
    setShowList(false)
  }

  const orderArray = () => {
    setOrderId((prevOrderId) => {
      const newOrderId = prevOrderId === "asc" ? "dsc" : "asc";
      setOrder(newOrderId === "asc" ? "Ascending Order" : "Descending Order");
      return newOrderId;
    });
  };

  const selectedData = (e)=>{ 
    setSelectedValues(e)
  }

  const defaultDataa = (e)=>{
    setDefaultValues(e)
  }


  const callListData = (e)=>{
    console.log(e)
    setIsDisabled(e&&e.length>0?false:true)
    setIsExportToExcel(e&&e.length>0?e:[])
  }

  const excelData = {
    "callType": "SALES",
    "coeExternalIds": selectedValues?.coeExternalId?selectedValues?.coeExternalId:typeof(storedData?.coe)=='object'?storedData?.coe:[storedData?.coe],
    "filters": {
      "coeExternalIds": selectedValues?.coeExternalId?selectedValues?.coeExternalId:typeof(storedData?.coe)=='object'?storedData?.coe:[storedData?.coe],
      "fromD": storedData?.fromD,
      "toD": storedData?.toD,
      "customerName":storedData?.customerName,
      "customerMobile":historyObject?.agentPhone?historyObject?.agentPhone:storedData?.customerMobile,
      "fileName":storedData?.fileName,
      "sellerROS":storedData?.sellerROS,
      "callScoreRange":callRange?callRange.split("-").map(Number):storedData?.cScore,
      "fatalCall":storedData?.callHealth==0?storedData?.callHealth:storedData?.callHealth==1?storedData?.callHealth:selectedValues?.callHealth,
      "callDurationRange":storedData?.callDuration?storedData?.callDuration:durationRange?durationRange.split("-").map(Number):selectedValues.callDuration&&[selectedValues?.callDuration[0]*60,selectedValues?.callDuration[1]*60],
      "words":storedData?.words,
      "momentId":storedData?.momentId,
      "coeName":selectedValues?.coeName?selectedValues?.coeName:storedData?.selectedCOE,
      "pitchRange":storedData?.pitch,
      "convProRange":storedData?.prob,
      "opportunityRange":storedData?.opp,
      "urgencyRange":storedData?.urgency,
    },
    "fromD": storedData?.fromD,
    "reportType": callView == "Group" ? "Call Detail Report" : "Agent Detail Report",
    "toD": storedData?.toD,
    "userExternalIds": selectedValues?.agentExId
  }

  const exportExcelHandler = async()=>{
    if(isExportToExcel&&isExportToExcel.length>0){
      await apiCall.post('/odio/api/user/conversationCallDetailReport',excelData).then(res=>{
        if(res.data.status==0){
          console.log(res)
          // setLoading(false)
          setShowModal(true)
        }
        else{
          console.log(res)
          toaster.error(res.data.message)
        }
      })
    }
    else{
      <NodataFound/>
    }
  }
  

  console.log(callView,"callView=====>")

  return (
    <>
      <div className="page-wrapper">
        <div className="page-content">
          <div className="row row-cols-1 row-cols-md-2 row-cols-xl-4">
            <div className="col-12 mb-2">
              <div className="row">
                <div className="col-md-12">
                  <div className="d-inline">
                    <h2 className="dashboard-headings d-inline">
                      Calls{" "}
                    </h2> 
                    {tabRedirected == 'reports' && historyObject? <>
                      <span class="badge rounded-pill bg-warning bg-gradient text-dark active-filter-on-table">{`CALL DATE: FROM(${positionFormat(dateFormat(historyObject?.fromD))}) TO(${positionFormat(dateFormat(historyObject?.toD))})`}<a href="javascript:;"></a></span>
                      {historyObject?.coeName?.map(item=>{return (<span className="badge rounded-pill bg-warning bg-gradient text-dark active-filter-on-table">{`COE: ${item}`}<a href="javascript:;"></a></span>)})}
                    </>: <>
                    {storedData?.customerName&&storedData?.customerName.length>0?<span class="badge rounded-pill bg-warning bg-gradient text-dark active-filter-on-table">{`CUSTOMER NAME: ${storedData?.customerName[0]}`}<a href="javascript:;"></a></span>:""}
                    {historyObject?.agentPhone?<span class="badge rounded-pill bg-warning bg-gradient text-dark active-filter-on-table">{`CUSTOMER MOBILE: ${historyObject?.agentPhone}`}<a href="javascript:;"></a></span>:storedData?.customerMobile&&storedData?.customerMobile.length>0?<span class="badge rounded-pill bg-warning bg-gradient text-dark active-filter-on-table">{`CUSTOMER MOBILE: ${storedData?.customerMobile}`}<a href="javascript:;"></a></span>:""}
                    {storedData?.selectedSeller||history.location?.state?.employeeName?<span class="badge rounded-pill bg-warning bg-gradient text-dark active-filter-on-table">{`SELLER NAME: ${storedData?.selectedSeller?storedData?.selectedSeller:history?.location?.state?.employeeName}`}<a href="javascript:;"></a></span>:""}
                    {storedData?.sellerROS?<span class="badge rounded-pill bg-warning bg-gradient text-dark active-filter-on-table">{`SELLER ROS: (${storedData?.sellerROS[0]}-${storedData?.sellerROS[1]})`}<a href="javascript:;"></a></span>:""}
                    {storedData?.cScore?<span class="badge rounded-pill bg-warning bg-gradient text-dark active-filter-on-table">{`CALL SCORE: (${storedData?.cScore[0]}-${storedData?.cScore[1]})`}<a href="javascript:;"></a></span>:callRange?<span class="badge rounded-pill bg-warning bg-gradient text-dark active-filter-on-table">{`CALL SCORE: (${callRange?.split("-")[0]}-${callRange?.split('-')[1]})`}<a href="javascript:;"></a></span>:""}
                    {storedData?.callHealth!=undefined?<span class="badge rounded-pill bg-warning bg-gradient text-dark active-filter-on-table">{`CALL HEALTH: ${storedData?.callHealth==1?"Fatal":storedData?.callHealth==0?"Non-Fatal":""}`}<a href="javascript:;"></a></span>:""}
                    {storedData||defaultValues.length>0?<span class="badge rounded-pill bg-warning bg-gradient text-dark active-filter-on-table">{`CALL DATE: FROM(${storedData&&storedData.fromD?positionFormat(storedData.fromD):defaultValues.from}) TO(${storedData&&storedData.toD?positionFormat(storedData.toD):defaultValues.to})`}<a href="javascript:;"></a></span>:""}
                    {storedData?.callDuration?<span class="badge rounded-pill bg-warning bg-gradient text-dark active-filter-on-table">{`CALL DURATION: (${storedData?.callDuration[0]}-${storedData?.callDuration.length ==2?storedData?.callDuration[1]:storedData?.callDuration[0]})`}<a href="javascript:;"></a></span>:durationRange?<span class="badge rounded-pill bg-warning bg-gradient text-dark active-filter-on-table">{durationRange?.split("-")[1] !== undefined?`CALL DURATION: (${durationRange?.split("-")[0]}-${durationRange?.split("-")[1]})`:`CALL DURATION: (${durationRange?.split("-")[0]})`}<a href="javascript:;"></a></span>:""}
                    {storedData?.words&&storedData?.words.length>0?<span class="badge rounded-pill bg-warning bg-gradient text-dark active-filter-on-table">{`WORDS: ${storedData?.words}`}<a href="javascript:;"></a></span>:""}
                    {storedData?.moment?<span class="badge rounded-pill bg-warning bg-gradient text-dark moment-active-filter-on-table">{`MOMENTS: ${storedData?.moment}`}<a href="javascript:;"></a></span>:""}
                    {storedData?typeof(storedData.selectedCOE)!='string'?storedData?.selectedCOE?.map(item=>{return (<span className="badge rounded-pill bg-warning bg-gradient text-dark active-filter-on-table">{`COE: ${item}`}<a href="javascript:;"></a></span>)}):<span className="badge rounded-pill bg-warning bg-gradient text-dark active-filter-on-table">{`COE: ${storedData?.selectedCOE}`}<a href="javascript:;"></a></span>:""}
                    {storedData?.pitch?<span class="badge rounded-pill bg-warning bg-gradient text-dark active-filter-on-table">{`PITCH SCORE: (${storedData?.pitch[0]}-${storedData?.pitch[1]})`}<a href="javascript:;"></a></span>:""}
                    {storedData?.prob?<span class="badge rounded-pill bg-warning bg-gradient text-dark active-filter-on-table">{`CONVERSION PROBABILITY: (${storedData?.prob[0]}-${storedData?.prob[1]})`}<a href="javascript:;"></a></span>:""}
                    {storedData?.opportunity?<span class="badge rounded-pill bg-warning bg-gradient text-dark active-filter-on-table">{`OPPORTUNITY: ${storedData?.opportunity[0]}-${storedData?.opportunity[1]}`}<a href="javascript:;"></a></span>:""}
                    {storedData?.urgency?<span class="badge rounded-pill bg-warning bg-gradient text-dark active-filter-on-table">{`URGENCY: ${storedData?.urgency[0]}-${storedData?.urgency[1]}`}<a href="javascript:;"></a></span>:""}
                    {storedData?.fileName?<span class="badge rounded-pill bg-warning bg-gradient text-dark active-filter-on-table">{`FILE NAME: ${storedData?.fileName}`}<a href="javascript:;"></a></span>:""}
                    </>}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12 mb-4 d-flex justify-space-between" ref={ref}>
              <div className="col-md-12 callListingExcel" style={{height:'2rem'}}>
                <div className="d-flex">
                <div className="d-flex align-items-end">
                  <button className={`btn ps-0 dropdown-toggle ${showList?"show":""}`} data-bs-toggle="dropdown" onClick={()=>setShowList(!showList)} aria-expanded={showList} style={{ zIndex: 2, position: 'relative' }}>
                    Sort
                  </button>
                  <div onClick={orderArray} style={{cursor:'pointer', zIndex: 1}}>
                                {orderId === "asc" ? 
                                    <span className="pe-1">
                                        <img className="alignRevert" src={asc} alt=""></img>
                                    </span> : 
                                    <span className="pe-1">
                                        <img className="alignRevert" src={dsc} alt=""></img>
                                    </span>
                                }
                    </div>
                  </div>
                  {sortedName?<span class="badge rounded-pill bg-warning bg-gradient text-dark active-filter-on-sort">{`SORT: ${sortedName}`}</span>:""}
                  {order && <span class="badge rounded-pill bg-warning bg-gradient text-dark active-filter-on-sort">{`ORDER: ${order}`}</span>}
                </div>
                <ul className={`dropdown-menu ${showList?"show sortList":""}`}  data-popper-placement={`${showList?"bottom-start":""}`} style={{ zIndex: 3, position: 'absolute' }}>
                  {showList?
                  <>
                    <li>
                      <a className="dropdown-item active-column" data-column="5" onClick={()=>sortArray("callAt","Call At")} style={{cursor:"pointer"}}>Call At</a>
                    </li>
                    {callView == "Group" && <><li>
                      <a className="dropdown-item active-column" data-column="6" onClick={()=>sortArray("duration","Duration")} style={{cursor:"pointer"}}>Duration</a>
                    </li>
                    <li>
                      <a className="dropdown-item active-column" data-column="7" onClick={()=>sortArray("alarms","Alarms")} style={{cursor:"pointer"}}>Alarms</a>
                    </li>
                    <li>
                      <a className="dropdown-item active-column" data-column="8"  onClick={()=>sortArray("callScore","Call Score")} style={{cursor:"pointer"}}>Call Score</a>
                    </li></>}
                  </>
                  :""}
                </ul> 
                <div>
                  <ToggleButtonGroup
                    color="primary"
                    value={callView}
                    exclusive
                    onChange={handleCallListingChange}
                    aria-label="Platform"
                    size="small"
                    sx={{marginRight:'10px', height:'2rem'}}
                  >
                    <ToggleButton value="Group">Group View</ToggleButton>
                    <ToggleButton value="Agent">Agent View</ToggleButton>
                  </ToggleButtonGroup>
                  <button disabled={isDisabled} style={{backgroundColor:isDisabled?"gray":"#0077b6"}} className="exportExcel" onClick={exportExcelHandler}><i class='bx bx-download'></i></button>
                </div>
                <Modal style={{height:"225px",marginTop:"36px"}} show={showModal} onHide={()=>setShowModal(false)}>
                  <Modal.Header closeButton style={{borderBottom:"none"}}/>
                  <Modal.Body>
                    <h5 style={{textAlign:"center"}}>Request Sucessfully Submitted!</h5>
                    <p style={{textAlign:"center"}}>Report will be generated in 5-10 mins. You can view in REPORTS Section.</p>
                  </Modal.Body>
                </Modal>
              </div>
            </div>
            <div className="col-12 mb-4 salesCallCard"> 
              {callView == "Group" ? history.location.state!=null&&historyObject!=null?<SalesColumn loaderTrue={loaderTrue} loaderFalse={loaderFalse} loader={loader} selectedData={selectedData} sortedField={sortedField} orderId={orderId} defaultDataa={defaultDataa} callListData={callListData} historyObject={historyObject} filterData={filterData} defaultData={defaultData} flag={flag} setFlag={setFlag} forPageWhenFilter={forPageWhenFilter} setForPageWhenFilter={setForPageWhenFilter} handleCallViewChange={handleCallViewChange}/>
              :history.location.state==null&&historyObject==null?<SalesColumn loaderTrue={loaderTrue} loaderFalse={loaderFalse} loader={loader} selectedData={selectedData} sortedField={sortedField} orderId={orderId} defaultDataa={defaultDataa} callListData={callListData} filterData={filterData} defaultData={defaultData} flag={flag} setFlag={setFlag} forPageWhenFilter={forPageWhenFilter} setForPageWhenFilter={setForPageWhenFilter} handleCallViewChange={handleCallViewChange}/>:"" : <AgentCallDetail sortedField={sortedField} orderId={orderId} filterData={filterData} handleCallViewChange={handleCallViewChange}/>}
            </div>
          </div>
          {/*end row*/}
          {callView == "Group" && <div className="call-upload-btn">
            <Button
              onClick={() => showDetailModal()}
              // disabled={isLoading}
              // onClick={!isLoading ? handleClick : null}
            >
              <i className="lni lni-upload" data-tip="Upload Call"></i>
            </Button>
          </div>}
        </div>
        <CallUploadModal 
          //setShow={setShow}
          show={show}
          closeButton={closeButton} 
        />
        {/* <UploadGLModal show={showGLModal} closeButton={closeButton}/> */}
        <ReactTooltip place="bottom" type="dark" effect="solid"/>
        <Filter
                componentType="Conversation-Sales"
                filterCallList={filterCallList}
                defaultConvFilter={defaultConvFilter}
                name={"SALES"}
                handleCallViewChange={handleCallViewChange}
                historyObject={historyObject}
                setEmptyObject={setEmptyObject}
        />
      </div>
    </>
  );
}) 
export default withClickOutside(ConversationSales)
