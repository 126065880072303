import { data } from "jquery";
import React, { useEffect, useState, useMemo  } from "react";
import { connect } from 'react-redux'; 
import Select from 'react-select'
import profileService from "../Services/profile.service";
// import countryCodes from 'country-codes-list'
import toast from "../Toast/toaster";
import CountrySelect from "../Commons/CountrySelect";
import Loading from "../Commons/Loading";
import {SET_USERNAME} from '../../redux-container/login/types'
import { logout } from "../../redux-container/login/action";

 function EditProfile({props, profileData, setEditProfile, setProfileData, onUpdateDetails,logout}){
    console.log(profileData)
    const [inputs, setInputs]=useState({
        userId: profileData.id,
        userFirstName: profileData.firstName,
        userLastName: profileData.lastName,
        email: profileData.email,
        secondaryEmailId: profileData.secEmail[0][0]?profileData.secEmail[0][0].id:'',
        secondaryEmail:profileData.secEmail[0][0]?profileData.secEmail[0][0].email:'',
        mobileNumberId:profileData.userPhoneNumbers[0][0]?.id,
        mobileNumber:profileData.userPhoneNumbers[0][0]?.mobileNumber,
        mobileNumberCountryCode:profileData.userPhoneNumbers[0][0]?.countryCode,

        phoneNumberId:profileData.userPhoneNumbers[0][1]?.id,
        phoneNumber:profileData.userPhoneNumbers[0][1]?.mobileNumber,
        phoneNumberCountryCode: profileData.userPhoneNumbers[0][1]?.countryCode,
        phoneNumberStateCode: profileData.userPhoneNumbers[0][1]?.stateCode,
        phoneNumberExtension: profileData.userPhoneNumbers[0][1]?.extension,
        roleId: profileData.role === 'ADMIN'? 1: 0,
        caller: profileData.caller,
        status:1
    });
      
    const [readOnlyValue, setReadOnly]=useState(true)
    const [loader, setLoader] = useState(false)
    const [errors,setErrors]=useState({});
    const [disabled, setDisabled] = useState(true)
    const [updatedProfile , setUpdatedProfile] = useState({})
    const [confirm, setConfirmation] = useState(false)    
    let [count, setCount] = useState(0)

    const handleChange = (event) =>{
        if(event.target && event.target.name != "email"){
            let { name, ext, value } = event.target;
            if(name === 'userFirstName' || name === 'userLastName')
            {
                value = value.replace(/[^A-Za-z]/ig, '')
            }
            if(name === 'mobileNumber' || name === 'phoneNumber' || name ==='phoneNumberExtension' || name === 'phoneNumberStateCode')
            {
                value = value.replace(/[^0-9]/g, '')
            }
            setInputs(inputs => ({ ...inputs, [name]: value }))
            setUpdatedProfile(updatedProfile => ({...updatedProfile, [name]: value}))
        }
        else if(event.target.name === 'email')
        {
            setCount(count+1)
                if(confirm === false && count < 2){
                    let p = window.confirm('It is primary mail Id.. If you change this you will be directed to login page. Are you sure you want to change this?')
                    setConfirmation(p)
                }
                else{
                    setInputs(inputs => ({ ...inputs, [event.target.name]: event.target.value }))
                    setUpdatedProfile(updatedProfile => ({...updatedProfile, [event.target.name]: event.target.value}))
                }        
        }
        else
          {
              setInputs(inputs => ({ ...inputs, mobileNumberCountryCode: event.phone }))
              setUpdatedProfile(updatedProfile => ({...updatedProfile, mobileNumberCountryCode: event.phone}))
          }

        if(event != '' || event.target.value != ''){
            //console.log(e.target.value);
            setDisabled(false);
            }
            else
            setDisabled(true);
    }

    const formError={};
    const validate = (values) =>{
            if(!values.userFirstName)
            {
                formError.userFirstName='Enter First Name'
            }
            if(!values.userLastName)
            {
                formError.userLastName='Enter last Name'
            }
            if(!values.email)
            {
                formError.email="Enter email Id"
            }
            else{
            if(!/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(values.email)){
                formError.email="Email is invalid"
            }
            }
            if(values.secondaryEmail)
            {
                if(!/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(values.secondaryEmail))
                    formError.secondaryEmail="Email is invalid"
            }

            if(values.mobileNumber)
            {
                if(!values.mobileNumberCountryCode)
                formError.mobileNumberCountryCode="Please Country Code" 
                if(values.mobileNumber[0] == 0)
                formError.mobileNumber = 'Mobile Number cannot start with 0'
                if(values.mobileNumberCountryCode === '91'){
                   if(values.mobileNumber.length < 10 || values.mobileNumber.length > 10)
                        formError.mobileNumber="Please enter 10 digits"
                }
            }
            else
            {
                formError.mobileNumber='Please enter mobile number'
            }

            if(values.phoneNumber)
            {
                if(!values.phoneNumberCountryCode)
                {
                    formError.phoneNumberCountryCode="Please Country Code"
                } 
                if(values.phoneNumber[0] == 0)
                    formError.phoneNumber = 'Mobile Number cannot start with 0'
                if(values.phoneNumberCountryCode === '91'){
                    if(values.phoneNumber.length < 10 || values.phoneNumber.length > 10)
                         formError.phoneNumber="Please enter 10 digits" 
                }
           }
           
        return formError;
    }
  
    function handleFormSubmit(event) {
    setLoader(true);
    event.preventDefault();
    validate(inputs)
    setErrors(formError);
    if (Object.keys(formError).length === 0) {
        profileService.updateProfile(inputs)
        .then(res=>{
               setLoader(false)
               setEditProfile(false)
               if(updatedProfile.email)
               {
                   logout();
               }
               profileService.profile().then((response) => {
               let res=response.data.data;
                 setProfileData({
                    id:res.id,
                    firstName:res.firstName,
                    lastName:res.lastName,
                    email:res.email,
                    extField:res.phoneNumberExtension,
                    userPhoneNumbers:[res.userPhoneNumbers],
                    secEmail:[res.secondaryEmails],
                    managerId:res.managerId,    
                    manager:res.managerName,
                    designation:res.designation,
                    role:res.role,
                    caller : res.caller,
                    coe:[res.clientCoes],
                    status:response.data.status
                })
                onUpdateDetails(inputs.userFirstName)
            })
           }  
         ) .catch(error=>{
             setLoader(false)
           })
       }
       else{
         setLoader(false)
     }
    }

    const handleCountryCodeChange = (event) =>{
        console.log(event)
        setUpdatedProfile(updatedProfile => ({...updatedProfile, phoneNumberCountryCode: event.phone}))
       setInputs(inputs => ({ ...inputs, phoneNumberCountryCode: event.phone }));
       if(event != ''){
        //console.log(e.target.value);
        setDisabled(false);
        }
        else
        setDisabled(true);
    }

    
    const handleMobileCountryCodeChange = (event) =>{
        console.log(event)
        setUpdatedProfile(updatedProfile => ({...updatedProfile, mobileNumberCountryCode: event.phone}))
       setInputs(inputs => ({ ...inputs, mobileNumberCountryCode: event.phone }));
       if(event != ''){
        //console.log(e.target.value);
        setDisabled(false);
        }
        else
        setDisabled(true);
    }

    const getData = () => {
       profileData.userPhoneNumbers.map((numList)=>(
        numList.map(numsList=>(
          numsList.type=="MOBILE" ?  setInputs.mobileNumberId=numsList.id :null,
          numsList.type=="LANDLINE" ? setInputs.phoneNumberId=numsList.id :null
        ))
        ));    
        if(profileData.role=="ADMIN")
        {
            setReadOnly(false)
        }
       
    }

    const cancelProfile=()=>{
        setEditProfile(false)
     }

   useEffect(() => {
        getData();
    }, [])

    
    return(
        <>
            <div>
                <div className="d-flex align-items-center">
                    <div><i className="bx bxs-user me-1 font-22 edit-profile-icon"></i>
                    </div>
                    <h5 className="mb-0 edit-profile-header">My Profile</h5>
                </div>
                <hr/>
                <form className="row g-3" >
                    <div className="col-md-6">
                        <label for="inputFirstName" className="form-label">First Name*</label>
                        <div className="input-group"> <span className="input-group-text bg-transparent"><i className="bx bxs-user"></i></span>
                            <input type="text" className="form-control" id="inputFirstName" placeholder="First Name" 
                            name="userFirstName" value={inputs.userFirstName} onChange={handleChange}  maxLength='32'/>
                        </div>
                            <p className="my-1 mb-0 lh-1 font-12" style={{height:'18px'}}>{errors.userFirstName && <span style={{color:'red'}}>{errors.userFirstName}</span> }</p>  
                    </div>
                    <div className="col-md-6">
                        <label for="inputLastName" className="form-label">Last Name*</label>
                        <div className="input-group"> <span className="input-group-text bg-transparent"><i className="bx bxs-user"></i></span>
                            <input type="text" className="form-control" id="inputLastName" placeholder="Last Name"
                            name="userLastName" value={inputs.userLastName} onChange={handleChange} maxLength='32'/>
                        </div>
                            <p className="my-1 mb-0 lh-1 font-12" style={{height:'18px'}}>{errors.userLastName && <span style={{color:'red'}}>{errors.userLastName}</span>}</p> 
                    </div>
                    <div className="col-md-6 mt-0">
                        <label for="inputEmail" className="form-label">Email*</label>
                        <div className="input-group"> <span className="input-group-text bg-transparent"><i className="lni lni-envelope"></i></span>
                            <input type="email" className="form-control" id="inputEmail" placeholder="example@user.com"
                            name="email" value={inputs.email} onChange={handleChange} readOnly={readOnlyValue} maxLength='64'/>
                        </div>
                        <p className="my-1 mb-0 lh-1 font-12" style={{height:'18px'}}>  {errors.email && <span style={{color:'red'}}>{errors.email}</span>}</p>
                    </div>
                    <div className="col-md-6 mt-0">
                        <label for="inputSecondaryEmail" className="form-label">Secondary Email</label>
                        <div className="input-group"> <span className="input-group-text bg-transparent"><i className="lni lni-envelope"></i></span>
                            <input type="email" className="form-control" id="inputSecondaryEmail" placeholder="example@user.com" 
                            name="secondaryEmail" value={inputs.secondaryEmail} maxLength='64' onChange={handleChange}/>
                        </div>
                        <p className="my-1 mb-0 lh-1 font-12" style={{height:'18px'}}>  {errors.secondaryEmail && <span style={{color:'red'}}>{errors.secondaryEmail}</span>}</p>
                    </div>
                    <div className="col-md-6 mt-0">
                        <label for="inputEmail" className="form-label">Phone</label>
                        <div className="row g-3">
                            <div className="col-md-7">
                                <CountrySelect value={inputs.phoneNumberCountryCode} setInputs={setInputs.phoneNumberId} name="phoneNumberCountryCode" handleCountryCodeChange={handleCountryCodeChange}/>
                                <span>{errors.phoneNumberCountryCode && <p style={{color:'red'}}>{errors.phoneNumberCountryCode}</p>}</span> 
                            </div>
                            <div className="col-md-5">
                                <input type="num" className="form-control" id="stateCode" placeholder="Std Code" name="phoneNumberStateCode"
                                    value={inputs.phoneNumberStateCode} onChange={handleChange}/>
                            </div>
                            <div className="col-md-7">
                                <div className="input-group"> <span className="input-group-text bg-transparent"><i className="lni lni-phone-set"></i></span>
                                    <input type="num" className="form-control" placeholder="Phone Number" name="phoneNumber"
                                    value={inputs.phoneNumber} onChange={handleChange} />
                                </div>
                                <span>{errors.phoneNumber && <p style={{color:'red'}}>{errors.phoneNumber}</p>}</span> 
                            </div>
                            <div className="col-md-5">
                                <input type="extNum" className="form-control" id="ext-link" placeholder="Ext" name="phoneNumberExtension" value={inputs.phoneNumberExtension} maxlength='6' onChange={handleChange} />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 mt-0">
                        <label for="inputPassword" className="form-label">Mobile Number*</label>
                        <div className="row g-3">
                            <div className="col-md-7">
                                <CountrySelect value={inputs.mobileNumberCountryCode} setInputs={setInputs.mobileNumberId} name="mobileNumberCountryCode" handleMobileCountryCodeChange={handleMobileCountryCodeChange} />
                                    <p className="my-1 mb-0 lh-1 font-12" style={{height:'18px'}}>{errors.mobileNumberCountryCode && <span style={{color:'red'}}>{errors.mobileNumberCountryCode}</span>}</p> 
                            </div>
                            <div className="col-md-5">
                                <div className="input-group"> 
                                    <span className="input-group-text bg-transparent"><i className="lni lni-mobile"></i></span>
                                    <input type="tel" className="form-control" placeholder="Mobile Number" 
                                    name="mobileNumber" value={inputs.mobileNumber} onChange={handleChange} />
                                </div>
                                <p className="my-1 mb-0 lh-1 font-12" style={{height:'18px'}}>{errors.mobileNumber && <span style={{color:'red'}}>{errors.mobileNumber}</span>}</p> 
                            </div>
                        </div>
                    </div>
                    <div className="col-12 text-sm-end mt-4 ">
                        <button type="button" className="btn px-5 cancel-btn" id="update-cancel" onClick={cancelProfile}><i className="bx bx-arrow-back me-1"></i>Cancel</button>
                        <button type="submit" className="btn px-5 update-btn" id="update-profile" disabled={disabled?disabled:''} onClick={handleFormSubmit} >{loader ? <Loading variant="light"/> : <> Update</>}</button>                 
                    </div>
                </form>
            </div>
            
        </>
    ) 
}
const mapStateToProps = (state) =>{
    return{
       userData:state.login.userData
    }
}

const mapDispatchToProps = (dispatch) =>{
    return{
        onUpdateDetails: (inputs) => dispatch({type:SET_USERNAME, payload: inputs}),
        logout: () => dispatch(logout())
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (EditProfile);
