import React from 'react'
import { Card } from 'react-bootstrap'
import forbidden_error from "../assets/images/error.png"
import odio_logo_blue from "../assets/images/odio_logo_blue.png";

function ForbiddenAccess() {
  return (
    <div className="d-flex justify-content-center align-items-center vh-100">
    <Card style={{width:'70%',height:'23rem'}}>
        <Card.Body>
           <div className="d-flex gap-5 mt-3">
             <div className="d-flex justify-content-center flex-column ms-2 gap-2">
                <img src={odio_logo_blue} width={140} alt="" style={{}}/>
                <p className="mb-0 ms-2"><b>403. </b>That's an error</p>
                <p className="ms-2">Your client does not have any permission to create user other than agent. <b>That's all we know.</b></p>
             </div>
             <div>
                <img src={forbidden_error} width={440} alt="" />
             </div>
           </div>
        </Card.Body>
    </Card>
    </div>
  )
}

export default ForbiddenAccess