import React from "react"
import noData from "../../assets/images/Group 1.svg"

const NodataFound = ()=>{

    return(
        <div className="page-wrapper">
			<div className="page-content dashboard-filter">
                <div className="d-flex align-content-center justify-content-center no-data">
                    <span>
                        <img src={noData} alt="noData"/>
                    </span>
                </div>
                <div className="no-data-found">
                    <h4>No Data Found</h4>
                </div>
            </div>
        </div>
    )
}

export default NodataFound