import React from "react";
import conversationService from "../../Services/conversation.service";
import { CONVERSATIONS_SALE_DASHBOARD } from "../../../Constants/Enum";
import { useEffect } from "react";
import Loading from "../../Commons/Loading";
import { useState } from "react";
import ConversationsTable from "../../Commons/Table/ConversationsTable";
import Filter from "../../Filter/Filter";
import { Card,OverlayTrigger } from "react-bootstrap";
import PaginationAlter from "../../Commons/Table/PaginationAlter";
import phone from "../../../assets/images/callList-images/phone.svg"
import calender from "../../../assets/images/callList-images/calendar.svg"
import time from "../../../assets/images/callList-images/timer.svg"
import alarm from "../../../assets/images/callList-images/alarm.svg"
import bulb from "../../../assets/images/callList-images/bulb.svg"
import moment from "../../../assets/images/callList-images/moment.svg"
import seller from "../../../assets/images/callList-images/seller.svg"
import ticket from "../../../assets/images/callList-images/ticket.svg"
import user from "../../../assets/images/callList-images/user.svg"
import { useHistory } from "react-router-dom";
import { Popover } from "react-bootstrap";
import NodataFound from "../../Commons/NoDataFound";
import BubbleLoader from "../../Commons/BubbleLoader";


export default function SupportColumn(props) {
  const [callLists, setCallLists] = useState([])
  const [availablePages, setAvailablePages] = useState("")
  const [currentPage, setCurrentPage] = useState("")
  const [filterData,setFilterData] = useState({})
  const [defaultData, setDefaultData] = useState([])
  const [showCard, setShowCard] = useState(false)
  const [cardId, setCardId] = useState("")
  const [detail, setDetail] = useState([])
  const [detailLoader, setDetailLoader] = useState(false)

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  let callRange = urlParams.get('callScore')
  let durationRange = urlParams.get('callDuration')
  const params = urlParams.get('name')
  console.log(params)
  const storedData = JSON.parse(localStorage.getItem("AGENT"))
  let role = JSON.parse(localStorage.getItem("USER_DETAIL"))?.userRole
  let userDetail = JSON.parse(localStorage.getItem("USER_DETAIL"))?.clientConfig?.data?.conversationListing
 

const callBack = (e)=>{
  setCurrentPage(e)
  console.log(e)
  setDetail([]);
  setShowCard(false);
}

const defaultConvFilter = (val)=>{
  console.log(val)
  setDefaultData(val)
}

const filterCallList = (val)=>{
  setFilterData(val)
}

const onClickCard = (id,phone,item) => {
  if(detailLoader){
    return  // to prevent multiple API calls while the previous one is still in progress
  }
  else{
    if (showCard && cardId === id) {
      setShowCard(false); // Collapse the card if it is already open
    } 
    else {
      setDetailLoader(true)
      setShowCard(true);
      setCardId(id);
      let val ={  
        "page":parseInt(localStorage.getItem("selectedPage")) ? parseInt(localStorage.getItem("selectedPage")) : 1,
        "pageSize":5,  
        "callType":"SALES",
        "clientExternalId":JSON.parse(localStorage.getItem("USER_DETAIL"))?.externalId,
        "fromD":storedData&&storedData.fromD?storedData.fromD:defaultData.from, 
        "toD":storedData&&storedData.toD?storedData.toD:defaultData.to,
        "customerMobile":phone,
        "coeExternalIds": item?.coeExternalId ? [item?.coeExternalId] : storedData && storedData.coe
        // "coeName":filterData?.coeName?filterData?.coeName:storedData?.selectedCOE,
      }
      conversationService.getCalls(val).then(res=>{
        console.log(res)
        if(res?.status==200){
          setDetailLoader(false)
          setDetail(res?.data?.data?.details)
        }
      })
    }
  }
}

const conversion = (duration)=>{
  let hh = duration.split(":")[0]
  let mm=duration.split(":")[1]
  let ss = duration.split(":")[2]
  console.log(ss)
  let text = ""
  if(hh>0){
    text = `${hh} Hrs. ${mm} Min. ${ss} Sec.`
  }
  else if(mm>0){
    text = `${mm} Min. ${ss} Sec.`
  }
  else if(ss>0){
    text  = text + `${ss} Sec.`
  }
  console.log(text)
  return text
}

function callList(){
  filterData?.loader?props.loaderTrue():props.loaderTrue()
   let val ={
    "page":parseInt(localStorage.getItem("selectedPage")) ? parseInt(localStorage.getItem("selectedPage")) : 1,
    "pageSize":5,  
    "callType":"SUPPORT",
    "clientExternalId":JSON.parse(localStorage.getItem("USER_DETAIL"))?.externalId,
    "fromD":storedData&&storedData.fromD?storedData.fromD:defaultData.from, 
    "toD":storedData&&storedData.toD?storedData.toD:defaultData.to,
    // "coeExternalIds":storedData?.coe,
    "sort":props.sortedField?props.sortedField:"", 
    "customerName":storedData?.customerName?storedData?.customerName:filterData?.customerName,
    "customerMobiles":storedData?.customerMobile?[storedData?.customerMobile]:filterData?.customerMobile?[filterData?.customerMobile]:null,
    "userIds":storedData?.sellerName?.length>0?storedData?.sellerName:history.location?.state?.employeeId?[history.location?.state?.employeeId]:filterData?.sellerName,
    "fileName":storedData?.fileName?storedData?.fileName:filterData?.fileName,
    "sellerROS":storedData?.sellerROS?storedData?.sellerROS:filterData?.sellerROS,
    "callScoreRange":storedData?.cScore?storedData?.cScore:callRange?callRange.split("-").map(Number):filterData?.callScore,
    "fatalCall":storedData?.callHealth==0?storedData?.callHealth:storedData?.callHealth==1?storedData?.callHealth:filterData?.callHealth,
    "callDurationRange":storedData?.callDuration?storedData?.callDuration:durationRange?durationRange.split("-").map(Number):filterData.callDuration&&[filterData?.callDuration[0]*60,filterData?.callDuration[1]*60],
    "words":storedData?.words?storedData?.words:filterData?.words,
    "momentId":storedData?.momentId?storedData?.momentId:filterData?.moment,
    "coeName":filterData?.coeName?filterData?.coeName:storedData?.selectedCOE,
    "pitchRange":storedData?.pitch?storedData?.pitch:filterData?.pitch,
    "convProRange":storedData?.prob?storedData?.prob:filterData?.prob,
    "opportunityRange":storedData?.opp?storedData?.opp:filterData?.opportunity,
    "urgencyRange":storedData?.urgency?storedData?.urgency:filterData?.urgency,
    }
    
    if(role === 'AGENT_ADMIN')
      val.coeExternalId = storedData?.coe[0]

    conversationService.getCallListing(val).then(response => {
    console.log(response)
    setCallLists(response? response.data.data.response: [])
    setLoader(false)
    setAvailablePages(response?.data.data.availablePages)
    props.loaderFalse()
  }).catch(error => {
    console.log("Error:", error);
    setCallLists([]);
    props.loaderFalse();
  });
}

useEffect(()=>{
  callList()
},[currentPage,filterData,props.sortedField])

useEffect(()=>{
  props.selectedData(filterData)
  props.defaultData(defaultData)
  props?.callListData(callLists)
},[filterData,defaultData,callLists])


  
  const [loader, setLoader] = useState(true)
  
  const history = useHistory()
  const clickHandler = (id,rec,duration,date,empId,seller,customer,coeId,coe,phone,externalId,name)=>{
    localStorage.setItem("URL",rec)
    history.push(`sales_call_detail?callId=${id}&name=${name}`,{
      callId:id,
      url:rec,
      duration:duration,
      date:date,
      id:empId,
      seller:seller,
      customer:customer,
      coeId:coeId,
      coe:coe,
      phone:phone,
      agentExternalId:externalId,
      callName: name
  })
  }

  const popoverr =(alarms,name,rec,moment)=> (
    <Popover id="popover-trigger-hover-focus" title="right" style={{"padding":"4px"}}>
      <h3 className="popover-header">{name}</h3>
      <div className="popover-body">
        <div className="popover-body-call">
          {
            name==='Alarms'?alarms.map(val=>{
              return(
                <p className="mb-0">
                  <i className="bx bx-stats font-16 align-middle me-1"/>
                  {val}
                </p>
              )
            }):
            name==='Recommendations'?rec.map(val=>{
              return(
                <p className="mb-0">
                  <i className="bx bx-stats font-16 align-middle me-1"/>
                  {val}
                </p>
              )
            }):
            name==='Moments'?moment.map(val=>{
              return(
                <p className="mb-0">
                  <i className="bx bx-stats font-16 align-middle me-1"/>
                  {val}
                </p>
              )
            })
            :
            ""
          }
        </div>
      </div>
    </Popover>
  )
  
 
  console.log(callLists)

  return (
    
    <>
    {props.loader ? (
        <div className="loader-container">
          <Loading variant="dark" />
        </div>
      ) : (
      <>
        {callLists!=''&&callLists.length>0?callLists.map((item,id)=>{
          console.log(id)
          return(
        <>
          <Card  style={detail.length>0&&showCard&&id==cardId?{cursor:"pointer",marginBottom:"0.1rem"}:{cursor:"pointer"}} onClick={()=>onClickCard(id,item?.phone,item) }>
            <Card.Body className="salesCallCard">
              <div className="user">
                <div className="caller">
                  <div className="name">
                    <span>
                      <img src={user} alt=""/>
                    </span>
                    <span className="text">{item?.customerName}</span>
                  </div>
                  <div className="rank" style={{cursor:"pointer"}}>{`#${item.phone} ${`(${item?.totalCalls})`}`}</div>
                </div>
                <div className="callCount">
                  <div className="box">
                  {item.callAlarms.length>0?<OverlayTrigger trigger={['hover', 'focus']} placement="right" overlay={popoverr(item?.callAlarms,"Alarms","")} rootClose>
                    <button style={{"border":"none","backgroundColor":"white"}}>
                      <img src={alarm} alt=""/>
                      <span>{item?.callAlarms?.length}</span>
                    </button>
                  </OverlayTrigger>:
                  <>
                    <img src={alarm} alt=""/>
                    <span>{item?.callAlarms?.length}</span>
                  </>}
                    
                  </div>
                  <div className="box">
                    {item.callRec.length>0?<OverlayTrigger trigger={['hover', 'focus']} placement="right" overlay={popoverr("","Recommendations",item?.callRec)} rootClose>
                      <button style={{"border":"none","backgroundColor":"white"}}>
                        <img src={bulb} alt=""/>
                        <span>{item?.callRec?.length}</span>
                      </button>
                    </OverlayTrigger>:
                    <>
                      <img src={bulb} alt=""/>
                      <span>{item?.callRec?.length}</span>
                    </>}
                  </div>
                  <div className="box">
                  {item.moments.length>0?<OverlayTrigger trigger={['hover', 'focus']} placement="right" overlay={popoverr("","Moments","",item?.moments)} rootClose>
                      <button style={{"border":"none","backgroundColor":"white"}}>
                        <img src={moment} alt=""/>
                        <span>{item?.moments?.length}</span>
                      </button>
                    </OverlayTrigger>:                                                                                                                                                                                                                                                                                                                                                                                                                        
                    <>
                    <img src={moment} alt=""/>
                    <span>{item?.moments?.length}</span>
                    </>}
                  </div>
                </div>
                <div className="score">
                  {userDetail.includes("CALL SCORE")?<div className="scoreCommonStyle callScore">
                    <span className="text">CALL<br/>SCORE</span>
                    <span className="badge" style={{background:item.callColour=='Amber'?"#FFBF00":item.callColour==null?"Grey":item.callColour}}>{item.callScore}%</span>
                  </div>:""}                                                                                                                                                                                                                                                                                                                                                                                                                        
                  {userDetail.includes("PITCH SCORE")?<div className="scoreCommonStyle pitchScore">
                    <span className="text">PITCH<br/>SCORE</span>
                    <span className="badge" style={{background:item.pitchColour=='Amber'?"#FFBF00":item.pitchColour==null?"Grey":item.pitchColour}}>{item.pitchScore}%</span>
                  </div>:""}
                </div>
              </div>
              <hr/>
              <div className="seller">
                <div className="name">
                  <span className="icon">
                    <img src={seller} alt=""/>
                  </span>
                  {`${item.sellerName} (${item.employeeId})`}
                </div>
                <div className="number">
                  <span className="icon">
                    <img src={phone} alt=""/>
                  </span>
                  {item.phone}
                </div>
                <div className="date">
                  <span className="icon">
                    <img src={calender} alt=""/>
                  </span>
                  {item.date}
                </div>
                <div className="time">
                  <span className="icon">
                    <img src={time} alt=""/>
                  </span>
                  {conversion(item.talkDuration)}
                </div>
                <div>
                  <button className="groupCall" onClick={()=>{clickHandler(item.phone,item.audioRecordingUrl,item.talkDuration,item.date,item.employeeId,item.sellerName,item.customerName,item.coeIds,item.coeName,item?.phone,item.userExternalId,"GROUP")}}>
                    View Group
                  </button>
                </div>
              </div>
            </Card.Body>
          </Card>
         {detailLoader&&cardId==id?
          <div className="calls-container">
            <BubbleLoader/>
          </div>:
          detail.length>0&&detail.map((d,val)=>{
          return (showCard && cardId==id?
            <Card className={`${val==detail.length-1?'group-detail-noMargin':'group-details'}`}>
              <Card.Body className="salesCallCard">
                <div className="user">
                  <div className="caller">
                    <div className="name">
                      <span>
                        <img src={user} alt=""/>
                      </span>
                      <span className="text">{d?.customerName}</span>
                    </div>
                    <div className="rank" style={{cursor:"pointer"}}>{`#${d.callId}`}</div>
                  </div>
                  <div className="callCount">
                    <div className="box">
                    {d.callAlarms.length>0?<OverlayTrigger trigger={['hover', 'focus']} placement="right" overlay={popoverr(d?.callAlarms,"Alarms","")} rootClose>
                      <button style={{"border":"none",backgroundColor:"#F4F4F4"}}>
                        <img src={alarm} alt=""/>
                        <span>{d?.callAlarms?.length}</span>
                      </button>
                    </OverlayTrigger>:
                    <>
                      <img src={alarm} alt=""/>
                      <span>{d?.callAlarms?.length}</span>
                    </>}
                      
                    </div>
                    <div className="box">
                      {d.callRec.length>0?<OverlayTrigger trigger={['hover', 'focus']} placement="right" overlay={popoverr("","Recommendations",d?.callRec)} rootClose>
                        <button style={{"border":"none",backgroundColor:"#F4F4F4"}}>
                          <img src={bulb} alt=""/>
                          <span>{d?.callRec?.length}</span>
                        </button>
                      </OverlayTrigger>:
                      <>
                        <img src={bulb} alt=""/>
                        <span>{d?.callRec?.length}</span>
                      </>}
                    </div>
                    <div className="box">
                    {d.moments.length>0?<OverlayTrigger trigger={['hover', 'focus']} placement="right" overlay={popoverr("","Moments","",d?.moments)} rootClose>
                        <button style={{"border":"none",backgroundColor:"#F4F4F4"}}>
                          <img src={moment} alt=""/>
                          <span>{d?.moments?.length}</span>
                        </button>
                      </OverlayTrigger>:                                                                                                                                                                                                                                                                                                                                                                                                                        
                      <>
                      <img src={moment} alt=""/>
                      <span>{d?.moments?.length}</span>
                      </>}
                    </div>
                  </div>
                  <div className="score">
                    {userDetail.includes("CALL SCORE")?<div className="scoreCommonStyle callScore">
                      <span className="text">CALL<br/>SCORE</span>
                      <span className="badge" style={{background:d.callColour=='Amber'?"#FFBF00":d.callColour==null?"Grey":d.callColour}}>{d.callScore}%</span>
                    </div>:""}                                                                                                                                                                                                                                                                                                                                                                                                                        
                    {userDetail.includes("PITCH SCORE")?<div className="scoreCommonStyle pitchScore">
                      <span className="text">PITCH<br/>SCORE</span>
                      <span className="badge" style={{background:d.pitchColour=='Amber'?"#FFBF00":d.pitchColour==null?"Grey":d.pitchColour}}>{d.pitchScore}%</span>
                    </div>:""}
                  </div>
                </div>
                <hr/>
                <div className="seller">
                  <div className="name">
                    <span className="icon">
                      <img src={seller} alt=""/>
                    </span>
                    {`${d.sellerName}`}
                  </div>
                  <div className="number">
                    <span className="icon">
                      <img src={phone} alt=""/>
                    </span>
                    {d.phone}
                  </div>
                  <div className="date">
                    <span className="icon">
                      <img src={calender} alt=""/>
                    </span>
                    {d.date}
                  </div>
                  {/* <div className="time">
                    <span className="icon">
                      <img src={time} alt=""/>
                    </span>
                    {conversion(d.talkDuration)}
                  </div> */}
                  <div>
                    <button className="groupCall" onClick={()=>{clickHandler(d?.callId,d?.audioRecordingUrl,d?.talkDuration,d?.date,d?.employeeId,d?.sellerName,d?.customerName,d?.coeIds,d?.coeName,d?.phone,d?.userExternalId,"SINGLE_CALL")}}>
                      View Call
                    </button>
                  </div>
                </div>
              </Card.Body>
            </Card>:"")})} 
        </>
          )
        }):<NodataFound id="salesColumn"/> 
        }
      </>
    )}
    <Filter
      componentType = "Conversation-Support"
      filterCallList= {filterCallList}
      defaultConvFilter={defaultConvFilter}
      name={"SUPPORT"}
    />
        
    {callLists.length>0 && availablePages>0?
    <PaginationAlter
      callBack={callBack}
      availablePages = {availablePages}
      loaderTrue = {props.loaderTrue}
      loaderFalse = {props.loaderFalse}
    />:""}
    </>
  );
}