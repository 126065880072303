import CoeUsersColumn from "./CoeUsersColumn";
import React,{ useState, useEffect } from "react";
import { connect } from "react-redux";
import { getCoeUsersList } from "../../../redux-container/settings/manage_coe/action";
import { SETTINGS_COMPONENT_TYPE_DATA } from "../../../Constants/Enum";
import AddUser from "./AddUser";
import Loading from "../../Commons/Loading";

function CoeUsersTable(props) {
  const [isOpen, setIsOpen] = useState(false);

  function displayCoe() {
    props.setVisible(false);
    props.handlePageIndex(props.pageIndex);
  }

  function handleShow() {
    setIsOpen(true);

    console.log("show", isOpen);
  }

  return (
    <>
      {props.loader ? (
        <Loading variant="dark" />
      ) : isOpen ? (
        <AddUser
          modalHeading={SETTINGS_COMPONENT_TYPE_DATA.MANAGE_COE_ADD_USER}
          setIsOpen={setIsOpen}
          selectedRowData={props.coeData}
        />
      ) : (
        <div className="coemember-table viewProfile-table mt-3">
          <div className="d-flex top-fixed-box">
            <div className=" mb-2 text-sm-left myTeamLHeader">
              <a
                type="button"
                className="backtomanageCoe-table"
                onClick={displayCoe}
              >
                <i className="bx bx-arrow-back me-1"></i>
              </a>
              <h5 className="account-name account-id">
                {" "}
                {props.coeData.coeName}
              </h5>
            </div>
            <div className="nav-item add-coe-btn-top" role="presentation">
              <a
                id="edit-coe-member-profile"
                className="nav-link btn account-edit-btn edit-account"
                data-bs-toggle="tab"
                role="tab"
                aria-selected="false"
                onClick={() => handleShow(true)}
              >
                Add User
              </a>
            </div>
          </div>
          <div className="view-team-card-details">
            <CoeUsersColumn id={props.coeData.coeId} />
          </div>
        </div>
      )}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    loader: state.manage_coe.loader,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCoeUsersList: (Id) => {
      dispatch(getCoeUsersList(Id));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CoeUsersTable);
