export const BASE_URL = "http://65.108.148.94/odio/";
export const PASSWORD_REGEX = /^(?=.*\d)(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/;
export const AUTHENTICATED = "AUTHENTICATED";
export const USER_DETAIL = "USER_DETAIL";
export const ACCESS_TOKEN = "ACCESS_TOKEN"; 
export const USER_PERMISSIONS = "USER_PERMISSIONS";
export const COE_LIST = "COE_LIST";
export const MOMENT_BUCKETS = "MOMENT_BUCKETS";

export const conversionFormat = {
    "dd/MM/yy": { format: 'dd/MM/yy', value: "DD-MM-YYYY" },
    "MM/dd/yy": { format: 'MM/dd/yy', value: "MM-DD-YYYY" },
    "yy/MM/dd": { format: 'yy/MM/dd', value: "YYYY-MM-DD" },
  
    "dd/MM/yyyy": { format: 'dd/MM/yyyy', value: "DD-MM-YYYY" },
    "MM/dd/yyyy": { format: 'MM/dd/yyyy', value: "MM-DD-YYYY" },
    "yyyy/MM/dd": { format: 'yyyy/MM/dd', value: "YYYY-MM-DD" },
  
    "dd-MM-yy": { format: 'dd-MM-yy', value: "DD-MM-YYYY" },
    "MM-dd-yy": { format: 'MM-dd-yy', value: "MM-DD-YYYY" },
    "yy-MM-dd": { format: 'yy-MM-dd', value: "YYYY-MM-DD" },
  
    "dd-MM-yyyy": { format: 'dd-MM-yyyy', value: "DD-MM-YYYY" },
    "MM-dd-yyyy": { format: 'MM-dd-yyyy', value: "MM-DD-YYYY" },
    "yyyy-MM-dd": { format: 'yyyy-MM-dd', value: "YYYY-MM-DD" },
  
    "dd.MM.yy": { format: 'dd.MM.yy', value: "DD-MM-YYYY" },
    "MM.dd.yy": { format: 'MM.dd.yy', value: "MM-DD-YYYY" },
    "yy.MM.dd": { format: 'yy.MM.dd', value: "YYYY-MM-DD" },
  
    "dd.MM.yyyy": { format: 'dd.MM.yyyy', value: "DD-MM-YYYY" },
    "MM.dd.yyyy": { format: 'MM.dd.yyyy', value: "MM-DD-YYYY" },
    "yyyy.MM.dd": { format: 'yyyy.MM.dd', value: "YYYY-MM-DD" },
  
    "dd MMM yyyy": { format: 'dd MMM yyyy', value: "DD-MM-YYYY" },
    "dd MMMM yyyy": { format: 'dd MMMM yyyy', value: "DD-MM-YYYY" },
    "MMMM dd, yyyy": { format: 'MMMM dd, yyyy', value: "MM-DD-YYYY" },
    "EEE, MMMM dd, yyyy": { format: 'EEE, MMMM dd, yyyy', value: "MM-DD-YYYY" },
    "EEEE, MMMM dd, yyyy": { format: 'EEEE, MMMM dd, yyyy', value: "MM-DD-YYYY" },
    "MMM dd, yyyy": { format: 'MMM dd, yyyy', value: "MM-DD-YYYY" },
    "yyyy MM dd": { format: 'yyyy MM dd', value: "YYYY-MM-DD" }
  };