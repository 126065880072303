import { useEffect, useState } from "react";

function Typewriter({ text, speed, check, onTypingComplete }) {
  const [displayText, setDisplayText] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    if (currentIndex < text.length) {
      const interval = setInterval(() => {
        let nextIndex = currentIndex + Math.floor(Math.random() * speed) + 1;
        if (nextIndex > text.length) {
          nextIndex = text.length;
        }
        setDisplayText(text.substring(0, nextIndex));
        setCurrentIndex(nextIndex);

        if (nextIndex === text.length) {
          clearInterval(interval);
          check && onTypingComplete();
        }
      }, 25);

      return () => clearInterval(interval);
    }
  }, [currentIndex, text, onTypingComplete, speed]);

  return <>{displayText}</>;
}

export default Typewriter;
