import React, { useEffect, useState } from "react";
import { Nav, Tab, Tabs, Row, Col, Sonnet } from 'react-bootstrap';
import Account from "./Account";
import ChangePassword from "./ChangePassword";
import MyTeam from "./MyTeam";
import Integrations from "./Integrations"
import {toast} from 'react-toastify'
import { history } from "../../redux-container/store";
import { connect } from "react-redux";
import profileService from "../Services/profile.service";
import {getTeamList} from "../../redux-container/accounts/action";
import clientService from "../Services/client.service";
import { USER_DETAIL } from "../../Constants/constant";

function ProfileSidebar(props){
    const details = JSON.parse(localStorage.getItem(USER_DETAIL));
    const [key, setKey] = useState('myProfile');
    const [loading, setLoading] = useState(false)
    const[role, setRole] = useState('AGENCY_ADMIN')
    const [profileData, setProfileData] = useState({
        id:'',
        firstName:'',
        lastName:'',
        email:'',
        userPhoneNumbers:[],
        secEmail:[],
        managerId:'',
        manager:'',
        designation:'',
        role:'',
        caller : '',
        coe:[],
        status:''
    });
  const [data, setData] = useState([]);
  const [] = useState([]);
  const [isHide, setIsHide] = useState(true);
  const onEdit = () => setIsHide(false);
  const onEditClose = () => setIsHide(true);

  useEffect(() => {
    document.title = "Account - Odio"
    }, []);

  const handleSelect=(key) => {
        setKey(key);
        if (key === 'myProfile'){
            getData()
            
        }
        if (key==='myTeam')
        {
             props.getTeamsList();
              
        }
        if (key==='Integrations')
        {
          console.log("my Integrations call")
        }
        if (key==='changePassword')
        {
          console.log("my changePassword call")
        }
    }

    const getData =()=>{
         setLoading(true)
        profileService.profile().then((response) => {
            let res=response.data.data;
            setLoading(false)
            setProfileData({
                id:res.id,
                firstName:res.firstName,
                lastName:res.lastName,
                email:res.email,
                userPhoneNumbers:[res.userPhoneNumbers],
                secEmail:[res.secondaryEmails],
                managerId:res.managerId,    
                manager:res.managerName,
                designation:res.designation,
                role:res.role,
                caller : res.caller,
                coe:[res.clientCoes],
                status:response.data.status
               
            })
           
          }).catch((error) => {
              console.log(error);
              setLoading(false)
                 if(data.status === 400)
                  {
                  toast.error(data.message);
                  }
                 else if(data.status === 401)
                  {
                      toast.error(data.message);
                  }
                  else if(data.status === 403)
                  {
                      history.push("./login");
                      toast.error(data.message);
                  }
                  else if(data.status === 404)
                  {
                      toast.error(data.message);
                  }
                
                 else if(data.status === 500)
                  {
                     // toast.error('User doesn’t exist.');
                  }
              else
              {
                  
                   console.log("Oops somethings went wrong, Network Error.")
              }
                 
          });
    }
    useEffect(() => {
        
        getData();
    }, [])

    return(
        <>
          <div className="page-wrapper account-content">
                <div className="page-content account-details-page">
                    <div className="row">
                        <div className="col-md-12">
                            <h4 className="mb-2">Account</h4>
                            <div className="card edit-border">
                                <div className="card-body account-page">
                                    <Tabs  id="controlled-tab-example" activekey={key} onSelect={handleSelect} >
                                        <Tab eventKey="myProfile" title="My Profile">
                                            <Account profileData={profileData} loading={loading} setProfileData={setProfileData}/>
                                        </Tab>
                                        <Tab eventKey="integrations" title="Integrations">
                                            <Integrations />
                                        </Tab>
                                        {details.userRole === 'ADMIN' &&
                                        <Tab eventKey="myTeam" title="My Team">
                                            <MyTeam/>
                                        </Tab>}
                                        <Tab eventKey="changePassword" title="Security">
                                            <ChangePassword />
                                        </Tab>
                                    </Tabs>
                                    <div className="">
                                        {/* <Tab.Container id="left-tabs-example" defaultActiveKey="myProfile" onSelect={handleSelect}>
                                            <div className="px-0">
                                                    <Nav variant="pills" className="nav-tabs">
                                                        <Nav.Item>
                                                            <Nav.Link eventKey="myProfile" >
                                                                <div className="d-flex align-items-center">
                                                                    <div className="tab-icon">
                                                                        <i className="lni lni-user" />
                                                                    </div>
                                                                    <div className="tab-title text-nowrap" type="button">MY PROFILE</div>
                                                                </div>
                                                            </Nav.Link>
                                                        </Nav.Item>
                                                        <Nav.Item>
                                                            <Nav.Link eventKey="Integrations">
                                                                <div className="d-flex align-items-center">
                                                                    <div className="tab-icon">
                                                                        <i className="bx bx-transfer" />
                                                                    </div>
                                                                    <div className="tab-title text-nowrap" type="button">INTEGRATIONS</div>
                                                                </div>
                                                            </Nav.Link>
                                                        </Nav.Item>
                                                        <Nav.Item>
                                                            <Nav.Link eventKey="myTeam">
                                                                <div className="d-flex align-items-center">
                                                                    <div className="tab-icon">
                                                                        <i className="lni lni-network" />
                                                                    </div>
                                                                    <div className="tab-title text-nowrap" type="button">MY TEAM</div>
                                                                </div>
                                                            </Nav.Link>
                                                        </Nav.Item>
                                                        <Nav.Item>
                                                            <Nav.Link eventKey="changePassword">
                                                                <div className="d-flex align-items-center">
                                                                    <div className="tab-icon">
                                                                        <i className="fadeIn animated bx bxs-lock" />
                                                                    </div>
                                                                    <div className="tab-title text-nowrap" type="button">CHANGE PASSWORD</div>
                                                                </div>
                                                            </Nav.Link>
                                                        </Nav.Item>
                                                        
                                                    </Nav>
                                            </div>
                                            <div className="account-details-box setting-box card moment-list-group-scroll">
                                                <Tab.Content>
                                                    <Tab.Pane eventKey="myProfile">
                                                    <Account profileData={profileData} loading={loading}/>
                                                    </Tab.Pane>
                                                    <Tab.Pane eventKey="myTeam">
                                                    <MyTeam/>
                                                    </Tab.Pane>
                                                    <Tab.Pane eventKey="Integrations">
                                                    <Integrations />
                                                    </Tab.Pane>
                                                    <Tab.Pane eventKey="changePassword">
                                                    <ChangePassword />
                                                    </Tab.Pane>
                                                
                                                </Tab.Content>
                                            </div>
                                        </Tab.Container> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
             </div>
        </>
    )
}

const mapDispatchToProps = (dispatch) =>{
    
    return{
        getTeamsList: () => {dispatch(getTeamList())}    
    }
}

export default connect(null, mapDispatchToProps) (ProfileSidebar);