import  toast  from "../Toast/toaster";
import { apiCall } from "./Interceptor";
import 'react-toastify/dist/ReactToastify.min.css';

const GET_REPORT_LIST = "/odio/api/user/getReportExcel";
const GET_REPORT_TYPE = "/odio/api/reports/get-report-type";
const GET_FREQUENCY_TYPE = "/odio/api/reports/all/frequency";
const GET_TRIGGERED_REPORTS = "/odio/api/reports/triggered/reports";
const CREATE_REPORT = "/odio/api/reports/trigger/reports";
const UPDATE_REPORT = "/odio/api/reports/update";
const REPORT_CONFIG = "/odio/api/reports/get-report-configuration";

const getReportList = async (
  externalId,
  frequency,
  fromD,
  page,
  reportType,
  toD
) => {
  const response = await apiCall.get(
    `${GET_REPORT_LIST}?coeExternalId=${externalId}&frequency=${frequency}&fromD=${fromD}&pageNo=${page}&pageSize=10&reportType=${reportType}&toD=${toD}`
  );
  return response;
};

const getReportTypes = async () => {
  const response = await apiCall.get(GET_REPORT_TYPE);
  return response.data.data;
};

const getAllFrequency = async () => {
  const response = await apiCall.get(GET_FREQUENCY_TYPE);
  return response;
};

const gettriggeredReports = async () => {
  const response = await apiCall.get(GET_TRIGGERED_REPORTS);
  return response;
};

const getReportConfig = async () => {
  const response = await apiCall.get(REPORT_CONFIG);
  return response.data.data;
};

const createReport = async (data) => {
  const response = await apiCall.post(CREATE_REPORT,data)
  // toast.success(response.data.message);
  return response;
};

const updateReport = async (data) => {
  const response = await apiCall.put(UPDATE_REPORT, data);
  return response;
};

const deleteReport = async (Id) => {
  const DELETE_REPORT = `/odio/api/reports/delete?reportId=${Id}`;
  const response = await apiCall.get(DELETE_REPORT);
  toast.success(response.data.message);
  return response;
};

const userListOfcoe = async (data) => {
  const DELETE_REPORT = `/odio/api/user/get/userList?coeId=${data.coeId}&roleName=${data.role}`;
  const response = await apiCall.get(DELETE_REPORT);
  //toast.success(response.data.message)
  return response.data.data;
};

export default {
  getReportList,
  getReportTypes,
  getAllFrequency,
  gettriggeredReports,
  getReportConfig,
  createReport,
  updateReport,
  deleteReport,
  userListOfcoe,
};
