import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from "react-router-dom";
import { Form, Button } from 'react-bootstrap';
//import adminService from '../Services/admin.service';

import background from '../../assets/images/login-images/reset-password.png';
import './login.css';
import { height } from '@mui/system';

export default function ResetPassword() {
    const history = useHistory();
    const [newPasswordShown, setNewPasswordShown] = useState(false);
    const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);

    const [inputs, setInputs] = useState({
        newPassword: '',
        confirmPassword: ''
    });

    const errors = {};
    const [formError, setFormErrors] = useState({});
    const [isSubmit, setIsSubmit] = useState(false)

    const [message, setMessage] = useState('')

    const { newPassword, confirmPassword } = inputs;

    useEffect(() => {
        document.title = "Reset Password - Odio";
      }, [])

    function handleChange(e) {
        const { name, value } = e.target;
        setInputs(inputs => ({ ...inputs, [name]: value }));
    }
    const validate = (values) => {

        const regex = /^(?=.*\d)(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/;

        if (!values.newPassword) {
            console.log("Password is required.")
            errors.newPassword = "Enter your new password."

        }
        else if (!regex.test(values.newPassword)) {

            errors.newPassword = "This is not a valid password format."
        }
        else if (values.newPassword < 8) {

            errors.newPassword = "Password must be more than 8 characters."
        } if (!values.confirmPassword) {

            errors.confirmPassword = "Renter your new password."
        }

        if (values.confirmPassword && regex.test(values.newPassword) && values.newPassword && values.newPassword !== values.confirmPassword) {
            console.log("Password and confirm not equal.")

            errors.confirmPassword = "Password and confirm  password are not same."
        }

        return errors;
    }

    let location = useLocation();
    const [email, setEmail] = useState('')
    const [token, setToken] = useState('')
    const [isFirstLogin, setIsFirstLogin] = useState(false)
    const [pshow, setpshow] = useState(false)
    const [cpshow, setcpshow] = useState(false)

    useEffect(() => {
        console.log("location---", location)
        console.log("location---", location.search)
        let paramString = location.search
        if (paramString.includes('email')) {
            const queryParams = new URLSearchParams(window.location.search);
            const tokenFromParam = queryParams.get('token');
            const emailFromParam = queryParams.get('email');
            const setIsFirstLoginFromParam = queryParams.get('isFirstLogin');

            setEmail(emailFromParam)
            setToken(tokenFromParam)
            setIsFirstLogin(setIsFirstLoginFromParam)
            console.log("token----", tokenFromParam)
            console.log("email----", emailFromParam)
            console.log("isLoginFromParam----", setIsFirstLoginFromParam)

        }



    }, [location])

    const toggleNewPassword = () => {
        setNewPasswordShown(!newPasswordShown);
        setpshow(!pshow);
    };
    const toggleConfirmPassword = () => {
        setConfirmPasswordShown(!confirmPasswordShown);
        setcpshow(!cpshow);
    };
    function handleSubmit(e) {
        e.preventDefault();
        validate(inputs)
        setMessage('')
        setFormErrors(errors)

        if (Object.keys(errors).length === 0) {
            console.log("error Api")
            if (isFirstLogin) {
                // adminService.savePassaword(newPassword, confirmPassword, email).then(
                //     (response) => {
                //         if (response.data.status == "success") {
                //             console.log("response.data reset password", response.data)
                //             setMessage(response.data.message)
                //             history.push('./login')
                //         }

                //     }
                // ).catch((error) => {
                //     console.log("error messagae ===>", error);
                //     if (error.message === "Request failed with status code 400")
                //         setMessage("User does not exist with email ")
                //     else if (error.message === "Network Error")
                //         setMessage("Oops somethings went wrong ,Network Error.")

                // });
            }
            else {
                // adminService.resetPassword(newPassword, confirmPassword, email, token).then(
                //     (response) => {
                //         if (response.data.status == "success") {
                //             console.log("response.data reset password", response.data)
                //             history.push('./login')
                //         }

                //     }
                // ).catch((error) => {
                //     console.log("error messagae ===>", error);
                //     if (error.message === "Request failed with status code 400")
                //         setMessage("It's like old password , please reset your new password.")
                //     else if (error.message === "Network Error")
                //         setMessage("Oops somethings went wrong ,Network Error.")

                // });
            }
        }



    }
    function backToLogin() {
        history.push("./login")
    }
    function handleFocusPass(){
        formError.newPassword="";
    }
    function handleFocusCPass(){
        formError.confirmPassword="";
    }
    return (
        <div className="wrapper reset-password-content">
            <div className="authentication-header header-sign" style={{backgroundImage : `url(${background})`}} />
            
            <div className="section-authentication-signin d-flex justify-content-center my-5 my-lg-0">
                <div className="container-fluid">
                    <div className="row row-cols-1 row-cols-lg-2 row-cols-xl-3">
                        <div className="col mx-auto">
                            <div className="card mb-5">
                                <div className="card-body">
                                    <div className="p-4 rounded">
                                        <div class="text-center mb-4 p-0">
                                            <a class="navbar-brand" href="#">
                                                <img src="images/odio_logo_blue.png" width="140" alt="Odio Logo" />
                                            </a>
                                        </div>
                                        <div className="form-body">
                                            <form className="row g-3">
                                                <h4 className=" font-weight-bold"> Reset Password</h4>
                                                <p className="text-muted m-0">We received your reset password request. Please enter your new password!</p>
                                                <div className="mb-0">
                                                    <label className="form-label">New Password</label>
                                                    <div className="input-group"> <span className="input-group-text bg-transparent"><i className="bx bxs-lock-open" /></span>
                                                        {/* <input type="text" name="newPassword" value={newPassword} onChange={handleChange} className="form-control form-control-lg" id="inputChoosePassword" placeholder="Enter New Password" />
                                                        */}
                                                        <input type={newPasswordShown ? "text" : "password"} name="newPassword" value={newPassword} onFocus={handleFocusPass} onChange={handleChange} className="form-control form-control-lg border-end-0" id="inputChoosePassword" defaultValue={12345678} placeholder="Enter Password" />
                                                        <a onClick={toggleNewPassword} tabindex="-1" href="javascript:;" className="input-group-text bg-transparent">{pshow ? <i className="bx bx-show"></i> : <i className="bx bx-hide"></i> }</a>

                                                    </div>
                                                </div>
                                                <p style={{ color: "red", height: "16px" }} className="my-1 lh-1 font-12">{formError.newPassword}</p>
                                                <div className="my-0 ">
                                                    <label className="form-label">Confirm Password</label>
                                                    <div className="input-group"> <span className="input-group-text bg-transparent"><i className="bx bxs-lock" /></span>
                                                        {/* <input type="text" name="confirmPassword" value={confirmPassword} onChange={handleChange} className="form-control form-control-lg" id="inputConfirmPassword" placeholder="Confirm Password" />
                                                       */}
                                                        <input type={confirmPasswordShown ? "text" : "password"} name="confirmPassword" value={confirmPassword} onFocus={handleFocusCPass} onChange={handleChange} className="form-control form-control-lg border-end-0" id="inputChoosePassword" defaultValue={12345678} placeholder="Enter Password" />
                                                        <a onClick={toggleConfirmPassword} tabindex="-1" href="javascript:;" className="input-group-text bg-transparent">{cpshow ? <i className="bx bx-show"></i> : <i className="bx bx-hide"></i> }</a>


                                                    </div>
                                                </div>
                                                <p style={{ height: "20px" }} className="my-1 lh-1 font-12">
                                                    <span style={{ color: "red" }} className="my-1">{formError.confirmPassword}</span>
                                                    <span style={{ color: "red" }} className="my-1">{message}</span>
                                                </p>
                                                <div className="d-grid gap-2 mt-0">
                                                    <Button className="btn btn-lg change-btn" variant="primary" onClick={handleSubmit}>Change Password</Button>
                                                    <Button className='btn btn-lg btn-light' variant="light" onClick={backToLogin}><i class="bx bx-arrow-back me-0"></i> Back to Login</Button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*end row*/}
                </div>
            </div>
            <footer className="bg-white shadow-sm border-top p-2 text-center fixed-bottom">
                <p className="mb-0">Copyright © 2021 - VoiceIO Artificial Intelligence Pvt Ltd</p>
            </footer>
        </div>
    );
}
