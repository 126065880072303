import React, { useState, useEffect } from "react";
import { Card, Stack, Col, Row } from "react-bootstrap"
import Loading from "../../../Commons/Loading";
import ApexChart from "../../../Graphs/ApexChart";
import dashboardService from "../../../Services/dashboard.service";
import DoubleCarouselCard from "./DoubleCarousalCard";

export default function DoubleCarousel(props) {

    const widgetDataType = (val) => {
        props.showDetailModal(val)
    }
    // console.log("wid_card___>", props);
    return (
        <>
            <Card className="w-100 mx-1 bg-transparent card-remover my-1" >
                <Card.Body className="p-0">
                    <div>
                        {props.row?.map((data, index) => {
                            return (
                                <DoubleCarouselCard carusalData={props.carusalData[data.wgt_code]} loader={props.loading && !Boolean(props.carusalData[data.wgt_code])} key={index} data={data} showDetailModal={widgetDataType} widgetData={props.widgetData} defaultFilteredData={props.defaultFilteredData} name={props.name} />
                            );
                        })}
                    </div>
                </Card.Body>
            </Card>

        </>
    )
}