import React from "react";
import slackLogoIcon from "../../../assets/images/slackLogoIcon.png";

export default function Integrations({ urlSlack }) {
let { sharedUrl, appName } = urlSlack;
    return (<>
        <div id="primaryIntegrations" className="mt-2">
            <div className="d-flex mt-4">
                {appName ? <>
                <h4>Your slack is already integrated with {appName} App.</h4>
                    
                </> : <>
                    <div className="d-flex">
                        <img src={slackLogoIcon} className="aap-ing-img" alt="user avatar" />
                    </div>
                    <div className="ms-2 app-Integrations-content">
                        <h4 className="mb-0 font-16">Slack</h4>
                        <p className="mb-1 font-14">You will receive notifications on Slack.</p>
                        <a className="btn bg-medium-blue text-white font-14 px-5 py-1" href={sharedUrl}>Add Slack</a>
                    </div>
                </>}
            </div>
        </div >
    </>)
}