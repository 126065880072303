import {
  useTable,
  useSortBy,
  usePagination,
  useBlockLayout,
  useRowSelect,
} from "react-table";
import { useSticky } from "react-table-sticky";
import { Button, Dropdown } from "react-bootstrap";
import { Styles } from "./TableStyle";
import "./table.css";
import CommonSwitchCase from "../CommonSwitchCase";
import { useEffect, useState } from "react";
import SalesCallDetail from "../../Conversations_Dashboard/Sales/SalesCallDetail";
import * as React from 'react'

export default function SimpleTable({
  columns,
  data,
  componentType,
  isEdit,
  isDelete,
  isAdd,
  isReco,
  isExcelPdfButton,
  coeId,
}) {
  const [buttonValue, setButtonValue] = useState("");
  const [selectedRowData, setSelectedRowData] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    pageOptions,
    state,
    canNextPage,
    //gotoPage,
    allColumns,
    getToggleHideAllColumnsProps,
    PageCount,
    canPreviousPage,
    prepareRow,
    selectedFlatRows,

    // state: { pageIndex, pageSize, selectedRowIds },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    useSortBy,
    usePagination,
    useBlockLayout,
    useSticky,
    useRowSelect
  );

  const { pageIndex } = state;

  function setView(buttonValue, selectedRowData) {
    setIsOpen(true);
    setSelectedRowData(selectedRowData);
    setButtonValue(buttonValue);
  }

  return isOpen ? (
    CommonSwitchCase.switchCase(
      setIsOpen,
      componentType,
      buttonValue,
      selectedRowData
    )
  ) : (
    <>
      <div className="d-flex mb-2 odio-tableTop">
        {/* < Dropdown >
                        <Dropdown.Toggle variant="outline-secondary font-16" id="dropdown-basic" >
                            <i className='lni lni-cogs'></i>
                        </Dropdown.Toggle >
                        <Dropdown.Menu>
                            {allColumns.map(column => (
                                <div key={column.id}>

                                    <Dropdown.Item href="#/action-1">
                                        <label>
                                            <input type="checkbox" {...column.getToggleHiddenProps()} />{' '}
                                            {column.id}
                                        </label>
                                    </Dropdown.Item>
                                </div>
                            ))}

                        </Dropdown.Menu>
                    </Dropdown > */}

        {isExcelPdfButton && (
          <>
            {" "}
            <Button variant="outline-secondary ms-1 border-end-0 font-14 fw-normal excel">
              Excel
            </Button>
            <Button variant="outline-secondary font-14 fw-normal pdf">
              Pdf
            </Button>{" "}
          </>
        )}
      </div>

      <Styles>
        <div
          {...getTableProps()}
          className="table sticky font-14 odio-table odio-group-scroll mb-2"
        >
          <div className="header">
            {headerGroups.map((headerGroup) => (
              <div {...headerGroup.getHeaderGroupProps()} className="tr">
                {headerGroup.headers.map((column) => (
                  <div
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className="th d-flex justify-content-between"
                  >
                    {column.render("Header")}
                    <span>
                      {column.isSorted ? (column.isSortedDesc ? "↓" : "↑") : ""}
                    </span>
                  </div>
                ))}
              </div>
            ))}
          </div>
          <div {...getTableBodyProps()} className="body">
            {page.map((row) => {
              prepareRow(row);

              const rowLength = row.cells.length - 1;
              return (
                <div {...row.getRowProps()} className="tr">
                  {row.cells.map((cell, index) => (
                    <div {...cell.getCellProps()} className="td">
                      {cell.render("Cell")}
                      {rowLength == index ? (
                        <>
                          {isEdit && (
                            <a
                              type="button"
                              className="edit-Emp-member"
                              onClick={() => {
                                setView("EDIT", cell.row.original);
                              }}
                            >
                              <i className="lni lni-pencil mx-1"></i>
                            </a>
                          )}

                          {isDelete && (
                            <a
                              type="button"
                              className="del-coe-btn"
                              data-bs-toggle="modal"
                              data-bs-target="#deleteEmpModal"
                              onClick={() => {
                                setView("DELETE", cell.row.original);
                              }}
                            >
                              <i className="lni lni-archive mx-1" />
                            </a>
                          )}

                          {isAdd && (
                            <a
                              type="button"
                              className="del-coe-btn"
                              data-bs-toggle="modal"
                              onClick={() => {
                                setView("ADD", cell.row.original);
                              }}
                            >
                              <i className="lni lni-plus mx-1" />
                            </a>
                          )}
                          {isReco && (
                            <a
                              type="button"
                              className="del-coe-btn"
                              data-bs-toggle="modal"
                              onClick={() => {
                                setView("RECO", cell.row.original);
                              }}
                            >
                              <i className="lni lni-list"></i>
                            </a>
                          )}
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  ))}
                </div>
              );
            })}
          </div>
        </div>
        <div className="odio-tablefooter d-flex justify-content-between align-items-center">
          <span>
            Showing{" "}
            <strong>
              {pageIndex + 1} of {pageOptions.length}{" "}
            </strong>
          </span>
          {/* <span>
                        | go to page
                        <input type='number' defaultValue={pageIndex + 1} onChange={(e) => {
                            const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0
                            gotoPage(pageNumber)
                        }} />
                    </span> */}

          <div>
            <Button
              variant="outline-secondary"
              className="paginate_button page-item mx-1 previous font-14 fw-normal"
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
            >
              Prev
            </Button>
            <Button
              variant="outline-secondary"
              className="paginate_button page-item mx-1 previous font-14 fw-normal"
              onClick={() => nextPage()}
              disabled={!canNextPage}
            >
              Next
            </Button>
          </div>
        </div>
      </Styles>
    </>
  );
}
