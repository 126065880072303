import React, { useEffect, useState } from 'react'
import ReactTable from '../../Commons/Table/ReactTable';
import { SETTINGS_COMPONENT_TYPE } from '../../../Constants/Enum';
import NodataFound from '../../Commons/NoDataFound';
import Loading from '../../Commons/Loading';

function ManageCompetitorsColumns(props) {
    console.log(props, 'lplp%%%')
    const [roles, setRoles] = useState([])

    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const competitorColumn = [
        {
            Header: "Competitor Id",
            accessor: (d) => (d.displayId),
        },
        {
            Header: "Competitor Name",
            accessor: (d) => `${d.competitorName ? d.competitorName : "-" }`,
        },
        {
            Header: "Competitor Aliases",
            accessor: (d) => (
                <ul class="mb-0">
                    {d.competitorAliases.length > 0 ? d.competitorAliases.map(alias => (
                        <li><a type="button"
                            className="edit-Emp-member"
                            >{alias.aliasName}</a></li>
                    )
                    ) : "-"}
                </ul>),
            disableSortBy: true,
        },
        {
            Header: "Sector",
            accessor: (d) => `${d.sector ? d.sector : "-"}`,
        },
        {
            Header: "Action",
            accessor: "action",
            sticky: "right",
            disableSortBy: true,
            Cell: (e) => (
                <p className="mb-0 text-center d-inline">
                    <a
                        type="button"
                        className="edit-Emp-member"
                        onClick={() => OnClickEdit(e.row.original, "EDIT")}
                    >
                        <i className="lni lni-pencil mx-1" data-tip="Edit Competitor"></i>
                    </a>
                </p>
            ),
        },
    ];

    function OnClickEdit(e) {
        props.setEditCompetitorVisibility(true);
        props.setCompetitorData(e);
    }

    return (
        <>
            {props.loader ?
                (<div className="loader-container">
                    <Loading variant="dark" />
                </div>
                ) : props.competitorsList != null && props.competitorsList.length > 0 ? (
                    <div className="table-responsive dataTable-style react-table">
                        <ReactTable
                            columns={competitorColumn}
                            componentType={SETTINGS_COMPONENT_TYPE.MANAGE_COMPETITORS}
                            data={props?.competitorsList}
                            isDelete={true}
                            getCompetitorsList={props?.getCompetitorsList}
                        />

                    </div>
                ) : <NodataFound />
            }
        </>
    );
}

export default ManageCompetitorsColumns