import React,{ useState } from "react";
import { Col,Row } from "react-bootstrap";
import { Tab, Tabs,Nav } from "react-bootstrap";
import Loading from "../../Commons/Loading";
import { Keyword } from './Keyword';

export const Keywords=(props)=> {
    const [keyClicked, setKeyClicked] = useState("agent");

    const handleSelect = (keyClicked)=>{
        setKeyClicked(keyClicked)
      }

  return (
    <>
    {props.loader ? (
      <div className="loader-container">
        <Loading variant="dark" />
      </div>) :
      
      <Col lg={8} style={{ height: "70vh",padding:'10px' }}>
      <div style={{ display: "flex" }}>
          <div style={{ overflowX: "auto", flex: 1 }}>
              <table className="table table-bordered mb-0">
                  <thead className="fixed-header-table">
                      <tr className="agent-table">
                          <th>Agent Word Cloud</th>
                      </tr>
                  </thead>
                  <tbody className="fixed-header-table">
                      {Object.entries(props.keywordsCount.agentWordCloud ? props.keywordsCount.agentWordCloud : '').map(item => {
                          return (
                              <tr>
                                  <td style={{ display:'flex',justifyContent:'space-between' }}>
                                      {item[0]}
                                      <span className='badge btn btn-outline-success font-normal font-12 rounded-pill'>{item[1]}</span>
                                  </td>
                              </tr>)
                      })}
                  </tbody>
              </table>
          </div>
          <div style={{ overflowX: "auto", flex: 1 }}>
              <table className="table table-bordered mb-0">
                  <thead className="fixed-header-table">
                      <tr className="agent-table">
                          <th>Customer Word Cloud</th>
                      </tr>
                  </thead>
                  <tbody className="fixed-header-table">
                      {Object.entries(props?.keywordsCount?.customerWordCloud ? props?.keywordsCount?.customerWordCloud : '').map(item => {
                          return (
                              <tr>
                                  <td style={{ display:'flex',justifyContent:'space-between' }}>
                                      {item[0]}
                                      <br />
                                      <span className='badge btn btn-outline-success font-normal font-12 rounded-pill'>{item[1]}</span>
                                  </td>
                              </tr>)
                      })
}
                  </tbody>
              </table>
          </div>
      </div>
  </Col>
    }</>
  )
}
