import toast from "../Toast/toaster";
import { apiCall } from "../../Components/Services/Interceptor";
const PROFILE_GET = "/odio/api/user/get-user-profile";
const PROFILE_UPDATE = "/odio/api/user/update";
const CHANGE_PASSWORD = "/odio/api/user/reset-password";
const MY_TEAM = "/odio/api/user/get-user-team";
const GET_MY_TEAM_CALLER = "/odio/api/user/team";
const GET_MY_TEAM = "/odio/api/user/teamHierarchy";

const profile = async () => {
  return await apiCall.get(PROFILE_GET);
};

const managerProfile = async (id) => {
  return await apiCall.get(PROFILE_GET + "?userId=" + id);
};

const agentProfile = async(id)=>{
  return await apiCall.get(`${PROFILE_GET}?userId=${id}`)
}

const updateProfile = async (data) => {
  await apiCall
    .put(PROFILE_UPDATE, data)
    .then((response) => {
      if (response) {
        if (response.data.status === 200) {
          toast.success("Successfull");
        }
      }
      return response;
})
}

const localData = JSON.parse(localStorage.getItem("AGENT"))

const myTeam = async () => {
    const localData = JSON.parse(localStorage.getItem("AGENT"))
    const response = await apiCall.get(`${MY_TEAM}?fromD=${localData?.fromD?localData.fromD:null}&"toD"=${localData?.toD?localData.toD:null}&coeIds=${localData?.selectedCoeRange?localData?.selectedCoeRange:null}`);
    return response;
}

const getMyTeam = async (data) => {
    const response = await apiCall.post(GET_MY_TEAM,{"coeIds":data.coeIds,"fromD":data.fromD,"toD":data.toD, "userId": data.userId});
    return response;
}

const getMyTeamCaller = async (data) => {
  const response = await apiCall.post(GET_MY_TEAM_CALLER,{"coeIds":data.coeIds,"fromD":data.fromD,"toD":data.toD});
  return response;
}

export default {
  profile,
  managerProfile,
  agentProfile,
  updateProfile,
  myTeam,
  getMyTeam,
  getMyTeamCaller
};
