import React, { Component, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { useHistory } from 'react-router-dom';
import {logout} from '../../redux-container/login/action';
import { AUTHENTICATED, USER_DETAIL } from "../../Constants/constant";

import authService from "../Services/auth.service";
 function Profile(props) {

  
  const history = useHistory();

  function handleLogout() {
    //authService.logout()
    props.logout(localStorage.getItem(AUTHENTICATED));

  }


  const userRole = JSON.parse(localStorage.getItem(USER_DETAIL))


  const permissions = JSON.parse(localStorage.getItem(USER_DETAIL))?.viewPermissions
  let settingPermission = permissions.some(item => item.viewName === "Settings")

  useEffect(() => {
    document.title = "User Profile  - Odio"
  }, [])

  // useEffect(()=>{
  //   if(userRole=='ADMIN'){
  //     setIsAdmin(true)
  //   }
  //   else{
  //     setIsAdmin(false)
  //   }
  // })
  return (
    <div>
      <li key='Account'><Link to="/account" className="dropdown-item">
          <i className="bx bx-user"></i>
          Account </Link>
      </li>
      {settingPermission ?
      <li key='Settings'>
      <Link to="/setting" className="dropdown-item">
          <i className="bx bx-cog"></i>
          Settings</Link>
      </li>:""}
      {userRole.userRole=='ADMIN'?
      <li key='Upload'>
      <Link to="/upload_GLID" className="dropdown-item" >
          <i className="bx bx-upload"></i>
          Upload GLID</Link>
      </li>:""
      }
      <li key='divider'>
        <div className="dropdown-divider mb-0"></div>
      </li>
      <li key='logout'>
        <a onClick={handleLogout} className="dropdown-item">
          <i className="bx bx-log-out-circle"></i>
            Logout
        </a>
      </li>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    logout: (auth) => dispatch(logout(auth)),
  };
};

export default connect(null, mapDispatchToProps)(Profile);