import React, { useEffect } from "react";
import { useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { Card } from "react-bootstrap";
import { Button } from "react-bootstrap";
import "../../Conversations_Dashboard/Sales/SalesCallDetail.css";
import callsService from "../../Services/calls.service";
import MemberDetail from "../AgentDetail/MemberDetail";
import Loading from "../Loading";
import "../../Profile/profile.css";
import toast from "../../Toast/toaster";
import profileService from "../../Services/profile.service";
import TeamStats from "./TeamStats";
import { Form } from "react-bootstrap";
import { Select } from "@mui/material";
import Filter from "../../Filter/Filter";
import AgentMoments from "./AgentMoments";
import AgentWordCloud from "./AgentWordCloud";
import { useCallback } from "react";
import AgentRecommendation from "./AgentRecommendation";
import NodataFound from "../NoDataFound";

export default function TeamMemberDetail(props) {
  const TeamTabValue = localStorage.getItem("TeamTabValue");
  const [key, setKey] = useState("detail");
  const [show, setShow] = useState(false);
  const onClickHandler = () => {
    setShow(true);
  };
  const [loader, setLoader] = useState(false);

  const [filteredData, setFilteredData] = useState();
  const [defaultFilterData, setDefaultFilterData] = useState();
  const [teamStats, setTeamStats] = useState([]);
  const [callScore, setCallScore] = useState();
  const [pitchScore, setPitchScore] = useState();
  const [agentData, setAgentData] = useState({
    id: "",
    firstName: "",
    lastName: "",
    email: "",
    userPhoneNumbers: [],
    secEmail: [],
    managerId: "",
    manager: "",
    designation: "",
    role: "",
    caller: "",
    coe: [],
    status: "",
    team:"",
    externalId:""
  });

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  console.log(queryString, "TEAMMEMDET", urlParams);
  let params = urlParams.get("id");
  let externalId = urlParams.get("agentExternalId");
  let phone = urlParams.get("phone");
  const tabRedirected = urlParams.get("tabSelected");
  console.log("tabRedirected",tabRedirected,TeamTabValue)
  const tabValue = urlParams.get("tabValue");
  const notifPostId = urlParams?.get("postId");
  console.log(notifPostId);
  const eventType = urlParams?.get("eventType");
  const commentShow = urlParams?.get("commentShow"); 
  const from = urlParams.get("fromD")
    ? urlParams.get("fromD")
    : urlParams.get("from");
  const to = urlParams.get("toD") ? urlParams.get("toD") : urlParams.get("to");

  let userDetail = JSON.parse(localStorage.getItem("USER_DETAIL"))?.clientConfig
    ?.data?.conversationListing;

  if (JSON.parse(localStorage.getItem("USER_DETAIL")).userRole == "AGENT")
    params = JSON.parse(localStorage.getItem("USER_DETAIL")).userId;

  const handleSelect = (key) => {
    // localStorage.setItem("TeamTabValue", key);
    setKey(key);
  };

  const storedData = JSON.parse(localStorage.getItem("AGENT"));

  const getAgentData = () => {
    profileService
      .agentProfile(params)
      .then((response) => {
        let res = response.data.data;
        setLoader(false);
        console.log("data by manager modal", response.data.data,loader);
        setAgentData({
          id: res.employeeId,
          firstName: response.data.data.firstName,
          lastName: res.lastName,
          email: res.email,
          userPhoneNumbers: [res.userPhoneNumbers],
          secEmail: [res.secondaryEmails],
          managerId: res?.managerId,
          manager: res?.managerName,
          designation: res?.designation,
          role: res?.role,
          caller: res?.caller,
          coe: [res.clientCoes],
          status: response.data?.status,
          team: res?.team,
          externalId: res?.clientCoes[0]?.externalId
        });
      })
      .catch((error) => {
        console.log(error);
        toast.error(error);
      });
  };

  // for filtered Data values variable is used
  let values = {
    coeIds: filteredData
      ? filteredData.coeId
        ? filteredData.coeId
        : defaultFilterData?.coeIds
      : defaultFilterData?.coeIds,
    fromD: JSON.parse(localStorage.getItem("AGENT"))?.fromD,
    toD: JSON.parse(localStorage.getItem("AGENT"))?.toD,
    // "userExternalId":[externalId],
    userId: [params],
  };

  const getTeamStats = useCallback(() => {
    setLoader(true);
    // if(params){
    //   delete values.userExternalId
    // }
    // else{
    //   delete values.userId
    // }
    callsService.getTeamMemberStats(values).then((res) => {
      if (res?.data?.status === 0) {
        console.log(res);
        setTeamStats(res.data.data);
        setLoader(false);
      }else {
        setTeamStats([]);
        setLoader(false);
      }
    });
  });

  let firstDay = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
  let firstDate = `${firstDay.getDate()}-${
    firstDay.getMonth() + 1
  }-${firstDay.getFullYear()}`;
  let current = `${new Date().getDate()}-${
    new Date().getMonth() + 1
  }-${new Date().getFullYear()}`;

  // for default filter valuesDef is used
  let valuesDef = {
    coeIds: defaultFilterData?.coeIds,
    fromD:
      storedData && storedData.fromD
        ? storedData.fromD
        : JSON.parse(localStorage.getItem("AGENT"))?.fromD,
    toD:
      storedData && storedData.toD
        ? storedData?.toD
        : JSON.parse(localStorage.getItem("AGENT"))?.toD,
    // "userExternalId":[externalId],
    userId: [params],
  };

  const getDefStats = () => {
    // if(params){
    //   delete valuesDef.userExternalId
    // }
    // else{
    //   delete valuesDef.userId
    // }
    callsService.getTeamMemberStats(valuesDef).then((res) => {
      if (res) {
        console.log(res);
        setTeamStats(res.data.data);
        setLoader(false);
        setCallScore(res.data ? (res.data.data[0] ? res.data.data[0].callScore : 0) : 0);
        setPitchScore(
          res.data ? (res.data.data[0] ? res.data.data[0].pitchScore : 0) : 0
        );
        getAgentData();
      }
    });
  };

  useEffect(() => {
    document.title = "Team Member profile - Odio";
  }, []);

  useEffect(() => {
    if (key == "teamStats" && filteredData) {
      getTeamStats();
      console.log("getTeamStats", key, filteredData);
    }
  }, [filteredData]);

  useEffect(() => {
    setLoader(true);
    if (key == "detail" || key == "wordCloud" || tabRedirected == "teamMoments") {
      getAgentData();
    }
  }, [key == "detail", key == "wordCloud",tabRedirected == "teamMoments"]);

  useEffect(() => {
    if (
      (key == "detail" &&
        defaultFilterData &&
        defaultFilterData.coeIds &&
        tabRedirected != "teamStats" &&
        tabRedirected != "teamMoments") ||
        key == "teamStats" ||
        tabRedirected == "teamStats"
    ) {
      getDefStats();
    }
  }, [key == "teamStats", defaultFilterData, tabRedirected == "teamStats"]);

  const filterData = (e) => {
    setFilteredData(e);
    console.log(e);
  };

  const defaultFilter = (e) => {
    setDefaultFilterData(e);
    console.log(e);
  };

  console.log(externalId, agentData)

  return (
    <>
      <div className="page-wrapper agentDetail">
        <div className="page-content">
          <div className="row row-cols-1 row-cols-md-2 row-cols-xl-4">
            <div className="col-12 mb-4">
              <div className="row">
                <div className="col-md-12">
                  <div className="d-flex team-member-page-heading">
                    <div className="dashboard-headings">
                      <h2
                        className="m-0"
                        style={{ textTransform: "capitalize" }}
                      >
                        {`${agentData.firstName} ${agentData.lastName}`}
                      </h2>
                      <p className="font-12 mb-1 text-primary-gray">
                        {`EmployeeId: ${agentData.id}`}
                      </p>
                    </div>
                    <div className="detail-top-head text-end">
                      {userDetail.includes("CALL SCORE") ? (
                        <span className="badge rounded-pill btn btn-outline-success px-2 my-1 mx-1 py-2 font-14">
                          {`Call Score ${callScore ? callScore : 0}%`}
                        </span>
                      ) : userDetail.includes("PITCH SCORE") ? (
                        <span className="badge rounded-pill btn btn-outline-danger px-2 my-1 py-2 font-14">
                          {`Pitch Score ${pitchScore ? pitchScore : 0}%`}
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <Card>
                <Card.Body className="call-details-box">
                  <div className="setting-section-nev agent-detail">
                    <Tabs
                      id="controlled-tab-example"
                      activeKey={key}
                      defaultActiveKey={
                        tabRedirected ? tabRedirected : "detail"
                      }
                      onSelect={handleSelect}
                    >
                      <Tab eventKey="detail" title="DETAIL">
                        <MemberDetail
                          tabSelected={key}
                          loader={loader}
                          agentData={agentData}
                        />
                      </Tab>
                      <Tab eventKey="teamStats" title="STATS">
                        {teamStats?.length > 0 ? (
                          <TeamStats
                            keySelected={key}
                            tabRedirected={tabRedirected}
                            teamStats={teamStats}
                            loader={loader}
                            userId={params}
                            defaultFilterData={defaultFilterData}
                            filteredData={filteredData}
                            externalId={externalId?externalId:agentData?.externalId}
                            phone={phone}
                            tabValue={tabValue}
                            postId={notifPostId}
                            eventType={eventType}
                          />
                        ) : <NodataFound/>}
                      </Tab>
                      <Tab eventKey="teamMoments" title="MOMENTS">
                        <AgentMoments
                          loader={loader}
                          userId={params}
                          keySelected={key}
                          tabRedirected={tabRedirected}
                          defaultFilterData={defaultFilterData}
                          filteredData={filteredData}
                          from={from}
                          to={to}
                          firstDate={firstDate}
                          current={current}
                          externalId={externalId}
                          phone={phone}
                          tabValue={tabValue}
                          postId={notifPostId}
                          eventType={eventType}
                        />
                      </Tab>
                      {/* <Tab eventKey="reports" title="REPORTS">
                    </Tab> */}
                      <Tab eventKey="wordCloud" title="WORD CLOUD">
                        {key == "wordCloud" && (
                          <AgentWordCloud
                            filteredData={filteredData}
                            userId={params}
                            keySelected={key}
                            defaultFilterData={defaultFilterData}
                            from={from}
                            to={to}
                            firstDate={firstDate}
                            agentData={agentData}
                            current={current}
                          />
                        )}
                      </Tab>
                      <Tab eventKey="recommendation" title="RECOMMENDATION">
                        <AgentRecommendation
                          keySelected={key}
                          userId={params}
                          filteredData={filteredData}
                          defaultFilterData={defaultFilterData}
                        />
                      </Tab>
                    </Tabs>
                  </div>
                </Card.Body>
              </Card>
              {agentData.coe.length > 0 && (
                <Filter
                  componentType="AGENT-FILTER"
                  agentData={agentData}
                  filterData={filterData}
                  defaultFilter={defaultFilter}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
