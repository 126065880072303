import React, { useEffect } from "react";
import { useState } from 'react';
import MomentGroup from "./MomentGroup";
import "./manageMoments.css";
import Loading from "../../Commons/Loading";
import { Form } from 'react-bootstrap';
import toast from '../../Toast/toaster'
import momentService from "../../Services/moment.service";
import { Modal, Button } from "react-bootstrap";
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { USER_DETAIL } from "../../../Constants/constant";
import { fabClasses } from "@mui/material";

function ManageMoments(props) {
    const [selectedBucketId, setSelectedBucketId] = useState(props.data ? props.data[0] ? props.data[0].momentBucketId : '' : '')
    const [momentData, setMomentData] = useState(props.momentDetails ? props.momentDetails : '')
    const [groupName, setGroupName] = useState('');
    const [loader, setLoader] = useState(false)
    const [addLoader, setAddLoader] = useState(false)
    const [formError, setFormErrors] = useState('');
    const userDetail = JSON.parse(localStorage.getItem("USER_DETAIL")).accountType
    const [inputs, setInputs] = useState({
        bucketName: '',
        bucketType: userDetail,
        momentGroups: []
    });
    const [onAddBucket, setOnAddBucket] = useState(false)
    const [onAddMomentGroup, setOnAddMomentGroup] = useState(false)
    const [mometnGroupError, setMometnGroupError] = useState(false)
    const [show, setShow] = useState(true);
    const [bucketData, setBucketData] = useState([])
    const accType = localStorage.getItem(USER_DETAIL);
    let accountType = JSON.parse(accType);
    //var mometnGroupError = ''

    const getMomentList = () => {
        if (loader) {
            momentService.getMomentData(selectedBucketId).then(res => {
                console.log('moment--', res);
                setLoader(false)
                setMomentData(res);
            })
        }
    }

    useEffect(() => {
        setSelectedBucketId(props.data[0].momentBucketId)
        getMomentList();
    }, [props.data])

    useEffect(() => {
        getMomentList();
    }, [selectedBucketId])

    useEffect(() => { setMomentData(props.momentDetails) }, [props.momentDetails]);

    const handleSelect = (e) => {
        setLoader(true);
        setSelectedBucketId(e.target.value);
    }

    const handleChange = (e) => {
        const alphanumericRegex = /^[a-zA-Z\s]+$/;
        if (e.target.name == 'bucketName') {
            if (alphanumericRegex.test(e.target.value) || e.target.value === '') {
                setInputs(inputs => ({ ...inputs, [e.target.name]: e.target.value }))
            }
        }
        else {
            if (alphanumericRegex.test(e.target.value) || e.target.value === '') {
                setGroupName(e.target.value)
            }
        }
    }

    const addMomentGroup = () => {
        const data = {
            momentBucketId: selectedBucketId,
            momentGroupName: groupName,
            moments: []
        }
        if (groupName) {
            setMometnGroupError('')
            momentService.createMomentGroup(data).then(res => {
                if (res) {
                    if (res.data.status === 0) {
                        setAddLoader(true);
                        momentService.getMomentData(selectedBucketId).then(res => {
                            console.log('moment--', res);
                            setOnAddMomentGroup(false)
                            setAddLoader(false)
                            setMomentData(res);
                            setGroupName('')
                        })
                    }
                    else {
                        setOnAddMomentGroup(false)
                        setAddLoader(false)
                    }
                }
            })

        } else {
            setMometnGroupError('Please fill the moment group name.')
        }
    }

    const handleModalKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
        }
    };

    useEffect(() => {
        // Add an event listener for 'keydown' on the document
        document.addEventListener('keydown', handleModalKeyDown);

        // Clean up the event listener when the component unmounts
        return () => {
            document.removeEventListener('keydown', handleModalKeyDown);
        };
    }, [onAddMomentGroup]);

    const addMomentBucket = () => {
        setOnAddBucket(onAddBucket ? false : true)
    }
    const addnewMomentGroup = () => {
        setOnAddMomentGroup(onAddMomentGroup ? false : true)
    }

    const createMomentBucket = () => {
        //console.log('create',inputs)
        if (inputs.bucketName) {
            setFormErrors('')
            momentService.createMomentBucket(inputs).then(() => {
                setOnAddBucket(false)
                momentService.getMomentBuckets().then((res => {
                    if (res) {
                        setBucketData(res)
                        setInputs({bucketName:""})
                    }
                }))
            })
        } else {
            setFormErrors('Please fill the moment group name.')
        }
        //alert('created')
    }

    console.log(props.data[0])

    return (
        <>
            {props.loading ? <div className="loader-container"><Loading variant="dark" /></div> :
                <div id="moment-profile" className="">
                    <div className="manage-moment-head mb-2">
                        <div className=" px-2">
                            <div className="row top-fixed-box">
                                <div className="col-md-12">
                                    <div className={`w-75 m-auto p-style `}>
                                        {/* <Select  name="bucket" disabled={accountType.accountType=="BOTH"?'':'disabled'}  aria-label="Default select example"  defaultValue={props.data[0].momentBucketId} className="w-50 mb-3 py-1" onChange={handleSelect} >
                                   */}
                                        <Select name="bucket" aria-label="Default select example" defaultValue={props.data[0].momentBucketId} className="w-50 mb-3 py-1" onChange={handleSelect} >

                                            {bucketData && bucketData.length > 0 ? bucketData.map(bucket => {
                                                return <MenuItem value={bucket.momentBucketId} key={bucket.momentBucketId}>{bucket.momentBucketName}</MenuItem>
                                            }) : props.data ? props.data.map(bucket => {
                                                return <MenuItem value={bucket.momentBucketId} key={bucket.momentBucketId}>{bucket.momentBucketName}</MenuItem>
                                            }) :
                                                <MenuItem>Select Bucket</MenuItem>}
                                        </Select>
                                        <button className="moment-bucket" onClick={addMomentBucket}>
                                            {addLoader ? <Loading variant="light" /> : <> Add Moment Bucket</>}
                                        </button>
                                        <button className="moment-bucket" onClick={addnewMomentGroup}>
                                            {addLoader ? <Loading variant="light" /> : <> Add Moment Group</>}
                                        </button>
                                        {/* <div className="addCoe-right-btn text-end">
                                    <button className="btn account-edit-btn edit-account" onClick={addMomentGroup}>
                                    {addLoader ? <Loading variant="light"/> : <> Add Moment Group</>} 
                                    </button>
                                </div> */}
                                        <Modal show={onAddBucket} onHide={() => setOnAddBucket(false)} onkeyDown={handleModalKeyDown}>
                                            <Modal.Header>
                                                <Modal.Title>Create New Bucket</Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>
                                                <form className="row g-3">
                                                    <div className="col-md-12">
                                                        <label htmlFor="editCOEName" className="form-label">Bucket Name* </label>
                                                        <div className="input-group"> <span className="input-group-text bg-transparent py-0"><i className="fadeIn animated bx bx-buildings" /></span>
                                                            <input type="text" name="bucketName" value={inputs.bucketName} className="form-control" placeholder="Moment Bucket Name" onChange={handleChange} />
                                                        </div>
                                                        <p class="my-1 mb-0 lh-1 font-12" style={{ color: "red", height: '18px' }}><span>{formError}</span></p>
                                                    </div>
                                                </form>
                                            </Modal.Body>
                                            <Modal.Footer>
                                                <Button variant="primary" onClick={createMomentBucket}>
                                                    Create Bucket
                                                </Button>
                                            </Modal.Footer>
                                        </Modal>
                                        <Modal show={onAddMomentGroup} onHide={() => setOnAddMomentGroup(false)} onkeyDown={handleModalKeyDown}>
                                            <Modal.Header>
                                                <Modal.Title>Create New Moment Group</Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>
                                                <form className=" g-3">
                                                    <div className="">
                                                        <label htmlFor="editCOEName" className="form-label">Moment Group Name* </label>
                                                        <div className="input-group w-100">
                                                            <input type="text" id="moment-group-name" value={groupName} className="input-field input-field-bt-border w-100 font-16" placeholder="Moment Group Name" onChange={handleChange} />
                                                        </div>
                                                        <p class="my-1 mb-0 lh-1 font-12" style={{ color: "red", height: '18px' }}><span>{mometnGroupError}</span></p>
                                                    </div>
                                                </form>
                                            </Modal.Body>
                                            <Modal.Footer>
                                                <Button variant="primary" onClick={addMomentGroup}>
                                                    Create Moment Group
                                                </Button>
                                            </Modal.Footer>
                                        </Modal>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                    {loader ? <div className="loader-container"><Loading variant="dark" /></div> :
                        <div className="manageMoment-container p-1">
                            {/* <div className="card mb-2">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-8">
                                    <div className=" mb-2 moment-group-input">
                                        <input className="input-field input-field-bt-border w-100 font-16" type="text" id="moment-group-name"   value={groupName} placeholder="Moment Group Name" onChange={handleChange}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="addCoe-right-btn text-end">
                                        <button className="btn account-edit-btn edit-account" onClick={addMomentGroup}>
                                        {addLoader ? <Loading variant="light"/> : <> Add Moment Group</>} 
                                        </button>
                                    </div>
                                </div>
                            </div> 
                        </div>
                    </div> */}
                            {momentData ?
                                momentData.map(group =>
                                    (<MomentGroup key={group.momentGroupId} momentData={group} bucketId={selectedBucketId} setMomentData={setMomentData} />)

                                ) : ''
                            }
                        </div>
                    }
                </div>
            }
        </>
    )
}
export default ManageMoments;
