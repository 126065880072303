import { apiCall } from "../../Components/Services/Interceptor";

const getCallListing = async(val)=>{
    const GET_CALL_LISTING = `/odio/api/call/get-call-listing`
    console.log("qwert",val);

    // let invalidUserIds = false;
    // if (val.userIds.includes(null)) {
    //     invalidUserIds = true;
    // }
    // val.userIds = val.userIds.filter(item => item != null);
    // if ( val.userIds.length == 0) {
    //     const response = {};
    //     response.data = {};
    //     response.data.response = [];
    //     return response;
    // }
    const response = await apiCall.post(GET_CALL_LISTING, val);
    let callData = new Map( 
        response.data.data.response.map(obj=>{
          return [obj.callId,{url:obj.audioRecordingUrl,detail:obj.details.length>0?obj.details.map(item=>{return {callId:item.callId, url:item.audioRecordingUrl}}):""}]
        })
    )
    let notifUrl = Object.fromEntries(callData.entries())
    localStorage.setItem("URLS",JSON.stringify(notifUrl))
    return response;
}

const getCallListingMoments = async(val,id)=>{
    const GET_MOMENTS = id != undefined ? `/odio/api/moment/moments-list?BucketId=${id}&type=${val}` : `/odio/api/moment/moments-list?&type=${val}`
    const response  = await apiCall.get(GET_MOMENTS)
    return response
}

const getCalls = async(val)=>{
    const response = await apiCall.post('/odio/api/call/get-call-listing/calls',val)
    return response
}

export default {
    getCallListing,
    getCallListingMoments,
    getCalls
}