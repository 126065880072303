import { useHistory, useLocation } from "react-router-dom";
import AddNewCoe from "../CompanySettings/ManageCoe/AddNewCoe";
import EditUser from "../CompanySettings/ManageUser/EditUser";
import DeleteData from "../CompanySettings/DeleteData";
import AddUser from '../CompanySettings/ManageCoe/AddUser'
import { SETTINGS_COMPONENT_TYPE, SETTINGS_COMPONENT_TYPE_DATA, CONVERSATIONS_SALE_DASHBOARD } from "../../Constants/Enum"
import * as React from 'react'
// import EditMomentBucket from "../CompanySettings/ManageMoments/EditMomentBucket";
import Recommendations from "../Conversations_Common/Recommendations";
import EditCompetitor from "../CompanySettings/Competitors/EditCompetitor";


const switchCase = (setIsOpen, componentType, eventName, selectedRowData, getCompetitorsList) => {
    switch (componentType) {
        case SETTINGS_COMPONENT_TYPE.MANAGE_COE:
            switch (eventName) {
                case 'EDIT':
                    console.log("You are a Edit Coe.")
                    return <AddNewCoe setIsOpen={setIsOpen} selectedRowData={selectedRowData} name={"Update COE"} />;
                case 'DELETE':
                    console.log("You are a Delete employee", componentType)
                    return <DeleteData componentName={SETTINGS_COMPONENT_TYPE.MANAGE_COE} modalHeading={SETTINGS_COMPONENT_TYPE_DATA.MANAGE_COE_DELETE_DATA_MODAL_HEADING} modalBodyData={SETTINGS_COMPONENT_TYPE_DATA.MANAGE_COE_DELETE_DATA} setIsOpen={setIsOpen} selectedRowData={selectedRowData} />;
                case 'ADD':
                    console.log("You are a Add user to COE")
                    return <AddUser modalHeading={SETTINGS_COMPONENT_TYPE_DATA.MANAGE_COE_ADD_USER} setIsOpen={setIsOpen} selectedRowData={selectedRowData} />;
                default:
                    return null;
            }
        case SETTINGS_COMPONENT_TYPE.MANAGE_USERS:
            switch (eventName) {
                case 'EDIT':
                    console.log("You are a Edit Employe")
                    return <EditUser setIsOpen={setIsOpen} selectedRowData={selectedRowData} />;
                case 'DELETE':
                    console.log("You are a delete employee")
                    return <DeleteData componentName={SETTINGS_COMPONENT_TYPE.MANAGE_USERS} modalHeading={SETTINGS_COMPONENT_TYPE_DATA.MANAGE_USER_DELETE_DATA_MODAL_HEADING} modalBodyData={SETTINGS_COMPONENT_TYPE_DATA.MANAGE_USER_DELETE_DATA} setIsOpen={setIsOpen} selectedRowData={selectedRowData} />;
                default:
                    return null;
            }
            
            case SETTINGS_COMPONENT_TYPE.MANAGE_COMPETITORS:
                switch (eventName) {
                    case 'DELETE':
                        return <DeleteData componentName={SETTINGS_COMPONENT_TYPE.MANAGE_COMPETITORS} modalHeading={SETTINGS_COMPONENT_TYPE_DATA.MANAGE_COMPETITOR_DELETE_DATA_MODAL_HEADING} modalBodyData={SETTINGS_COMPONENT_TYPE_DATA.MANAGE_COMPETITOR_DELETE_DATA} setIsOpen={setIsOpen} selectedRowData={selectedRowData} getCompetitorsList={getCompetitorsList}/>
                    default:
                        return null
                }    

            case SETTINGS_COMPONENT_TYPE.MANAGE_REPORTS:
            switch (eventName) {
                case 'EDIT':
                    console.log("You want to Edit Report")
                    return <EditUser setIsOpen={setIsOpen} selectedRowData={selectedRowData} />;
                case 'DELETE':
                    console.log("You are a delete report")
                    return <DeleteData componentName={SETTINGS_COMPONENT_TYPE.MANAGE_REPORTS} modalHeading={SETTINGS_COMPONENT_TYPE_DATA.MANAGE_REPORT_DELETE_DATA_MODAL_HEADING} modalBodyData={SETTINGS_COMPONENT_TYPE_DATA.MANAGE_REPORT_DELETE_DATA} setIsOpen={setIsOpen} selectedRowData={selectedRowData} />;
                default:
                    return null;
            }

         case SETTINGS_COMPONENT_TYPE.MANAGE_COE_USERS:
            switch (eventName) {
                case 'DELETE':
                    console.log("You are a delete coe user")
                    return <DeleteData  componentName={SETTINGS_COMPONENT_TYPE.MANAGE_COE_USERS} modalHeading={SETTINGS_COMPONENT_TYPE_DATA.MANAGE_COE_USER_DELETE_DATA_MODAL_HEADING} modalBodyData={SETTINGS_COMPONENT_TYPE_DATA.MANAGE_COE_USER_DELETE_DATA} setIsOpen={setIsOpen} selectedRowData={selectedRowData} />;
                default:
                    return null;
            } 

            case CONVERSATIONS_SALE_DASHBOARD.SALES_DASHBOARD:
                switch (eventName) {
                    case 'RECO':
                        console.log("Add Recomendation")
                        return <Recommendations modalHeading={CONVERSATIONS_SALE_DASHBOARD.SALES_DASHBOARD} setIsOpen={setIsOpen} selectedRowData={selectedRowData} />;
                  
                    default:
                        return null;
                }
                case CONVERSATIONS_SALE_DASHBOARD.SUPPORT_CALL:
                switch (eventName) {
                    case 'RECO':
                        console.log("Add Recomendation")
                        return <Recommendations modalHeading={CONVERSATIONS_SALE_DASHBOARD.SUPPORT_CALL} setIsOpen={setIsOpen} selectedRowData={selectedRowData} />;
                  
                    default:
                        return null;
                }
    }
}

export default {
    switchCase

}