import React, { useEffect, useState } from "react";
import './/question.css';
import MicNoneIcon from '@mui/icons-material/MicNone';
import GraphicEqIcon from '@mui/icons-material/GraphicEq';
import CheckIcon from '@mui/icons-material/Check';
import Form from 'react-bootstrap/Form';
import { apiCall } from "../Services/Interceptor";
import RecordRTC from "recordrtc";

function Question() {
    const [topics, setTopics] = useState([]);
    const [questions, setQuestions] = useState([]);
    const [selectedTopic, setSelectedTopic] = useState('');
    const [questionRecordingStates, setQuestionRecordingStates] = useState({});
    const [recordedAnswers, setRecordedAnswers] = useState({});
    const [audioRecorder, setAudioRecorder] = useState(null);

    useEffect(() => {
        apiCall.get('/odio/api/getQuestionsCategoryList').then((response) => setTopics(response.data.data))
    }, []);

    useEffect(() => {
        apiCall.get(`/odio/api/getQuestions?categoryType=${selectedTopic}`).then((response) => setQuestions(response.data.data))
    }, [selectedTopic]);

    const handleTopicChange = (event) => {
        setSelectedTopic(event.target.value);

        setQuestionRecordingStates({});
    };

    const sendAudioToServer = (audioURL, id) => {
        fetch(audioURL)
            .then((response) => response.blob())
            .then((audioBlob) => {
                
                console.log(audioBlob,"audioblob")
                const formData = new FormData();
                console.log(formData,"formDat")
                formData.append("file", audioBlob,"clip-SBC00626.wav");
                formData.append("questionId", id); 
                // for (const [key, value] of formData.entries()) {
                //     console.log(key, value , "keyvalue");
                // }

                const headers = new Headers();
                headers.append("Accept", "*/*");

                fetch(`/odio/api/uploadQuestionFile?questionId=${id}`, {
                    method: "POST",
                    body: formData,
                    headers: headers,
                })
                    .then((response) => {
                        if (response.ok) {
                            console.log(response,"Audio sent to the server successfully.");
                        } else {
                            console.error("Error sending audio to the server.");
                        }
                    })
                    .catch((error) => {
                        console.error("Network error:", error);
                    });
            })
            .catch((error) => {
                console.error("Error converting audio URL to Blob:", error);
            });
    };

    const handleRecordStart = (question) => {
        setQuestionRecordingStates({
            ...questionRecordingStates,
            [question]: true,
        });

        if (!audioRecorder) {
            navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
                const recorder = new RecordRTC(stream, { type: "audio", recorderType: RecordRTC.StereoAudioRecorder, numberOfAudioChannels: 2, mimeType: "audio/wav", });
                recorder.startRecording();
                setAudioRecorder(recorder);
            });
        }
    };

    const handleSaveRecording = (question,id) => {
        if (questionRecordingStates[question] && audioRecorder) {
            audioRecorder.stopRecording(() => {
                const audioBlob = audioRecorder.getBlob();
                const audioURL = window.URL.createObjectURL(audioBlob);
            
                sendAudioToServer(audioURL, id);

                setRecordedAnswers({
                    ...recordedAnswers,
                    [question]: audioURL,
                });
                setQuestionRecordingStates({
                    ...questionRecordingStates,
                    [question]: false,
                });
                setAudioRecorder(null);
                console.log(audioURL, "audioURL");
            });
        }
    };

    return (
        <div>
            <Form.Select className="topic-select" value={selectedTopic} onChange={handleTopicChange}>
                <option className="topic-values" value="">Select a Topic</option>
                {topics.map((topic) => (
                    <option className="topic-values" key={topic.categoryId} value={topic.category}>
                        {topic.category}
                    </option>
                ))}
            </Form.Select>
            <div className="topic-questions">
                {selectedTopic && (
                    <div className="questions">
                        {questions?.map((question) => (
                            <div className="question" key={question.questionId}>
                                <p>{question.questions}</p>

                                <div className="status">
                                {/* To Check if recorder is working correctly */}
                                {recordedAnswers[question.questions] ? (<p>Recording Saved successfully</p>) : (<p>Click to start Record</p>)}

                                <div className="question-buttons">
                                    {questionRecordingStates[question.questions] ? (
                                        <GraphicEqIcon className="question-button" />
                                    ) : (
                                        <MicNoneIcon
                                            className="question-button"
                                            onClick={() => handleRecordStart(question.questions)}
                                        />
                                    )}
                                    <CheckIcon
                                        onClick={() => handleSaveRecording(question.questions,question.questionId)}
                                        disabled={
                                            !questionRecordingStates[question.questions] ||
                                            recordedAnswers[question.questions]
                                        }
                                    />
                                </div>
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
}

export default Question;
