import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { useDataState } from "../../Dashboard/DashboardSales/SalesDashboard/WordCloudApiState";
import toaster from "../../Toast/toaster";

export default function PaginationAlter(props) {
  const [items, setItems] = useState([])
  const [selectedPage, setSelectedPage] = useState(1);
  const [selectedWordPage, setSelectedWordPage] = useState(1);
  const [selectedChatPage, setSelectedChatPage] = useState(1);
  const [selectedLiveAssistPage, setSelectedLiveAssistPage] = useState(1);
  const [searchPage, setSearchPage] = useState('');
  const [notifPage, setNotifPage] = useState(1)
  const history = useHistory()
  const location = useLocation()
  useEffect(() => { props?.forPageWhenFilter && setSelectedPage(1) }, [props?.forPageWhenFilter])
  let storedData = JSON.parse(localStorage.getItem("AGENT"))
  let wordData = useDataState()

  const handlePageChange = (e) => {
    console.log(e.selected + 1)
    const selectedPageNumber = e.selected + 1;
    props.callBack(e.selected + 1)
    wordData.groupIds.length > 0 ? setSelectedWordPage(selectedPageNumber) : setSelectedPage(selectedPageNumber);

    wordData.groupIds.length > 0 ? localStorage.setItem('selectedWordPage', e.selected + 1) : localStorage.setItem('selectedPage', e.selected + 1);
  }

  const handleChatPageChange = (e) => {
    const selectedPageNumber = e.selected + 1;
    props.callBack(e.selected + 1)
    setSelectedChatPage(selectedPageNumber);
    localStorage.setItem('selectedChatPage', e.selected + 1)
  }

  const handleNotifPageChange = (e) => {
    const selectedPageNumber = e.selected + 1;
    props.callBack(e.selected + 1)
    setNotifPage(selectedPageNumber)
    localStorage.setItem('notifSelectedPage', e.selected + 1)
  }

  const handleLiveAssistPageChange = (e) => {
    const selectedPageNumber =  e.selected + 1;
    props.callBack(e.selected +1)
    setSelectedLiveAssistPage(selectedPageNumber)
    localStorage.setItem("selectedLiveAssistPage", e.selected + 1)
  }

  useEffect(() => {
    if ((JSON.parse(localStorage.getItem("USER_DETAIL"))?.clientConfig?.data?.conversationCallDetail?.keywords === 'true' ? true : localStorage.getItem('selectedPage')) && (location.pathname == '/sales_conversations' || location.pathname == '/voice')) {
      const selectedPageNumber = wordData.groupIds.length > 0 ? parseInt(localStorage.getItem('selectedWordPage')) : parseInt(localStorage.getItem('selectedPage'));
      let pageNo = props.customPageNumber || (selectedPageNumber <= props.availablePages ? selectedPageNumber : 1);
      setSelectedPage(pageNo);
      props.callBack(pageNo);
    }
    else if (localStorage.getItem('selectedChatPage') && location.pathname == '/chat') {
      const selectedPageNumber = parseInt(localStorage.getItem('selectedChatPage'));
      setSelectedChatPage(selectedPageNumber);
      props.callBack(selectedPageNumber);
    }
    else if (localStorage.getItem('notifSelectedPage') && location.pathname == '/notifications') {
      const selectedPageNumber = parseInt(localStorage.getItem('notifSelectedPage'));
      setNotifPage(selectedPageNumber);
      props.callBack(selectedPageNumber);
    }
    else if( localStorage.getItem("selectedLiveAssistPage") && location.pathname == '/live_assist'){
      const selectedPageNumber = parseInt(localStorage.getItem('selectedLiveAssistPage'));
      setSelectedLiveAssistPage(selectedPageNumber);
      props.callBack(selectedPageNumber);
    }
  }, []);

  const handlePageSearch = (e) => {
    e.preventDefault();
    const pageNumber = parseInt(searchPage);
    if (!isNaN(pageNumber) && pageNumber > 0 && pageNumber <= props.availablePages) {
      const selectedPageNumber = pageNumber - 1;
      props.callBack(pageNumber);
      setSelectedPage(pageNumber);
      setSearchPage('');
      // Update the appropriate state based on the current path
      if (location.pathname === '/notifications') {
        setNotifPage(pageNumber);
        localStorage.setItem('notifSelectedPage', pageNumber);
      } else if (location.pathname === '/chat') {
        setSelectedChatPage(pageNumber);
        localStorage.setItem('selectedChatPage', pageNumber);
      } else if (location.pathname === '/live_assist') {
        setSelectedLiveAssistPage(pageNumber);
        localStorage.setItem('selectedLiveAssistPage', pageNumber);
      } else {
        if (wordData.groupIds.length > 0) {
          setSelectedWordPage(pageNumber);
          localStorage.setItem('selectedWordPage', pageNumber);
        } else {
          setSelectedPage(pageNumber);
          localStorage.setItem('selectedPage', pageNumber);
        }
      }
    } 
    else {
      toaster.error('Invalid page number');
    }
    setSearchPage('')
  };

  return (
    <div className="d-flex align-items-baseline justify-content-between">
    <ReactPaginate
      previousLabel={"Prev"}
      nextLabel={"Next"}
      breakLabel={"..."}
      pageCount={props.availablePages}
      // selected={storedData?.pageSelected}
      forcePage={location.pathname == '/notifications' ? (notifPage - 1) : location.pathname == '/chat' ? (selectedChatPage - 1) : location.pathname == '/live_assist' ? (selectedLiveAssistPage - 1) : (selectedPage - 1)}
      marginPagesDisplayed={1}
      pageRangeDisplayed={3} //on clicking dotspagepagepage
      onPageChange={location.pathname == '/notifications' ? handleNotifPageChange : location.pathname == '/chat' ? handleChatPageChange : location.pathname == '/live_assist' ? handleLiveAssistPageChange : handlePageChange}
      containerClassName={"pagination"}
      pageClassName={"page-item"}
      pageLinkClassName={"page-link"}
      previousClassName={'page-item'}
      previousLinkClassName={"page-link"}
      nextClassName={"page-item"}
      nextLinkClassName={"page-link"}
      breakLinkClassName={"page-link"}
      activeClassName={"active"}
    />
    <form onSubmit={handlePageSearch} className="d-flex align-items-center" style={{position:'absolute',right:'5rem'}}>
        <input
          type="number"
          min="1"
          max={props.availablePages}
          value={searchPage}
          onChange={(e) => setSearchPage(e.target.value)}
          placeholder="Go to page"
          className="paginationAlter-input"
        />
        <button type="submit" className="groupCall">Go</button>
      </form>
    </div>
  )
}