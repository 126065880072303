import React from "react";
import { useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useHistory } from "react-router-dom";

function BarGraph(props) {
    console.log(props?.widgetData)
    const history = useHistory()
    let rdata = JSON.parse(localStorage.getItem("ROUTING_PERMISSIONS"))
    const values = props?.widgetData?.map(item=>{
      return item.result
    })
    const dates = props?.widgetData?.map(item=>{
      return item.dateString
    })

    const value = props.comparedData!=''?props?.comparedData?.map(item=>{
      return item.result
    }):""

    const comparedDates = props?.comparedData !=''?props?.comparedData?.map(item => {
      return item.dateString;
    }):''

    const type = props?.widgetData?props?.widgetData[0]?.type?props?.widgetData[0]?.type:"":""

    const longerArray = props?.isCompare==true?[...dates,...comparedDates]:dates

    
    const [chartCategories, setChartCategories] = useState([...dates, '', ...comparedDates]);

    const handleLegendClick = (event, chartContext, { seriesIndex }) => {
      if(seriesIndex==0){
        setChartCategories([dates]);
      } 
      else if(seriesIndex==1) {
        const combinedCategories = [];
        for (let i = 0; i < Math.max(dates.length, comparedDates.length); i++) {
          if (dates[i]) combinedCategories.push(dates[i]);
          if (comparedDates[i]) combinedCategories.push(comparedDates[i]);
        }
        setChartCategories(combinedCategories);
      }
    }

    const sendTocalls = (data, index) => {
      console.log(data, index, "skbbscldnc");
      // let storedData = JSON.parse(localStorage.getItem("AGENT"))
      //let graphdata = categoriesVal?categoriesVal[value] :''
      let key = props.widgetName
      // localStorage.setItem("AGENT", JSON.stringify(
      //   {
      //     "pageSelected": 1,
      //     "fromD": props.widgetData?.from == undefined ? props.defaultFilteredData?.from : props.widgetData?.from,
      //     "toD": props.widgetData?.to == undefined ? props.defaultFilteredData?.to : props.widgetData?.to,
      //     "selectedDate": props.widgetData?.selectedDate == undefined ? props.defaultFilteredData?.selectedDate : props.widgetData?.selectedDate,
      //     // "selectedRange": storedData?.selectedDate === "This Year" ? "7" : '5',
      //     "selectedRange": storedData?.selectedRange,
      //     "selectedCoeRange": storedData?.selectedCoeRange,
      //     "selectedCOE":storedData?.selectedCOE,
      //     "coe":storedData?.coe
      //   }))
      history.push(rdata && rdata.Conversations && Array.isArray(rdata.Conversations) && rdata.Conversations.length > 1 ? `/voice?${key}&fromDate=${data[index]}&toDate=${data[index]}&redirected=${true}` : `/sales_conversations?${key}&fromDate=${data[index]}&toDate=${data[index]}&redirected=${true}`)
    }

    console.log(longerArray)

    const series =  [{
      name: props.widgetName,
      data: values,
      colors: '#0077B6'
    }]
    const seriesVal = [
    {
      name: props.widgetName,
      data: values,
    },
    {
      name:"Compared",
      data:value,
      //colors: '#77B6EA'
    }]
    const options = {
      chart: {
        type: 'bar',
        toolbar: {
            show: false
          },
          events: {
            dataPointSelection: (event, chartContext, config) => {
              console.log(chartContext, config);
              console.log(config.w.config.series[config.seriesIndex])
              console.log(config.w.config.series[config.seriesIndex].name)
              console.log(config.w.config.series[config.seriesIndex].data[config.dataPointIndex])
              console.log(config.dataPointIndex);
              sendTocalls(config.w.config.xaxis.categories, config.dataPointIndex)
            }
          }
        // events: {
        //   legendClick: handleLegendClick
        // }
      },
      stroke: {
        show: true,
        width: 5,
        colors: ['transparent']
      },
      plotOptions: {
        bar: {
          columnWidth: '40%',
          // distributed: true,
          horizontal: false,
          endingShape: 'rounded'
        },
      },
      dataLabels: {
        enabled: false
      },
      legend: {
        show: true
      },
      xaxis: {
        title: {
          // text: "Dates",
          style:{
            color:"#666666",
            fontSize:"11px",
            textAlign:"right",
          }
        },
        type: 'category', // Change this from 'day' to 'category'
        categories: dates,
        labels: {
          rotate:-30,
          rotateAlways:true,
          formatter: (value, index) => {
            return value;
          },
        },
        group: {
          style: {
            fontSize: '11px',
            fontWeight: 800,
            colors: ['#333']
          },
          groups: [
            {
              title: 'Dates',
              cols: dates ? dates.length : 0,
            },
          ],
        },
      },
      yaxis:{
        title: {
          text: type!=''?`${props.widgetName}(${type})`:props.widgetName,
          style:{
            color:"#666666",
            fontSize:"11px",
            textAlign:"right"
          }
        }
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        shared: false,
        intersect: true,
        x: {
          formatter: (val, { series, seriesIndex, dataPointIndex, w }) => {
            return w.globals.seriesNames[seriesIndex] == props.widgetName ? dates[dataPointIndex] : comparedDates[dataPointIndex]
          },
        },
      },
      grid: {
        padding: {
            bottom: 20 // Increase this value if labels are still cut off
        }
    }
        //colors: [{'#0077B6','#77B6EA'}]
    }

    return (
      <ReactApexChart id="chart mt-3" options={options} series={props.isCompare==true&&value!=''?seriesVal:props.isCompare == false || value ==''?series:series} type="bar" height="350"  />
        
    );
}
export default BarGraph;


