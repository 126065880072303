//import ReactLoading from "react-loading";
import * as React from 'react'
import { Spinner } from "react-bootstrap";
import "../../App.css"
export default function Loading() {
    return (
        <>
            {/* <ReactLoading className="LoadingComponent loading" type="spokes" color="#0000FF"
            /> */}
            <Spinner animation="border" />
        </>
    )
}