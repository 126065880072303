import { List, ListItem, ListItemText, Typography } from '@mui/material';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import Typewriter from '../KnowledgeAI/TypewriterEffect';

function LiveCallTranscript({ isPopupOpen, closePopup, messages }) {
    const textAreaRef = useRef(null)

    useEffect(() => {
        if (isPopupOpen) {
          textAreaRef.current.scrollTo({
            top: textAreaRef.current.scrollHeight,
            behavior: 'smooth',
          });
        }
      }, [messages]);

  return (
    <div>
      {isPopupOpen && (
        <div className="popup">
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <h5 className="mb-0 text-uppercase font-14" style={{alignContent:'center'}}>
              Live Call Details
            </h5>
            <button className='live-cut-button' onClick={closePopup}>
              <i className='bx bx-x bx-sm'></i>
            </button>
          </div>
          <hr className="my-2" />
          <div id="textarea" className="filter_scroll px-1" ref={textAreaRef}>
            <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
              {messages.map((msg, index) => (
                <>
                <ListItem key={index} alignItems="flex-start" style={{ color: msg.intent === 0 ? 'red' : 'green',backgroundColor: '#ecf0f3', padding:'0' }}>
                  <ListItemText>
                    <Typography variant="body1" style={{ fontSize: '14px', color: msg.intent === 0 ? 'red' : 'green' }}>
                        {/* {msg.text} */}
                        <Typewriter text={msg.text} speed={2} check={false}/>
                    </Typography>
                  </ListItemText>
                </ListItem>
                <hr style={{marginTop:'-5px',marginBottom:'-5px'}} />
                </>
              ))}
            </List>
          </div>
        </div>
      )}
    </div>
  );
}

export default LiveCallTranscript;
