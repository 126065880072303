import React from "react";
class  Footer extends React.Component
{render(){
     return(
    
    <>
        <footer className="page-footer">
			<p className="mb-0">Copyright © 2024 - VoiceIO Artificial Intelligence Pvt Ltd</p>
		</footer> 
    </>
    )
}}
export default Footer;
