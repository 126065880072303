import React from "react";
import { Row, Col } from "reactstrap";
import { Card } from "reactstrap"
import { CardContent } from "@mui/material"
import SimpleTable from "../Commons/Table/SimpleTable";
import Filter from "../Filter/Filter";
class SalesInteractionCalls extends React.Component {
    componentDidMount(){
        document.title = "Interaction Calls - Odio"
    }
    render() {
        return (
            <div class="page-wrapper">
                <div class="page-content">
                <Filter />
                    <div class="row row-cols-1 row-cols-md-2 row-cols-xl-4">
                        <div class="col-12 mb-4">
                            <Row>
                                <Col class="col-md-12"> <h2 class="dashboard-headings">Calls11 </h2></Col>
                            </Row>

                        </div>
                        <div class="col-12 mb-4">
                            <Card variant="outlined">
                                <CardContent>

                                    <SimpleTable />

                                </CardContent>
                            </Card>
                        </div>
                    </div>
                    <div class="search-overlay"></div>
                    <div class="overlay toggle-icon"></div>

                </div>
            </div >
        )
    }
}
export default SalesInteractionCalls;
