import React, { useEffect, useState } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import notificationsService from '../Services/notifications.service'
import callsService from '../Services/calls.service'
import conversationService from '../Services/conversation.service'
import Loading from './Loading'
import { format, addDays, differenceInDays } from 'date-fns';

export default function NotificationList(props){ 

    const [data,setData] = useState()
    const [unreadIds, setUnreadIds] = useState([])
    const [loader, setLoader] = useState(false)
    const [dropdownWidth, setDropdownWidth] = useState(400);
    const history = useHistory()
    let storedData = JSON.parse(localStorage.getItem("AGENT"))
    const rdata = JSON.parse(localStorage.getItem("ROUTING_PERMISSIONS"))


    const getNotification = ()=>{
        setLoader(true)
        notificationsService.getAllNotifications(1).then(response=>{  
        if(response){
            setLoader(false)
            console.log(response)
            setData(response?.data?.data?.notificationResponseList)
		    setDropdownWidth("")
            let notificationIds = []
            response?.data?.data?.notificationResponseList?.map(item=>{
                if(item.notificationReadStatus.readStatus==false){
                    notificationIds.push(item.notificationId)
                }
            })
            setUnreadIds(notificationIds)
            // setUnread(response?.data?.data?.unReadCount)
        }})
    }
    
    console.log(unreadIds)


    useEffect(()=>{
        getNotification()
    },[])

    
    const clickHandler = ()=>{
       history.push("/notifications")
       localStorage.setItem("AGENT",JSON.stringify({
        "pageSelected":1,
        "analysis":storedData?.analysis,
        "productivity":storedData?.productivity,
        "fromD":storedData?.fromD,
        "toD":storedData.toD,
        "agentDetailCOE":storedData?.agentDetailCOE,
        "userIds":storedData?.userIds,
        "userNames":storedData?.userNames,
        "empId":storedData?.employeeId,
        "empName":storedData?.user,
        "role":storedData?.role,
        "designation":storedData?.designation,
        "type":storedData?.type,
        "teamCOE":storedData?.teamCOE,
        "callsFrom":storedData?.callsFrom,
        "callsTo":storedData?.callsTo,
        "aht":storedData?.aht,
        "callScore":storedData?.callScore,
        "pitchScore":storedData?.pitchScore,
        "customerName":storedData?.customerName,
        "customerMobile":storedData?.customerMobile,
        "fileName":storedData?.fileName,
        "sellerROS":storedData?.sellerROS,
        "sellerName":storedData?.sellerName,
        "selectedSeller":storedData?.selectedSeller,
        "cScore":storedData?.cScore,
        "callHealth":storedData?.callHealth,
        "callDuration":storedData?.callDuration,
        "words":storedData?.words,
        "moment":storedData?.moment,
        "momentId":storedData?.momentId,
        // "coeName":this.state.coe,
        "pitch":storedData?.pitch,
        "prob":storedData?.prob,
        "opportunity":storedData?.opp,
        "urgency":storedData?.urgency,
        "coe":storedData?.coe,
        "selectedCOE":storedData?.selectedCOE,
        "selectedCoeRange":storedData?.selectedCoeRange,
        "selectedDate":storedData?.selectedDate,
        "selectedRange":storedData?.selectedRange,
        "momentBucket":storedData?.momentBucket,
        "momentBucketId":storedData?.momentBucketId,
        "frequency":storedData?.frequency,
        "reportType":storedData?.reportType}))
       props.closeNotifyShow()
    }

    // let dependency = JSON.parse(localStorage.getItem("URLS"))
    // useEffect(()=>{
    //     const val ={
    //         "page":JSON.parse(localStorage.getItem("AGENT"))?.pageSelected,
    //         "pageSize":5,  
    //         "callType":"SALES",
    //         "fromD":JSON.parse(localStorage.getItem("AGENT"))&&JSON.parse(localStorage.getItem("AGENT"))?.fromD,
    //         "toD":JSON.parse(localStorage.getItem("AGENT"))&&JSON.parse(localStorage.getItem("AGENT")).toD,
    //     }
    //     conversationService.getCallListing(val).then(res=>{
    //         if(res){
    //             console.log(res)
    //         }
    //     })
    // },[dependency=='{}'])

    const notificationHandler = async (id,notificationId,tabName,tabValue,item,userId)=>{
        props.closeNotifyShow()
        console.log(item)
        console.log(item)
        let val ={  
            "pageSize":5,  
            "callType":JSON.parse(localStorage.getItem("USER_DETAIL"))?.accountType,
            "clientExternalId":JSON.parse(localStorage.getItem("USER_DETAIL"))?.externalId,
            "fromD":storedData&&storedData.fromD, 
            "toD":storedData&&storedData.toD,
        }
        // if(!localStorage.getItem('URLS')){ 
        //     await conversationService.getCallListing(val)
        // }
        notificationsService.readNotifications([notificationId]).then(res=>{
            if(res){
                // let redirectedUrl = ''
                // let urls = Object.entries(JSON.parse(localStorage.getItem("URLS")))
                // let filteredUrl =  urls.filter(item=>{
                //     if(item[0]!=id&& item[1].detail.length>0){
                //         item[1].detail.forEach(element => {
                //             if(element.callId === id){
                //                 console.log(element)
                //                 redirectedUrl = element.url
                //                 return element;
                //             }
                //         });  
                //     }
                //     else if(item[0]==id&&item[1].detail.length>0){
                //         redirectedUrl = item[1].url
                //         return item
                //     }
                //     else if(item[0]==id&&item[1].detail==''){
                //         console.log(item)
                //         redirectedUrl = item[1].url
                //         return item
                //     }
                // })

                let requestBody = {
                    "callId": item?.notification?.callId,
                    "groupId": item?.notification?.groupId,
                    "clientExternalId":JSON.parse(localStorage.getItem("USER_DETAIL"))?.externalId,
                    "fromD":storedData&&storedData.fromD, 
                    "toD":storedData&&storedData.toD,
                }

                id?notificationsService.getRedirectedData(requestBody).then(val=>{
                    history.push(rdata && rdata.Conversations && Array.isArray(rdata.Conversations) && rdata.Conversations.length > 1 ? `voice_detail?callId=${id}&tabSelected=${tabName}&postId=${item?.notification?.notificationEvent?.postId}&eventType=${item?.notification?.notificationEvent?.eventType}&commentShow=${item?.notification?.notificationEvent?.eventType=='COMMENT'?true:false}&name=${item?.notification?.callId !== null ? 'SINGLE_CALL' : 'GROUP'}` : `sales_call_detail?callId=${id}&tabSelected=${tabName}&postId=${item?.notification?.notificationEvent?.postId}&eventType=${item?.notification?.notificationEvent?.eventType}&commentShow=${item?.notification?.notificationEvent?.eventType=='COMMENT'?true:false}&name=${item?.notification?.callId !== null ? 'SINGLE_CALL' : 'GROUP'}`,{
                        agentExternalId:item?.notification?.userExternalId,
                        tabSelected:tabName,
                        tabValue:tabValue,
                        externalId:item?.notification?.userExternalId,
                        phone:item?.notification?.mobile,
                        postId:item?.notification?.notificationEvent?.postId,
                        eventType:item?.notification?.notificationEvent?.eventType,
                        url:val?.data?.data?.encoderRecordingUrl,
                        commentShow:item?.notification?.notificationEvent?.eventType=='COMMENT'?true:false,
                        callId:item?.notification?.callId,
                        id:val?.data?.data?.agentId,
                        coe:val?.data?.data?.coeName,
                        customer:val?.data?.data?.customerName,
                        seller:val?.data?.data?.agentName,
                        duration:val?.data?.data?.totalDuration,
                        date:`${val?.data?.data?.date} ${val?.data?.data?.time}`
                    })})
                :
                    history.push(
                    `team_member_detail?id=${userId}&tabSelected=${tabName}&postId=${item?.notification?.notificationEvent?.postId}&eventType=${item?.notification?.notificationEvent?.eventType}&commentShow=${item?.notification?.notificationEvent?.eventType=='COMMENT'?true:false}&agentExternalId=${item?.notification?.userExternalId}`,
                    {   
                        tabSelected:tabName,
                        tabValue:tabValue,
                        externalId:item?.notification?.userExternalId,
                        phone:item?.notification?.mobile,
                        postId:item?.notification?.notificationEvent?.postId,
                        eventType:item?.notification?.notificationEvent?.eventType,
                        // url:redirectedUrl,
                        commentShow:item?.notification?.notificationEvent?.eventType=='COMMENT'?true:false
                    })
            }
        })
    }

    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();

    today = dd + '/' + mm + '/' + yyyy;

    const todayDate  = today.split("/")
    const d =todayDate[0] 
    console.log(d)   

    const dateFunc = (d1)=>{
        // let newDate = d1.split("T")
        // console.log(newDate)
        // let datee = `${newDate[0].split("-")[1]}-${newDate[0].split("-")[2]}-${newDate[0].split("-")[0]}`
        // let convDate = new Date(datee)
        // console.log(convDate)
        // let notifDate = convDate.getDate()
        // console.log(notifDate)
        // const monthNames = ["January", "February", "March", "April", "May", "June",
        // "July", "August", "September", "October", "November", "December"
        // ];
        // let date =""
        // if((notifDate)-1==d){
        //     date = "Tomorrow"   
        //  }
        // else if((notifDate)+1==d){
        //     date = "Yesterday"
        // }
        // else if(notifDate==d){
        //     date="Today"   
        // }
        // else if((notifDate)+2==d){
        //     date="2 Days ago"
        // }
        // else if((notifDate)+3==d){
        //     date="3 Days ago"
        // }
        // else if((notifDate)+4==d){
        //     date="4 Days ago" 
        // }
        // else{
        //     date  = `${monthNames[convDate.getMonth()]} ${notifDate}, ${convDate.getFullYear()}`
        // }
        // return date
        const convDate = new Date(d1);
        const notifDate = convDate.getDate();
        const currentDate = new Date();
        const currentDay = currentDate.getDate();

        if (addDays(convDate, 1).getDate() === currentDay) {
          return "Tomorrow";
        } else if (addDays(convDate, -1).getDate() === currentDay) {
          return "Yesterday";
        } else if (notifDate === currentDay) {
          return "Today";
        } else {
          const daysAgo = differenceInDays(currentDate, convDate);
          if (daysAgo === 2) {
            return "2 Days ago";
          } else if (daysAgo === 3) {
            return "3 Days ago";
          } else if (daysAgo === 4) {
            return "4 Days ago";
          } else {
            return format(convDate, 'MMMM dd, yyyy');
          }
        }
    }

    const readAllNotification = ()=>{
        if(unreadIds.length>0){
            notificationsService.readNotifications(unreadIds).then(res=>{
                if(res){
                    notificationsService.getAllNotifications(1).then(response=>{
                        if(response){
                            setData(response?.data?.data?.notificationResponseList)
                        }
                    })
                }
            })
        }
    }

    return(
        <div id='notification-List' className={`dropdown-menu dropdown-menu-end ${props.notifyShow?'show':''}`} data-bs-popper="none" style={{width:dropdownWidth}} >
            <a href="javascript:;">
                <div className="msg-header">
                    <p className="msg-header-title">Notifications</p>
                    <p className="msg-header-clear ms-auto" onClick={readAllNotification}>Mark all as read</p>
                </div>
            </a>
            <div className="header-notifications-list">
                <>
                {loader?
                <div className='loader-container'>
                    <Loading variant="dark"/>
                </div>
                :
                data?.map((item)=>{
                return(
                item.notificationReadStatus.readStatus==false?   
                <a className="dropdown-item" style={{cursor:"pointer"}} onClick={()=>notificationHandler(item.notification.callId !== null? item.notification.callId : item.notification.groupId,item?.notificationId,item?.notification?.notificationEvent?.tabName,item?.notification?.notificationEvent?.tabValue,item,item?.notification?.userId)}>
                    <div className="d-flex align-items-center" style={{whiteSpace:"pre-wrap"}}>
                        <div className="notify bg-light-primary text-primary"><i className={item?.notification?.notificationEvent?.value?.includes("fatal Call")?`bx bx-x-circle`:`bx bx-group`} /></div>
                        <div className="flex-grow-1">
                            <h6 className="msg-name">{item?.notification.callId !== null? item?.notification.callId : item?.notification.groupId}<span className="msg-time float-end">{dateFunc(item?.notification?.notificationEvent?.createdAt)}</span></h6>
                            <p className="msg-info">{item.notification?.notificationMessage}</p>
                        </div>
                    </div>
                </a>:"")
                 } 
                )
                 } 
                </>
            </div>
            <a style={{cursor:"pointer"}} onClick={clickHandler}>
                <div className="text-center msg-footer">View All Notifications</div>
            </a>
        </div>
    )
}