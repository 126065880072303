import { Card } from "react-bootstrap";
import React,{ useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import Stats from "../Sales/Stats/Stats";
import Moments from "../Sales/Moments";
import Signals from "../../Conversations_Dashboard/Sales/Signals/Signals"
import Questions from "../Questions/Questions";
import Actions from "../Actions/Actions";
import Transcript from "../Transcript/Transcript";
import Feedbacks from "../../Conversations_Common/Feedbacks/Feedbacks";
import { Button } from "react-bootstrap";
import MoreInfo from "../Sales/Modals/MoreInfo";
import FoulLanguage from "../FoulLanguage/FoulLanguage";
import "../Sales/SalesCallDetail.css"
import callsService from "../../Services/calls.service";
import { apiCall } from "../../Services/Interceptor";
import Loading from "../../Commons/Loading";
import AudioPlayer from "../AudioPlayer";
import { useHistory } from "react-router-dom";
import NodataFound from "../../Commons/NoDataFound";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import { groupMoment } from "./mock/email_group_moments";
import { singleMoment } from "./mock/email_single_moment";
import { transcript } from "./mock/email_transcript";
import { callListingGroup } from "./mock/email_calllisting_group";

const EmailDetail = ()=>{
    const [key, setKey] = useState("stats");
    const [show, setShow]= useState(false)
    const [loader, setLoader] = useState(false)
    const [callStats, setCallStats] = useState([])
    const [callQuestions, setCallQuestions] = useState([])
    const [startTime, setStartTime] = useState()
    const [time, setTime] = useState("")
    const [callTime, setCallTime] = useState([])
    const [foulLanguage, setFoulLanguage] = useState([])
    const [callMoments, setCallMoments] = useState([])
    const [callTranscript, setCallTranscript]= useState([])
    const [callFeedback, setCallFeedback] = useState([])
    const [callAction, setCallAction] = useState([])
    const [callSignals, setCallSignals] = useState([])
    const [customerName,setCustomerName] = useState("")
    const [sellerName, setSellerName] = useState("")
    const [fileName, setFileName] = useState("")
    const [likeDislikeData, setLikeDislikeData] = useState([])
    const history =  useHistory()
    const [historyObject, setHistoryObject] = useState("")
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const params = urlParams.get('emailId')
    const mobile = urlParams.get('no')
    const callid = urlParams.get('id')
    const talkDuration = urlParams.get('duration')
    // const recUrl = urlParams.get('url').split("/")
    const recUrl = []
  
    let url = historyObject.url
    const date = urlParams.get('date')
    const sellerId = urlParams.get('id')
    const coeName = urlParams.get('coe')
    const coeIds = historyObject.coeId
    const seller = urlParams.get('seller')
    const customer = urlParams.get('customer')
    const typeOfCall = urlParams.get('name')
    console.log(queryString,params,typeOfCall,mobile,"url1")
    console.log(callListingGroup.data.details[0].callScore,"stats")

    
    let userDetail = JSON.parse(localStorage.getItem("USER_DETAIL"))?.clientConfig?.data?.conversationListing
    console.log(history,"history")

    const onClickHandler=()=>{
        setShow(true)
    }
    const closeButton=()=>setShow(false)

    useEffect(() => {
        console.log(history.location,"history")
      setHistoryObject(history.location.state)
    //   if(key=='stats'){
        // if(typeOfCall=='GROUP'){
        // callsService.getCallDetailStats(mobile,typeOfCall).then((res) =>{
            // setCallStats(callListingGroup)
        // })}
        // else if(typeOfCall=="SINGLE_CALL"){
        //     callsService.getCallDetailStats(callid,typeOfCall).then((res) =>{
        //         setCallStats(res.data.data)
        // })}
    //       setLoader(true)
    //       if(res.status==200)
    //       {
    //         setCallStats(res.data.data)
    //         setLoader(false)
    //         callsService.getLikeDislike(params,"STATS").then(res=>{
    //           if(res){
    //             setLikeDislikeData(res.data.data)
    //           }
    //         })
    //       }
    //     })
    // if(typeOfCall=='SINGLE_CALL'){
    //       callsService.getCallTime(params).then(response=>{
    //           if(response){
    //             setCallTime(response.data)
    //             setFileName(response.data.recordingUrl)
    //             setLoader(false)
    //           }
    //       })
    //     }
    //   }   
    },[])
    
    const handleMomentData = async (isLoader = true) => {
    //   isLoader && setLoader(true);
        //   let response = await apiCall.post("/odio/api/call/call-detail/moments",{[`${typeOfCall=='SINGLE_CALL'?"callId":"customerMobiles"}`]:parseInt(params),"fromD":JSON.parse(localStorage.getItem("AGENT"))?.fromD,"toD":JSON.parse(localStorage.getItem("AGENT"))?.toD,"coeIds":coeIds})
        //   if(response){
              typeOfCall === "GROUP" ? setCallMoments(groupMoment.data) : setCallMoments(singleMoment.data)
            //   isLoader && setLoader(false)
            //   callsService.getLikeDislike(params,"MOMENTS").then(res=>{
            //     if(res){
            //       setLikeDislikeData(res.data.data)
            //     }
            //   })
        //   }
    }
  
    useEffect(()=>{
        handleMomentData();
    },[])
    

    const handleSelect = async (keyVal) => {
      if (key !== keyVal) {
        setKey(keyVal);
        if(keyVal=='transcript'){
        //   setLoader(true)
        //   callsService.getCallDetailTranscript(params).then(response=>{
        //     if(response.status==200){
              setCallTranscript(transcript.data)
              setCustomerName(transcript.data?.customerName)
              setSellerName(transcript.data?.sellerName)
            //   setLoader(false)
            //   callsService.getLikeDislike(params,"TRANSCRIPT").then(res=>{
            //     if(res){
            //       setLikeDislikeData(res.data.data)
            //     }
            //   })
            }
        //   }
        //   )
        // }
        // if(keyVal=='questions'){
        //   setLoader(true)
        //   callsService.getCallDetailQuestions(params).then(response=>{
        //     if(response){
        //       setCallQuestions(response.data.data)
        //       console.log(response)
        //       setLoader(false)
        //       callsService.getLikeDislike(params,"QUESTIONS").then(res=>{
        //         if(res){
        //           setLikeDislikeData(res.data.data)
        //         }
        //       })
        //     }
        //   })
        // }
    
        // if(keyVal=='foulLanguage'){
        //   setLoader(true)
        //   callsService.getCallDetailFoul(params).then(response=>{
        //     if(response){
        //       setFoulLanguage(response.data.data)
        //       setLoader(false)
        //       callsService.getLikeDislike(params,"FOUL_LANGUAGE").then(res=>{
        //         if(res){
        //           setLikeDislikeData(res.data.data)
        //         }
        //       })
        //     }
        //   })
        // }
    
        if(keyVal === 'callMoments')
        {
          handleMomentData();
        }
    
        if(keyVal=='feedback'){
        //   setLoader(true)
        //   callsService.getCallDetailFeedback(params).then(res=>{
        //     if(res){
        //       console.log(res)
        //       setCallFeedback(res.data.data)
        //       setLoader(false)
        //     }
        //   })
        }
    
        if(keyVal=='actions'){
        //   setLoader(true)
        //   callsService.getCallDetailActions(params).then(res=>{
        //     if(res){
        //       console.log(res)
        //       setCallAction(res.data.data)
        //       setLoader(false)
        //       callsService.getLikeDislike(params,"ACTIONS").then(res=>{
        //         if(res){
        //           setLikeDislikeData(res.data.data)
        //         }
        //       })
        //     }
        //   })
          
        }
    
        // if(keyVal=='signals'){
        //   setLoader(true)
        //   callsService.getCallDetailSignals(params).then(res=>{
        //     if(res){
        //       console.log("signalsssssss",res)
        //       setCallSignals(res.data.data)
        //       setLoader(false)
        //       callsService.getLikeDislike(params,"SIGNALS").then(res=>{
        //         if(res){
        //           setLikeDislikeData(res.data.data)
        //         }
        //       })
        //     }
        //   })
        // }
      }
    }

    const callBack = (e)=>{
      console.log(e)
      setTime(e)
    }

    useEffect(() => {
        document.title = "Voice Details - Odio"
    }, []); 

    return(
      <div className="page-wrapper">
        <div className="page-content">
          <div className="row row-cols-1 row-cols-md-2 row-cols-xl-4">
            <div className="col-12">
              <div className="row">
                <div className="col-md-12">
                  <div className="d-flex justify-content-between py-3">
                    <h2 className="dashboard-headings py-2">
                      {`Email Id #${params}`}
                      <Button
                      
                      className="btn-mdl py-1 ms-2"
                      onClick={onClickHandler}
                      >
                      More Info.
                      </Button>
                    </h2>
                    <div className="detail-top-head text-end">
                        {userDetail.includes("CALL SCORE")?<span className="badge rounded-pill btn btn-outline-success px-2 my-1 py-2 font-14">
                        Email Score {callListingGroup.data.details[0].callScore}%
                        </span>:""}
                        {userDetail.includes("PITCH SCORE")?<span className="badge rounded-pill btn btn-outline-danger px-2 my-1 py-2 font-14">
                        Pitch Score {callStats? callStats[0]? callStats[0].pitchScore : '0' : '0'}%
                        </span>:""}
                        {userDetail.includes("CONVERSION PROBABILITY")?<span className="badge rounded-pill btn btn-outline-warning px-2 my-1 py-2 font-14">
                        Conv. Probability {callStats? callStats[0]? callStats[0].convProbability : '0' : '0'}%
                        </span>:""}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* {typeOfCall=='SINGLE_CALL'?<div className="col-12">
              <AudioPlayer recUrl={historyObject?.url} startTime={startTime} callBack={callBack} callTranscript={callTranscript} key={key} talkDuration={callTime.duration} callTime={callTime}/>
            </div>:""} */}
            <div className="col-12">
                  <Card>
                      <Card.Body className="p-5">
                        <div className="setting-section-nev">
                          <Tabs
                            id="controlled-tab-example"
                            activekey={key} 
                            onSelect={handleSelect}
                          >
                            <Tab eventKey="callMoments" title="MOMENTS">
                            <Moments handleMomentData={handleMomentData} callMoments= {callMoments} tabSelected={key}  loader={loader} callId={params} fileName={fileName} agentExternalId={historyObject.agentExternalId} likeDislikeData={likeDislikeData} phone={historyObject?.phone} typeOfCall={typeOfCall}/>
                            </Tab>
                            {typeOfCall=='SINGLE_CALL'?<Tab eventKey="transcript" title="EMAIL">
                            <Transcript callTranscript={callTranscript} tabSelected={key} loader={loader} callId={params} url={url} fileName={fileName} agentExternalId={historyObject.agentExternalId} phone={historyObject?.phone} likeDislikeData={likeDislikeData} typeOfCall={typeOfCall}/>
                            </Tab>:""}
                            {typeOfCall=='SINGLE_CALL'?<Tab eventKey="actions" title="ACTIONS">
                            {/* <Actions callAction={callAction} tabSelected={key} loader={loader} callId={params} customerName={customerName} sellerName={sellerName} fileName={fileName} agentExternalId={historyObject.agentExternalId} typeOfCall={typeOfCall} likeDislikeData={likeDislikeData}/> */}
                            </Tab>:""}
                            <Tab eventKey="feedback" title="FEEDBACK">
                            {/* <Feedbacks /> */}
                            </Tab>
                          </Tabs>
                        </div>
                      </Card.Body>
                  </Card>
              </div>
          </div>
        </div>
        {
          // customerName&&sellerName!==[]&&
        <MoreInfo show={show} setShow={setShow} closeButton={closeButton} customerName={historyObject?.customer} sellerName={historyObject?.seller} talkDuration={null} date={historyObject?.date} url={null} sellerId={historyObject?.id} coeName={historyObject?.coe} typeOfCall={typeOfCall}/>}
      </div>
    )
}

export default EmailDetail