import React, { useEffect, useState } from "react";
import userService from "../../Services/user.service";
import Loading from "../../Commons/Loading";
import SimpleTable from "../../Commons/Table/SimpleTable";
import ReactTable from "../../Commons/Table/ReactTable";
import { SETTINGS_COMPONENT_TYPE } from "../../../Constants/Enum";
import AddNewEmpolyee from "./AddNewEmployee";
import Filter from "../../Filter/Filter";
import { connect } from "react-redux";
import NodataFound from "../../Commons/NoDataFound";

function ManageUsersColumns(props) {
  // const [data, setData] = useState([]);
  console.log(props,'lplp%%%')
  const [roles, setRoles] = useState([])
  const [currentPage, setCurrentPage] = useState(() => {
    const savedPage = localStorage.getItem('currentPage');
    return savedPage ? parseInt(savedPage) : 0;
  });

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const column = [
    {
      Header: "Employee Id",
      accessor: "employeeId",
    },
    {
      Header: "Employee",
      accessor: (d) => `${d.firstName.charAt(0).toUpperCase()+d.firstName.slice(1).toLowerCase()} ${d.lastName.charAt(0).toUpperCase().slice(1).toLowerCase()}`, 
      Cell: (d) => (
        <div style={{textTransform:"capitalize"}}>
          {`${d.cell.row.original.firstName} ${d.cell.row.original.lastName}`}
        </div>
        ),
    },
    

    {
      Header: "Email",
      accessor: (d) => `${d.email ? d.email : "-"}`,
      width: 200,
    },
    {
      Header: "Mobile",
      accessor: (d) =>
        `${
          // d.userPhoneNumbers
          //   ? d.userPhoneNumbers.length > 0
          //     ? d.userPhoneNumbers[0].type === "MOBILE"
          //       ? d.userPhoneNumbers[0].mobileNumber
          //       : "-"
          //     : "-"
          //   : "-"
          d.mobile?d.mobile:"-"
        }`,
      width: 200,
      disableSortBy: true,
    },
    {
      Header: "COE",
      accessor: (d) => (
        <ul class="mb-0">
        {d.clientCoes? d.clientCoes.map(coe =>(
          <li><a type="button"
           className="edit-Emp-member"
           onClick={() => displayCoe(coe)}>{coe.name}</a></li>
        )
        ): console.log('client', d)}
       </ul>),
       disableSortBy: true,
    },
    {
      Header: "Designation",
      accessor: (d) => `${d.designation ? d.designation : "-"}`,
    },
    {
      Header: "Role",
      accessor: (d) => `${d.role ? d.role : "-"}`,
    },
    {
      Header: "Caller",
      accessor: (d) => (
        <p className="text-center mb-0">
          {d.caller && `${d.caller === true}` ? (
            <i className="lni lni-checkmark"></i>
          ) : (
            "-"
          )}
        </p>
      ),
    },
    // {
    //   Header: "Call Upload",
    //   accessor: (d) => (
    //     <p className="text-center mb-0">
    //       {d.caller && `${d.caller === true}` ? 
    //       (<i className="lni lni-checkmark"></i>) : ("-")
    //       }
    //     </p>
    //   ),
    //   disableSortBy: true,
    // },
    
    {
      Header: "Action",
      accessor: "action",
      sticky: "right",
      disableSortBy: true,
      Cell: (e) => (
        <p className="mb-0 text-center d-inline">
          <a
            type="button"
            className="edit-Emp-member"
            onClick={() => OnClickEdit(e.row.original, "EDIT")}
          >
            <i className="lni lni-pencil mx-1" data-tip="Edit User"></i>
          </a>
        </p>
      ),
    },
  ];

  const displayCoe = (coe) =>{
    console.log("coeUsers", coe);
    if (coe) {
      props.setCOETab(coe)
    }
  }

  function OnClickEdit(e) {
    console.log(e,'lplp&')
    const capitalizedFirstName = capitalizeFirstLetter(e.firstName);
    const capitalizedLastName = capitalizeFirstLetter(e.lastName);
    
    const updatedUserData = {
      ...e,
      firstName: capitalizedFirstName,
      lastName: capitalizedLastName,
      manager: e.manager,
      managerId : e.managerId
    };
    console.log(updatedUserData, 'lplp&*');
    props.setEditUserVisibility(true);
    console.log(e)
    props.setUserData(updatedUserData);
  }

  useEffect(()=>{
    userService.getRoleOptions().then(res=>{
      if(res)
        console.log(res)
        setRoles(res.data.data)
    })
  },[])

  const onRowClick = (pageIndex) => {
    setCurrentPage(pageIndex);
    localStorage.setItem('currentPage', pageIndex);
  };

  return (
    <>
    { props.loader?
      (<div className="loader-container">
        <Loading variant="dark" />
      </div>
      ): props.usersList != null && props.usersList.length > 0 ? (
        <div className="table-responsive dataTable-style react-table">
          <ReactTable
            onRowClick={onRowClick}
            setIsEdit={props.setIsEdit}
            columns={column}
            componentType={SETTINGS_COMPONENT_TYPE.MANAGE_USERS }
            data={props.usersList}
            isDelete={true}
            isExcelPdfButton={true}
            handlePageIndex={(e)=>{console.log()}}
            prePageIndexx={currentPage}
          />
         
        </div>
      ): <NodataFound />
    }
      <Filter
        componentType="FILTER USERS"
        data={props.usersList}
        dropdownData={props.users}
        roles={roles}
        coeOptions={props?.coeOptions}
      />
    </>
  );
}

const mapStateToProps = (state) => {
  console.log(state)
  return {
    usersList: state.manage_users.usersList,
    loader: state.manage_users.loader,
    users: state.manage_users.users,
    filteredUserData: state.manage_users.filteredUserData,
  };
};

export default connect(mapStateToProps)(ManageUsersColumns) ;
