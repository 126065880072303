import React, { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import Calender from "./Setting/Calender";
import Reports from "./Reports/Reports";
import General from "./Setting/General";
import Contact from "./Setting/Contact";
import Integrations from "./Setting/Integrations";
import ManageCoe from "./ManageCoe/ManageCoe";
import ManageMoments from "./ManageMoments/ManageMoments";
import ManageUsers from "./ManageUser/ManageUsers";
import "./CompanySettings.css";
import momentService from "../Services/moment.service";
import ratingService from "../Services/rating.service";
import { connect } from "react-redux";
import { getclientcoeList, setCoeList } from "../../redux-container/settings/manage_coe/action";
import { getUserList, setUser } from "../../redux-container/settings/manage_users/action";
import clientService from "../Services/client.service";
import ManageRatings from "./Ratings/ManageRatings";
import { SIGN_IN } from "../../redux-container/login/types";
import { USER_DETAIL } from "../../Constants/constant";
import reportConfigService from "../Services/reportConfig.service";
import { SettingsAccessibility } from "@mui/icons-material";
import notificationsService from "../Services/notifications.service";
import { apiCall } from "../Services/Interceptor";
import ManageCompetitors from "./Competitors/ManageCompetitors";

function CompanySidebar(props) {
  const [key, setKey] = useState("general");
  const [loading, setLoading] = useState(false);
  const [urlSlack, setUrlSlack] = useState('');

  let flag = true;
  let accType = localStorage.getItem(USER_DETAIL);
  accType = JSON.parse(accType);
  //let momentBucketId = accType.accountType == "SUPPORT"?'2':'1';
  let momentBucketName = accType.accountType == "SUPPORT" ? 'Support Moment Bucket' : 'Sale Moment Bucket';

  const [data, setData] = useState([
    // {
    //   momentBucketId: 0,
    //   momentBucketName: 'Select',
    // },
  ]);
  console.log(data)
  const [momentDetails, setMomentDetails] = useState([
    // {
    //   momentGroupId: 0,
    //   momentGroupName: "BUDGET",
    //   moments: [
    //     {
    //       momentId: 46,
    //       momentName: "Approvals",
    //       momentWeightage: 1.0,
    //       momentApplicableOn: "CUSTOMER",
    //       mentions: [],
    //     },
    //   ],
    // },
  ]);
  const [ratingDetails, setRatingDetails] = useState([]);
  const [clientDetails, setClientDetails] = useState({
    clientId: 1,
    externalId: "Eze19",
    logoUrl: "",
    name: "",
  });

  const [timeZones, setTimeZones] = useState([]);
  const [calenderConfigs, setcalenderConfigs] = useState([
    {
      key: "START_MONTH_OF_YEAR",
      value: "",
    },
    {
      key: "START_DAY_OF_WEEK",
      value: "",
    },
    {
      key: "TIME_ZONE",
      value: "",
    },
    {
      key: "DATE_FORMAT",
      value: "",
    },
    {
      key: "DURATION_FORMAT",
      value: "",
    },
    {
      key: "WEEK_OFF",
      value: "",
    },
    {
      key: "TIME_FORMAT",
      value: "",
    },
    {
      key: "WORKING_HOURS_TO",
      value: "",
    },
    {
      key: "WORKING_HRS_FROM",
      value: "",
    },
  ]);
  const [contacts, setContacts] = useState({
    contactId: 1,
    countryCode: '',
    email: " ",
    firstName: " ",
    lastName: " ",
    phoneNumber: '',
  });
  const [parameters, setParameters] = useState([]);
  const [coeBucket, setCoeBucket] = useState([]);
  const [reportDet, setReportDet] = useState([]);
  const [competitors, setCompetitors] = useState([])

  const showCompetitor = JSON.parse(localStorage.getItem("USER_PERMISSIONS"))?.some((tab) => tab.name == "Competitor Tracker")

  useEffect(() => {
    let desk = localStorage.getItem("TabValue");
    setKey(desk ? desk : "general")
    document.title = "Settings - Odio";
    setLoading(true);
    handleSelect(desk ? desk : key)
    clientService.getClient().then((res) => {
      if (res) {
        setClientDetails(res);

        const data = {
          clientName: res ? res.name : '',
          clientLogo: res ? res.logoUrl : '',
        };
        props.onUpdateDetails(data);
      }
      setLoading(false);
    }).catch((error) => {
      setLoading(false);
    })

    ratingService
      .getParameters()
      .then((res) => {
        if (res) {
          setParameters(res);
        }
      })
      .catch((error) => { });

    clientService
      .getTimezones()
      .then((res) => {
        if (res) {
          setTimeZones(res);
        }
      })
      .catch((error) => {
        setLoading(false);
      });

    props.getUserList();

    return () =>{
      localStorage.removeItem('currentPage')
    }

  }, []);

  const getCompetitorsList = () => {
    setLoading(true);
    clientService.getCompetitors()
      .then((res) => {
        if (res) {
          setCompetitors(res.data.data.competitorDTOS)
        }
        setLoading(false)
      })
      .catch((err) => {
        setLoading(false)
      });
  }

  const handleSelect = async (key1) => {
    localStorage.setItem('currentPage', 0);
    if (key !== key1) {
      localStorage.setItem("TabValue", key1);
      setKey(key1);
      console.log("keySelected", key1)
      if (key1 === "general") {
        setLoading(true);
        console.log("general");
        clientService.getClient().then((res) => {
          console.log(res);
          if (res) {
            setClientDetails(res);
            setLoading(false);
            const data = {
              clientName: res ? res.name : '',
              clientLogo: res ? res.logoUrl : '',
            }
            props.onUpdateDetails(data);
          }
        })
          .catch((error) => {
            setLoading(false);
          });
      }
      if (key1 === "manageMoment") {
        setLoading(true);
        momentService
          .getMomentBuckets('SALES')
          .then((res) => {
            console.log(res);
            setData(res);
            if (res) {
              momentService
                .getMomentData(res[0].momentBucketId)
                .then((response) => {
                  setMomentDetails(response);
                })
                .catch((error) => {
                  setLoading(false);
                });
            }
            setLoading(false);
          })
          .catch((error) => {
            setLoading(false);
          });
      }

      if (key1 === "manageRatings") {
        setLoading(true);
        ratingService
          .getRatings()
          .then((res) => {
            console.log(res);
            setRatingDetails(res);
            setLoading(false);
          })
          .catch((error) => {
            setLoading(false);
          });
      }
      if (key1 === "manageCoe") {
        setLoading(true);
        props.getclientCoeList();
        // momentService
        //   .getMomentBuckets()
        //   .then((res) => {
        //     console.log(res);
        //     setCoeBucket(res);
        //     setLoading(false);
        //   })
        //   .catch((error) => {
        //     console.log('abc')
        //     setLoading(false);
        //   });
        //setLoading(false)
      }
      if (key1 === "manageUsers") {
        props.getclientCoeList();
        if (flag)
          props.getUserList();
      }
      if (key1 === "manageCompetitors" && showCompetitor) {
        getCompetitorsList();
      }
      if (key1 === "contact") {
        //props.updateContact();
        setLoading(true);
        clientService
          .getContact()
          .then((res) => {
            if (res) {
              console.log(res);
              setContacts(res);
            }
            setLoading(false);
          })
          .catch((error) => {
            setLoading(false);
          });
      }
      if (key1 === "calender") {
        setLoading(true);
        clientService
          .getCalenderConfigs()
          .then((res) => {
            console.log(res);
            if (res) {
              setcalenderConfigs(res);
            }
            setLoading(false);
          })
          .catch((error) => {
            setLoading(false);
          });
      }
      if (key1 === 'reports') {
        setLoading(true)
        reportConfigService.getReportConfig().then((res) => {
          console.log(res);
          if (res) {
            setReportDet(res);
          }
          setLoading(false);
        })
          .catch((error) => {
            setLoading(false);
          });
      }
      if (key1 === 'Integrations')
        try {
          setLoading(true);
          const response = await apiCall.get('/odio/api/slack/get/shareableUrl');
          const urlRes = response.data;
          console.log('slackkkkkkk', urlRes);
          setUrlSlack(urlRes);
          setLoading(false)
        } catch (error) {
          console.log(error);
          setLoading(false)
        }
    }
  };

  const setTab = (e) => {
    flag = false
    handleSelect('manageUsers')
    props.setUser(e)
  }

  const setCOETab = (coe) => {
    flag = false
    handleSelect('manageCoe')
    console.log('coe', coe)
    props.setCoeList(coe)
    // props.setUser(coe)
  }
  const setMomentBucket = (coe) => {
    flag = false
    // handleSelect('manageMoment')
    setKey('manageMoment')
    setLoading(true);
    momentService
      .getMomentData(coe.momentBucketId)
      .then((response) => {
        setMomentDetails(response);
        setLoading(false);

      })
      .catch((error) => {
        setLoading(false);
      });
    console.log('coe', coe)
    setData([coe])
    // props.setUser(coe)
  }

  // const callBack = (e)=>{
  //   setMomentBucketId(e)
  // }

  return (
    <>
      <div className="page-wrapper account-content">
        <div className="page-content account-details-page">
          <div className="row row-cols-1 row-cols-md-1 row-cols-lg-2 row-cols-xl-2">
            <div className="col-md-12">
              <h4 className="mb-2">Settings</h4>
              <div className="card edit-border">
                <div className="card-body setting-page">
                  <Tabs
                    id="controlled-tab-example"
                    activeKey={key}
                    onSelect={handleSelect}
                  >
                    <Tab eventKey="general" title="General">
                      <General
                        clientDetails={clientDetails}
                        loading={loading}
                      />
                    </Tab>
                    {/* <Tab eventKey="contact" title="Contact">
                      <Contact contacts={contacts} loading={loading} />
                    </Tab> */}
                    <Tab eventKey="calender" title="Calendar">
                      <Calender
                        calenderConfigs={calenderConfigs}
                        timeZones={timeZones}
                        loading={loading}
                      />
                    </Tab>
                    <Tab eventKey="Integrations" title="Integrations">
                      <Integrations loading={loading} urlSlack={urlSlack} />
                    </Tab>
                    <Tab eventKey="reports" title="Reports">
                      {key === "reports" && <Reports loading={loading} reportDet={reportDet} clientDetails={clientDetails} setReportDet={(val) => setReportDet(val)} />}
                    </Tab>
                    <Tab eventKey="manageMoment" title="Moments">
                      {data.length>0&&<ManageMoments
                        data={data}
                        loading={loading}
                        momentDetails={momentDetails}

                      />}
                    </Tab>
                    <Tab eventKey="manageCoe" title="COE">
                      <ManageCoe coeBucket={coeBucket} setMomentBucket={setMomentBucket} setTab={setTab} />
                    </Tab>
                    <Tab eventKey="manageUsers" title="Users">
                      <ManageUsers setCOETab={setCOETab} />
                    </Tab>
                    <Tab eventKey="manageRatings" title="Ratings">
                      <ManageRatings
                        data={data}
                        loading={loading}
                        parameters={parameters}
                        ratingDetails={ratingDetails}
                      />
                    </Tab>
                    {showCompetitor && <Tab eventKey="manageCompetitors" title="Competitors">
                      <ManageCompetitors
                        loader={loading}
                        competitorsList={competitors}
                        getCompetitorsList={getCompetitorsList}
                      />
                    </Tab>}
                  </Tabs>
                  <div className="d-flex"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    coeUsersList: state.manage_coe.coeUsersList
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getclientCoeList: () => {
      dispatch(getclientcoeList());
    },
    getUserList: () => {
      dispatch(getUserList());
    },

    setUser: (data) => {
      dispatch(setUser(data))
    },
    setCoeList: (coe) => {
      dispatch(setCoeList(coe))
    },
    onUpdateDetails: (inputs) => dispatch({ type: SIGN_IN, payload: inputs }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanySidebar);
