import React, { useEffect } from "react";
import { useState } from 'react';
import momentService from "../../Services/moment.service";
import "./manageMoments.css";
import { Modal, Button } from "react-bootstrap";
import Moments from "./Moments";
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import WantToDeleteModal from '../../Commons/Modal/WantToDeleteModal'
import ReactTooltip from 'react-tooltip';
import {useRef} from 'react';


function MomentGroup(props) {
    const [isDelete, setIsDelete] = useState(false)
    const [isHide, setIsHide] = useState(true);
    const [groupName, setGroupName] = useState(props.momentData ? props.momentData.momentGroupName:'Moment Group Name');
    const [updatedGroupName, setUpdatedGroupName] = useState(props.momentData ? props.momentData.momentGroupName:'Moment Group Name');
    const [isOpen, setIsOpen] = useState(false)
    const [onAddMoment, setOnAddMoment] = useState(false)
    const [formError, setFormError] = useState([])
    const [input, setInput] = useState({
        momentName:'',
        positiveRemarks:'',
        negativeRemarks:''
    })
    let btnRef = useRef();
    const[defaultData, setDefaultData] = useState({
        moments: [
            {
                momentName: "Moment Name",
                momentWeightage: 1.0,
                momentApplicableOn: "ALL",
                mentions: []
            },
        ]
    })
    
    
    useEffect(()=>{
        const componentDidMount= function() {
            window.addEventListener('load', handleScroll);
        }
        
        const componentWillUnmount= function() {
            window.removeEventListener('load', handleScroll);
        }
        const handleScroll = function(){

            var editScroll = document.querySelector(".account-content .card.edit-border");
            editScroll.style.margin = "10px !Important";
            window.onscroll = function(e) {
                //console.log(e);    
                //var nav = document.getElementById('changeColor');
                if ( window.pageYOffset > 50 ) {
                    document.getElementById('changeColor').classList.add("navbar1");
                } else {
                    document.getElementById('changeColor').classList.remove("navbar1");
                }
            }
        } 
    },[])  
   
    const onEdit = () => {
        setIsHide(false);
    }

    useEffect(() => { setGroupName(props.momentData.momentGroupName)}, [props.momentData]);
    
    const onUpdateGroupName = () => {
        const data= {
            momentGroupId: props.momentData ? props.momentData.momentGroupId:'',
            momentGroupName: updatedGroupName
        }
        if(updatedGroupName != groupName){
            momentService.updateMomentGroup(data).then(res =>{
                console.log('updated', res);
                if(res.data.status === 0)
                {
                    setGroupName(updatedGroupName)
                }
            }).catch(error =>{
                console.log(error);
            })
        }
       
        setIsHide(true)
    };

    const onEditClose = () => {
        setIsHide(true)
    }

    const handleChange = (e) => {
        setUpdatedGroupName(e.target.value)
        //setIsHide(true)
    }

    const deleteMomentGroups = () =>{
        setIsDelete(false)
        momentService.deleteMomentGroup(props.momentData.momentGroupId)
        .then(res =>{
            if(res)
            {console.log('mg', props.bucketId)
                if(res.data.status === 0)
                {
                    momentService.getMomentData(props.bucketId).then(res => {
                        console.log('moment--', res);
                        props.setMomentData(res);
                    })
                }
            }
        })
    }
    const deleteMomentGroup = () =>{
        setIsDelete(true)
    }


    const formErrors = {};
    const validate = (values)=>{
        if (!values.momentName) {
            formErrors.momentName = "Moment Name is required";
        }
        if (!values.momentApplicableOn) {
            formErrors.momentApplicableOn = "Applicable On is required";
        }
        if (!values.momentWeightage) {
            formErrors.momentWeightage = "Weightage is required";
        }
        if (!values.negativeRemarks) {
            formErrors.negativeRemarks = "Negative Remarks is required";
        }
        if (!values.positiveRemarks) {
            formErrors.positiveRemarks = "Positive Remarks is required";
        }
        return formErrors;
    }

    const handleChanges = (e)=>{
        const alphanumericRegex = /^[a-zA-Z\s]+$/;
        setFormError([])
        if(e.target.name=='momentName'||e.target.name=='positiveRemarks'||e.target.name=='negativeRemarks'){
            if (alphanumericRegex.test(e.target.value) || e.target.value === '') {
                setInput({...input, [e.target.name]: e.target.value })
                btnRef.current.removeAttribute("disabled");
            }
        }
        else{
            setInput({...input, [e.target.name]: e.target.value })
            btnRef.current.removeAttribute("disabled");
        }

    }
    const handleKeyDown = (e)=>{
        const regex = /^[a-zA-Z ]+$/;

        if (!regex.test(e.key)) {
            e.preventDefault(); // Prevent the input of the forbidden character
        }
    }

    useEffect(() => {
        setInput({...input, fatal:true, mentionRequestList:[], momentGroupId: props?.momentData.momentGroupId, })
    }, [props?.momentData?.momentGroupId]);

    const addMoment = () =>{
        /* const data = {
            fatal:true,
            momentApplicableOn: "ALL",
            momentGroupId: props.momentData.momentGroupId, 
            momentId: 0, 
            momentName: 'Moment Name',
            momentWeightage: 1,
            positiveRemarks:"POSITIVE",
            negativeRemarks:"NEGATIVE"
        } */
        validate(input)
        setFormError(formErrors)
        if(Object.keys(formErrors).length==0){
            if(btnRef.current){
                btnRef.current.setAttribute("disabled", "disabled");
            }
            momentService.createMoment(input).then(res =>{
                console.log('updated', res);
                setOnAddMoment(false)
                setInput({})
                setInput({fatal:true,mentionRequestList:[], momentGroupId: props?.momentData.momentGroupId})
                // setInput({...input, fatal:true, momentGroupId: props?.momentData.momentGroupId, })
                if(res.data.status === 0)
                {
                    momentService.getMomentData(props.bucketId).then(res => {
                        console.log('moment--', res);
                        props.setMomentData(res);
                    })
                }
            })
        }
    }

    const openCloseMG = ()=>{
        setIsOpen(!isOpen)
    }

    const closeButton = ()=>{
        setIsDelete(false)
    }

    console.log(props.momentData)

    return (
        <div className="moment-group">
            <div className="moment-group-tittle d-flex justify-content-between mt-3 mb-1 p-sticky pointer-event" >
                <div>{isHide ? 
                    <>
                    <span id="changeColor" className="gp-editable-nameInput-val">{groupName} </span>
                    <button className="btn p-0 gp-edit-moment-member" onClick={onEdit} >
                        <i className="lni lni-pencil text-primary-blue" data-tip="Edit"></i>
                    </button>
                    </>
                    :<><input type="text" className="input-field-bt-border font-20 w-auto h-auto" value={updatedGroupName} onChange={handleChange}/>
                    <button className="btn p-0 gp-save-moment-member" onClick={onUpdateGroupName} >
                        <i className="lni lni-checkmark text-primary-blue" data-tip="Save"></i>
                    </button>
                    <button className="btn p-0 gp-trash-moment-member" onClick={onEditClose}>
                        <i className="lni lni-cross-circle text-primary-blue" data-tip="Cancel"></i>
                    </button>
                    </>
                    }
                </div>
                <div className="del-btn">
                    <button type="button" className="btn del-moment-group p-0 mx-1" onClick={deleteMomentGroup}>
                        <i className="lni lni-archive text-primary-blue me-0" data-tip="Delete Moment Group"></i>
                    </button>
                    <button type="button" className="btn del-moment-group ms-1 p-0" onClick={openCloseMG}>
                        {isOpen?<i className="lni lni-minus font-14" data-tip="Close"></i>:<i className="lni lni-plus font-14" data-tip="Add Moment Group"></i>}
                    </button>
                    
                </div>
            </div>
            {isOpen?
                <>{
                    props.momentData.moments?
                    props.momentData.moments.length?
                    props.momentData.moments.length>0 ?
                        props.momentData.moments.map(group =>(
                            <Moments key={props.bucketId} moments={group} bucketId={props.bucketId} setMomentData={props.setMomentData}/>
                        ))
                        : ''
                        : ''
                        :'xyz'
                }
                
            <div className="text-center">
                <button className="btn text-primary-blue add-moment-btn font-16" onClick={()=> setOnAddMoment(true)}>Add Moment</button>
            </div></>
            :''}
            <Modal show={onAddMoment} onHide={() => setOnAddMoment(false)}>
                <Modal.Header>
                <Modal.Title>Create New Moment</Modal.Title>
                </Modal.Header>
                <Modal.Body >
                <div className="d-flex justify-content-between">
                    <div>
                        <label for="Applies-To" className="me-1 d-block">Moment Name* </label>
                        <input type="text" id="momentName" className="input-field-bt-border  font-20 w-auto h-auto" value={input.momentName} placeholder="Enter Moment Name" name='momentName' onChange={handleChanges}/>
                        <p className="my-1 mb-0 lh-1 font-12" style={{ color: "red" }}>{formError.momentName}</p>
                    </div>
                </div>
                <div className="d-flex mt-4">
                        <div>
                            <label for="Applies-To" className="me-1 d-block">Applies To* </label>
                            <div>
                                <Select  id="Applies-To" name='momentApplicableOn' sx={{ width: 120 }} onChange={handleChanges}>
                                    <MenuItem  value="ALL" key='101'>ALL</MenuItem>
                                    <MenuItem  value="USER" key='102'>USER</MenuItem>
                                    <MenuItem  value="CUSTOMER" key='103'>CUSTOMER</MenuItem>
                                </Select>
                            </div>
                            <p className="my-1 mb-0 lh-1 font-12" style={{ color: "red" }}>{formError.momentApplicableOn}</p>
                        </div>
                        <div className="mx-1">
                            <div>
                                <label for="Applies-To" className="me-1 d-block">Weightage* </label>
                                <div>
                                    <Select  id="Weightage" name='momentWeightage' sx={{ width: 100 }} onChange={handleChanges}>
                                        <MenuItem value='1' key='201'>1</MenuItem>
                                        <MenuItem value='2' key='202'>2</MenuItem>
                                        <MenuItem value='3' key='203'>3</MenuItem>
                                        <MenuItem value='4' key='204'>4</MenuItem>
                                        <MenuItem value='5' key='205'>5</MenuItem>
                                        <MenuItem value='6' key='206'>6</MenuItem>
                                        <MenuItem value='7' key='207'>7</MenuItem>
                                        <MenuItem value='8' key='208'>8</MenuItem>
                                        <MenuItem value='9' key='209'>9</MenuItem>
                                        <MenuItem value='10' key='210'>10</MenuItem>
                                    </Select>
                                </div>
                                <p className="my-1 mb-0 lh-1 font-12" style={{ color: "red" }}>{formError.momentWeightage}</p>
                            </div>
                        </div>
                    </div>
                {/* <div className="semantically-similar-match mt-4">
                    <div className="heading">
                        <h6>Semantically Similar Matches</h6>
                    </div>
                    <div>
                        <div className="d-flex input-of-keyword mt-4 ">
                            <input className="input-field input-field-bt-border w-50" type="text" id="input-similar-match"  name='semantic' placeholder="Add Phrase/Keyword" />
                            <div style={{marginLeft:"18px"}}>
                                <label for="weightage">Weightage</label>
                                <div>
                                    <Select  id="Weightage" name='semanticWeightage' aria-label=" example"  sx={{ width: 50 }} >
                                        <MenuItem value='1' key='201'>1</MenuItem>
                                        <MenuItem value='2' key='202'>2</MenuItem>
                                        <MenuItem value='3' key='203'>3</MenuItem>
                                        <MenuItem value='4' key='204'>4</MenuItem>
                                    </Select>
                                </div>
                            </div>                   
                        </div>
                        
                    </div>
                </div>
                <div className="exact-similar-match mt-4">
                    <div className="heading">
                        <h6>Exact Matches</h6>
                    </div>
                    <div>
                        <div className="d-flex input-of-keyword mt-4 ">
                            <input className="input-field input-field-bt-border w-50" type="text" id="input-similar-match"  name='exact' placeholder="Add Phrase/Keyword" />
                            <div style={{marginLeft:"18px"}}>
                                <label for="weightage">Weightage</label>
                                <div>
                                <Select  id="Weightage" name='exactWeightage' aria-label=" example" sx={{ width: 50 }} >
                                    <MenuItem value='1' key='201'>1</MenuItem>
                                    <MenuItem value='2' key='202'>2</MenuItem>
                                    <MenuItem value='3' key='203'>3</MenuItem>
                                    <MenuItem value='4' key='204'>4</MenuItem>
                                </Select>
                                </div>
                            </div>                   
                        </div>
                    </div>
                </div> */}
                <div className="exact-similar-match mt-4">
                    <div className="heading">
                        <h6>Positive Remarks*</h6>
                    </div>
                    <div>
                        <div className="input-of-keyword mt-4">
                            <input className="input-field input-field-bt-border w-50" type="text" id="input-exact-match" name='positiveRemarks' placeholder="Add Remarks" value={input.positiveRemarks} onChange={handleChanges} />
                            <p className="my-1 mb-0 lh-1 font-12" style={{ color: "red" }}>{formError.positiveRemarks}</p>
                        </div>
                    </div>
                </div>
                <div className="exact-similar-match mt-4">
                    <div className="heading">
                        <h6>Negative Remarks*</h6>
                    </div>
                    <div>
                        <div className="input-of-keyword mt-4">
                            <input className="input-field input-field-bt-border w-50" type="text" id="input-exact-match" name='negativeRemarks' placeholder="Add Remarks" value={input.negativeRemarks} onChange={handleChanges}/>
                            <p className="my-1 mb-0 lh-1 font-12" style={{ color: "red" }}>{formError.negativeRemarks}</p>
                        </div>
                    </div>
                </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={addMoment} ref={btnRef}>
                        Create Moment
                    </Button>
                </Modal.Footer>
            </Modal>
            <WantToDeleteModal show={isDelete} close={closeButton} isConform={deleteMomentGroups} />
            <ReactTooltip place="bottom" type="dark" effect="solid"/>
        </div>
    )
}

export default MomentGroup;