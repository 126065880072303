import { apiCall } from "./Interceptor"

const GET_ACTIVITY_LOG = '/odio/api/call/insight/activityLog'
const GET_PRODUCTIVITY = '/odio/api/call/insight/productivity'
const GET_COMPARATIVE = '/odio/api/call/insight/ComparativeAnalysis'
const GET_DURATION = '/odio/api/call/insight/DurationMetrics'
const GET_CONVERSION = '/odio/api/call/insight/ConversionMetrics'
const GET_QUESTION = '/odio/api/call/insight/questions'

const getActivityLog = async (val)=>{
    const response = await apiCall.post(GET_ACTIVITY_LOG,val)
    return response
}
const getProductivity = async(val)=>{
    const response = await apiCall.post(GET_PRODUCTIVITY,val)
    return response
}
const getComparative = async(val)=>{
    const response = await apiCall.post(GET_COMPARATIVE, val)
    return response
}
const getDurationMetrics = async(val)=>{
    const response = await apiCall.post(GET_DURATION,val)
    return response
}
const getConversionMetrics = async(val)=>{
    const response = await apiCall.post(GET_CONVERSION, val)
    return response
}
const getQuestion = async(val)=>{
    const response = await apiCall.post(GET_QUESTION, val)
    return response
}
export default {
    getActivityLog,
    getProductivity,
    getComparative,
    getDurationMetrics,
    getConversionMetrics,
    getQuestion}