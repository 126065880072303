import createRootReducer from "../redux-container/combineReducer";
import {createStore, applyMiddleware, compose} from 'redux';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import reduxThunk from 'redux-thunk';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
export const history = createBrowserHistory();

export default createStore(
    createRootReducer(history),
    composeEnhancers(applyMiddleware(reduxThunk, routerMiddleware(history)))
)