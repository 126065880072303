import { Tab, Tabs,Nav } from "react-bootstrap";
import React,{ useState } from "react";
import All_action from "./All_action";
import EmailQuestions from "../Sales/Modals/EmailTranscript";
import Moments from "../Sales/Moments";
import { Col,Row } from "react-bootstrap";
import Loading from "../../Commons/Loading";
import EmailTranscript from "../Sales/Modals/EmailTranscript";

export default function Actions(props) {
  const [key, setKey] = useState("all");
  const [show, setShow] = useState(false)
  const [keyClicked,setKeyClicked]=useState("all");
  const closeButton=()=>setShow(false)
  const handleClick=()=>{
    console.log("clicked")
    setShow(true)
  }
  const handleSelect = (keyClicked)=>{
    setKeyClicked(keyClicked)
  }

  const setEmailFalse= ()=>{
    setShow(false)
  }

  return (
    <>
      {props.loader?
      <div className="loader-container">
        <Loading variant="dark"/>
      </div>:
     
      <div className="tab-content py-3">
        <div className="tab-pane fade active show" role="tabpanel" id="primaryaction">
          <div className="d-flex">
            <div className="moment-filter-tab-box">
              <Tab.Container id="left-tabs-example" defaultActiveKey={keyClicked?keyClicked:"all"}>
                <Row>
                  <Col sm={10}>
                    <Nav variant="pills" className="flex" onSelect={handleSelect}>
                      <Nav.Item>
                        <Nav.Link eventKey="all">All</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="customer">{props?.customerName}</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="seller">{props?.sellerName}</Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </Col>
                  <Col sm={2} className="moments-email-btn mb-3 text-sm-right pe-0">
                    <a className="btn compose-mail-btn" onClick={handleClick}>Email Actions</a>
                  </Col>
                  <Col sm={12} className="px-0">
                    <Tab.Content>
                      {keyClicked=='all'||keyClicked=='customer'||keyClicked=='seller'?
                        <Tab.Pane eventKey={keyClicked}>
                          <All_action keyClicked={keyClicked?keyClicked:""} callAction={props.callAction?props.callAction:''} tabSelected={props.tabSelected} callId={props?.callId} customerName={props?.customerName} sellerName={props?.sellerName} loader={props.loader} likeDislikeData={props?.likeDislikeData} agentExternalId={props?.agentExternalId} agentId={props?.agentId}/>
                        </Tab.Pane>
                      :""}
                    </Tab.Content>
                  </Col>
                </Row>
              </Tab.Container>
            </div>
            
          </div>
        
        </div>
        <EmailTranscript show={show} setShow={setShow} closeButton={closeButton} callAction={props.callAction} tabSelected={props.tabSelected} customerName={props?.customerName} sellerName={props?.sellerName} callId={props?.callId} setEmailFalse={setEmailFalse} fileName={props?.fileName} typeOfCall={props?.typeOfCall} agentId={props?.agentId}/>
      </div>
      }
    </>
  );
}
