import { apiCall } from "./Interceptor";

const getCallDetailStats = async (id, type) => {
  const GET_CALL_STATS = `/odio/api/call/get-call-detail/stats?${type == 'SINGLE_CALL' ? `callId=${id}` : `groupId=${id}`}`;
  const response = await apiCall.get(GET_CALL_STATS);
  return response;
}

const getTeamMemberStats = async (id) => {
  const GET_TEAM_STATS = "/odio/api/call/member/stats"
  const response = await apiCall.post(GET_TEAM_STATS, id)
  return response
}

const getTeamMemberMoments = async (val) => {
  const GET_TEAM_MOMENTS = "/odio/api/call/team/member/moments"
  const response = await apiCall.post(GET_TEAM_MOMENTS, val)
  return response
}

const getTeamWordCloud = async (value) => {
  const GET_WORD_CLOUD = "/odio/api/call/member/wordCloud"
  const response = await apiCall.post(GET_WORD_CLOUD, value)
  return response
}

const getTopThreeCalls = async (val) => {
  const GET_TOP_THREE_CALLS = "/odio/api/call/team/top/callers"
  const response = await apiCall.post(GET_TOP_THREE_CALLS, val)
  return response
}

const getAgentRecommendation = async (val) => {
  const GET_AGENT_RECOMMENDATION = "/odio/api/call/team/agent/recommendation"
  const response = await apiCall.post(GET_AGENT_RECOMMENDATION, val)
  return response
}

const getTeamComment = async (value) => {
  const GET_TEAM_COMMENT = "/odio/api/comment/create/team/comment"
  const response = await apiCall.post(GET_TEAM_COMMENT, value) 
  return response
}

const getCallDetailQuestions = async (id) => {
  const GET_CALL_QUESTIONS = `/odio/api/call/get-call-detail/question?callId=${id}`
  const response = await apiCall.get(GET_CALL_QUESTIONS)
  return response
}

const getCallDetailSignals = async (id) => {
  const GET_CALL_SIGNALS = `/odio/api/call/call/detail/signals?callId=${id}`
  const response = await apiCall.get(GET_CALL_SIGNALS)
  return response
}

const getCallDetailMoments = async (id, type, coe) => {
  const response = await apiCall.post("/odio/api/call/call-detail/moments", { [type == 'SINGLE_CALL' ? "callId" : "groupId"]: id, "fromD": JSON.parse(localStorage.getItem("AGENT"))?.fromD, "toD": JSON.parse(localStorage.getItem("AGENT"))?.toD, 'coeIds': coe })
  return response
}

const getCallDetailActions = async (id) => {
  const GET_CALL_ACTION = `/odio/api/comment/get-transComment-action?callId=${id}`
  const response = await apiCall.get(GET_CALL_ACTION)
  return response
}

const getCallDetailFoul = async (id) => {
  const GET_FOUL_LANGUAGE = `/odio/api/call/get-call-detail/foul?callId=${id}`
  const response = await apiCall.get(GET_FOUL_LANGUAGE)
  return response
}

const getCallDetailFeedback = async (id) => {
  const GET_FEEDBACK = `/odio/api/comment/get-comment-in-action?callId=${id}`
  const response = await apiCall.get(GET_FEEDBACK)
  return response
}

const getCallDetailTranscript = async (id) => {
  const GET_CALL_TRANSCRIPT = `/odio/api/call/get-call-detail/transcript?callId=${id}`
  const response = await apiCall.get(GET_CALL_TRANSCRIPT)
  return response
}

const getCallDetailSummary = async (id) => {
  const GET_CALL_SUMMARY = `/odio/api/call/summary?callId=${id}`
  const response = await apiCall.get(GET_CALL_SUMMARY)
  return response
}

const getCallDetailPointer = async (id) => {
  const GET_CALL_POINTERS = `/odio/api/call/pointer?callId=${id}`
  const response = await apiCall.get(GET_CALL_POINTERS)
  return response
}

const getCallDetailKeyword = async (id) => {
  const GET_CALL_KEYWORDS = `/odio/api/call/wordCloudKeys?callId=${id}`
  const response = await apiCall.get(GET_CALL_KEYWORDS)
  return response
}

const getEmailTranscript = async (val) => {
  const GET_EMAIL_TRANSCRIPT = "/odio/api/call/get-transcript"
  const response = apiCall.post(GET_EMAIL_TRANSCRIPT, val)
  return response
}

const createCallDetailComments = async (val) => {
  const CREATE_COMMENT = '/odio/api/comment/create'
  const response = await apiCall.post(CREATE_COMMENT, val)
  return response
}

const getCallDetailComments = async (callId, type, id) => {
  const GET_COMMENT = `/odio/api/comment/get-comment?callId=${callId}&commentType=${type}&postId=${id}`
  const response = await apiCall.get(GET_COMMENT)
  return response
}

const getCallTime = async (id) => {
  const GET_TIME = `/odio/api/call/time?callId=${id}&clientId=${JSON.parse(localStorage.getItem("USER_DETAIL"))?.externalId}`
  const response = await apiCall.get(GET_TIME)
  return response
}

const addLikeDislike = async (val) => {
  const response = await apiCall.post("/odio/api/comment/Likes/dislikes", val)
  return response
}

const getLikeDislike = async (id, type) => {
  console.log("WWQQQQQQQQQQQQQQQQQQ", { id, type })
  const response = await apiCall.get(`/odio/api/comment/get-likeAndDislike?callId=${id}&commentType=${type}`)
  return response
}

const getCustomerSuggestions = async (data) => {
  const response = await apiCall.post(`/odio/api/call/customerAutoSuggestion?customerName=${data}`)
  return response
}


export default {
  getCallDetailStats,
  createCallDetailComments,
  getCallDetailComments,
  getTeamMemberStats,
  getTeamMemberMoments,
  getTeamWordCloud,
  getTopThreeCalls,
  getAgentRecommendation,
  getTeamComment,
  getCallDetailQuestions,
  getCallDetailMoments,
  getCallDetailSignals,
  getCallDetailActions,
  getCallDetailFoul,
  getCallDetailTranscript,
  getCallDetailSummary,
  getCallDetailPointer,
  getCallDetailKeyword,
  getCallDetailFeedback,
  getEmailTranscript,
  getCallTime,
  addLikeDislike,
  getLikeDislike,
  getCustomerSuggestions
}