import React from "react";

import General from "../CompanySettings/Setting/General";

class Settings extends React.Component {
  render() {
    return (
      <div className="page-wrapper" data-simplebar="true">
        <div className="page-content account-details-page">
          <div className="row row-cols-1 row-cols-md-1 row-cols-lg-2 row-cols-xl-2">
            <div className="col-md-12">
              <div className="card">
                <div className="card-body call-details-box">
                  <div className="d-flex">
                    <div className="px-0">
                     
                      <General />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Settings;
