import { apiCall } from "./Interceptor";

const SAVE_FIREBASE_TOKEN = "/odio/api/notification/save-firebase-token"
const GET_NOTIFICATIONS = "/odio/api/notification/get-all"
const CREATE_NOTIFICATIONS = '/odio/api/notification/create'

const firebaseToken = async(data,val)=>{
        const response = await apiCall.post(SAVE_FIREBASE_TOKEN+"?fireBaseToken="+data+"&deviceType="+val)
        console.log(response)
        return response
}

const getAllNotifications = async(pageno) =>{
     const response = await apiCall.get(`${GET_NOTIFICATIONS}?pageNo=${pageno}&pageSize=10`)
     return response
}

const createNotifications = async(data)=>{
        const header= {
           "Source":"WEB"
        }
        const response = await apiCall.post(CREATE_NOTIFICATIONS,data,{headers:header})
        return response
}

const readNotifications = async(ids)=>{
        const response = await apiCall.put('/odio/api/notification/read-status?notificationIds',ids)
        return response
}

const getRedirectedData = async(val)=>{
        const response = await apiCall.post('/odio/api/notification/callDetails',val)
        return response
}

export default {firebaseToken, getAllNotifications, createNotifications, readNotifications, getRedirectedData}