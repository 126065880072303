import React, { useEffect, useState, useLayoutEffect } from "react";
import { SETTINGS_COMPONENT_TYPE } from "../../../Constants/Enum";
import ReactTable from "../../Commons/Table/ReactTable";
import reportConfigService from "../../Services/reportConfig.service";
import coeService from "../../Services/coe.service";
import dashboardService from "../../Services/dashboard.service";
import Filter from "../../Filter/Filter";
import PaginationAlter from "../../Commons/Table/PaginationAlter";
import Loading from "../../Commons/Loading";
import NodataFound from "../../Commons/NoDataFound";
import userService from "../../Services/user.service";

export default function ManageSettingReportColumn(props) {
  const [reportList, setReportList] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [defaultFilteredData, setDefaultFilteredData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [availablePages, setAvailablePages] = useState("");
  const [loader, setLoader] = useState(false);
  const [coeList, setCoeList] = useState([]);
  const [userList, setUserList] = useState();

  const [dateList, setDateList] = useState([]);
  const storedData = JSON.parse(localStorage?.getItem("AGENT"));
  const convertDate = (date) => {
    console.log(date);
    let newDate = date.split("T");
    console.log(newDate);
    return `${newDate[0].split("-")[2]}-${newDate[0].split("-")[1]}-${newDate[0].split("-")[0]
      }`;
  };
  const onIncludeEmployee = (d) => {
    let array = [];
    console.log("SENTTO", d.sentTo, userList)
    d?.sentTo
      ?.split(",")
      .map((m) => {
        return parseInt(m);
      })
      .forEach((x) => {
        let user = userList?.find(y => y?.id === x)
        if (user) {
          array.push(user.firstName);
        }
      });

    console.log("user", array);
    return (
      array.join(", ")
    );
  };
  const column = [
    {
      Header: "Report Type",
      accessor: (d) => `${d.reportName}`,
    },
    {
      Header: "Frequency",
      accessor: (d) => `${d.reportFrequencyType}`,
    },
    {
      Header: "COE Name",
      accessor: (d) =>
        `${props.coeList.find((x) => x.coeId === d.coeId)?.coeName || "-"}`,
    },
    {
      Header: "Report Time",
      accessor: "triggerTime",
    },
    {
      Header: "Day",
      accessor: (d) => `${d.weeklyDay ? d.weeklyDay : "-"}`,
    },
    {
      Header: "Date",
      accessor: (d) => `${d.triggerDate ? d.triggerDate : "-"}`,
    },
    {
      Header: "From Date",
      accessor: (d) => `${d.customFromDate ? d.customFromDate : "-"}`,
    },
    {
      Header: "To Date",
      accessor: (d) => `${d.customToDate ? d.customToDate : "-"}`,
    },
    {
      Header: "Include Employees",
      accessor: (d) => `${onIncludeEmployee(d)}`,
    },
    // {
    //     Header:"Action",
    //     accessor:'action',
    //     sticky: "right",
    //   disableSortBy: true,
    //   Cell: (d) => (
    //     <p className="mb-0 text-center d-inline">
    //       <a
    //         type="button"
    //         className="edit-Emp-member"
    //         onClick={() => (d
    //             // props.setEditData(d)
    //             // console.log("EDITCLICK",d.data)
    //             )}
    //       >
    //         <i className="lni lni-pencil mx-1" data-tip="Edit User"></i>
    //       </a>
    //     </p>
    //   ),
    // }
  ];

  const callBack = (e) => {
    setCurrentPage(e);
  };

  const filterData = (e) => {
    console.log(e);
    setFilteredData(e);
  };

  const defaultFilter = (e) => {
    setDefaultFilteredData(e);
  };

  const getCoes = () => {
    coeService.getCOEList().then((res) => {
      setCoeList(res.data.data);
    });
  };
  const getReport = () => {
    reportConfigService.gettriggeredReports().then((res) => {
      console.log("RES", res);
      if (res) {
        setLoader(false)
        props.setReportList(res.data.data);
      }
    });
  };
  useEffect(() => {
  //   reportConfigService.gettriggeredReports().then((res) => {
  //     console.log("RES", res);
  //     props.setReportList(res.data);
  //   });

  //   coeService.getCOEList().then((res) => {
  //     console.log("COELIST", res);
  //     setCoeList(res ? res.data.data : "");
  //   });
    userService.getUsers().then((res) => {
      console.log("USERLIST", res);
      setUserList(res ? res.data.data : "");
    });
  //   if (props.reportList.length > 0) {
  //     getReport()
  //   }
  }, []);


  // useEffect(() => {
  //   //   setLoader(true)
  //   //       if(!storedData?.coe&&!storedData?.fromD&&!storedData?.toD&&JSON.parse(localStorage.getItem("USER_DETAIL"))?.userRole=='ADMIN'){
  //   // return null
  //   //       }
  //   //       else if(!storedData?.coe&&!storedData?.fromD&&!storedData?.toD&&JSON.parse(localStorage.getItem("USER_DETAIL"))?.userRole=='AGENT'){
  //   //     return null  
  //   //     }
  //   //       else{
  //   //           getReport()
  //   //       }
  //   if (props.reportList.length > 0) {
  //     getReport()
  //   }

  // }, [])

  console.log(dateList);
  console.log("AAAAAAAAAAAAAAAAAAAAAAAAAAA");
  return (
    <>
      {loader ? (
        <div className="loader-container">
          <Loading variant="dark" />
        </div>
      ) : props.reportList.length > 0 ? (
        <div className="view-team-card-details reports">
          <div className="table-responsive dataTable-style react-table">
            <ReactTable
              columns={column}
              data={props.reportList}
              componentType={SETTINGS_COMPONENT_TYPE.MANAGE_SETTING_REPORTS}
              handlePageIndex={(e) => { console.log() }}
            // isDelete={true}
            />
          </div>
        </div>
      ) : (
        <NodataFound />
      )}
      {/* <Filter
        componentType="REPORTS"
        filterData={filterData}
        defaultFilterData={defaultFilter}
      /> */}
      {/* {reportList.length > 0 && availablePages.length > 0 ? (
        <PaginationAlter callBack={callBack} availablePages={availablePages} />
      ) : (
        ""
      )} */}
    </>
  );
}
