import React, { useEffect, useLayoutEffect } from "react";
import { useState } from 'react';
import RatingGroup from "./RatingGroup";
import "../ManageMoments/manageMoments.css";
import "./Rating.css";
import Loading from "../../Commons/Loading";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import ratingService from "../../Services/rating.service";

function ManageRatings(props) {
    const[selectedRating, setSelectedRating] = useState('User')
    const[addRatings, setAddRatings] = useState(false)
    const[isDeleted, setIsDeleted] = useState(false)
    
    const[ratingDetails, setRatingDetails] = useState(props.ratingDetails)
    const[loader, setLoader] = useState(true)
    
    const getRatingList = () =>{
        console.log(loader)
        if(loader){
            console.log(selectedRating)
            ratingService.getRatingsData(selectedRating).then(res => {
                console.log(res);
                setRatingDetails(res);
                setLoader(false);
            }).catch(error =>{
                setLoader(false);
            })
        }
    }
    useEffect(() =>{
        if(loader){
            console.log('moment');
        getRatingList();
        }
    },[selectedRating])

    const handelRatingType = (e) =>{
        console.log(e);
        setLoader(true);
        setSelectedRating(e.target.textContent);
        //getRatingsData(e.target.textContent);
        
    }
    

    const addRating = () =>{
        setAddRatings(true);
    }

    function deleteRating(e){
        ratingService.deleteRating(e).then(res => {
            console.log(res);
            //setLoader(true);
            if(res.data.status === 200){
                console.log(selectedRating)
                ratingService.getRatingsData(selectedRating).then(res => {
                    console.log(res);
                    setRatingDetails(res);
                    setLoader(false);
                }).catch(error =>{
                    setLoader(false);
                })
            }
            
        })
    }

    return (
    <> {isDeleted?setLoader(true):''}
      {props.loading ? <div className="loader-container"><Loading variant="dark"/></div> :
        <div id="moment-profile" className="">
            <div className="manage-moment-head mb-2">
                <div className=" px-2">
                    <div className="row top-fixed-box">
                        <div className="col-md-12 marginM-0">
                            <div className=" mb-3 w-50 m-auto rating-font">
                                <Autocomplete
                                    disablePortal
                                    disableClearable
                                    id="combo-box-demo"
                                    options={['User', 'COE', 'Sale Call', 'Support Call']}
                                    value={selectedRating} 
                                    onChange={handelRatingType}
                                    renderInput={(params) => <TextField name="role" {...params}  value=''  label=''/>}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            { loader?  <div className="loader-container"><Loading variant="dark"/></div> :
            <div className="rating">

            {selectedRating?ratingDetails?
                ratingDetails.length>0?
                    ratingDetails.map(group =>
                    (   <>
                        {/* {console.log(group)} */}
                        <RatingGroup parameters={props.parameters} deleteRating={deleteRating}  ratingData={group} ratingDetails={ratingDetails} /></>)
                        )
                        :''
                        : '': ''
            }
               
                {
                    addRatings?<RatingGroup parameters={props.parameters} setAddRatings={setAddRatings} setLoader={setLoader} setRatingDetails={setRatingDetails} entityType={selectedRating.toUpperCase().replace(" ", "_")} />
                    :<div className="text-center">
                        <button className="btn text-primary-blue add-moment-btn font-16" onClick={addRating}>Add Rating</button>
                    </div>
                }
                
            </div>
            }
        </div>
        }
     </>
    )
}


export default ManageRatings;