import React, { useEffect, useState } from "react";
import { apiCall } from "../Services/Interceptor";
import { useHistory } from "react-router-dom";

export default function SlackIntegration() {
  const [resMessage, setResMessage] = useState('');
  const [isSuccess,setIsSuccess] = useState(false)

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const params = urlParams.get("code");
  const history = useHistory();
  console.log("code_____>>>",params);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await apiCall.post("/odio/api/slack/create/appCode?appCode=" + params);
        console.log(response);
        if (response.data.status === 200) {
          console.log("Success");
          setIsSuccess(true);
          redirectToSettings();
        }
      } catch (error) {        
        setResMessage(error.data.message);
        redirectToSettings();
      }
    };
    fetchData();

  }, []);

  const redirectToSettings = () => {
    setTimeout(() => {
      history.push("/setting");
    }, 3000);
  };

  return (
    <>
      {isSuccess ? (
        <h1 style={{ marginTop: "20%", marginLeft: "25%" }}>
          Slack integration successful
        </h1>
      ) : (
        <h1 style={{ marginTop: "20%", marginLeft: "25%" }}>{resMessage}</h1>
      )}
    </>
  );
}
