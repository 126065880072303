
import { Card } from "react-bootstrap";
import React,{ useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import Stats from "../Sales/Stats/Stats";
import Moments from "../Sales/Moments";
import Signals from "../../Conversations_Dashboard/Sales/Signals/Signals"
import Questions from "../Questions/Questions";
import Actions from "../Actions/Actions";
import Transcript from "../Transcript/Transcript";
import Feedbacks from "../../Conversations_Common/Feedbacks/Feedbacks";
import { Button } from "react-bootstrap";
import MoreInfo from "../Sales/Modals/MoreInfo";
import FoulLanguage from "../FoulLanguage/FoulLanguage";
import "../Sales/SalesCallDetail.css"
import callsService from "../../Services/calls.service";
import { apiCall } from "../../Services/Interceptor";
import Loading from "../../Commons/Loading";
import AudioPlayer from "../AudioPlayer";
import { useHistory } from "react-router-dom";
import NodataFound from "../../Commons/NoDataFound";
import { Summary } from "../Sales/Summary";
import Pointers from "../Sales/Pointers";
import { Keywords } from "../Sales/Keywords";
import { useDispatch, useSelector } from "react-redux";
import { setCallScore } from "../../../redux-container/callList/actions";

const VoiceDetail = ()=>{
    const [key, setKey] = useState("stats");
    const [show, setShow]= useState(false)
    const [loader, setLoader] = useState(false)
    const [callStats, setCallStats] = useState([])
    const [callQuestions, setCallQuestions] = useState([])
    const [startTime, setStartTime] = useState()
    const [time, setTime] = useState("")
    const [callTime, setCallTime] = useState([])
    const [foulLanguage, setFoulLanguage] = useState([])
    const [callMoments, setCallMoments] = useState([])
    const [callTranscript, setCallTranscript]= useState([])
    const [callSummary, setCallSummary] = useState([]);
    const [pointerData, setPointerData] = useState([])
    const [keywordsCount, setKeywordsCount] = useState([]);
    const [callFeedback, setCallFeedback] = useState([])
    const [callAction, setCallAction] = useState([])
    const [callSignals, setCallSignals] = useState([])
    const [customerName,setCustomerName] = useState("")
    const [sellerName, setSellerName] = useState("")
    const [fileName, setFileName] = useState("")
    const [likeDislikeData, setLikeDislikeData] = useState([])
    const history =  useHistory()
    const [historyObject, setHistoryObject] = useState("")
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const params = urlParams.get('callId')
    const talkDuration = urlParams.get('duration')
    const notifPostId = urlParams?.get("postId");
    const dispatchAction = useDispatch()
    const storedCallScore = useSelector(state=>state?.callList?.callScore)
    // const recUrl = urlParams.get('url').split("/")
    const recUrl = []
    const [flag, setFlag] = useState(true);
  
    let url = historyObject?.url
    const date = urlParams.get('date')
    const sellerId = urlParams.get('id')
    const coeName = urlParams.get('coe')
    const coeIds = historyObject?.coeId
    const seller = urlParams.get('seller')
    const customer = urlParams.get('customer')
    const typeOfCall = urlParams.get('name')
    const tabRedirected = urlParams.get("tabSelected");
    const eventType = urlParams?.get("eventType");
    
    let userDetail = JSON.parse(localStorage.getItem("USER_DETAIL"))?.clientConfig?.data?.conversationListing

    let showSummary = JSON.parse(localStorage.getItem("USER_DETAIL"))?.clientConfig?.data?.conversationCallDetail?.summary
  let showPointers = JSON.parse(localStorage.getItem("USER_DETAIL"))?.clientConfig?.data?.conversationCallDetail?.pointer
  let showKeywords = JSON.parse(localStorage.getItem("USER_DETAIL"))?.clientConfig?.data?.conversationCallDetail?.keywords
  // const [audioData, setAudioData] = useState('')
  // const [isLoading, setIsLoading] = useState(true)

    const onClickHandler=()=>{
        setShow(true)
    }
    const closeButton=()=>setShow(false)

    // useEffect(()=>{
    //   const getAudio = async (data) => {
    //     try{
    //     const response = await apiCall.get(`odio/api/internal/stream?fileName=${data}`, { responseType: 'arraybuffer' })
    //     const blob = new Blob([response.data], { type: 'audio/mp3' });
    //     const dataUrl = URL.createObjectURL(blob);
    //     setAudioData(dataUrl)
    //     setIsLoading(false);
    //   }catch{
    //     setIsLoading(false);
    //     toaster.error('Unable to fetch the Audio')
    //   }}
    //   if(historyObject?.fileName !== undefined){
    //   getAudio(historyObject?.fileName)
    //   // setApiCalll(false)
    // }},[historyObject?.fileName])

    useEffect(() => {
      setLoader(true)
      setHistoryObject(history.location.state)
      if(key=='stats'){
        let coeIds = JSON.parse(localStorage.getItem("AGENT"))?.selectedCoeRange
        callsService.getCallDetailStats(params, typeOfCall).then((res) => {
          setLoader(true)
          if(res.status==200)
          {
            setCallStats(res.data.data)
            dispatchAction(setCallScore(res?.data?.data?.callScore?.value))
            setLoader(false)
            
          }
        })
        if(typeOfCall=='SINGLE_CALL'){
          callsService.getCallTime(params).then(response=>{
              if(response){
                setCallTime(response.data.data)
                setFileName(response.data.data.recordingUrl)
                setLoader(false)
                // console.log(response,"scxhscxhsagx")
              }
          })
        }
        callsService.getLikeDislike(params,handlePayload()).then(res=>{
          if(res){
            setLikeDislikeData(res.data.data)
          }
          tabRedirected && flag && setFlag(false);
        }).catch(err => {
          tabRedirected && flag && setFlag(false);
        });
      }   
    },[key=='stats'])

    useEffect(()=>{
      handleSelect(tabRedirected)
    },[tabRedirected])

    let handleSwith = (check) => {
      let val = '';
      switch (check) {
        case "stats":
          val = "STATS";
          break;
        case "callMoments":
          val = "MOMENTS";
          break;
        case "signals":
          val = "SIGNALS";
          break;
        case "transcript":
          val = "TRANSCRIPT";
          break;
        case "summary":
          val = "SUMMARY"
          break;
        case "pointers":
          val = "pointers";
          break;
        case "actions":
          val = "ACTIONS";
          break;
        case "questions":
          val = "QUESTIONS";
          break;
        case "foulLanguage":
          val = "FOUL_LANGUAGE";
          break;
        case 'keywords':
          val = "KEYWORDS";
        default:
          val = ''
          break;
      }
      return val;
    }
    function handlePayload() {
      if (tabRedirected && flag) {
        return handleSwith(tabRedirected)
      } else return handleSwith(key)
    }
    
    const handleMomentData = async (isLoader = true) => {
      isLoader && setLoader(true);
          let response = await apiCall.post("/odio/api/call/call-detail/moments",{[`${typeOfCall=='SINGLE_CALL'?"callId":"groupId"}`]:parseInt(params),"fromD":JSON.parse(localStorage.getItem("AGENT"))?.fromD,"toD":JSON.parse(localStorage.getItem("AGENT"))?.toD,"coeIds":coeIds ? coeIds : JSON.parse(localStorage.getItem("AGENT"))?.selectedCoeRange})
          if(response){
              setCallMoments(response.data.data)
              isLoader && setLoader(false)
              callsService.getLikeDislike(params,"MOMENTS").then(res=>{
                if(res){
                  setLikeDislikeData(res.data.data)
                }
              })
          }
    }
  

    const handleSelect = async (keyVal) => {
      if (key !== keyVal) {
        setKey(keyVal);
        if(keyVal=='transcript'){
          setLoader(true)
          callsService.getCallDetailTranscript(params).then(response=>{
            if(response.status==200){
              setCallTranscript(response.data.data)
              setCustomerName(response?.data?.data?.customerName)
              setSellerName(response?.data?.data?.sellerName)
              setLoader(false)
              callsService.getLikeDislike(params,"TRANSCRIPT").then(res=>{
                if(res){
                  setLikeDislikeData(res.data.data)
                }
              })
            }
          }).catch(()=>{
            setLoader(false)
          })
        }
        if(keyVal=='summary'){
          setLoader(true);
         callsService.getCallDetailSummary(params).then((response) => {
          if (response) {
            console.log(response.data.data[0].summary.summary,"summdata")
            setCallSummary(response.data.data[0].summary.summary)
            setLoader(false)
          }
        }).catch(()=>{
          setLoader(false)
        })
        }
        if(keyVal == 'pointers'){
          setLoader(true)
          callsService.getCallDetailPointer(params).then((response)=> {
            if (response) {
              setPointerData(response.data.data[0])
              setLoader(false)
            }
          }).catch(()=>{
            setLoader(false)
          })
        }
        if(keyVal == 'keywords'){
          setLoader(true);
          callsService.getCallDetailKeyword(params).then((response)=>{
            if (response) {
              setKeywordsCount(response.data.data[0])
              setLoader(false)
            }
          }).catch(()=>{
            setLoader(false)
          })
        }
        if(keyVal=='questions'){
          setLoader(true)
          callsService.getCallDetailQuestions(params).then(response=>{
            if(response){
              setCallQuestions(response.data.data)
              console.log(response)
              setLoader(false)
              callsService.getLikeDislike(params,"QUESTIONS").then(res=>{
                if(res){
                  setLikeDislikeData(res.data.data)
                }
              })
            }
          }).catch(()=>{
            setLoader(false)
          })
        }
    
        if(keyVal=='foulLanguage'){
          setLoader(true)
          callsService.getCallDetailFoul(params).then(response=>{
            if(response){
              setFoulLanguage(response.data.data)
              setLoader(false)
              callsService.getLikeDislike(params,"FOUL_LANGUAGE").then(res=>{
                if(res){
                  setLikeDislikeData(res.data.data)
                }
              })
            }
          }).catch(()=>{
            setLoader(false)
          })
        }
    
        if(keyVal === 'callMoments')
        {
          handleMomentData();
        }
    
        if(keyVal=='feedback'){
          setLoader(true)
          callsService.getCallDetailFeedback(params).then(res=>{
            if(res){
              console.log(res)
              setCallFeedback(res.data.data)
              setLoader(false)
            }
          }).catch(()=>{
            setLoader(false)
          })
        }
    
        if(keyVal=='actions'){
          setLoader(true)
          callsService.getCallDetailActions(params).then(res=>{
            if(res){
              console.log(res)
              setCallAction(res.data.data)
              setLoader(false)
              callsService.getLikeDislike(params,"ACTIONS").then(res=>{
                if(res){
                  setLikeDislikeData(res.data.data)
                }
              })
            }
          }).catch(()=>{
            setLoader(false)
          })
          
        }
    
        if(keyVal=='signals'){
          setLoader(true)
          callsService.getCallDetailSignals(params).then(res=>{
            if(res){
              console.log("signalsssssss",res)
              setCallSignals(res.data.data)
              setLoader(false)
              callsService.getLikeDislike(params,"SIGNALS").then(res=>{
                if(res){
                  setLikeDislikeData(res.data.data)
                }
              })
            }
          }).catch(()=>{
            setLoader(false)
          })
        }
      }
    }

    const audioTime = (e) => {
      // console.log(e);
      // let a = e.split(":");
      // var seconds = +a[0] * 60 * 60 + +a[1] * 60 + +a[2];
      let totalSeconds;
      if (!e.includes(":")) {
        totalSeconds = parseInt(e.split('.')[1])       
      } else {
        let a = e.split(":");
        totalSeconds = +a[0] * 60 * 60 + +a[1] * 60 + +a[2];
      }
      let element = document.getElementById("audio-element");
      if (element) {
      element.currentTime = totalSeconds;
      element.play();
      }else {
        console.error("Audio element not found");
      }
      // document.getElementsByClassName('page-content')[1].scrollLeft();
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    };

    const callBack = (e)=>{
      console.log(e)
      setTime(e)
    }

    useEffect(() => {
        document.title = "Voice Details - Odio"
    }, []); 

    return(
      <div className="page-wrapper">
        <div className="page-content">
          <div className="row row-cols-1 row-cols-md-2 row-cols-xl-4">
            <div className="col-12">
              <div className="row">
                <div className="col-md-12">
                  <div className="d-flex justify-content-between py-3">
                    <h2 className="dashboard-headings py-2">
                      {`${typeOfCall=='SINGLE_CALL'?'Call Id':'Group Id'} #${params}`}
                      <Button
                      
                      className="btn-mdl py-1 ms-2"
                      onClick={onClickHandler}
                      >
                      More Info.
                      </Button>
                    </h2>
                    <div className="detail-top-head text-end">
                        {userDetail.includes("CALL SCORE")?<span className="badge rounded-pill btn btn-outline-success px-2 my-1 py-2 font-14">
                        Call Score {callStats ? storedCallScore : "0"}%
                        </span>:""}
                        {userDetail.includes("PITCH SCORE")?<span className="badge rounded-pill btn btn-outline-danger px-2 my-1 py-2 font-14">
                        Pitch Score {callStats ? callStats?.pitchScore?.value : '0'}%
                        </span>:""}
                        {userDetail.includes("CONVERSION PROBABILITY")?<span className="badge rounded-pill btn btn-outline-warning px-2 my-1 py-2 font-14">
                        Conv. Probability {callStats? callStats?.convProbability?.value : '0'}%
                        </span>:""}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {typeOfCall=='SINGLE_CALL'?<div className="col-12">{console.log(fileName,historyObject?.url,"scxhscxhsagx")}
              <AudioPlayer recUrl={fileName} startTime={startTime} callBack={callBack} callTranscript={callTranscript} talkDuration={callTime.duration} callTime={callTime} audioName={historyObject?.fileName} 
              // audioData={audioData} isLoading={isLoading}
              />
            </div>:""}
            <div className="col-12">
                  <Card>
                      <Card.Body className="p-5">
                        <div className="setting-section-nev">
                          <Tabs
                            style={{width:'103%'}}
                            id="controlled-tab-example"
                            activekey={key} 
                            defaultActiveKey={tabRedirected && eventType != 'CALL' ? tabRedirected : "stats"}
                            onSelect={handleSelect}
                          >
                            <Tab eventKey="stats" title="STATS" className="voice-section">
                            {callStats?Object.keys(callStats)?.length > 0?<Stats callStats ={callStats} tabSelected={key} loader={loader} callId={params} fileName={historyObject?.url} agentExternalId={historyObject?.agentExternalId} phone={historyObject?.phone} likeDislikeData={likeDislikeData} typeOfCall={typeOfCall}/>:<div className="loader-container"><Loading variant="dark"/></div>:<NodataFound/>}
                            </Tab>
                            <Tab eventKey="callMoments" title="MOMENTS">
                            <Moments handleMomentData={handleMomentData} callMoments= {callMoments} tabSelected={key}  loader={loader} callId={params} fileName={fileName} agentExternalId={historyObject?.agentExternalId} likeDislikeData={likeDislikeData} phone={historyObject?.phone} typeOfCall={typeOfCall} audioTime={audioTime}/>
                            </Tab>
                            {typeOfCall=='SINGLE_CALL'?<Tab eventKey="signals" title="SIGNALS">
                            <Signals callSignals={callSignals} tabSelected={key} customerName={customerName} sellerName={sellerName} loader={loader} callId={params} fileName={fileName} agentExternalId={historyObject?.agentExternalId} likeDislikeData={likeDislikeData} phone={historyObject?.phone} typeOfCall={typeOfCall}/>
                            </Tab>:""}
                            {typeOfCall=='SINGLE_CALL'?<Tab eventKey="questions" title="QUESTIONS">
                            <Questions callQuestions={callQuestions} tabSelected={key}  loader={loader} callId={params} customerName={customerName} sellerName={sellerName} fileName={fileName} agentExternalId={historyObject?.agentExternalId} phone={historyObject?.phone} likeDislikeData={likeDislikeData} typeOfCall={typeOfCall}/>
                            </Tab>:""}
                            {typeOfCall=='SINGLE_CALL'?<Tab eventKey="foulLanguage" title="FOUL LANGUAGE"> 
                            <FoulLanguage foulLanguage={foulLanguage} tabSelected={key}  loader={loader} callId={params} fileName={fileName} agentExternalId={historyObject?.agentExternalId} phone={historyObject?.phone} likeDislikeData={likeDislikeData} typeOfCall={typeOfCall}/>
                            </Tab>:""}
                            {typeOfCall=='SINGLE_CALL'?<Tab eventKey="transcript" title="TRANSCRIPT">
                            <Transcript callTranscript={callTranscript} tabSelected={key} loader={loader} callId={params} url={url} fileName={fileName} agentExternalId={historyObject?.agentExternalId} phone={historyObject?.phone} likeDislikeData={likeDislikeData} typeOfCall={typeOfCall} postId={notifPostId} audioTime={audioTime}/>
                            </Tab>:""}
                            {showSummary && typeOfCall=='SINGLE_CALL'?<Tab eventKey="summary" title="SUMMARY">
                            <Summary callSummary={callSummary} loader={loader} />
                            </Tab>:""}
                            {showPointers && typeOfCall=='SINGLE_CALL'?<Tab eventKey="pointers" title="POINTERS">
                            <Pointers callPointer={pointerData} loader={loader} />
                            </Tab>:""}
                            {showKeywords && typeOfCall=='SINGLE_CALL'?<Tab eventKey="keywords" title="KEYWORDS">
                            <Keywords keywordsCount={keywordsCount} loader={loader} />
                            </Tab>:""}
                            {typeOfCall=='SINGLE_CALL'?<Tab eventKey="actions" title="ACTIONS">
                            <Actions callAction={callAction} tabSelected={key} loader={loader} callId={params} customerName={customerName} sellerName={sellerName} fileName={fileName} agentExternalId={historyObject?.agentExternalId} typeOfCall={typeOfCall} likeDislikeData={likeDislikeData}/>
                            </Tab>:""}
                            <Tab eventKey="feedback" title="FEEDBACK">
                            <Feedbacks callFeedback={callFeedback} loader={loader} agentExternalId={historyObject?.agentExternalId}/>
                            </Tab>
                          </Tabs>
                        </div>
                      </Card.Body>
                  </Card>
              </div>
          </div>
        </div>
        {
          // customerName&&sellerName!==[]&&
        <MoreInfo show={show} setShow={setShow} closeButton={closeButton} customerName={historyObject?.customer} sellerName={historyObject?.seller} talkDuration={historyObject?.duration} date={historyObject?.date} processTime={historyObject ? historyObject?.processingTime : callTime?.more_info?.processingTime} url={url} sellerId={historyObject?.id} coeName={historyObject?.coe} typeOfCall={typeOfCall} userId={historyObject?.userId}/>}
      </div>
    )
}

export default VoiceDetail