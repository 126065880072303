import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux';
import { SIGN_OUT } from '../redux-container/login/types';
import background from "../assets/images/login-images/forgot-password.png"
import odio_logo_blue from "../assets/images/odio_logo_blue.png";

function BalicOnboard() {
    const [inputs, setInputs] = useState('');
    const dispatch = useDispatch()
    let btnRef = useRef();

    useEffect(()=>{
        localStorage.clear();
        sessionStorage.clear();
        dispatch({ type: SIGN_OUT, payload: false})
        document.title = "Balic OnBoard"
    },[])

    function handleChange(e) {
        setInputs(e.target.value);
      }

    async function handleSubmit(e) {
        e.preventDefault();
        if (btnRef.current) {
            btnRef.current.setAttribute("disabled", "disabled");
        }
        const url = `https://app.odioiq.com/odio/balic_onboard?employeeId=${inputs}`;
        window.location.href = url;
    }

  return (
    <>
      <div className="wrapper forget-content">
        <div className="section-authentication-signin d-flex justify-content-center my-5 my-lg-0">
          <div className="container-fluid">
            <div className="row row-cols-1 row-cols-lg-2 row-cols-xl-3">
              <div className="col mx-auto">
                <div className="card mb-5">
                  <div className="card-body">
                    <div className="p-4 rounded">
                      <div className="text-center mb-4 p-0">
                        <a className="navbar-brand" href="#">
                          <img src={odio_logo_blue} width={140} alt="" />
                        </a>
                      </div>
                        <div className="form-body">
                          <form className="row g-3" onSubmit={handleSubmit} >
                            <h4 className=" font-weight-bold">
                              Balic OnBoarding
                            </h4>
                            <p className="text-muted m-0">
                              Enter your Employee Id
                            </p>
                            <div className="mb-4">
                              <div className="input-group">
                                {" "}
                                <span className="input-group-text bg-transparent">
                                  <i className="fadeIn animated bx bx-user-plus" />
                                </span>
                                <input
                                  type="text"
                                  name="employeeId"
                                  value={inputs}
                                  onChange={handleChange}
                                  className="form-control form-control-lg"
                                  id="employeeId"
                                  placeholder="Employee ID"
                                />
                              </div>
                            </div>
                            <div className="d-grid gap-2 mt-0">
                              <button
                                type="submit"
                                className="btn btn-lg f-send-btn"
                                ref={btnRef}
                                disabled={!inputs}
                              >
                              Submit
                              </button>
                              <a href="/login" className="btn btn-light btn-lg" style={{color:'#0a58ca'}}>
                                <i className="bx bx-arrow-back me-1" />
                                Back to Login
                              </a>
                            </div>
                          </form>
                        </div> 
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default BalicOnboard