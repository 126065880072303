import React, {useEffect,useState} from 'react'
import CommentOnModal from '../Sales/Modals/CommentOnModal'
import EmailTranscript from '../Sales/Modals/EmailTranscript'
import callsService from '../../Services/calls.service'
import notificationsService from '../../Services/notifications.service'
import toaster from '../../Toast/toaster'

export default function All_FoulLang(props) {
  const [likeButton, setLikeButton] = useState(false)
  const [dislikeButton, setDislikeButton] = useState(false)
  const [mailButton, setMailButton] = useState(false)
  const [commentButton, setCommentButton] = useState(false)
  const [emailShow, setEmailShow] = useState(false)
  const [commentShow, setCommentShow] = useState(false)
  const [foulId, setFoulId] = useState("")
  const [foul, setFoul] = useState("")
  const [speaker, setSpeaker] = useState("")
  const [commentData, setCommentData] = useState([])
  const [likeId, setLikeId] = useState("")
  const [dislikeId, setDislikeId] = useState("")
  const [likeDislike, setLikeDislike] = useState([])

  const likeButtonHandler = (id,label)=>{
    setLikeButton(true)
    setLikeId(id)
    let value={
      "callId": props?.callId,
      "commentType": "FOUL_LANGUAGE",
      "dislike": null,
      "like": 1,
      "postId": id
    }
    callsService.addLikeDislike(value).then(res=>{
      if(res){
        callsService.getLikeDislike(props?.callId,"FOUL_LANGUAGE").then(res=>{
          if(res){
            setLikeDislike(res.data.data)
            setLikeButton(false)
          }
        })
      }
    })
    let notificationBody = [{
			"callId":props?.callId,
			"userExternalId":props?.agentExternalId,
			"mobileNumber":props?.phone,
			"notificationEvent":{
				"eventType":"LIKE",
				"tabName":"foulLanguage",
				"tabValue":label,
				"postId":id,
			}
		}]
    notificationsService.createNotifications(notificationBody).then(res=>{
      if(res){
        console.log(res)
        toaster.success(res.data.message)
      }
    })
  }
  const dislikeButtonHandler = (id,label)=>{
    setDislikeButton(true)
    setDislikeId(id)
    let value={
      "callId": props?.callId,
      "commentType": "FOUL_LANGUAGE",
      "dislike": 0,
      "like": null,
      "postId": id
    }
    callsService.addLikeDislike(value).then(res=>{
      if(res){
        callsService.getLikeDislike(props?.callId,"FOUL_LANGUAGE").then(res=>{
          if(res){
            setLikeDislike(res.data.data)
            setDislikeButton(false)
          }
        })
      }
    })
    let notificationBody = [{
			"callId":props?.callId,
			"userExternalId":props?.agentExternalId,
			"mobileNumber":props?.phone,
			"notificationEvent":{
				"eventType":"DISLIKE",
				"tabName":"foulLanguage",
				"tabValue":label,
				"postId":id,
			}
		}]
    notificationsService.createNotifications(notificationBody).then(res=>{
      if(res){
        console.log(res)
        toaster.success(res.data.message)
      }
    })
  }
  const commentButtonHandler = (id,foul,speaker)=>{
    // setCommentButton(true)
    setCommentShow(true)
    setFoulId(id)
    setFoul(foul)
    setSpeaker(speaker)
  }
  const updateCommentState = ()=>{
    setCommentButton(true)
    setCommentShow(true)
    // setId(id)
    // setStats(label)
  }
  const mailHandler = ()=>{
    setEmailShow(true)
    setMailButton(true)
  }

  const closeButton = ()=>{
    setCommentShow(false)
    setEmailShow(false)
  }

  const callback = (e)=>{
    setCommentData(e)
  }

  console.log(commentData)
  console.log(props?.foulLanguage)

  useEffect(()=>{
    if(props?.eventType=='COMMENT'&&props?.tabRedirected=='foulLanguage'){
      setCommentShow(true)
      setFoulId(props?.postId)
    }
  },[])


  const questionSeller = (seller,data)=>{
    return(
      <>
      {data.map((val,id)=>{
          let transNew = Object.entries(commentData.length>0?commentData[0]?.postTypeComments:props?.foulLanguage[0]?.postTypeComments).filter(value=>{
            return(value[1][0].postId==val.index)
          })
          let ids = transNew.length>0?transNew[0][1]:''
          let likeIcon = likeDislike.length>0?likeDislike.filter(value=>{
            return(value.postId==val.index)
          }):
          props?.likeDislikeData.filter(value=>{
            return(value.postId==val.index)
          })
        return(
          <a class="list-group-item list-group-item-action">
            <div class="d-flex w-100 justify-content-between">
              <h5 class="mb-1 font-16">
                <i class="bx bx-user-voice me-1"></i>
                {seller}
                <span class="badge rounded-pill bg-light-blue text-dark f-normal">seller</span>
              </h5>
              <small class="text-muted">
                <i class="bx bx-time"></i>
                {val.startTime}
              </small>
            </div>
              <>
              <p class="mb-1">
                {val.trans}        
              </p>
              <div class="row">
                <div class="col-6"> 
                  <div class="list-after-click-group">
                    {likeIcon.length>0&& likeIcon[0].like!==null&&likeIcon[0].postId==val.index||likeButton&&likeId==val.index?<button class="btn list-like-after" ><span class=" text-primary-blue"> <i class="bx bx-like"></i></span></button>:
                    likeIcon.length>0&&likeIcon[0].dislike!=null&&likeIcon[0].postId==val.index||dislikeButton&&dislikeId==val.index?<button class="btn list-dislike-after"><span class=" text-primary-blue"> <i class="bx bx-dislike"></i></span></button>:''}
                    {ids&&ids[0].postId==val.index||commentButton&&foulId==val.index?<button class="btn list-comment-after switcher-btn-1" onClick={()=>commentButtonHandler(val.index,val.trans,"seller")} data-bs-toggle="tooltip" data-bs-placement="bottom" title="Comment on Stats"><span class="text-primary-blue"><i class="lni lni-comments-alt"></i></span></button>:''}
                  </div>
                </div>
                <div class="col-6">
                  <div className="list-hover-input-group">
                    {likeIcon.length>0&& likeIcon[0].like!==null&&likeIcon[0].postId==val.index||likeButton&&likeId==val.index? '':<button className="btn list-like-before" onClick={()=>likeButtonHandler(val.index,val.trans)}>
                        <span className="text-primary-blue">
                          <i className="bx bx-like"></i>
                        </span>
                    </button>}
                    {likeIcon.length>0&&likeIcon[0].dislike!=null&&likeIcon[0].postId==val.index||dislikeButton&&dislikeId==val.index? ""
                    :
                    <button className="btn list-dislike-before" onClick={()=>dislikeButtonHandler(val.index,val.trans)}>
                      <span className="text-primary-blue">
                        <i className="bx bx-dislike"></i>
                      </span>
                    </button>}
                    {/* <button className="btn compose-mail-btn" onClick={mailHandler} 
                    data-bs-toggle="tooltip" data-bs-placement="bottom" title="Email Stats">
                      <span className="text-primary-blue">
                        <i className="bx bx-mail-send"></i>
                      </span>
                    </button> */}
                    {ids&&ids[0].postId==val.index||commentButton&&foulId==val.index?'':  
                    <button className="btn list-comment-before switcher-btn-1" 
                    onClick={()=>commentButtonHandler(val.index,val.trans,"seller")}
                    data-bs-toggle="tooltip" data-bs-placement="bottom" title="Comment on Stats">
                      <span className="text-primary-blue">
                        <i className="lni lni-comments-alt"></i>
                      </span>
                    </button>
                    }
                  </div>
                </div>
              </div>
              </>
          </a>
        )
      })}
      </>
    )
  }

  const questionCustomer = (customer,data)=>{
    
    return(
      <>
      {data.map((val,id)=>{
        let transNew = Object.entries(commentData.length>0?commentData[0]?.postTypeComments:props?.foulLanguage[0]?.postTypeComments).filter(value=>{
            return(value[1][0].postId==val.index)
          })
          let ids = transNew.length>0?transNew[0][1]:''
          let likeIcon = likeDislike.length>0?likeDislike.filter(value=>{
            return(value.postId==val.index)
          }):
          props?.likeDislikeData.filter(value=>{
            return(value.postId==val.index)
          })
        return(
        <a class="list-group-item list-group-item-action">
          <div class="d-flex w-100 justify-content-between">
            <h5 class="mb-1 font-16">
              <i class="bx bx-user-voice me-1"></i>
              {customer}
              <span class="badge rounded-pill bg-light-blue text-dark f-normal">customer</span>
            </h5>
            <small class="text-muted">
              <i class="bx bx-time"></i>
              {val.startTime}
            </small>
          </div>  
            <p class="mb-1">
            {val.trans}        
            </p>
            <div class="row">
              <div class="col-6"> 
                <div class="list-after-click-group">
                  {likeIcon.length>0&& likeIcon[0].like!==null&&likeIcon[0].postId==val.index||likeButton&&likeId==val.index?<button class="btn list-like-after" ><span class=" text-primary-blue"> <i class="bx bx-like"></i></span></button>:
                  likeIcon.length>0&&likeIcon[0].dislike!=null&&likeIcon[0].postId==val.index||dislikeButton&&dislikeId==val.index?<button class="btn list-dislike-after"><span class=" text-primary-blue"> <i class="bx bx-dislike"></i></span></button>:''}
                  {/* {mailButton?<button class="btn compose-mail-btn" onClick={mailHandler} data-bs-toggle="tooltip" data-bs-placement="bottom" title="Email Stats"><span class="text-primary-blue"><i class="bx bx-mail-send"></i></span></button>:''} */}
                  {ids&&ids[0].postId==val.index||commentButton&&foulId==val.index?<button class="btn list-comment-after switcher-btn-1" onClick={()=>commentButtonHandler(val.index,val.trans,"customer")} data-bs-toggle="tooltip" data-bs-placement="bottom" title="Comment on Stats"><span class="text-primary-blue"><i class="lni lni-comments-alt"></i></span></button>:''}
                </div>
              </div>
              <div class="col-6">
                <div className="list-hover-input-group">
                  {likeIcon.length>0&& likeIcon[0].like!==null&&likeIcon[0].postId==val.index||likeButton&&likeId==val.index? '':<button className="btn list-like-before" onClick={()=>likeButtonHandler(id,val.trans)}>
                      <span className="text-primary-blue">
                        <i className="bx bx-like"></i>
                      </span>
                  </button>}
                  {likeIcon.length>0&&likeIcon[0].dislike!=null&&likeIcon[0].postId==val.index||dislikeButton&&dislikeId==val.index? ""
                  :
                  <button className="btn list-dislike-before" onClick={()=>dislikeButtonHandler(val.index,val.trans)}>
                    <span className="text-primary-blue">
                      <i className="bx bx-dislike"></i>
                    </span>
                  </button>}
                  {/* <button className="btn compose-mail-btn" onClick={mailHandler} 
                  data-bs-toggle="tooltip" data-bs-placement="bottom" title="Email Stats">
                    <span className="text-primary-blue">
                      <i className="bx bx-mail-send"></i>
                    </span>
                  </button> */}
                  {ids&&ids[0].postId==val.index||commentButton&&foulId==val.index?'':  
                  <button className="btn list-comment-before switcher-btn-1" 
                  onClick={()=>commentButtonHandler(val.index,val.trans,"customer")}
                  data-bs-toggle="tooltip" data-bs-placement="bottom" title="Comment on Stats">
                    <span className="text-primary-blue">
                      <i className="lni lni-comments-alt"></i>
                    </span>
                  </button>
                  }
                </div>
              </div>
            </div>
        </a>
        )
       })}
      </>
    )
  }

  const quesAll = (data)=>{
    return(
      <>
      {data.agent.map((item,id)=>{
        let transNew = Object.entries(commentData.length>0?commentData[0]?.postTypeComments:props?.foulLanguage[0]?.postTypeComments).filter(val=>{
          return(val[1][0].postId==item.index)
        })
        let ids = transNew.length>0?transNew[0][1]:''
        let likeIcon = likeDislike.length>0?likeDislike.filter(val=>{
          return(val.postId==item.index)
        }):
        props?.likeDislikeData.filter(val=>{
          return(val.postId==item.index)
        })
        return(
        <a class="list-group-item list-group-item-action">
          <div class="d-flex w-100 justify-content-between">
            <h5 class="mb-1 font-16">
              <i class="bx bx-user-voice me-1"></i>
              {props.foulLanguage[0].sellerName}
              <span class="badge rounded-pill bg-light-blue text-dark f-normal">{"seller"}</span>
            </h5>
            <small class="text-muted">
              <i class="bx bx-time"></i>
              {item?.startTime}
            </small>
          </div>
            <p class="mb-1">
              {item.trans}        
            </p>
          <div class="row">
            <div class="col-6"> 
              <div class="list-after-click-group">
                {likeIcon.length>0&& likeIcon[0].like!==null&&likeIcon[0].postId==item.index||likeButton&&likeId==item.index?<button class="btn list-like-after" ><span class=" text-primary-blue"> <i class="bx bx-like"></i></span></button>:
                likeIcon.length>0&&likeIcon[0].dislike!=null&&likeIcon[0].postId==item.index||dislikeButton&&dislikeId==item.index?<button class="btn list-dislike-after"><span class=" text-primary-blue"> <i class="bx bx-dislike"></i></span></button>:''}
                {/* {mailButton?<button class="btn compose-mail-btn" onClick={mailHandler} data-bs-toggle="tooltip" data-bs-placement="bottom" title="Email Stats"><span class="text-primary-blue"><i class="bx bx-mail-send"></i></span></button>:''} */}
                {ids&&ids[0].postId==item.index||commentButton&&foulId==item.index?<button class="btn list-comment-after switcher-btn-1" onClick={()=>commentButtonHandler(item.index,item.trans,"seller")} data-bs-toggle="tooltip" data-bs-placement="bottom" title="Comment on Stats"><span class="text-primary-blue"><i class="lni lni-comments-alt"></i></span></button>:''}
              </div>
            </div>
            <div class="col-6">
              <div className="list-hover-input-group">
                {likeIcon.length>0&& likeIcon[0].like!==null&&likeIcon[0].postId==item.index||likeButton&&likeId==item.index? '':<button className="btn list-like-before" onClick={()=>likeButtonHandler(item.index,item.trans)}>
                    <span className="text-primary-blue">
                      <i className="bx bx-like"></i>
                    </span>
                </button>}
                {likeIcon.length>0&&likeIcon[0].dislike!=null&&likeIcon[0].postId==item.index||dislikeButton&&dislikeId==item.index? ""
                :
                <button className="btn list-dislike-before" onClick={()=>dislikeButtonHandler(item.index,item.trans)}>
                  <span className="text-primary-blue">
                    <i className="bx bx-dislike"></i>
                  </span>
                </button>}
                {/* <button className="btn compose-mail-btn" onClick={mailHandler} 
                data-bs-toggle="tooltip" data-bs-placement="bottom" title="Email Stats">
                  <span className="text-primary-blue">
                    <i className="bx bx-mail-send"></i>
                  </span>
                </button> */}
                {ids&&ids[0].postId==item.index||commentButton&&foulId==item.index?'':  
                <button className="btn list-comment-before switcher-btn-1" 
                onClick={()=>commentButtonHandler(item.index,item.trans,"seller")}
                data-bs-toggle="tooltip" data-bs-placement="bottom" title="Comment on Stats">
                  <span className="text-primary-blue">
                    <i className="lni lni-comments-alt"></i>
                  </span>
                </button>
                }
              </div>
            </div>
          </div>
        </a>
      )})}
      {data.customer.map((item,id)=>{
        let transNew = Object.entries(commentData.length>0?commentData[0]?.postTypeComments:props?.foulLanguage[0]?.postTypeComments).filter(val=>{
          return(val[1][0].postId==id)
        })
        let ids = transNew.length>0?transNew[0][1]:''
        let likeIcon = likeDislike.length>0?likeDislike.filter(val=>{
          return(val.postId==id)
        }):
        props?.likeDislikeData.filter(val=>{
          return(val.postId==id)
        })
        return(
        <a class="list-group-item list-group-item-action">
          <div class="d-flex w-100 justify-content-between">
            <h5 class="mb-1 font-16">
              <i class="bx bx-user-voice me-1"></i>
              {props.foulLanguage[0].customerName}
              <span class="badge rounded-pill bg-light-blue text-dark f-normal">{"customer"}</span>
            </h5>
            <small class="text-muted">
              <i class="bx bx-time"></i>
              {item?.startTime}
            </small>
          </div>
            <p class="mb-1">
              {item.trans}        
            </p>
          <div class="row">
            <div class="col-6"> 
              <div class="list-after-click-group">
                {likeIcon.length>0&& likeIcon[0].like!==null&&likeIcon[0].postId==item.index||likeButton&&likeId==item.index?<button class="btn list-like-after" ><span class=" text-primary-blue"> <i class="bx bx-like"></i></span></button>:
                dislikeButton&&dislikeId==item.index?<button class="btn list-dislike-after"><span class=" text-primary-blue"> <i class="bx bx-dislike"></i></span></button>:''}
                {ids&&ids[0].postId==item.index||commentButton&&foulId==item.index?<button class="btn list-comment-after switcher-btn-1" onClick={()=>commentButtonHandler(item.index,item.trans,"customer")} data-bs-toggle="tooltip" data-bs-placement="bottom" title="Comment on Stats"><span class="text-primary-blue"><i class="lni lni-comments-alt"></i></span></button>:''}
              </div>
            </div>
            <div class="col-6">
              <div className="list-hover-input-group">
                {likeIcon.length>0&& likeIcon[0].like!==null&&likeIcon[0].postId==item.index||likeButton&&likeId==item.index||dislikeButton&&dislikeId==item.index? '':<button className="btn list-like-before" onClick={()=>likeButtonHandler(item.index)}>
                    <span className="text-primary-blue">
                      <i className="bx bx-like"></i>
                    </span>
                </button>}
                {dislikeButton&&dislikeId==item.index||likeButton&&likeId==item.index ? ""
                :
                <button className="btn list-dislike-before" onClick={()=>dislikeButtonHandler(item.index)}>
                  <span className="text-primary-blue">
                    <i className="bx bx-dislike"></i>
                  </span>
                </button>}
                {/* <button className="btn compose-mail-btn" onClick={mailHandler} 
                data-bs-toggle="tooltip" data-bs-placement="bottom" title="Email Stats">
                  <span className="text-primary-blue">
                    <i className="bx bx-mail-send"></i>
                  </span>
                </button> */}
                {ids&&ids[0].postId==item.index||commentButton&&foulId==item.index?'':  
                <button className="btn list-comment-before switcher-btn-1" 
                onClick={()=>commentButtonHandler(item.index,item.trans,"customer")}
                data-bs-toggle="tooltip" data-bs-placement="bottom" title="Comment on Stats">
                  <span className="text-primary-blue">
                    <i className="lni lni-comments-alt"></i>
                  </span>
                </button>
                }
              </div>
            </div>
          </div>
        </a>
        )
      })}
      </>
    )
  }

  return (
    <div class="list-group moment-list-group-scroll mb-2">
      {props.foulLanguage?Object.entries(props.foulLanguage).map((item,id)=>{
        console.log(item)
        return(
          <>
            {props.keyClicked=='seller'?
            questionSeller(item[1].sellerName,item[1].abusiveLanguage.agent,id):
            props.keyClicked=='customer'?
            questionCustomer(item[1].customerName,item[1].abusiveLanguage.customer,id):
            props.keyClicked=='all'?
            quesAll(item[1].abusiveLanguage,id)
            :
            ""}
        </>
        )
      }):""}
      {commentShow && foulId!=='' && <CommentOnModal updateState={updateCommentState} commentShow={commentShow} closeButton={closeButton} tabSelected={props?.tabRedirected=='foulLanguage'?props?.tabRedirected:props?.tabSelected} callId={props?.callId} foulId={foulId} foul={foul} foulSpeaker={speaker} phone={props?.phone} agentExternalId={props.agentExternalId} callback={callback}/>}
      <EmailTranscript show={emailShow} closeButton={closeButton} foulLanguage={props.foulLanguage?props.foulLanguage:""} tabSelected={props.tabSelected} callId={props?.callId} agentId={props?.agentId}/>
    </div>
    );
}