import toast from "../Toast/toaster";
import { apiCall } from './Interceptor';

const GET_RATINGS = '/odio/api/client/get-ratings?entityType=USER&dateType=PERCENTAGE&type=NORMAL';
const CREATE_RATING = '/odio/api/client/create-rating';
const UPDATE_RATING = '/odio/api/client/update-rating';

    const getRatings = async () => {
        const response = await apiCall.get(GET_RATINGS);
        return response.data.data;
     }

    const getRatingsData = async (entityType) => {  
        entityType = entityType.toUpperCase().replace(/ /g,"_");
        const GET_RATING_DATA = `/odio/api/client/get-ratings?entityType=${entityType}`
        const response = await apiCall.get(GET_RATING_DATA);
        return response.data.data;   
    }

    const getParameters = async()=>{
        const GET_PARAMETER_DATA = `/odio/api/client/get-parameters?dateType=PERCENTAGE&type=NORMAL`
        const response = await apiCall.get(GET_PARAMETER_DATA);
        return response.data.data;
    }

    const createRating = async(data) =>{
        const response = await apiCall.post(CREATE_RATING, data);
        toast.success(response.data.message)
        return response;
    }

    const updateRating = async(data) =>{
      const response = await apiCall.put(UPDATE_RATING, data);
      toast.success(response.data.message)
      return response;
    }

    const deleteRating = async(Id) =>{
        const DELETE_RATING = `/odio/api/client/delete-rating?ratingId=${Id}`
        const response = await apiCall.put(DELETE_RATING);
        toast.success(response.data.message)
        return response;
    }

  export default{
      getRatings,
      getRatingsData,
      getParameters,
      createRating,
      updateRating,
      deleteRating
  }

