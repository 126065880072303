// import { useState } from "react";
import { Button } from "react-bootstrap";
import { useEffect, useState } from "react";
import {addCOEAction, updateCOEAction, getclientcoeList} from '../../../redux-container/settings/manage_coe/action';
import './manageCoe.css'
import { connect } from "react-redux";
import Loading from '../../Commons/Loading';
import {UPDATE_COE, ADD_COE} from '../../../redux-container/settings/type'
import toast from "../../Toast/toaster";
import coeService from "../../Services/coe.service";
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import * as React from 'react'

function AddNewCoe(props) {
    console.log(props)
    const [inputs, setInputs] = useState({
        coeName: props.selectedRowData? props.selectedRowData.coeName: '' ,
        // externalId : props.selectedRowData? props.selectedRowData.externalId: '',
        coeId : props.selectedRowData? props.selectedRowData.coeId: '' ,
        momentBucketId : props.selectedRowData? props.selectedRowData.momentBucketId : ''
    });

    console.log(inputs.momentBucketId)
    const errors = {};
    const [formError, setFormErrors] = useState({});
    const [message, setMessage] = useState('')
    const [loading, setLoading] = useState(false)
    //const { coeName, externalId, momentBucketId } = inputs;
    const [show, setShow] = useState(true)

    const [items, setItems] = useState([]);
    useEffect(() => {
        setItems([
            { value: "Sales Bucket" },
            { value: "Support Bucket" },
        ])
    }, [])
    const validate = (values) => {

        if (!values.coeName) {
            errors.coeName = "Please enter COE name."
        }
        return errors;
    }

    function handleChange(e) {
        console.log(e)
        const { name, value } = e.target;
        if(props.name === 'Update COE')
        {
            setInputs(inputs => ({ ...inputs,  coeId: props.selectedRowData.coeId }));
        }
        if(e.target.name=='coeName'){
            const alphanumericRegex = /^[a-zA-Z\s]+$/;
            if (alphanumericRegex.test(e.target.value) || e.target.value === '') {
                setInputs(inputs => ({ ...inputs, [name]: value }));
            }
        }
        else{
            setInputs(inputs => ({ ...inputs, [name]: value }));
        }
        
        
    }

    function onCancel()
    {
        if(props.name === "Update COE")
           {
            console.log('Update')
            props.setCoeVisibility(false)
           }
        else{
            console.log('add')
            props.setIsOpen(false)
        }
    }

    function handleSubmit(e) {
        e.preventDefault();
        validate(inputs)
        setMessage('')
        setFormErrors(errors)
        setLoading(true);
        console.log("errors====>", errors)
        
        if (Object.keys(errors).length == 0) {
           if(props.name === 'New COE')
               { 
                    coeService.createCOE(inputs).then((res) =>{
                    console.log(res);
                    if(res.data.status===0){
                        toast.success(res.data.message);
                    }
                    else{
                        toast.error(res.data.message);
                    }
                    props.onAddCOE(inputs)
                    props.getclientCoeList();
                    setLoading(false);
                    props.setIsOpen(false);
                    }).catch(error =>{
                        setLoading(false)
                    if(error.response.status === 400)
                        toast.error(error.response.data.message);
                        // console.log(error.response.data.message);
                    else{
                        toast.error(error.response.data.message);
                        console.log(error.response.data.message);
                    }
                    })
               }
            else
                {
                    coeService.updateCOE(inputs).then((res) =>{
                        console.log(res);
                        props.onCOEUpdate(inputs)
                        toast.success(res.data.message);
                        setLoading(false);
                        props.setCoeVisibility(false) 
                        props.getclientCoeList();
                        return res.data.status;
                        }).catch(error =>{
                            setLoading(false)
                            if(error.response.status === 400){
                                toast.error(error.response.data.message);
                                console.log(error.response.data.message);
                            }
                                else{
                                toast.error(error.response.data.message);
                                console.log(error.response.data.message);
                                }
                        })
                    
                }
        }
    }

    return (

        show &&
        <div className="coe-edit-box mt-3">
            <div className="card-title d-flex align-items-center">
                <div><i className="fadeIn animated bx bx-buildings" />
                </div>
                <h5 className="mb-0 edit-profile-header">{props.name}</h5>
            </div>
            <hr />
            <form className="row g-3">
                <div className="col-md-6">
                    <label htmlFor="editCOEName" className="form-label">COE Name* </label>
                    <div className="input-group"> <span className="input-group-text bg-transparent py-0"><i className="fadeIn animated bx bx-buildings" /></span>
                         <input type="text" name="coeName" value={inputs.coeName} className="form-control" placeholder="COE Name" onChange={handleChange} />
                    </div>
                    <p class="my-1 mb-0 lh-1 font-12" style={{ color: "red",height:'18px' }}><span>{formError.coeName}</span></p>
                </div>        

                {/* <div className="col-md-6">
                    <label htmlFor="editCOEExId" className="form-label">External Id</label>
                    <input type="text" name="externalId" value={inputs.externalId} className="form-control" placeholder="External Id" onChange={handleChange} />
                </div> */}

                <div className="col-md-6">
                    <label htmlFor="inputBucket" className="form-label">Moment Bucket </label>
                    <div>
                        <Select className="w-100" name="momentBucketId" value={inputs.momentBucketId}  onChange={handleChange}>
                            <MenuItem  disabled> Select</MenuItem>
                            {props.coeBucket!=''?props?.coeBucket?.map((bucket, i) => (
                            <MenuItem  value={bucket.momentBucketId}>
                                {bucket.momentBucketName}
                            </MenuItem>
                            )):""} 
                        </Select>
                    </div>
                    {/* <select className="form-select" name="momentBucket"   onChange={handleChange}>
                        <option value={inputs.momentBucketId}>Select</option>
                        {items.map(bucket => (
                            <option  value={bucket.value}>
                                {bucket.value}
                            </option>
                        ))} 
                    </select> */}
                </div>
                <div className="col-12 text-sm-end cancelUpadte-btn mt-4 pt-2">
                    <Button className=" px-4 cancel-btn lh-base" variant="light" onClick={onCancel} ><i class="bx bx-arrow-back me-1"></i>Cancel</Button>
                    <Button className="update-btn px-4 lh-base border-0" onClick={handleSubmit} >{loading ? <Loading variant="light"/> : <> Submit</>}</Button>
                </div>
            </form>
        </div>

    )
}

const mapStateToProps = (state) =>{
    return{
       action_success : state.manage_coe.success,
    }
}

const mapDispatchToProps = (dispatch) =>{
    return{
        addCOEAction: (newData) => {dispatch(addCOEAction(newData))},
        updateCOEAction: (updatedData) => {dispatch(updateCOEAction(updatedData))},
        getclientCoeList: () => {dispatch(getclientcoeList())}, 
        onCOEUpdate:  (inputs) => dispatch({type:UPDATE_COE, payload: inputs }),
        onAddCOE: (inputs) => dispatch({type:ADD_COE, payload: inputs})
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddNewCoe);