import React, { useEffect, useState } from 'react'
import { Card, Col, ListGroup, Nav, Row, Tab } from 'react-bootstrap'
import Loading from '../../Commons/Loading'

const Pointers = (props) => {
    const {callPointer, loader} = props
    const [keyClicked ,setKeyClicked] =  useState("positive")
    const [pointerData, setPointerData] =  useState(keyClicked === "positive" ? callPointer?.positivePointer : callPointer?.negativePointer || [])
    console.log(callPointer,"pointdata")

    useEffect(()=>{
        setPointerData(keyClicked === "positive" ? callPointer?.positivePointer : keyClicked === "negative" ? callPointer?.negativePointer : callPointer?.sol_pointer || []);
    },[callPointer,keyClicked])

    const handleSelect = (keyClick)=>{
        setKeyClicked(keyClick)
      }

    const dataFunc = (pointerSet) =>{
      const splitData = pointerSet?.map(line => {
        const parts = line.split(":");
        return { category: parts[0]?.trim(), description: parts[1]?.trim() };
      })
        return <>
        <Card style={{ width: '95%', marginLeft:'3px',marginTop:'10px' }}>
        <Card.Body style={{paddingTop:'1rem'}}>
          <Card.Text>
          <ListGroup>
          {/* <ul> */}
              {splitData?.map((pointer, index) => {
                // return <li key={index} style={{paddingBottom:'5px'}}><p><b>{pointer.category}</b>{`: ${pointer.description}`}</p></li>;
                return <ListGroup.Item action variant={keyClicked == 'positive' ? 'success' : keyClicked == 'negative' ? 'danger' : 'warning'}><p style={{marginBottom:'0.5rem'}}><b>{pointer.category}</b>{pointer?.description && `: ${pointer?.description}`}</p></ListGroup.Item>
              })}
          {/* </ul> */}
          </ListGroup>
          </Card.Text>
        </Card.Body>
      </Card></>
    }

  return (
    <>
    {loader ? (
      <div className="loader-container">
        <Loading variant="dark" />
      </div>) :
    <div className="tab-content py-3">
        <div className="tab-pane fade active show" role="tabpanel" id="primarysignals" style={{marginLeft:'10px'}}>
          <div className="d-flex">
            <div className="moment-filter-tab-box">
              <Tab.Container id="left-tabs-example" defaultActiveKey={keyClicked?keyClicked:"negative"}>
                <Row>
                  <Col sm={10}>
                    <Nav variant="pills" className="flex" onSelect={handleSelect}>
                      <Nav.Item>
                        <Nav.Link eventKey="positive">Positive Pointer</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="negative">Negative Pointer</Nav.Link>
                      </Nav.Item>
                      {callPointer.sol_pointer && <Nav.Item>
                        <Nav.Link eventKey="suggestion">Suggestion Pointer</Nav.Link>
                        </Nav.Item>}
                    </Nav>
                  </Col>
                  <Col sm={12}  className="px-0">
                    <Tab.Content>
                    {keyClicked=='positive'||keyClicked=='negative' || keyClicked=='suggestion'?
                      <Tab.Pane eventKey={keyClicked}>
                        {dataFunc(pointerData)}
                      </Tab.Pane>
                    :""}
                    </Tab.Content>
                  </Col>
                </Row>
              </Tab.Container>
            </div>
          </div>
        </div>
        </div>
        }
    </>
  )
}

export default Pointers