import React, {useEffect,useState} from 'react'
import CommentOnModal from '../Sales/Modals/CommentOnModal'
import EmailTranscript from '../Sales/Modals/EmailTranscript'
import callsService from '../../Services/calls.service'
import notificationsService from '../../Services/notifications.service'
import toaster from '../../Toast/toaster'

export default function All_Ques(props) { 
  const [likeButton, setLikeButton] = useState(false)
  const [dislikeButton, setDislikeButton] = useState(false)
  const [mailButton, setMailButton] = useState(false)
  const [commentButton, setCommentButton] = useState(false)
  const [emailShow, setEmailShow] = useState(false)
  const [commentShow, setCommentShow] = useState(false)
  const [quesId, setQuesId] = useState("")
  const [questions,setQuestions] = useState("")
  const [speaker,setSpeaker] = useState("")
  const [likeId, setLikeId] = useState("")
  const [dislikeId, setDislikeId] = useState("")
  const [commentData, setCommentData] = useState([])
  const [likeDislike, setLikeDislike] = useState([])

  const likeButtonHandler = (id,label)=>{
    setLikeButton(true)
    setLikeId(id)
    let value={
      "callId": props?.callId,
      "commentType": "QUESTIONS",
      "dislike": null,
      "like": 1,
      "postId": id
    }
    callsService.addLikeDislike(value).then(res=>{
      if(res){
        callsService.getLikeDislike(props?.callId,"QUESTIONS").then(res=>{
          if(res){
            setLikeDislike(res.data.data)
            setLikeButton(false)
          }
        })
      }
    })
    let notificationBody = [{
			"callId":props?.callId,
			"userExternalId":props?.agentExternalId,
			"mobileNumber":props?.phone,
			"notificationEvent":{
				"eventType":"LIKE",
				"tabName":"questions",
				"tabValue":label,
				"postId":id,
			}
		}]
    notificationsService.createNotifications(notificationBody).then(res=>{
      if(res){
        console.log(res)
        toaster.success(res.data.message)
      }
    })
  }
  const dislikeButtonHandler = (id,label)=>{
    setDislikeButton(true)
    setDislikeId(id)
    let value={
      "callId": props?.callId,
      "commentType": "QUESTIONS",
      "dislike": 0,
      "like": null,
      "postId": id
    }
    callsService.addLikeDislike(value).then(res=>{
      if(res){
        callsService.getLikeDislike(props?.callId,"QUESTIONS").then(res=>{
          if(res){
            setLikeDislike(res.data.data)
            setDislikeButton(false)
          }
        })
      }
    })
    let notificationBody = [{
			"callId":props?.callId,
			"userExternalId":props?.agentExternalId,
			"mobileNumber":props?.phone,
			"notificationEvent":{
				"eventType":"DISLIKE",
				"tabName":"questions",
				"tabValue":label,
				"postId":id,
			}
		}]
    notificationsService.createNotifications(notificationBody).then(res=>{
      if(res){
        console.log(res)
        toaster.success(res.data.message)
      }
    })
  }
 
  const commentButtonHandler = (id,trans,speaker)=>{
    // setCommentButton(true)
    setCommentShow(true)
    setQuesId(id)
    setQuestions(trans)
    setSpeaker(speaker)
  }

  const updateCommentState = ()=>{
    setCommentButton(true)
    setCommentShow(true)
    // setId(id)
    // setStats(label)
  }
  const mailHandler = ()=>{
    setEmailShow(true)
    setMailButton(true)
  }

  const closeButton = ()=>{
    setCommentShow(false)
    setEmailShow(false)
  }

  const callback = (e)=>{
    setCommentData(e) 
  }

  useEffect(()=>{
    if(props?.eventType=='COMMENT'&&props?.tabRedirected=='questions'){
      setCommentShow(true)
      setQuesId(props?.postId)
    }
  },[])

  console.log(likeDislike)


  const questionSeller = (data)=>{
    return(
      <>
      {data.agent.map((item,id)=>{
        let transNew = Object.entries(commentData.length>0?commentData[0]?.postTypeComments:props?.callQuestions[0]?.postTypeComments).filter(val=>{
          return(val[1][0].postId==item.index)
        })
        let ids = transNew.length>0?transNew[0][1]:''
        let likeIcon = likeDislike.length>0? likeDislike.filter(val=>{
          return(val.postId==item.index)
        }):props?.likeDislikeData.filter(val=>{
          return(val.postId==item.index)
        })
        console.log(likeIcon)
        return(
        <a className={`${props?.postId==item?.index&&props?.tabRedirected=='questions'?'shadow list-group-item-action-trans':""} list-group-item`}>
          <div class="d-flex w-100 justify-content-between">
            <h5 class="mb-1 font-16">
              <i class="bx bx-user-voice me-1"></i>
              {props.callQuestions[0].agentName}
              <span class="badge rounded-pill bg-light-blue text-dark f-normal">{"seller"}</span>
            </h5>
            <small class="text-muted">
              <i class="bx bx-time"></i>
              {item.startTime}
            </small>
          </div>
            <p class="mb-1" style={{color:'black'}}>
              {item.trans}        
            </p>
          <div class="row">
            <div class="col-6"> 
              <div class="list-after-click-group">
                {likeIcon.length>0&& likeIcon[0].like!==null&&likeIcon[0].postId==item.index||likeButton&&likeId==item.index?<button class="btn list-like-after" ><span class=" text-primary-blue"> <i class="bx bx-like"></i></span></button>:
                likeIcon.length>0&&likeIcon[0].dislike!=null&&likeIcon[0].postId==item.index||dislikeButton&&dislikeId==item.index?<button class="btn list-dislike-after" ><span class=" text-primary-blue"> <i class="bx bx-dislike"></i></span></button>:''}
                {ids&&ids[0].postId==item.index||commentButton&&quesId==item.index?<button class="btn list-comment-after switcher-btn-1" onClick={()=>commentButtonHandler(item.index,item.trans,"seller")} data-bs-toggle="tooltip" data-bs-placement="bottom" title="Comment on Stats"><span class="text-primary-blue"><i class="lni lni-comments-alt"></i></span></button>:''}
              </div>
            </div>
            <div class="col-6">
              <div className="list-hover-input-group">
                {likeIcon.length>0&& likeIcon[0].like!==null&&likeIcon[0].postId==item.index||likeButton&&likeId==item.index? '':<button className="btn list-like-before" onClick={()=>likeButtonHandler(item.index,item.trans)}>
                    <span className="text-primary-blue">
                      <i className="bx bx-like"></i>
                    </span>
                </button>}
                {likeIcon.length>0&&likeIcon[0].dislike!=null&&likeIcon[0].postId==item.index||dislikeButton&&dislikeId==item.index? ""
                :
                <button className="btn list-dislike-before" onClick={()=>dislikeButtonHandler(item.index,item.trans)}>
                  <span className="text-primary-blue">
                    <i className="bx bx-dislike"></i>
                  </span>
                </button>}
                {/* <button className="btn compose-mail-btn" onClick={mailHandler} 
                data-bs-toggle="tooltip" data-bs-placement="bottom" title="Email Stats">
                  <span className="text-primary-blue">
                    <i className="bx bx-mail-send"></i>
                  </span>
                </button> */}
                {ids&&ids[0].postId==item.index||commentButton&&quesId==item.index?'':  
                <button className="btn list-comment-before switcher-btn-1" 
                onClick={()=>commentButtonHandler(item.index,item.trans,"seller")}
                data-bs-toggle="tooltip" data-bs-placement="bottom" title="Comment on Stats">
                  <span className="text-primary-blue">
                    <i className="lni lni-comments-alt"></i>
                  </span>
                </button>
                }
              </div>
            </div>
          </div>
        </a>
      )})}
      </>
    )
  }

  const questionCustomer = (data)=>{
    return(
      <>
      {data?.customer?.map((item,id)=>{
        let transNew = Object.entries(commentData.length>0?commentData[0]?.postTypeComments:props?.callQuestions[0]?.postTypeComments).filter(val=>{
          return(val[1][0].postId==item.index)
        })
        let ids = transNew.length>0?transNew[0][1]:''
        let likeIcon = likeDislike.length>0?likeDislike.filter(val=>{
          return(val.postId==item.index)
        }):
        props?.likeDislikeData.filter(val=>{return(val.postId==item.index)})
        return(
        <a class={`${props?.postId==item?.index&&props?.tabRedirected=='questions'?'shadow list-group-item-action-trans':''} list-group-item`}>
          <div class="d-flex w-100 justify-content-between">
            <h5 class="mb-1 font-16">
              <i class="bx bx-user-voice me-1"></i>
              {props.callQuestions[0].customerName}
              <span class="badge rounded-pill bg-light-blue text-dark f-normal">{"customer"}</span>
            </h5>
            <small class="text-muted">
              <i class="bx bx-time"></i>
              {item.startTime}
            </small>
          </div>
            <p class="mb-1" style={{color:'black'}}>
              {item.trans}        
            </p>
          <div class="row">
            <div class="col-6"> 
              <div class="list-after-click-group">
                {likeIcon.length>0&& likeIcon[0].like!==null&&likeIcon[0].postId==item.index||likeButton&&likeId==item.index?<button class="btn list-like-after"><span class=" text-primary-blue"> <i class="bx bx-like"></i></span></button>:
                likeIcon.length>0&&likeIcon[0].dislike!=null&&likeIcon[0].postId==item.index||dislikeButton&&dislikeId==item.index?<button class="btn list-dislike-after" ><span class=" text-primary-blue"> <i class="bx bx-dislike"></i></span></button>:''}
                {ids&&ids[0].postId==item.index||commentButton&&quesId==item.index?<button class="btn list-comment-after switcher-btn-1" onClick={()=>commentButtonHandler(item.index,item.trans,"customer")} data-bs-toggle="tooltip" data-bs-placement="bottom" title="Comment on Stats"><span class="text-primary-blue"><i class="lni lni-comments-alt"></i></span></button>:''}
              </div>
            </div>
            <div class="col-6">
              <div className="list-hover-input-group">
                {likeIcon.length>0&& likeIcon[0].like!==null&&likeIcon[0].postId==item.index||likeButton&&likeId==item.index? '':<button className="btn list-like-before" onClick={()=>likeButtonHandler(item.index,item.trans)}>
                  <span className="text-primary-blue">
                    <i className="bx bx-like"></i>
                  </span>
                </button>}
                {likeIcon.length>0&&likeIcon[0].dislike!=null&&likeIcon[0].postId==item.index||dislikeButton&&dislikeId==item.index? ""
                :
                <button className="btn list-dislike-before" onClick={()=>dislikeButtonHandler(item.index,item.trans)}>
                  <span className="text-primary-blue">
                    <i className="bx bx-dislike"></i>
                  </span>
                </button>}
                {/* <button className="btn compose-mail-btn" onClick={mailHandler} 
                data-bs-toggle="tooltip" data-bs-placement="bottom" title="Email Stats">
                  <span className="text-primary-blue">
                    <i className="bx bx-mail-send"></i>
                  </span>
                </button> */}
                {ids&&ids[0].postId==item.index||commentButton&&quesId==item.index?'':  
                <button className="btn list-comment-before switcher-btn-1" 
                onClick={()=>commentButtonHandler(item.index,item.trans,"customer")}
                data-bs-toggle="tooltip" data-bs-placement="bottom" title="Comment on Stats">
                  <span className="text-primary-blue">
                    <i className="lni lni-comments-alt"></i>
                  </span>
                </button>
                }
              </div>
            </div>
          </div>
        </a>
        )
      })}
      </>
    )
  }

  const quesAll = (data,id)=>{
    console.log(data,id)
    

    return(
      <>
      {data?.agent.map((item,id)=>{
        console.log(item)
        let transNew = Object.entries(commentData.length>0?commentData[0]?.postTypeComments:props?.callQuestions[0]?.postTypeComments).filter(val=>{
          return(val[1][0].postId==item.index)
        })
        let ids = transNew.length>0?transNew[0][1]:''
        let likeIcon = likeDislike.length>0? likeDislike.filter(val=>{
          return(val.postId==item.index)
        }):
        props?.likeDislikeData.filter(val=>{
          return(val.postId==item.index)
        })
        return(
        <a className={`${props?.postId==item?.index&&props?.tabRedirected=='questions'?'shadow list-group-item-action-trans':''} list-group-item`}>
          <div class="d-flex w-100 justify-content-between">
            <h5 class="mb-1 font-16">
              <i class="bx bx-user-voice me-1"></i>
              {props.callQuestions[0].agentName}
              <span class="badge rounded-pill bg-light-blue text-dark f-normal">{"seller"}</span>
            </h5>
            <small class="text-muted">
              <i class="bx bx-time"></i>
              {item.startTime}
            </small>
          </div>
            <p class="mb-1" style={{color:'black'}}>
              {item.trans}        
            </p>
          <div class="row">
            <div class="col-6"> 
              <div class="list-after-click-group">
                {likeIcon.length>0&& likeIcon[0].like!==null&&likeIcon[0].postId==item.index||likeButton&&likeId==item.index?<button class="btn list-like-after" ><span class=" text-primary-blue"> <i class="bx bx-like"></i></span></button>:
                likeIcon.length>0&&likeIcon[0].dislike!=null&&likeIcon[0].postId==item.index||dislikeButton&&dislikeId==item.index?<button class="btn list-dislike-after" ><span class=" text-primary-blue"> <i class="bx bx-dislike"></i></span></button>:''}
                {ids&&ids[0].postId==item.index||commentButton&&quesId==item.index?<button class="btn list-comment-after switcher-btn-1" onClick={()=>commentButtonHandler(item.index,item.trans,"seller")} data-bs-toggle="tooltip" data-bs-placement="bottom" title="Comment on Stats"><span class="text-primary-blue"><i class="lni lni-comments-alt"></i></span></button>:''}
              </div>
            </div>
            <div class="col-6">
              <div className="list-hover-input-group">
                {likeIcon.length>0&& likeIcon[0].like!==null&&likeIcon[0].postId==item.index||likeButton&&likeId==item.index? '':<button className="btn list-like-before" onClick={()=>likeButtonHandler(item.index,item.trans)}>
                    <span className="text-primary-blue">
                      <i className="bx bx-like"></i>
                    </span>
                </button>}
                {likeIcon.length>0&&likeIcon[0].dislike!=null&&likeIcon[0].postId==item.index||dislikeButton&&dislikeId==item.index ? ""
                :
                <button className="btn list-dislike-before" onClick={()=>dislikeButtonHandler(item.index,item.trans)}>
                  <span className="text-primary-blue">
                    <i className="bx bx-dislike"></i>
                  </span>
                </button>}
                {/* <button className="btn compose-mail-btn" onClick={mailHandler} 
                data-bs-toggle="tooltip" data-bs-placement="bottom" title="Email Stats">
                  <span className="text-primary-blue">
                    <i className="bx bx-mail-send"></i>
                  </span>
                </button> */}
                {ids&&ids[0].postId==item.index||commentButton&&quesId==item.index?'':  
                <button className="btn list-comment-before switcher-btn-1" 
                onClick={()=>commentButtonHandler(item.index,item.trans,"seller")}
                data-bs-toggle="tooltip" data-bs-placement="bottom" title="Comment on Stats">
                  <span className="text-primary-blue">
                    <i className="lni lni-comments-alt"></i>
                  </span>
                </button>
                }
              </div>
            </div>
          </div>
        </a>
      )})}
      {data?.customer?.map((item,id)=>{
        let transNew = Object.entries(commentData.length>0?commentData[0]?.postTypeComments:props?.callQuestions[0]?.postTypeComments).filter(val=>{
          return(val[1][0].postId==item.index)
        })
        
        let ids = transNew.length>0?transNew[0][1]:''
        let likeIcon = likeDislike.filter(val=>{
          return(val.postId==item.index)
        })
        return(
        <a class="list-group-item list-group-item-action">
          <div class="d-flex w-100 justify-content-between">
            <h5 class="mb-1 font-16">
              <i class="bx bx-user-voice me-1"></i>
              {props.callQuestions[0].customerName}
              <span class="badge rounded-pill bg-light-blue text-dark f-normal">{"customer"}</span>
            </h5>
            <small class="text-muted">
              <i class="bx bx-time"></i>
              {item.startTime}
            </small>
          </div>
            <p class="mb-1" style={{color:'black'}}>
              {item.trans}        
            </p>
          <div class="row">
            <div class="col-6"> 
              <div class="list-after-click-group">
                {likeIcon.length>0&& likeIcon[0].like!==null&&likeIcon[0].postId==item.index||likeButton&&likeId==item.index?<button class="btn list-like-after" ><span class=" text-primary-blue"> <i class="bx bx-like"></i></span></button>:''}
                {likeIcon.length>0&&likeIcon[0].dislike!=null&&likeIcon[0].postId==item.index||dislikeButton&&dislikeId==item.index?<button class="btn list-dislike-after" ><span class=" text-primary-blue"> <i class="bx bx-dislike"></i></span></button>:''}
                {/* {mailButton?<button class="btn compose-mail-btn" onClick={mailHandler} data-bs-toggle="tooltip" data-bs-placement="bottom" title="Email Stats"><span class="text-primary-blue"><i class="bx bx-mail-send"></i></span></button>:''} */}
                {ids&&ids[0].postId==item.index||commentButton&&quesId==item.index?<button class="btn list-comment-after switcher-btn-1" onClick={()=>commentButtonHandler(item.index,item.trans,"customer")} data-bs-toggle="tooltip" data-bs-placement="bottom" title="Comment on Stats"><span class="text-primary-blue"><i class="lni lni-comments-alt"></i></span></button>:''}
              </div>
            </div>
            <div class="col-6">
              <div className="list-hover-input-group">
                {likeIcon.length>0&& likeIcon[0].like!==null&&likeIcon[0].postId==item.index||likeButton&&likeId==item.index||dislikeButton&&dislikeId==item.index? '':<button className="btn list-like-before" onClick={()=>likeButtonHandler(item.index,item.trans)}>
                    <span className="text-primary-blue">
                      <i className="bx bx-like"></i>
                    </span>
                </button>}
                {likeIcon.length>0&&likeIcon[0].dislike!=null&&likeIcon[0].postId==item.index||dislikeButton&&dislikeId==item.index||likeButton&&likeId==item.index? ""
                :
                <button className="btn list-dislike-before" onClick={()=>dislikeButtonHandler(item.index,item.trans)}>
                  <span className="text-primary-blue">
                    <i className="bx bx-dislike"></i>
                  </span>
                </button>}
                {/* <button className="btn compose-mail-btn" onClick={mailHandler} 
                data-bs-toggle="tooltip" data-bs-placement="bottom" title="Email Stats">
                  <span className="text-primary-blue">
                    <i className="bx bx-mail-send"></i>
                  </span>
                </button> */}
                {ids&&ids[0].postId==item.index||commentButton&&quesId==item.index?'':  
                <button className="btn list-comment-before switcher-btn-1" 
                onClick={()=>commentButtonHandler(item.index,item.trans,"customer")}
                data-bs-toggle="tooltip" data-bs-placement="bottom" title="Comment on Stats">
                  <span className="text-primary-blue">
                    <i className="lni lni-comments-alt"></i>
                  </span>
                </button>
                }
              </div>
            </div>
          </div>
        </a>
        )
      })}
      </>
    )
  }

  console.log(props.callQuestions)
  return (
    <div class="list-group moment-list-group-scroll mb-2">
      {props.callQuestions?Object.entries(props.callQuestions).map((item,id)=>{
        console.log(item[1].customerName)
        return(
          <>
            {props.keyClicked=='seller'?
            questionSeller(item[1].questions):
            props.keyClicked=='customer'?
            questionCustomer(item[1].questions):
            props.keyClicked=='all'?
            quesAll(item[1].questions)
            :
            ""}
          </>
        )
      }):""}
      {commentShow && quesId!=='' && <CommentOnModal updateState={updateCommentState} commentShow={commentShow} closeButton={closeButton} tabSelected={props?.tabRedirected=='questions'?props?.tabRedirected:props?.tabSelected} callId={props?.callId} quesId={quesId} questions={questions} quesSpeaker={speaker} callback={callback} phone={props?.phone} agentExternalId={props.agentExternalId}/>}
      <EmailTranscript show={emailShow} closeButton={closeButton} ques={props.callQuestions} tabSelected={props.tabSelected} customerName={props?.customerName} sellerName={props?.sellerName} callId={props?.callId} agentId={props?.agentId}/>
    </div>
  );
}
