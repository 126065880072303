export const callListingGroup = {
    "data": {
        "details": [
            {
                "details": [],
                "callAlarms": ["Empathy"],
                "callColour": null,
                "callId": 7541,
                "callRatingName": null,
                "callRec": [],
                "callScore": 100,
                "customerName": "Joseph",
                "date": "28/01/2023 12:00:00",
                "filename": "Devis_16763526.wav",
                "moments": [
                    "Self introduction",
"Relevant Opening",
"Acknowledgment",
"Issue Identification",
"Correct Response",
"Relevant Closing",
"End Greeting"


                ],
                "parameters": {"audit": [
                        {
                            "k": "1123",
                            "v": "1",
                            "remark": "",
                            "time": [],
                            "moment_group_id": "879",
                            "l": "self_introduction_missing"
                        },
                        {
                            "k": "1124",
                            "v": "1",
                            "remark": "",
                            "time": [],
                            "moment_group_id": "879",
                            "l": "relevant_opening"
                        },
                        {
                            "k": "1125",
                            "v": "1",
                            "remark": "",
                            "time": [],
                            "moment_group_id": "880",
                            "l": "acknowledgment"
                        },
                        {
                            "k": "1126",
                            "v":"0",
                            "remark": "",
                            "time": [],
                            "moment_group_id": "880",
                            "l": "empathy"
                        },
                        {
                            "k": "1127",
                            "v": "1",
                            "remark": "",
                            "time": [],
                            "moment_group_id": "880",
                            "l": "issue_identification"
                        },
                        {
                            "k": "1128",
                            "v": "1",
                            "remark": "Agent did not confirmed the details of customer . ",
                            "time": [],
                            "moment_group_id": "880",
                            "l": "correct_response"
                        },
                        {
                            "k": "1129",
                            "v": "1",
                            "remark": "",
                            "time": [],
                            "moment_group_id": "881",
                            "l": "relevant_closing"
                        },
                        {
                            "k": "1130",
                            "v": "1",
                            "remark": "",
                            "time": [],
                            "moment_group_id": "881",
                            "l": "end_greeting"
                        }]
                    
                 
                },

                "phone": "16763526",
                "email": "devis@company.com",
                "pitchColour": null,
                "pitchRatingName": null,
                "pitchScore": 5.0,
                "processingTime": "Sat, 25 Feb 2023 00:00:00 GMT",
                "ratingColour": null,
                "recordingUrl": "https://demo-odio-calls.s3.ap-south-1.amazonaws.com/merged_calls/bajaj/Pratibha.Khopade__ShubhamPSFOutboundCampaign__26__-1__8219395419__2023-01-31_13-35-29.wav",
                "sellerName": "Devis",
                "talkDuration": "00:02:33",
                "userExternalId": 5362616,
                "employeeId": "5362616",
                "coeName": [
                    "Los Angeles"
                ],
                "coeIds": [
                    101
                ],
                "totalCalls": 1,
                "audioRecordingUrl": null
            },
           
        ],
        "message": null
    },
    "status": 0,
    "message": "Success"
}
