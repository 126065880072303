import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import {getFirestore} from "firebase/firestore"

const firebaseConfig = {
    apiKey: "AIzaSyCQhROATQpKYFOf2IczvK9LH_IHk7UFqeA",
    authDomain: "odiov2-web.firebaseapp.com",
    databaseURL: "https://odiov2-web-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "odiov2-web",
    storageBucket: "odiov2-web.appspot.com",
    messagingSenderId: "211075128765",
    appId: "1:211075128765:web:d6d08fe019ef08c6c706d4"
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);