import React from "react";
class SalesInteractionVideocalls extends React.Component
{
    componentDidMount(){
        document.title = "Interaction Video Calls - Odio"
    }
    render(){
     return(
            <div class="page-wrapper">
            <div class="page-content">
                <div class="row row-cols-1 row-cols-md-2 row-cols-xl-4">
                    <div class="col-12 mb-4">
                        <div class="row">
                            <div class="col-md-12">
                                <h2 class="dashboard-headings">Video Calls </h2>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 mb-4">
                        <div class="card">
                            <div class="card-body">
                                <div class="table-responsive">
                                    <table id="callInteractionTable" class="table table-striped table-bordered">
                                        <thead>
                                            <tr>
                                                <th>Call #</th>
                                                <th>Ticket #</th>
                                                <th>Agent</th>
                                                <th>Customer</th>
                                                <th>Phone</th>
                                                <th>Call At</th>
                                                <th>Duration</th>
                                                <th class="text-center">Alarms</th>
                                                <th class="text-center">Score</th>
                                                <th class="text-center">Reco.</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td><a href="sales-call-details.html">1</a></td>
                                                <td>000000</td>
                                                <td>Garrett Winters</td>
                                                <td>Cedric Kelly</td>
                                                <td>7901234567</td>
                                                <td>Dec 16, 2020</td>
                                                <td>2 Min. 40 Sec.</td>
                                                <td class="text-center"><span class="badge rounded-pill bg-warning text-dark font-13">4</span></td>
                                                <td class="text-center"><span class="badge rounded-pill bg-success font-13">80%</span></td>
                                                <td class="text-center"><a href="javascript: void(0);" class="call-list-data-optin" ><i class="lni lni-list"></i></a></td>
                                            </tr>
                                            <tr>
                                                <td><a href="sales-call-details.html">1</a></td>
                                                <td>000000</td>
                                                <td>Garrett Winters</td>
                                                <td>Cedric Kelly</td>
                                                <td>7901234567</td>
                                                <td>Dec 16, 2020</td>
                                                <td>2 Min. 40 Sec.</td>
                                                <td class="text-center"><span class="badge rounded-pill bg-warning text-dark font-13">4</span></td>
                                                <td class="text-center"><span class="badge rounded-pill bg-warning text-dark font-13">40%</span></td>
                                                <td class="text-center"><a href="javascript: void(0);" class="call-list-data-optin" ><i class="lni lni-list"></i></a></td>
                                            </tr>
                                            <tr>
                                                <td><a href="sales-call-details.html">1</a></td>
                                                <td>000000</td>
                                                <td>Garrett Winters</td>
                                                <td>Cedric Kelly</td>
                                                <td>7901234567</td>
                                                <td>Dec 16, 2020</td>
                                                <td>2 Min. 40 Sec.</td>
                                                <td class="text-center"></td>
                                                <td class="text-center"><span class="badge rounded-pill bg-danger font-13">10%</span></td>
                                                <td class="text-center"><a href="javascript: void(0);" class="call-list-data-optin" ><i class="lni lni-list"></i></a></td>
                                            </tr>
                                            <tr>
                                                <td><a href="sales-call-details.html">1</a></td>
                                                <td>000000</td>
                                                <td>Garrett Winters</td>
                                                <td>Cedric Kelly</td>
                                                <td>7901234567</td>
                                                <td>Dec 16, 2020</td>
                                                <td>2 Min. 40 Sec.</td>
                                                <td class="text-center"><span class="badge rounded-pill bg-warning text-dark font-13">4</span></td>
                                                <td class="text-center"><span class="badge rounded-pill bg-success font-13">80%</span></td>
                                                <td class="text-center"><a href="javascript: void(0);" class="call-list-data-optin" ><i class="lni lni-list"></i></a></td>
                                            </tr>
                                            <tr>
                                                <td><a href="sales-call-details.html">1</a></td>
                                                <td>000000</td>
                                                <td>Garrett Winters</td>
                                                <td>Cedric Kelly</td>
                                                <td>7901234567</td>
                                                <td>Dec 16, 2020</td>
                                                <td>2 Min. 40 Sec.</td>
                                                <td class="text-center"><span class="badge rounded-pill bg-warning text-dark font-13">4</span></td>
                                                <td class="text-center"><span class="badge rounded-pill bg-success font-13">80%</span></td>
                                                <td class="text-center"><a href="javascript: void(0);" class="call-list-data-optin" ><i class="lni lni-list"></i></a></td>
                                            </tr>
                                            <tr>
                                                <td><a href="sales-call-details.html">1</a></td>
                                                <td>000000</td>
                                                <td>Garrett Winters</td>
                                                <td>Cedric Kelly</td>
                                                <td>7901234567</td>
                                                <td>Dec 16, 2020</td>
                                                <td>2 Min. 40 Sec.</td>
                                                <td class="text-center"><span class="badge rounded-pill bg-warning text-dark font-13">4</span></td>
                                                <td class="text-center"><span class="badge rounded-pill bg-warning text-dark font-13">40%</span></td>
                                                <td class="text-center"><a href="javascript: void(0);" class="call-list-data-optin" ><i class="lni lni-list"></i></a></td>
                                            </tr>
                                            <tr>
                                                <td><a href="sales-call-details.html">1</a></td>
                                                <td>000000</td>
                                                <td>Garrett Winters</td>
                                                <td>Cedric Kelly</td>
                                                <td>7901234567</td>
                                                <td>Dec 16, 2020</td>
                                                <td>2 Min. 40 Sec.</td>
                                                <td class="text-center"></td>
                                                <td class="text-center"><span class="badge rounded-pill bg-danger font-13">10%</span></td>
                                                <td class="text-center"><a href="javascript: void(0);" class="call-list-data-optin" ><i class="lni lni-list"></i></a></td>
                                            </tr>
                                            <tr>
                                                <td><a href="sales-call-details.html">1</a></td>
                                                <td>000000</td>
                                                <td>Garrett Winters</td>
                                                <td>Cedric Kelly</td>
                                                <td>7901234567</td>
                                                <td>Dec 16, 2020</td>
                                                <td>2 Min. 40 Sec.</td>
                                                <td class="text-center"><span class="badge rounded-pill bg-warning text-dark font-13">4</span></td>
                                                <td class="text-center"><span class="badge rounded-pill bg-success font-13">80%</span></td>
                                                <td class="text-center"><a href="javascript: void(0);" class="call-list-data-optin" ><i class="lni lni-list"></i></a></td>
                                            </tr>
                                            <tr>
                                                <td><a href="sales-call-details.html">1</a></td>
                                                <td>000000</td>
                                                <td>Garrett Winters</td>
                                                <td>Cedric Kelly</td>
                                                <td>7901234567</td>
                                                <td>Dec 16, 2020</td>
                                                <td>2 Min. 40 Sec.</td>
                                                <td class="text-center"><span class="badge rounded-pill bg-warning text-dark font-13">4</span></td>
                                                <td class="text-center"><span class="badge rounded-pill bg-success font-13">80%</span></td>
                                                <td class="text-center"><a href="javascript: void(0);" class="call-list-data-optin" ><i class="lni lni-list"></i></a></td>
                                            </tr>
                                            <tr>
                                                <td><a href="sales-call-details.html">1</a></td>
                                                <td>000000</td>
                                                <td>Garrett Winters</td>
                                                <td>Cedric Kelly</td>
                                                <td>7901234567</td>
                                                <td>Dec 16, 2020</td>
                                                <td>2 Min. 40 Sec.</td>
                                                <td class="text-center"><span class="badge rounded-pill bg-warning text-dark font-13">4</span></td>
                                                <td class="text-center"><span class="badge rounded-pill bg-warning text-dark font-13">40%</span></td>
                                                <td class="text-center"><a href="javascript: void(0);" class="call-list-data-optin" ><i class="lni lni-list"></i></a></td>
                                            </tr>
                                            <tr>
                                                <td><a href="sales-call-details.html">1</a></td>
                                                <td>000000</td>
                                                <td>Garrett Winters</td>
                                                <td>Cedric Kelly</td>
                                                <td>7901234567</td>
                                                <td>Dec 16, 2020</td>
                                                <td>2 Min. 40 Sec.</td>
                                                <td class="text-center"></td>
                                                <td class="text-center"><span class="badge rounded-pill bg-danger font-13">10%</span></td>
                                                <td class="text-center"><a href="javascript: void(0);" class="call-list-data-optin" ><i class="lni lni-list"></i></a></td>
                                            </tr>
                                            <tr>
                                                <td><a href="sales-call-details.html">1</a></td>
                                                <td>000000</td>
                                                <td>Garrett Winters</td>
                                                <td>Cedric Kelly</td>
                                                <td>7901234567</td>
                                                <td>Dec 16, 2020</td>
                                                <td>2 Min. 40 Sec.</td>
                                                <td class="text-center"><span class="badge rounded-pill bg-warning text-dark font-13">4</span></td>
                                                <td class="text-center"><span class="badge rounded-pill bg-success font-13">80%</span></td>
                                                <td class="text-center"><a href="javascript: void(0);" class="call-list-data-optin" ><i class="lni lni-list"></i></a></td>
                                            </tr>
                                            <tr>
                                                <td><a href="sales-call-details.html">1</a></td>
                                                <td>000000</td>
                                                <td>Garrett Winters</td>
                                                <td>Cedric Kelly</td>
                                                <td>7901234567</td>
                                                <td>Dec 16, 2020</td>
                                                <td>2 Min. 40 Sec.</td>
                                                <td class="text-center"><span class="badge rounded-pill bg-warning text-dark font-13">4</span></td>
                                                <td class="text-center"><span class="badge rounded-pill bg-success font-13">80%</span></td>
                                                <td class="text-center"><a href="javascript: void(0);" class="call-list-data-optin" ><i class="lni lni-list"></i></a></td>
                                            </tr>
                                            <tr>
                                                <td><a href="sales-call-details.html">1</a></td>
                                                <td>000000</td>
                                                <td>Garrett Winters</td>
                                                <td>Cedric Kelly</td>
                                                <td>7901234567</td>
                                                <td>Dec 16, 2020</td>
                                                <td>2 Min. 40 Sec.</td>
                                                <td class="text-center"><span class="badge rounded-pill bg-warning text-dark font-13">4</span></td>
                                                <td class="text-center"><span class="badge rounded-pill bg-warning text-dark font-13">40%</span></td>
                                                <td class="text-center"><a href="javascript: void(0);" class="call-list-data-optin" ><i class="lni lni-list"></i></a></td>
                                            </tr>
                                            <tr>
                                                <td><a href="sales-call-details.html">1</a></td>
                                                <td>000000</td>
                                                <td>Garrett Winters</td>
                                                <td>Cedric Kelly</td>
                                                <td>7901234567</td>
                                                <td>Dec 16, 2020</td>
                                                <td>2 Min. 40 Sec.</td>
                                                <td class="text-center"></td>
                                                <td class="text-center"><span class="badge rounded-pill bg-danger font-13">10%</span></td>
                                                <td class="text-center"><a href="javascript: void(0);" class="call-list-data-optin" ><i class="lni lni-list"></i></a></td>
                                            </tr>
                                            <tr>
                                                <td><a href="sales-call-details.html">1</a></td>
                                                <td>000000</td>
                                                <td>Garrett Winters</td>
                                                <td>Cedric Kelly</td>
                                                <td>7901234567</td>
                                                <td>Dec 16, 2020</td>
                                                <td>2 Min. 40 Sec.</td>
                                                <td class="text-center"><span class="badge rounded-pill bg-warning text-dark font-13">4</span></td>
                                                <td class="text-center"><span class="badge rounded-pill bg-success font-13">80%</span></td>
                                                <td class="text-center"><a href="javascript: void(0);" class="call-list-data-optin" ><i class="lni lni-list"></i></a></td>
                                            </tr>
                                            <tr>
                                                <td><a href="sales-call-details.html">1</a></td>
                                                <td>000000</td>
                                                <td>Garrett Winters</td>
                                                <td>Cedric Kelly</td>
                                                <td>7901234567</td>
                                                <td>Dec 16, 2020</td>
                                                <td>2 Min. 40 Sec.</td>
                                                <td class="text-center"><span class="badge rounded-pill bg-warning text-dark font-13">4</span></td>
                                                <td class="text-center"><span class="badge rounded-pill bg-success font-13">80%</span></td>
                                                <td class="text-center"><a href="javascript: void(0);" class="call-list-data-optin" ><i class="lni lni-list"></i></a></td>
                                            </tr>
                                            <tr>
                                                <td><a href="sales-call-details.html">1</a></td>
                                                <td>000000</td>
                                                <td>Garrett Winters</td>
                                                <td>Cedric Kelly</td>
                                                <td>7901234567</td>
                                                <td>Dec 16, 2020</td>
                                                <td>2 Min. 40 Sec.</td>
                                                <td class="text-center"><span class="badge rounded-pill bg-warning text-dark font-13">4</span></td>
                                                <td class="text-center"><span class="badge rounded-pill bg-warning text-dark font-13">40%</span></td>
                                                <td class="text-center"><a href="javascript: void(0);" class="call-list-data-optin" ><i class="lni lni-list"></i></a></td>
                                            </tr>
                                            <tr>
                                                <td><a href="sales-call-details.html">1</a></td>
                                                <td>000000</td>
                                                <td>Garrett Winters</td>
                                                <td>Cedric Kelly</td>
                                                <td>7901234567</td>
                                                <td>Dec 16, 2020</td>
                                                <td>2 Min. 40 Sec.</td>
                                                <td class="text-center"></td>
                                                <td class="text-center"><span class="badge rounded-pill bg-danger font-13">10%</span></td>
                                                <td class="text-center"><a href="javascript: void(0);" class="call-list-data-optin" ><i class="lni lni-list"></i></a></td>
                                            </tr>
                                            <tr>
                                                <td><a href="sales-call-details.html">1</a></td>
                                                <td>000000</td>
                                                <td>Garrett Winters</td>
                                                <td>Cedric Kelly</td>
                                                <td>7901234567</td>
                                                <td>Dec 16, 2020</td>
                                                <td>2 Min. 40 Sec.</td>
                                                <td class="text-center"><span class="badge rounded-pill bg-warning text-dark font-13">4</span></td>
                                                <td class="text-center"><span class="badge rounded-pill bg-success font-13">80%</span></td>
                                                <td class="text-center"><a href="javascript: void(0);" class="call-list-data-optin" ><i class="lni lni-list"></i></a></td>
                                            </tr>
                                            <tr>
                                                <td><a href="sales-call-details.html">1</a></td>
                                                <td>000000</td>
                                                <td>Garrett Winters</td>
                                                <td>Cedric Kelly</td>
                                                <td>7901234567</td>
                                                <td>Dec 16, 2020</td>
                                                <td>2 Min. 40 Sec.</td>
                                                <td class="text-center"><span class="badge rounded-pill bg-warning text-dark font-13">4</span></td>
                                                <td class="text-center"><span class="badge rounded-pill bg-success font-13">80%</span></td>
                                                <td class="text-center"><a href="javascript: void(0);" class="call-list-data-optin" ><i class="lni lni-list"></i></a></td>
                                            </tr>
                                            <tr>
                                                <td><a href="sales-call-details.html">1</a></td>
                                                <td>000000</td>
                                                <td>Garrett Winters</td>
                                                <td>Cedric Kelly</td>
                                                <td>7901234567</td>
                                                <td>Dec 16, 2020</td>
                                                <td>2 Min. 40 Sec.</td>
                                                <td class="text-center"><span class="badge rounded-pill bg-warning text-dark font-13">4</span></td>
                                                <td class="text-center"><span class="badge rounded-pill bg-warning text-dark font-13">40%</span></td>
                                                <td class="text-center"><a href="javascript: void(0);" class="call-list-data-optin" ><i class="lni lni-list"></i></a></td>
                                            </tr>
                                            <tr>
                                                <td><a href="sales-call-details.html">1</a></td>
                                                <td>000000</td>
                                                <td>Garrett Winters</td>
                                                <td>Cedric Kelly</td>
                                                <td>7901234567</td>
                                                <td>Dec 16, 2020</td>
                                                <td>2 Min. 40 Sec.</td>
                                                <td class="text-center"></td>
                                                <td class="text-center"><span class="badge rounded-pill bg-danger font-13">10%</span></td>
                                                <td class="text-center"><a href="javascript: void(0);" class="call-list-data-optin" ><i class="lni lni-list"></i></a></td>
                                            </tr>
                                            <tr>
                                                <td><a href="sales-call-details.html">1</a></td>
                                                <td>000000</td>
                                                <td>Garrett Winters</td>
                                                <td>Cedric Kelly</td>
                                                <td>7901234567</td>
                                                <td>Dec 16, 2020</td>
                                                <td>2 Min. 40 Sec.</td>
                                                <td class="text-center"><span class="badge rounded-pill bg-warning text-dark font-13">4</span></td>
                                                <td class="text-center"><span class="badge rounded-pill bg-success font-13">80%</span></td>
                                                <td class="text-center"><a href="javascript: void(0);" class="call-list-data-optin" ><i class="lni lni-list"></i></a></td>
                                            </tr>
                                            <tr>
                                                <td><a href="sales-call-details.html">1</a></td>
                                                <td>000000</td>
                                                <td>Garrett Winters</td>
                                                <td>Cedric Kelly</td>
                                                <td>7901234567</td>
                                                <td>Dec 16, 2020</td>
                                                <td>2 Min. 40 Sec.</td>
                                                <td class="text-center"><span class="badge rounded-pill bg-warning text-dark font-13">4</span></td>
                                                <td class="text-center"><span class="badge rounded-pill bg-success font-13">80%</span></td>
                                                <td class="text-center"><a href="javascript: void(0);" class="call-list-data-optin" ><i class="lni lni-list"></i></a></td>
                                            </tr>
                                            <tr>
                                                <td><a href="sales-call-details.html">1</a></td>
                                                <td>000000</td>
                                                <td>Garrett Winters</td>
                                                <td>Cedric Kelly</td>
                                                <td>7901234567</td>
                                                <td>Dec 16, 2020</td>
                                                <td>2 Min. 40 Sec.</td>
                                                <td class="text-center"><span class="badge rounded-pill bg-warning text-dark font-13">4</span></td>
                                                <td class="text-center"><span class="badge rounded-pill bg-warning text-dark font-13">40%</span></td>
                                                <td class="text-center"><a href="javascript: void(0);" class="call-list-data-optin" ><i class="lni lni-list"></i></a></td>
                                            </tr>
                                            <tr>
                                                <td><a href="sales-call-details.html">1</a></td>
                                                <td>000000</td>
                                                <td>Garrett Winters</td>
                                                <td>Cedric Kelly</td>
                                                <td>7901234567</td>
                                                <td>Dec 16, 2020</td>
                                                <td>2 Min. 40 Sec.</td>
                                                <td class="text-center"></td>
                                                <td class="text-center"><span class="badge rounded-pill bg-danger font-13">10%</span></td>
                                                <td class="text-center"><a href="javascript: void(0);" class="call-list-data-optin" ><i class="lni lni-list"></i></a></td>
                                            </tr>
                                            <tr>
                                                <td><a href="sales-call-details.html">1</a></td>
                                                <td>000000</td>
                                                <td>Garrett Winters</td>
                                                <td>Cedric Kelly</td>
                                                <td>7901234567</td>
                                                <td>Dec 16, 2020</td>
                                                <td>2 Min. 40 Sec.</td>
                                                <td class="text-center"><span class="badge rounded-pill bg-warning text-dark font-13">4</span></td>
                                                <td class="text-center"><span class="badge rounded-pill bg-success font-13">80%</span></td>
                                                <td class="text-center"><a href="javascript: void(0);" class="call-list-data-optin" ><i class="lni lni-list"></i></a></td>
                                            </tr>
                                            
                                        </tbody>
                                        
                                    </table>
                                  
                                                 </div>
                    </div>
                </div>
            </div>
        </div>
       
        <div class="search-overlay"></div>
        <div class="overlay toggle-icon"></div>
       
        <footer class="page-footer">
            <p class="mb-0">Copyright © 2021 - VoiceIO Artificial Intelligence Pvt Ltd</p>
        </footer>
    </div>
    
    </div>
    
        )
    }}
export default SalesInteractionVideocalls;
