import axios from 'axios';
import { ACCESS_TOKEN } from '../../Constants/constant';
import { history } from "../../redux-container/store";
import toaster from '../Toast/toaster';
import { REQUEST_FAILURE, SIGN_OUT } from '../../redux-container/login/types';
import store from '../../redux-container/store';

export const apiCall = axios.create({
  baseURL: '',
})

let tokenRefreshPromise = null;

apiCall.interceptors.request.use(function (config) {
  if (localStorage.getItem('ACCESS_TOKEN')) {
    config.headers.Authorization = 'Bearer ' + localStorage.getItem(ACCESS_TOKEN);
    config.headers.Accept = 'application/json';
  } else {
    config.headers["Content-Type"] = 'application/json'
  }
  return config;
}, error => {
  return Promise.reject(error);
});

apiCall.interceptors.response.use(
  response => {
    return response
  },
  async error => {
    const originalRequest = error.config
    const originalResponse = error.response;
    console.log(originalRequest)
    if (error.response !== undefined) {
      console.log(error.response, "original1")
      if (error.response.data.status === 400 || error.response.status == 400) {
        toaster.error(error.response.data ? error.response.data.message : 'Bad Request')
        return Promise.reject(error.response)
      }
      if (error.response.data.status === 404) {
        toaster.error(error.response.data ? error.response.data.message : 'Not Found')
      }
      if (error.response.data.status === 401 && originalRequest.url !== "/odio/api/authenticate") {
        if (!tokenRefreshPromise) {
          tokenRefreshPromise = apiCall.post("/odio/api/refresh")
            .then(response => {
              const refreshedToken = response.data.data;
              localStorage.setItem('ACCESS_TOKEN', refreshedToken);
              return refreshedToken;
            })
            .catch(refreshError => {
              toaster.error("UnAuthorized Access")
              localStorage.clear();
              sessionStorage.clear();
              store.dispatch({ type: SIGN_OUT, payload: false });
              history.push("./login");
            })
            .finally(() => {
              tokenRefreshPromise = null;
            });
        }

        try {
          const refreshedToken = await tokenRefreshPromise;
          const response = await apiCall(originalRequest);
          return response;
        } catch (refreshError) {
          localStorage.clear();
          sessionStorage.clear();
          store.dispatch({ type: SIGN_OUT, payload: false });
          history.push("./login");
        }
      }
      if(error.response.data.status === 401 && originalRequest.url === '/odio/api/authenticate'){
        toaster.error(error.response.data ? error.response.data.message : 'Unauthorized Access')
        store.dispatch({ type: REQUEST_FAILURE, payload: false });
      }
      if (error.response.data.status === 403) {
        toaster.error(error.response.data ? error.response.data.message : 'Forbidden Access')
      }
      if (error.response.data.status === 500) {
        toaster.error(error.response.data ? error.response.data.message != '' ? error.response.data.message : 'We are facing system issue. Please try again later.' : "");
      }
      if(error.response.status===502){
        toaster.error("Bad Gateway")
        store.dispatch({ type: REQUEST_FAILURE, payload: false });
      }
    }
    else {
      toaster.error("Bad Gateway")
    }

  });
