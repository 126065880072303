import {
  GET_TEAM,
  FILTER_TEAM,
  RESET_FILTER,
  SET_LOADER,
  SET_FILTER_LOADER,
  TEAM_NOT_FOUND,
} from "./type";
import profileService from "../../Components/Services/profile.service";
import history from "../../redux-container/store";

export const getTeamList = () => async (dispatch) => {
  dispatch({ type: SET_LOADER, payload:true}); 
  await profileService
    .myTeam()
    .then((res) => {
      console.log("my team data", res);
      dispatch({ type: GET_TEAM, payload: res.data.data.team });
    })
    .catch((error) => {
      if (error.response && error.response.status === 400) {
        history.push("./login");
        console.log("Please login to continue.");
        dispatch({ type: TEAM_NOT_FOUND });
      } else if (error.response && error.response.status === 500) {
        // toast.error('User doesn’t exist.');
        dispatch({ type: TEAM_NOT_FOUND });
      } else {
        dispatch({ type: TEAM_NOT_FOUND });
        console.log("Oops somethings went wrong, Network Error.");
      }
    });
};

export const filterTeamList = (data) => async (dispatch) => {
  console.log(data);
  dispatch({ type: FILTER_TEAM, payload: data });
};

export const resetFilter = () => async (dispatch) => {
  dispatch({ type: RESET_FILTER });
};
