import React, { useEffect, useState } from 'react'
import { Card, OverlayTrigger, Popover } from 'react-bootstrap'
import phone from "../../assets/images/callList-images/phone.svg"
import calender from "../../assets/images/callList-images/calendar.svg"
import time from "../../assets/images/callList-images/timer.svg"
import alarm from "../../assets/images/callList-images/alarm.svg"
import bulb from "../../assets/images/callList-images/bulb.svg"
import moment from "../../assets/images/callList-images/moment.svg"
import seller from "../../assets/images/callList-images/seller.svg"
import user from "../../assets/images/callList-images/user.svg"
import conversationService from '../Services/conversation.service'
import Loading from '../Commons/Loading'
import NodataFound from '../Commons/NoDataFound'
import { useHistory } from "react-router-dom";
import PaginationAlter from '../Commons/Table/PaginationAlter'
import Filter from '../Filter/Filter'

function AgentCallDetail(props) {
    const [listLoader, setListLoader] = useState(false)
    const [detail, setDetail] = useState([])
    const [currentPage, setCurrentPage] = useState(1)
    const [filterData, setFilterData] = useState([])
    const [defaultData, setDefaultData] = useState([])
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const history = useHistory()
    let fromDate = urlParams.get("fromDate")
    let toDate = urlParams.get("toDate")
    let callRange = urlParams.get('callScore')
    let durationRange = urlParams.get('callDuration')
    let redirected = urlParams.get('redirected')
    let competitorWords = urlParams.get('competitorWords')
    let userDetail = JSON.parse(localStorage.getItem("USER_DETAIL"))?.clientConfig?.data?.conversationListing
    let role = JSON.parse(localStorage.getItem("USER_DETAIL"))?.userRole
    const storedData = JSON.parse(localStorage.getItem("AGENT"))
    const sellerId = storedData?.sellerName
    console.log(storedData, "skbckskcxbskc")

    useEffect(()=>{
        localStorage.setItem("selectedPage",1)

        return () => {
            localStorage.setItem("selectedPage", 1);
        };
    },[])

    useEffect(() => {
        setListLoader(true)
        let val = {
            "page": parseInt(localStorage.getItem("selectedPage")) ? parseInt(localStorage.getItem("selectedPage")) : 1,
            "pageSize": 5,
            "callType": "SALES",
            "sort": props.sortedField ? props.sortedField : "",
            "order":props.orderId?props.orderId:"",
            "clientExternalId": JSON.parse(localStorage.getItem("USER_DETAIL"))?.externalId,
            "fromD": fromDate ? fromDate : storedData && storedData.fromD,
            "toD": toDate ? toDate : fromDate ? fromDate : storedData && storedData.toD,
            "userIds": storedData?.sellerName?.length > 0 ? storedData?.sellerName : '',
            "coeExternalIds": (role == "AGENT" ? (storedData?.coe ? storedData?.coe : [JSON.parse(localStorage.getItem("COE_DATA"))[0].externalId]) : storedData && storedData.coe),
            "customerName": storedData?.customerName ? storedData?.customerName : props?.filterData?.customerName,
            "customerMobiles": props?.historyObject?.agentPhone ? [props?.historyObject?.agentPhone] : storedData?.customerMobile ? storedData?.customerMobile : props?.filterData?.customerMobile?.length > 0 ? props?.filterData?.customerMobile : undefined,
            "userIds": storedData?.sellerName?.length > 0 ? storedData?.sellerName : history.location?.state?.employeeId ? [history.location?.state?.employeeId] : props?.filterData?.sellerName?.length > 0 ? props?.filterData?.sellerName : [],
            "fileName": storedData?.fileName ? storedData?.fileName : props?.filterData?.fileName,
            "sellerROS": storedData?.sellerROS ? storedData?.sellerROS : props?.filterData?.sellerROS,
            "callScoreRange": storedData?.cScore ? storedData?.cScore : props?.filterData?.callScore,
            "fatalCall": storedData?.callHealth == 0 ? storedData?.callHealth : storedData?.callHealth == 1 ? storedData?.callHealth : props?.filterData?.callHealth,
            "callDurationRange": storedData?.callDuration ? storedData?.callDuration : props?.filterData.callDuration && [props?.filterData?.callDuration[0] * 60, props?.filterData?.callDuration[1] * 60],
            "words": storedData?.words ? storedData?.words : props?.filterData?.words,
            "momentId": storedData?.momentId ? storedData?.momentId : props?.filterData?.moment,
            "coeName": props?.filterData?.coeName ? props?.filterData?.coeName : storedData?.selectedCOE.length > 0 ? storedData?.selectedCOE : [JSON.parse(localStorage.getItem('COE_DATA'))?.[0]?.coeName],
            "coeExternalIds": role == "AGENT" ? storedData?.coe ? storedData?.coe : [JSON.parse(localStorage.getItem("COE_DATA"))[0].externalId] : props?.filterData?.coe ? props?.filterData?.coe : storedData?.coe,
            "pitchRange": storedData?.pitch ? storedData?.pitch : props?.filterData?.pitch,
            "convProRange": storedData?.prob ? storedData?.prob : props?.filterData?.prob,
            "opportunityRange": storedData?.opp ? storedData?.opp : props?.filterData?.opportunity,
            "urgencyRange": storedData?.urgency ? storedData?.urgency : props?.filterData?.urgency,
            "competitorTrackerWords": competitorWords ? competitorWords : undefined,
            "pagination":true,
        }
        conversationService.getCalls(val).then(res => {
            console.log(res)
            if (res?.status == 200) {
                setListLoader(false)
                setDetail(res?.data?.data)
            }
        })

    }, [props.sortedField, props.orderId, currentPage, props?.filterData])

    const popoverr = (alarms, name, rec, moment) => (
        <Popover id="popover-trigger-hover-focus" title="right" style={{ "padding": "4px" }}>
            <h3 className="popover-header">{name}</h3>
            <div className="popover-body">
                <div className="popover-body-call">
                    {
                        name === 'Alarms' ? alarms.map(val => {
                            return (
                                <p className="mb-0">
                                    <i className="bx bx-stats font-16 align-middle me-1" />
                                    {val}
                                </p>
                            )
                        }) : name === 'Recommendations' ? rec.map(val => {
                            return (
                                <p className="mb-0">
                                    <i className="bx bx-stats font-16 align-middle me-1" />
                                    {val}
                                </p>
                            )
                        }) : name === 'Moments' ? moment.map(val => {
                            return (
                                <p className="mb-0">
                                    <i className="bx bx-stats font-16 align-middle me-1" />
                                    {val}
                                </p>
                            )
                        }) : ""
                    }
                </div>
            </div>
        </Popover>
    )

    const conversion = (duration) => {

        if (duration.includes(":")) {
            let hh = duration?.split(":")[0]
            let mm = duration?.split(":")[1]
            let ss = duration?.split(":")[2]

            let text = ""
            if (hh > 0) {
                text = `${hh} Hrs. ${mm} Min. ${ss} Sec.`
            }
            else if (mm > 0) {
                text = text + `${mm} Min. ${ss} Sec.`
            }
            else if (ss > 0) {
                text = text + `${ss} Sec.`
            }
            return text

        } else {
            let text = `${duration} mins`
            return text
        }
    }

    const callBack = (e) => {
        setCurrentPage(e)
    }

    // const filterCallList = (val) => {
    //     setFilterData(val);
    // }

    // const defaultConvFilter = (val) => {
    //     setDefaultData(val)
    // }

    const clickHandler = (id, rec, duration, date, empId, seller, customer, coeId, coe, phone, externalId, userId, name,
        // filename
    ) => {
        localStorage.setItem("URL", rec)
        history.push(`sales_call_detail?callId=${id}&name=${name}`, {
            callId: id,
            url: rec,
            duration: duration,
            date: date,
            id: empId,
            seller: seller,
            customer: customer,
            coeId: coeId,
            coe: coe,
            phone: phone,
            agentExternalId: externalId,
            userId: userId,
            callName: name,
            // fileName: filename
        })
    }

    console.log(detail,"jxbkbkakx")

    return (
        <>
            {listLoader ? (
                <div className="loader-container">
                    <Loading variant="dark" />
                </div>
            ) : (
                <>
                    {detail?.details?.length > 0 ? detail?.details?.map((d, val) => {
                        return <Card>
                            <Card.Body style={{ cursor: "default" }} className="salesCallCard">
                                <div className="user">
                                    <div className="caller">
                                        <div className="name">
                                            <span>
                                                <img src={user} alt="" />
                                            </span>
                                            <span className="text">{d?.customerName}</span>
                                        </div>
                                        <div className="rank" style={{ cursor: "default" }}>{`#${d?.callId}`}</div>
                                    </div>
                                    <div className="callCount">
                                        <div className="box">
                                            {d?.callAlarms?.length > 0 ? <OverlayTrigger trigger={['hover', 'focus']} placement="right" overlay={popoverr(d?.callAlarms, "Alarms", "")} rootClose>
                                                <button style={{ "border": "none", "backgroundColor": "white" }}>
                                                    <img src={alarm} alt="" />
                                                    <span>{d?.callAlarms?.length}</span>
                                                </button>
                                            </OverlayTrigger> :
                                                <>
                                                    <img src={alarm} alt="" />
                                                    <span>{d?.callAlarms?.length}</span>
                                                </>}

                                        </div>
                                        <div className="box">
                                            {d.callRec.length > 0 ? <OverlayTrigger trigger={['hover', 'focus']} placement="right" overlay={popoverr("", "Recommendations", d?.callRec)} rootClose>
                                                <button style={{ "border": "none", "backgroundColor": "white" }}>
                                                    <img src={bulb} alt="" />
                                                    <span>{d?.callRec?.length}</span>
                                                </button>
                                            </OverlayTrigger> :
                                                <>
                                                    <img src={bulb} alt="" />
                                                    <span>{d?.callRec?.length}</span>
                                                </>}
                                        </div>
                                        <div className="box">
                                            {d?.moments?.length > 0 ? <OverlayTrigger trigger={['hover', 'focus']} placement="right" overlay={popoverr("", "Moments", "", d?.moments)} rootClose>
                                                <button style={{ "border": "none", "backgroundColor": "white" }}>
                                                    <img src={moment} alt="" />
                                                    <span>{d?.moments?.length}</span>
                                                </button>
                                            </OverlayTrigger> :
                                                <>
                                                    <img src={moment} alt="" />
                                                    <span>{d?.moments?.length}</span>
                                                </>}
                                        </div>
                                    </div>
                                    <div className="score">
                                        {userDetail.includes("CALL SCORE") ? <div className="scoreCommonStyle callScore">
                                            <span className="text">CALL<br />SCORE</span>
                                            <span className="badge" style={{ background: d.callColour == 'Amber' ? "#FFBF00" : d?.callColour == null ? "Grey" : d?.callColour }}>{d?.callScore}%</span>
                                        </div> : ""}
                                    </div>
                                </div>
                                <hr />
                                <div className="seller">
                                    <div className="name">
                                        <span className="icon">
                                            <img src={seller} alt="" />
                                        </span>
                                        {`${d.sellerName}`}
                                    </div>
                                    <div className="number">
                                        <span className="icon">
                                            <img src={phone} alt="" />
                                        </span>
                                        {d.phone}
                                    </div>
                                    <div className="date">
                                        <span className="icon">
                                            <img src={calender} alt="" />
                                        </span>
                                        {d.date}
                                    </div>
                                    <div className="time">
                                        <span className="icon">
                                            <img src={time} alt="" />
                                        </span>
                                        {conversion(d?.talkDuration)}
                                    </div>
                                    <div style={{ width: '20%', textAlign: 'right' }}>
                                        <button className="groupCall" onClick={() => { clickHandler(d.callId, d.audioRecordingUrl, d.talkDuration, d.date, d.employeeId, d.sellerName, d.customerName, d.coeIds, d.coeName, d?.phone, d.userExternalId, d.userId, "SINGLE_CALL") }}>
                                            View Call
                                        </button>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    }) : <NodataFound id="salesColumn" />
                    }
                </>
            )}
            {/* <Filter
                componentType="Conversation-Sales"
                filterCallList={filterCallList}
                defaultConvFilter={defaultConvFilter}
                name={"SALES"}
                handleCallViewChange={props?.handleCallViewChange}
            /> */}
            {detail?.details?.length > 0 && detail?.availablePages > 0 ?
                <PaginationAlter
                    callBack={callBack}
                    availablePages={detail?.availablePages}
                    customPageNumber={(durationRange || callRange || redirected || competitorWords) ? 1 : ''}
                /> : ""
            }
        </>)
}

export default AgentCallDetail