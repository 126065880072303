import React from "react";
import {Link} from "react-router-dom";
import meetLogoIcon from "../../assets/images/meetLogoIcon.png"
import './profile.css';

export default function Integrations(){
return(
    <>
        <div className="mt-3">
            <div className="d-flex top-fixed-box">
                <div className=" mb-2 text-sm-left myTeamLHeader">
                    <h5 className="account-name account-id" >Integrations</h5>
                </div>
            </div>
            <div className="d-flex">
                <div>
                    <img src={meetLogoIcon} className="meet-ing-img p-1" alt="user avatar" />
                </div>
                <div className="ms-2 app-Integrations-content">
                    <h4 className="mb-0 font-16">Google Calendar</h4>
                    <p className="mb-1 font-14">Sync your google calendar to get meetings analysed.</p>
                    <button className="btn bg-medium-blue text-white font-14 px-5">Sync</button>
                </div>
            </div>
        </div>
    </>
)
}
