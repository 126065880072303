import React, { useEffect, useState } from "react";
import {Modal, Button} from "react-bootstrap";
import {toast} from 'react-toastify';
import profileService from "../../Services/profile.service";
import Loading from "../Loading";

export default function UserDetailModal({ lgShow, setLgShow, closeButton, managerRecords, managerId, managerFirstName}){
    return(
        <>
         <Modal size="lg" show={lgShow} onHide={closeButton} aria-labelledby="manager-modal">
            <Modal.Header closeButton>
                <Modal.Title id="manager-modal">
                    <div>
                        <h5 className="modal-title">{lgShow ? managerRecords.firstName:''} {lgShow ? managerRecords.lastName:'-'}</h5>
                        <div className="d-flex lh-1 align-items-center">
                            <div>
                                <p className="font-12 account-emp-id  mb-0 text-primary-gray text-start fw-normal">Employee Id: {lgShow ? managerRecords.employeeId:'-'}</p>
                            </div>
                               {
                                    lgShow ? managerRecords.caller ?   
                                     <span className="caller-badge btn-warning position-relative fw-normal ms-lg-1"> 
                                    <i className="bx bx-phone-call text-dark align-middle me-1"></i>Caller
                                    </span> : null :null
                                }
                        </div>
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="modalcard-body">
                    <div className="d-flex flex-column align-items-center text-center">
                        <div className="profile-letter">
                        <h3 className="mb-0">{lgShow ? (managerRecords.firstName).charAt(0) : ''}{lgShow ? (managerRecords.lastName).charAt(0) : '-'}</h3>
                        </div>
                        <div className="mt-2 mb-3">
                            <p className="text-secondary mb-1 account-email-id">{lgShow ? (managerRecords.email? managerRecords.email :"-"):null}</p>
                        </div>
                    </div>
                    <div className="row w-100 m-0">
                        <div className="col-6 ps-0">
                            <ul className="list-group list-group-flush account-list">
                                <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                                    <h6 className="mb-0 "><i className="lni lni-mobile"></i>Mobile Number</h6>
                                    <span className="text-secondary">
                                        {
                                             lgShow ?managerRecords.userPhoneNumbers.map((num)=>(  
                                                num.length !=0?
                                                (num.length == 2? num[1].mobileNumber:
                                                num.map(numList=>(
                                                      numList.type== "MOBILE" ?  numList.mobileNumber : '-'
                                                      
                                                )) ): '-'
                                             )) : '-'
                                        }
                                   </span> 
                                </li>
                                <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                                    <h6 className="mb-0"><i className="lni lni-phone-set"></i>Phone Number</h6>
                                    <span className="text-secondary ">
                                        {
                                            lgShow ? managerRecords.userPhoneNumbers.map((num)=>( 
                                                num.length !=0?(
                                                   num.length == 2? num[0].mobileNumber:
                                                   num.map(numList=>(  
                                                     numList.type== "LANDLINE" ?  numList.mobileNumber :'-'
                                                   ))) : '-'
                                                )) : '-'  
                                        }
                                   </span> 
                                </li>
                                <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                                    <h6 className="mb-0"><i className="lni lni-envelope"></i>Secondary Email </h6>
                                    <span className="text-secondary">
                                       {
                                            //  lgShow ?managerRecords.secEmail?  (managerRecords.secEmail.map((emailList)=>(
                                            //      emailList.map(secList=>(
                                            //         secList.email?secList.email:"NA"
                                            //      ))
                                                
                                            //  ))):'-' : '-'
                                            '-'
                                        }
                                 </span>
                                </li>
                            </ul>
                        </div>
                        <div className="col-6">
                            <ul className="list-group list-group-flush account-list">
                                <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                                    <h6 className="mb-0 "><i className="fadeIn animated bx bx-user-plus"></i>Manager</h6>
                                    <span className="text-secondary"> 
                                    { lgShow ? ( managerRecords.manager ? managerRecords.manager[0]:"-") : null }
                                    </span>
                                </li>
                                <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                                    <h6 className="mb-0"><i className="lni lni-briefcase"></i>Designation</h6>
                                    <span className="text-secondary">{lgShow ? (managerRecords.designation? managerRecords.designation:"-") : null}</span>
                                </li>
                                <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                                    <h6 className="mb-0">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                            stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-key key-role">
                                        <path d="M21 2l-2 2m-7.61 7.61a5.5 5.5 0 1 1-7.778 7.778 5.5 5.5 0 0 1 7.777-7.777zm0 0L15.5 7.5m0 0l3 3L22 7l-3-3m-3.5 3.5L19 4"></path>
                                        </svg>
                                        Role
                                    </h6>
                                    <span className="text-secondary">{lgShow ? (managerRecords.role? managerRecords.role : "-"):null}</span>
                                </li>
                            </ul>
                        </div>
                        <div className="col-12">
                            <ul className="list-group list-group-flush account-list mt-2 mx-auto w-50">
                            {
                                 lgShow ? managerRecords.coe.map((coeList)=>(
                                     coeList.map(coesList=>(
                                     <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                                         <h6 className="mb-0"><i className="lni lni-users"></i>COE </h6>
                                          <span className="text-secondary">{coesList.name ? coesList.name :"-"}</span>
                                     </li>
                                        ))
                                    )) : '-'
                            }                 
                            </ul>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" className="px-5" onClick={closeButton}>Close</Button>
            </Modal.Footer>
        </Modal> 
        
        </>
    )
}